import { addSeconds, format } from 'date-fns'

const formattedTime = (seconds: number) => {
  var helperDate = addSeconds(new Date(0), seconds);
  if (seconds >= 3600) {
    return format(helperDate, 'hh:mm:ss');
  } else {
    return format(helperDate, 'mm:ss');
  }
}

const arrayRemove = (arr: Array<any>, value: any) => {
  return arr.filter(function(ele){
    return ele !== value;
  });
}

const sortChapters = (selectedChapters: any) => {
  return selectedChapters.sort((chapter1: any, chapter2: any) => {
    if (chapter1.number > chapter2.number) return 1;
    if (chapter1.number < chapter2.number) return -1;
    if (chapter1.chapter > chapter2.chapter) return 1;
    if (chapter1.chapter < chapter2.chapter) return -1;
  });
}

export { formattedTime, arrayRemove, sortChapters };