import React, { useContext } from 'react';
import { IonButtons, IonHeader, IonPage, IonToolbar, IonTitle, IonContent, IonList, IonItem, IonLabel, IonButton, IonBadge, IonBackButton } from '@ionic/react';
import { AppContext } from '../../State';

const Books: React.FC = () => {
  const { state } = useContext(AppContext);
  const { displayTime, Bible } = state;
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>Select Passages</IonTitle>
          <IonButtons slot="end">
            <IonButton fill="solid" href="/player">
              {displayTime}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          { Bible.bookNames.map((name: any) => (
            <IonItem key={name} href={`/books/${name}`}>
              <IonLabel>{name}</IonLabel>
              {
                (Bible.bookSelectedChaptersCount[name] > 0) ?
                <IonBadge slot="end">{Bible.bookSelectedChaptersCount[name]}</IonBadge> : ""
              }
            </IonItem>
          )) }
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Books;