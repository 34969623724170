import React, { useContext } from 'react';
import { IonBackButton, IonButtons, IonHeader, IonPage, IonToolbar, IonTitle, IonContent, IonList, IonItem, IonLabel, IonCheckbox, IonButton } from '@ionic/react';
import { AppContext } from '../../State';
import { RouteComponentProps } from 'react-router';

interface BookPageProps extends RouteComponentProps<{
  book: string;
}> {}

const Book: React.FC<BookPageProps> = ({match: {params}}) => {
  const { state, dispatch } = useContext(AppContext);
  const { displayTime, Bible } = state;
  const book = Bible.books[params.book];
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/books" />
          </IonButtons>
          <IonTitle>{params.book}</IonTitle>
          <IonButtons slot="end">
            <IonButton fill="solid" href="/player">
              {displayTime}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          { book.map((chapter: any) => (
            <IonItem key={chapter.chapter}>
              <IonLabel>{chapter.book} {chapter.chapter}</IonLabel>
              <IonCheckbox
                slot="end"
                checked={chapter.selected ? true : false}
                onIonChange={event => {
                  dispatch({
                    type: 'setTime',
                    event: event,
                    chapter: chapter
                  })
                }
              }/>
            </IonItem>
          )) }
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Book;