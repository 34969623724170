import React from 'react';
import { IonSlides, IonSlide, IonContent, IonPage, IonButton } from '@ionic/react';
import './Home.css';

const slideOpts = {
  initialSlide: 0,
  speed: 400,
  setWrapperSize: true,
};

export const Home: React.FC = () => (
  <IonPage>
    <IonContent fullscreen scrollY={false}>
      <IonSlides pager={true} options={slideOpts}>
        <IonSlide>
          <img src="/assets/logo-monochrome.svg" alt="" />
          <h2>Welcome to <b>WWG</b></h2>
          <p>The first <b>Christian fitness app</b> is a practical preview of the ionic framework in action, and a demonstration of proper code use.</p>
          <IonButton slot="end" href="/books">
            Begin
          </IonButton>
        </IonSlide>
        <IonSlide>
          <h1>Slide 2</h1>
          <IonButton slot="end" href="/books">
            Begin
          </IonButton>
        </IonSlide>
        <IonSlide>
          <h1>Slide 3</h1>
          <IonButton slot="end" href="/books">
            Begin
          </IonButton>
        </IonSlide>
      </IonSlides>
    </IonContent>
  </IonPage>
);

export default Home;