import React, { createContext, useReducer } from "react";
import Bible from './Bible';
import { formattedTime, arrayRemove, sortChapters } from './Helpers';

import * as firebase from "firebase/app";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAmlL9h1oWouNoDlbb12MPpiOt7RW8AJus",
  authDomain: "walk-with-god.firebaseapp.com",
  databaseURL: "https://walk-with-god.firebaseio.com",
  projectId: "walk-with-god",
  storageBucket: "walk-with-god.appspot.com",
  messagingSenderId: "504456116573",
  appId: "1:504456116573:web:95d7175d5a427281c6d0b4",
  measurementId: "G-02FW6LXHG4"
};

firebase.initializeApp(firebaseConfig);

firebase.analytics().setAnalyticsCollectionEnabled(true);

type ContextProps = {
  state: any,
  dispatch: any,
};

let AppContext = createContext<Partial<ContextProps>>({});

const initialState = {
  Bible: Bible,
  seconds: 0,
  displayTime: "0:00",
  isPlaying: false,
  showLoading: false,
  bibles: [],
  volume: []
}

let reducer = (state: any, action: any) => {
  switch(action.type) {
    case "setTime": {
      let newSeconds;
      let selectedChapters: any[] = Bible.selectedChapters;
      if (action.event.detail.checked) {
        action.chapter.selected = true;
        state.Bible.bookSelectedChaptersCount[action.chapter.book]++;
        state.Bible.selectedChapters.push(action.chapter);
        newSeconds = state.seconds += action.chapter.length_in_seconds;
      } else {
        action.chapter.selected = false;
        state.Bible.bookSelectedChaptersCount[action.chapter.book]--;
        selectedChapters = arrayRemove(state.Bible.selectedChapters as Array<any>, action.chapter);
        newSeconds = state.seconds -= action.chapter.length_in_seconds;
      }
      selectedChapters = sortChapters(selectedChapters);
      const displayTime = formattedTime(newSeconds);
      return { 
        ...state,
        seconds: newSeconds,
        displayTime: displayTime,
        Bible: {
          ...state.Bible,
          selectedChapters: selectedChapters
        }
      };
    }
    case "updateDisplayTime": {
      return { ...state, displayTime: action.displayTime}
    }
    case "setShowLoading": {
      return { ...state, showLoading: action.showLoading}
    }
    case "setIsPlaying": {
      return { ...state, isPlaying: action.isPlaying}
    }
    case "updateSeconds": {
      return { ...state, seconds: action.seconds}
    }
    case "setBibles": {
      return { ...state, bibles: action.bibles}
    }
    case "setVolume": {
      return { ...state, volume: action.volume}
    }
  }
  return state;
};

function AppContextProvider(props: any) {
  const fullInitialState = {
    ...initialState
  }

  let [state, dispatch] = useReducer(reducer, fullInitialState);
  let value = { state, dispatch };

  // useEffect( () => {
  //   console.log(Object.values(state.Bible.selectedChapters))
  //   console.log('useEffect called');
  // }, Object.values(state.Bible.selectedChapters) )


  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
}

let AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer };