const Bible = {
  "books": {
    "Genesis": [
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 1,
        "length_in_seconds": 342,
        "length": "5:42",
        "file_name": "A01___01_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___01_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 2,
        "length_in_seconds": 255,
        "length": "4:15",
        "file_name": "A01___02_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___02_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 3,
        "length_in_seconds": 294,
        "length": "4:54",
        "file_name": "A01___03_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___03_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 4,
        "length_in_seconds": 268,
        "length": "4:28",
        "file_name": "A01___04_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___04_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 5,
        "length_in_seconds": 208,
        "length": "3:28",
        "file_name": "A01___05_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___05_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 6,
        "length_in_seconds": 222,
        "length": "3:42",
        "file_name": "A01___06_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___06_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 7,
        "length_in_seconds": 236,
        "length": "3:56",
        "file_name": "A01___07_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___07_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 8,
        "length_in_seconds": 225,
        "length": "3:45",
        "file_name": "A01___08_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___08_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 9,
        "length_in_seconds": 251,
        "length": "4:11",
        "file_name": "A01___09_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___09_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 10,
        "length_in_seconds": 226,
        "length": "3:46",
        "file_name": "A01___10_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___10_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 11,
        "length_in_seconds": 250,
        "length": "4:10",
        "file_name": "A01___11_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___11_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 12,
        "length_in_seconds": 194,
        "length": "3:14",
        "file_name": "A01___12_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___12_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 13,
        "length_in_seconds": 166,
        "length": "2:46",
        "file_name": "A01___13_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___13_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 14,
        "length_in_seconds": 249,
        "length": "4:09",
        "file_name": "A01___14_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___14_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 15,
        "length_in_seconds": 185,
        "length": "3:05",
        "file_name": "A01___15_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___15_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 16,
        "length_in_seconds": 179,
        "length": "2:59",
        "file_name": "A01___16_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___16_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 17,
        "length_in_seconds": 274,
        "length": "4:34",
        "file_name": "A01___17_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___17_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 18,
        "length_in_seconds": 344,
        "length": "5:44",
        "file_name": "A01___18_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___18_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 19,
        "length_in_seconds": 387,
        "length": "6:27",
        "file_name": "A01___19_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___19_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 20,
        "length_in_seconds": 198,
        "length": "3:18",
        "file_name": "A01___20_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___20_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 21,
        "length_in_seconds": 295,
        "length": "4:55",
        "file_name": "A01___21_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___21_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 22,
        "length_in_seconds": 249,
        "length": "4:09",
        "file_name": "A01___22_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___22_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 23,
        "length_in_seconds": 195,
        "length": "3:15",
        "file_name": "A01___23_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___23_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 24,
        "length_in_seconds": 691,
        "length": "11:31",
        "file_name": "A01___24_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___24_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 25,
        "length_in_seconds": 301,
        "length": "5:01",
        "file_name": "A01___25_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___25_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 26,
        "length_in_seconds": 326,
        "length": "5:26",
        "file_name": "A01___26_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___26_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 27,
        "length_in_seconds": 515,
        "length": "8:35",
        "file_name": "A01___27_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___27_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 28,
        "length_in_seconds": 230,
        "length": "3:50",
        "file_name": "A01___28_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___28_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 29,
        "length_in_seconds": 323,
        "length": "5:23",
        "file_name": "A01___29_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___29_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 30,
        "length_in_seconds": 396,
        "length": "6:36",
        "file_name": "A01___30_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___30_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 31,
        "length_in_seconds": 544,
        "length": "9:04",
        "file_name": "A01___31_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___31_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 32,
        "length_in_seconds": 304,
        "length": "5:04",
        "file_name": "A01___32_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___32_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 33,
        "length_in_seconds": 200,
        "length": "3:20",
        "file_name": "A01___33_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___33_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 34,
        "length_in_seconds": 281,
        "length": "4:41",
        "file_name": "A01___34_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___34_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 35,
        "length_in_seconds": 265,
        "length": "4:25",
        "file_name": "A01___35_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___35_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 36,
        "length_in_seconds": 362,
        "length": "6:02",
        "file_name": "A01___36_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___36_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 37,
        "length_in_seconds": 349,
        "length": "5:49",
        "file_name": "A01___37_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___37_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 38,
        "length_in_seconds": 308,
        "length": "5:08",
        "file_name": "A01___38_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___38_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 39,
        "length_in_seconds": 233,
        "length": "3:53",
        "file_name": "A01___39_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___39_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 40,
        "length_in_seconds": 204,
        "length": "3:24",
        "file_name": "A01___40_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___40_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 41,
        "length_in_seconds": 522,
        "length": "8:42",
        "file_name": "A01___41_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___41_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 42,
        "length_in_seconds": 377,
        "length": "6:17",
        "file_name": "A01___42_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___42_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 43,
        "length_in_seconds": 352,
        "length": "5:52",
        "file_name": "A01___43_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___43_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 44,
        "length_in_seconds": 309,
        "length": "5:09",
        "file_name": "A01___44_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___44_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 45,
        "length_in_seconds": 284,
        "length": "4:44",
        "file_name": "A01___45_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___45_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 46,
        "length_in_seconds": 324,
        "length": "5:24",
        "file_name": "A01___46_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___46_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 47,
        "length_in_seconds": 385,
        "length": "6:25",
        "file_name": "A01___47_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___47_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 48,
        "length_in_seconds": 372,
        "length": "6:12",
        "file_name": "A01___48_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___48_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 49,
        "length_in_seconds": 540,
        "length": "9:00",
        "file_name": "A01___49_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___49_Genesis_____ENGESVO2DA.mp3"
      },
      {
        "book": "Genesis",
        "number": 1,
        "chapter": 50,
        "length_in_seconds": 286,
        "length": "4:46",
        "file_name": "A01___50_Genesis_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A01___50_Genesis_____ENGESVO2DA.mp3"
      }
    ],
    "Exodus": [
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 1,
        "length_in_seconds": 179,
        "length": "2:59",
        "file_name": "A02___01_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___01_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 2,
        "length_in_seconds": 232,
        "length": "3:52",
        "file_name": "A02___02_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___02_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 3,
        "length_in_seconds": 284,
        "length": "4:44",
        "file_name": "A02___03_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___03_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 4,
        "length_in_seconds": 315,
        "length": "5:15",
        "file_name": "A02___04_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___04_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 5,
        "length_in_seconds": 234,
        "length": "3:54",
        "file_name": "A02___05_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___05_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 6,
        "length_in_seconds": 311,
        "length": "5:11",
        "file_name": "A02___06_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___06_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 7,
        "length_in_seconds": 246,
        "length": "4:06",
        "file_name": "A02___07_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___07_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 8,
        "length_in_seconds": 352,
        "length": "5:52",
        "file_name": "A02___08_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___08_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 9,
        "length_in_seconds": 355,
        "length": "5:55",
        "file_name": "A02___09_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___09_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 10,
        "length_in_seconds": 330,
        "length": "5:30",
        "file_name": "A02___10_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___10_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 11,
        "length_in_seconds": 129,
        "length": "2:09",
        "file_name": "A02___11_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___11_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 12,
        "length_in_seconds": 511,
        "length": "8:31",
        "file_name": "A02___12_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___12_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 13,
        "length_in_seconds": 235,
        "length": "3:55",
        "file_name": "A02___13_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___13_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 14,
        "length_in_seconds": 339,
        "length": "5:39",
        "file_name": "A02___14_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___14_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 15,
        "length_in_seconds": 264,
        "length": "4:24",
        "file_name": "A02___15_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___15_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 16,
        "length_in_seconds": 373,
        "length": "6:13",
        "file_name": "A02___16_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___16_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 17,
        "length_in_seconds": 178,
        "length": "2:58",
        "file_name": "A02___17_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___17_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 18,
        "length_in_seconds": 278,
        "length": "4:38",
        "file_name": "A02___18_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___18_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 19,
        "length_in_seconds": 251,
        "length": "4:11",
        "file_name": "A02___19_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___19_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 20,
        "length_in_seconds": 214,
        "length": "3:34",
        "file_name": "A02___20_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___20_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 21,
        "length_in_seconds": 305,
        "length": "5:05",
        "file_name": "A02___21_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___21_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 22,
        "length_in_seconds": 270,
        "length": "4:30",
        "file_name": "A02___22_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___22_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 23,
        "length_in_seconds": 295,
        "length": "4:55",
        "file_name": "A02___23_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___23_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 24,
        "length_in_seconds": 184,
        "length": "3:04",
        "file_name": "A02___24_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___24_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 25,
        "length_in_seconds": 294,
        "length": "4:54",
        "file_name": "A02___25_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___25_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 26,
        "length_in_seconds": 303,
        "length": "5:03",
        "file_name": "A02___26_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___26_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 27,
        "length_in_seconds": 190,
        "length": "3:10",
        "file_name": "A02___27_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___27_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 28,
        "length_in_seconds": 395,
        "length": "6:35",
        "file_name": "A02___28_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___28_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 29,
        "length_in_seconds": 423,
        "length": "7:03",
        "file_name": "A02___29_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___29_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 30,
        "length_in_seconds": 319,
        "length": "5:19",
        "file_name": "A02___30_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___30_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 31,
        "length_in_seconds": 155,
        "length": "2:35",
        "file_name": "A02___31_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___31_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 32,
        "length_in_seconds": 407,
        "length": "6:47",
        "file_name": "A02___32_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___32_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 33,
        "length_in_seconds": 258,
        "length": "4:18",
        "file_name": "A02___33_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___33_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 34,
        "length_in_seconds": 369,
        "length": "6:09",
        "file_name": "A02___34_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___34_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 35,
        "length_in_seconds": 298,
        "length": "4:58",
        "file_name": "A02___35_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___35_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 36,
        "length_in_seconds": 333,
        "length": "5:33",
        "file_name": "A02___36_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___36_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 37,
        "length_in_seconds": 260,
        "length": "4:20",
        "file_name": "A02___37_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___37_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 38,
        "length_in_seconds": 314,
        "length": "5:14",
        "file_name": "A02___38_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___38_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 39,
        "length_in_seconds": 403,
        "length": "6:43",
        "file_name": "A02___39_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___39_Exodus______ENGESVO2DA.mp3"
      },
      {
        "book": "Exodus",
        "number": 2,
        "chapter": 40,
        "length_in_seconds": 292,
        "length": "4:52",
        "file_name": "A02___40_Exodus______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A02___40_Exodus______ENGESVO2DA.mp3"
      }
    ],
    "Leviticus": [
      {
        "book": "Leviticus",
        "number": 3,
        "chapter": 1,
        "length_in_seconds": 159,
        "length": "2:39",
        "file_name": "A03___01_Leviticus___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A03___01_Leviticus___ENGESVO2DA.mp3"
      },
      {
        "book": "Leviticus",
        "number": 3,
        "chapter": 2,
        "length_in_seconds": 152,
        "length": "2:32",
        "file_name": "A03___02_Leviticus___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A03___02_Leviticus___ENGESVO2DA.mp3"
      },
      {
        "book": "Leviticus",
        "number": 3,
        "chapter": 3,
        "length_in_seconds": 153,
        "length": "2:33",
        "file_name": "A03___03_Leviticus___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A03___03_Leviticus___ENGESVO2DA.mp3"
      },
      {
        "book": "Leviticus",
        "number": 3,
        "chapter": 4,
        "length_in_seconds": 336,
        "length": "5:36",
        "file_name": "A03___04_Leviticus___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A03___04_Leviticus___ENGESVO2DA.mp3"
      },
      {
        "book": "Leviticus",
        "number": 3,
        "chapter": 5,
        "length_in_seconds": 231,
        "length": "3:51",
        "file_name": "A03___05_Leviticus___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A03___05_Leviticus___ENGESVO2DA.mp3"
      },
      {
        "book": "Leviticus",
        "number": 3,
        "chapter": 6,
        "length_in_seconds": 295,
        "length": "4:55",
        "file_name": "A03___06_Leviticus___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A03___06_Leviticus___ENGESVO2DA.mp3"
      },
      {
        "book": "Leviticus",
        "number": 3,
        "chapter": 7,
        "length_in_seconds": 341,
        "length": "5:41",
        "file_name": "A03___07_Leviticus___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A03___07_Leviticus___ENGESVO2DA.mp3"
      },
      {
        "book": "Leviticus",
        "number": 3,
        "chapter": 8,
        "length_in_seconds": 359,
        "length": "5:59",
        "file_name": "A03___08_Leviticus___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A03___08_Leviticus___ENGESVO2DA.mp3"
      },
      {
        "book": "Leviticus",
        "number": 3,
        "chapter": 9,
        "length_in_seconds": 226,
        "length": "3:46",
        "file_name": "A03___09_Leviticus___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A03___09_Leviticus___ENGESVO2DA.mp3"
      },
      {
        "book": "Leviticus",
        "number": 3,
        "chapter": 10,
        "length_in_seconds": 248,
        "length": "4:08",
        "file_name": "A03___10_Leviticus___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A03___10_Leviticus___ENGESVO2DA.mp3"
      },
      {
        "book": "Leviticus",
        "number": 3,
        "chapter": 11,
        "length_in_seconds": 380,
        "length": "6:20",
        "file_name": "A03___11_Leviticus___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A03___11_Leviticus___ENGESVO2DA.mp3"
      },
      {
        "book": "Leviticus",
        "number": 3,
        "chapter": 12,
        "length_in_seconds": 91,
        "length": "1:31",
        "file_name": "A03___12_Leviticus___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A03___12_Leviticus___ENGESVO2DA.mp3"
      },
      {
        "book": "Leviticus",
        "number": 3,
        "chapter": 13,
        "length_in_seconds": 598,
        "length": "9:58",
        "file_name": "A03___13_Leviticus___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A03___13_Leviticus___ENGESVO2DA.mp3"
      },
      {
        "book": "Leviticus",
        "number": 3,
        "chapter": 14,
        "length_in_seconds": 540,
        "length": "9:00",
        "file_name": "A03___14_Leviticus___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A03___14_Leviticus___ENGESVO2DA.mp3"
      },
      {
        "book": "Leviticus",
        "number": 3,
        "chapter": 15,
        "length_in_seconds": 349,
        "length": "5:49",
        "file_name": "A03___15_Leviticus___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A03___15_Leviticus___ENGESVO2DA.mp3"
      },
      {
        "book": "Leviticus",
        "number": 3,
        "chapter": 16,
        "length_in_seconds": 380,
        "length": "6:20",
        "file_name": "A03___16_Leviticus___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A03___16_Leviticus___ENGESVO2DA.mp3"
      },
      {
        "book": "Leviticus",
        "number": 3,
        "chapter": 17,
        "length_in_seconds": 187,
        "length": "3:07",
        "file_name": "A03___17_Leviticus___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A03___17_Leviticus___ENGESVO2DA.mp3"
      },
      {
        "book": "Leviticus",
        "number": 3,
        "chapter": 18,
        "length_in_seconds": 248,
        "length": "4:08",
        "file_name": "A03___18_Leviticus___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A03___18_Leviticus___ENGESVO2DA.mp3"
      },
      {
        "book": "Leviticus",
        "number": 3,
        "chapter": 19,
        "length_in_seconds": 328,
        "length": "5:28",
        "file_name": "A03___19_Leviticus___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A03___19_Leviticus___ENGESVO2DA.mp3"
      },
      {
        "book": "Leviticus",
        "number": 3,
        "chapter": 20,
        "length_in_seconds": 294,
        "length": "4:54",
        "file_name": "A03___20_Leviticus___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A03___20_Leviticus___ENGESVO2DA.mp3"
      },
      {
        "book": "Leviticus",
        "number": 3,
        "chapter": 21,
        "length_in_seconds": 212,
        "length": "3:32",
        "file_name": "A03___21_Leviticus___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A03___21_Leviticus___ENGESVO2DA.mp3"
      },
      {
        "book": "Leviticus",
        "number": 3,
        "chapter": 22,
        "length_in_seconds": 308,
        "length": "5:08",
        "file_name": "A03___22_Leviticus___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A03___22_Leviticus___ENGESVO2DA.mp3"
      },
      {
        "book": "Leviticus",
        "number": 3,
        "chapter": 23,
        "length_in_seconds": 424,
        "length": "7:04",
        "file_name": "A03___23_Leviticus___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A03___23_Leviticus___ENGESVO2DA.mp3"
      },
      {
        "book": "Leviticus",
        "number": 3,
        "chapter": 24,
        "length_in_seconds": 190,
        "length": "3:10",
        "file_name": "A03___24_Leviticus___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A03___24_Leviticus___ENGESVO2DA.mp3"
      },
      {
        "book": "Leviticus",
        "number": 3,
        "chapter": 25,
        "length_in_seconds": 489,
        "length": "8:09",
        "file_name": "A03___25_Leviticus___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A03___25_Leviticus___ENGESVO2DA.mp3"
      },
      {
        "book": "Leviticus",
        "number": 3,
        "chapter": 26,
        "length_in_seconds": 426,
        "length": "7:06",
        "file_name": "A03___26_Leviticus___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A03___26_Leviticus___ENGESVO2DA.mp3"
      },
      {
        "book": "Leviticus",
        "number": 3,
        "chapter": 27,
        "length_in_seconds": 319,
        "length": "5:19",
        "file_name": "A03___27_Leviticus___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A03___27_Leviticus___ENGESVO2DA.mp3"
      }
    ],
    "Numbers": [
      {
        "book": "Numbers",
        "number": 4,
        "chapter": 1,
        "length_in_seconds": 493,
        "length": "8:13",
        "file_name": "A04___01_Numbers_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A04___01_Numbers_____ENGESVO2DA.mp3"
      },
      {
        "book": "Numbers",
        "number": 4,
        "chapter": 2,
        "length_in_seconds": 288,
        "length": "4:48",
        "file_name": "A04___02_Numbers_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A04___02_Numbers_____ENGESVO2DA.mp3"
      },
      {
        "book": "Numbers",
        "number": 4,
        "chapter": 3,
        "length_in_seconds": 476,
        "length": "7:56",
        "file_name": "A04___03_Numbers_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A04___03_Numbers_____ENGESVO2DA.mp3"
      },
      {
        "book": "Numbers",
        "number": 4,
        "chapter": 4,
        "length_in_seconds": 448,
        "length": "7:28",
        "file_name": "A04___04_Numbers_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A04___04_Numbers_____ENGESVO2DA.mp3"
      },
      {
        "book": "Numbers",
        "number": 4,
        "chapter": 5,
        "length_in_seconds": 316,
        "length": "5:16",
        "file_name": "A04___05_Numbers_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A04___05_Numbers_____ENGESVO2DA.mp3"
      },
      {
        "book": "Numbers",
        "number": 4,
        "chapter": 6,
        "length_in_seconds": 257,
        "length": "4:17",
        "file_name": "A04___06_Numbers_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A04___06_Numbers_____ENGESVO2DA.mp3"
      },
      {
        "book": "Numbers",
        "number": 4,
        "chapter": 7,
        "length_in_seconds": 749,
        "length": "12:29",
        "file_name": "A04___07_Numbers_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A04___07_Numbers_____ENGESVO2DA.mp3"
      },
      {
        "book": "Numbers",
        "number": 4,
        "chapter": 8,
        "length_in_seconds": 237,
        "length": "3:57",
        "file_name": "A04___08_Numbers_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A04___08_Numbers_____ENGESVO2DA.mp3"
      },
      {
        "book": "Numbers",
        "number": 4,
        "chapter": 9,
        "length_in_seconds": 247,
        "length": "4:07",
        "file_name": "A04___09_Numbers_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A04___09_Numbers_____ENGESVO2DA.mp3"
      },
      {
        "book": "Numbers",
        "number": 4,
        "chapter": 10,
        "length_in_seconds": 311,
        "length": "5:11",
        "file_name": "A04___10_Numbers_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A04___10_Numbers_____ENGESVO2DA.mp3"
      },
      {
        "book": "Numbers",
        "number": 4,
        "chapter": 11,
        "length_in_seconds": 382,
        "length": "6:22",
        "file_name": "A04___11_Numbers_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A04___11_Numbers_____ENGESVO2DA.mp3"
      },
      {
        "book": "Numbers",
        "number": 4,
        "chapter": 12,
        "length_in_seconds": 152,
        "length": "2:32",
        "file_name": "A04___12_Numbers_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A04___12_Numbers_____ENGESVO2DA.mp3"
      },
      {
        "book": "Numbers",
        "number": 4,
        "chapter": 13,
        "length_in_seconds": 280,
        "length": "4:40",
        "file_name": "A04___13_Numbers_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A04___13_Numbers_____ENGESVO2DA.mp3"
      },
      {
        "book": "Numbers",
        "number": 4,
        "chapter": 14,
        "length_in_seconds": 430,
        "length": "7:10",
        "file_name": "A04___14_Numbers_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A04___14_Numbers_____ENGESVO2DA.mp3"
      },
      {
        "book": "Numbers",
        "number": 4,
        "chapter": 15,
        "length_in_seconds": 355,
        "length": "5:55",
        "file_name": "A04___15_Numbers_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A04___15_Numbers_____ENGESVO2DA.mp3"
      },
      {
        "book": "Numbers",
        "number": 4,
        "chapter": 16,
        "length_in_seconds": 511,
        "length": "8:31",
        "file_name": "A04___16_Numbers_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A04___16_Numbers_____ENGESVO2DA.mp3"
      },
      {
        "book": "Numbers",
        "number": 4,
        "chapter": 17,
        "length_in_seconds": 129,
        "length": "2:09",
        "file_name": "A04___17_Numbers_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A04___17_Numbers_____ENGESVO2DA.mp3"
      },
      {
        "book": "Numbers",
        "number": 4,
        "chapter": 18,
        "length_in_seconds": 368,
        "length": "6:08",
        "file_name": "A04___18_Numbers_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A04___18_Numbers_____ENGESVO2DA.mp3"
      },
      {
        "book": "Numbers",
        "number": 4,
        "chapter": 19,
        "length_in_seconds": 230,
        "length": "3:50",
        "file_name": "A04___19_Numbers_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A04___19_Numbers_____ENGESVO2DA.mp3"
      },
      {
        "book": "Numbers",
        "number": 4,
        "chapter": 20,
        "length_in_seconds": 295,
        "length": "4:55",
        "file_name": "A04___20_Numbers_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A04___20_Numbers_____ENGESVO2DA.mp3"
      },
      {
        "book": "Numbers",
        "number": 4,
        "chapter": 21,
        "length_in_seconds": 352,
        "length": "5:52",
        "file_name": "A04___21_Numbers_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A04___21_Numbers_____ENGESVO2DA.mp3"
      },
      {
        "book": "Numbers",
        "number": 4,
        "chapter": 22,
        "length_in_seconds": 441,
        "length": "7:21",
        "file_name": "A04___22_Numbers_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A04___22_Numbers_____ENGESVO2DA.mp3"
      },
      {
        "book": "Numbers",
        "number": 4,
        "chapter": 23,
        "length_in_seconds": 332,
        "length": "5:32",
        "file_name": "A04___23_Numbers_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A04___23_Numbers_____ENGESVO2DA.mp3"
      },
      {
        "book": "Numbers",
        "number": 4,
        "chapter": 24,
        "length_in_seconds": 293,
        "length": "4:53",
        "file_name": "A04___24_Numbers_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A04___24_Numbers_____ENGESVO2DA.mp3"
      },
      {
        "book": "Numbers",
        "number": 4,
        "chapter": 25,
        "length_in_seconds": 174,
        "length": "2:54",
        "file_name": "A04___25_Numbers_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A04___25_Numbers_____ENGESVO2DA.mp3"
      },
      {
        "book": "Numbers",
        "number": 4,
        "chapter": 26,
        "length_in_seconds": 580,
        "length": "9:40",
        "file_name": "A04___26_Numbers_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A04___26_Numbers_____ENGESVO2DA.mp3"
      },
      {
        "book": "Numbers",
        "number": 4,
        "chapter": 27,
        "length_in_seconds": 218,
        "length": "3:38",
        "file_name": "A04___27_Numbers_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A04___27_Numbers_____ENGESVO2DA.mp3"
      },
      {
        "book": "Numbers",
        "number": 4,
        "chapter": 28,
        "length_in_seconds": 248,
        "length": "4:08",
        "file_name": "A04___28_Numbers_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A04___28_Numbers_____ENGESVO2DA.mp3"
      },
      {
        "book": "Numbers",
        "number": 4,
        "chapter": 29,
        "length_in_seconds": 299,
        "length": "4:59",
        "file_name": "A04___29_Numbers_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A04___29_Numbers_____ENGESVO2DA.mp3"
      },
      {
        "book": "Numbers",
        "number": 4,
        "chapter": 30,
        "length_in_seconds": 175,
        "length": "2:55",
        "file_name": "A04___30_Numbers_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A04___30_Numbers_____ENGESVO2DA.mp3"
      },
      {
        "book": "Numbers",
        "number": 4,
        "chapter": 31,
        "length_in_seconds": 443,
        "length": "7:23",
        "file_name": "A04___31_Numbers_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A04___31_Numbers_____ENGESVO2DA.mp3"
      },
      {
        "book": "Numbers",
        "number": 4,
        "chapter": 32,
        "length_in_seconds": 369,
        "length": "6:09",
        "file_name": "A04___32_Numbers_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A04___32_Numbers_____ENGESVO2DA.mp3"
      },
      {
        "book": "Numbers",
        "number": 4,
        "chapter": 33,
        "length_in_seconds": 383,
        "length": "6:23",
        "file_name": "A04___33_Numbers_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A04___33_Numbers_____ENGESVO2DA.mp3"
      },
      {
        "book": "Numbers",
        "number": 4,
        "chapter": 34,
        "length_in_seconds": 225,
        "length": "3:45",
        "file_name": "A04___34_Numbers_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A04___34_Numbers_____ENGESVO2DA.mp3"
      },
      {
        "book": "Numbers",
        "number": 4,
        "chapter": 35,
        "length_in_seconds": 319,
        "length": "5:19",
        "file_name": "A04___35_Numbers_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A04___35_Numbers_____ENGESVO2DA.mp3"
      },
      {
        "book": "Numbers",
        "number": 4,
        "chapter": 36,
        "length_in_seconds": 166,
        "length": "2:46",
        "file_name": "A04___36_Numbers_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A04___36_Numbers_____ENGESVO2DA.mp3"
      }
    ],
    "Deuteronomy": [
      {
        "book": "Deuteronomy",
        "number": 5,
        "chapter": 1,
        "length_in_seconds": 433,
        "length": "7:13",
        "file_name": "A05___01_Deuteronomy_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A05___01_Deuteronomy_ENGESVO2DA.mp3"
      },
      {
        "book": "Deuteronomy",
        "number": 5,
        "chapter": 2,
        "length_in_seconds": 360,
        "length": "6:00",
        "file_name": "A05___02_Deuteronomy_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A05___02_Deuteronomy_ENGESVO2DA.mp3"
      },
      {
        "book": "Deuteronomy",
        "number": 5,
        "chapter": 3,
        "length_in_seconds": 303,
        "length": "5:03",
        "file_name": "A05___03_Deuteronomy_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A05___03_Deuteronomy_ENGESVO2DA.mp3"
      },
      {
        "book": "Deuteronomy",
        "number": 5,
        "chapter": 4,
        "length_in_seconds": 520,
        "length": "8:40",
        "file_name": "A05___04_Deuteronomy_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A05___04_Deuteronomy_ENGESVO2DA.mp3"
      },
      {
        "book": "Deuteronomy",
        "number": 5,
        "chapter": 5,
        "length_in_seconds": 297,
        "length": "4:57",
        "file_name": "A05___05_Deuteronomy_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A05___05_Deuteronomy_ENGESVO2DA.mp3"
      },
      {
        "book": "Deuteronomy",
        "number": 5,
        "chapter": 6,
        "length_in_seconds": 223,
        "length": "3:43",
        "file_name": "A05___06_Deuteronomy_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A05___06_Deuteronomy_ENGESVO2DA.mp3"
      },
      {
        "book": "Deuteronomy",
        "number": 5,
        "chapter": 7,
        "length_in_seconds": 286,
        "length": "4:46",
        "file_name": "A05___07_Deuteronomy_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A05___07_Deuteronomy_ENGESVO2DA.mp3"
      },
      {
        "book": "Deuteronomy",
        "number": 5,
        "chapter": 8,
        "length_in_seconds": 185,
        "length": "3:05",
        "file_name": "A05___08_Deuteronomy_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A05___08_Deuteronomy_ENGESVO2DA.mp3"
      },
      {
        "book": "Deuteronomy",
        "number": 5,
        "chapter": 9,
        "length_in_seconds": 334,
        "length": "5:34",
        "file_name": "A05___09_Deuteronomy_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A05___09_Deuteronomy_ENGESVO2DA.mp3"
      },
      {
        "book": "Deuteronomy",
        "number": 5,
        "chapter": 10,
        "length_in_seconds": 214,
        "length": "3:34",
        "file_name": "A05___10_Deuteronomy_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A05___10_Deuteronomy_ENGESVO2DA.mp3"
      },
      {
        "book": "Deuteronomy",
        "number": 5,
        "chapter": 11,
        "length_in_seconds": 302,
        "length": "5:02",
        "file_name": "A05___11_Deuteronomy_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A05___11_Deuteronomy_ENGESVO2DA.mp3"
      },
      {
        "book": "Deuteronomy",
        "number": 5,
        "chapter": 12,
        "length_in_seconds": 339,
        "length": "5:39",
        "file_name": "A05___12_Deuteronomy_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A05___12_Deuteronomy_ENGESVO2DA.mp3"
      },
      {
        "book": "Deuteronomy",
        "number": 5,
        "chapter": 13,
        "length_in_seconds": 208,
        "length": "3:28",
        "file_name": "A05___13_Deuteronomy_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A05___13_Deuteronomy_ENGESVO2DA.mp3"
      },
      {
        "book": "Deuteronomy",
        "number": 5,
        "chapter": 14,
        "length_in_seconds": 223,
        "length": "3:43",
        "file_name": "A05___14_Deuteronomy_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A05___14_Deuteronomy_ENGESVO2DA.mp3"
      },
      {
        "book": "Deuteronomy",
        "number": 5,
        "chapter": 15,
        "length_in_seconds": 232,
        "length": "3:52",
        "file_name": "A05___15_Deuteronomy_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A05___15_Deuteronomy_ENGESVO2DA.mp3"
      },
      {
        "book": "Deuteronomy",
        "number": 5,
        "chapter": 16,
        "length_in_seconds": 241,
        "length": "4:01",
        "file_name": "A05___16_Deuteronomy_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A05___16_Deuteronomy_ENGESVO2DA.mp3"
      },
      {
        "book": "Deuteronomy",
        "number": 5,
        "chapter": 17,
        "length_in_seconds": 238,
        "length": "3:58",
        "file_name": "A05___17_Deuteronomy_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A05___17_Deuteronomy_ENGESVO2DA.mp3"
      },
      {
        "book": "Deuteronomy",
        "number": 5,
        "chapter": 18,
        "length_in_seconds": 208,
        "length": "3:28",
        "file_name": "A05___18_Deuteronomy_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A05___18_Deuteronomy_ENGESVO2DA.mp3"
      },
      {
        "book": "Deuteronomy",
        "number": 5,
        "chapter": 19,
        "length_in_seconds": 202,
        "length": "3:22",
        "file_name": "A05___19_Deuteronomy_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A05___19_Deuteronomy_ENGESVO2DA.mp3"
      },
      {
        "book": "Deuteronomy",
        "number": 5,
        "chapter": 20,
        "length_in_seconds": 218,
        "length": "3:38",
        "file_name": "A05___20_Deuteronomy_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A05___20_Deuteronomy_ENGESVO2DA.mp3"
      },
      {
        "book": "Deuteronomy",
        "number": 5,
        "chapter": 21,
        "length_in_seconds": 249,
        "length": "4:09",
        "file_name": "A05___21_Deuteronomy_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A05___21_Deuteronomy_ENGESVO2DA.mp3"
      },
      {
        "book": "Deuteronomy",
        "number": 5,
        "chapter": 22,
        "length_in_seconds": 304,
        "length": "5:04",
        "file_name": "A05___22_Deuteronomy_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A05___22_Deuteronomy_ENGESVO2DA.mp3"
      },
      {
        "book": "Deuteronomy",
        "number": 5,
        "chapter": 23,
        "length_in_seconds": 237,
        "length": "3:57",
        "file_name": "A05___23_Deuteronomy_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A05___23_Deuteronomy_ENGESVO2DA.mp3"
      },
      {
        "book": "Deuteronomy",
        "number": 5,
        "chapter": 24,
        "length_in_seconds": 212,
        "length": "3:32",
        "file_name": "A05___24_Deuteronomy_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A05___24_Deuteronomy_ENGESVO2DA.mp3"
      },
      {
        "book": "Deuteronomy",
        "number": 5,
        "chapter": 25,
        "length_in_seconds": 184,
        "length": "3:04",
        "file_name": "A05___25_Deuteronomy_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A05___25_Deuteronomy_ENGESVO2DA.mp3"
      },
      {
        "book": "Deuteronomy",
        "number": 5,
        "chapter": 26,
        "length_in_seconds": 214,
        "length": "3:34",
        "file_name": "A05___26_Deuteronomy_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A05___26_Deuteronomy_ENGESVO2DA.mp3"
      },
      {
        "book": "Deuteronomy",
        "number": 5,
        "chapter": 27,
        "length_in_seconds": 258,
        "length": "4:18",
        "file_name": "A05___27_Deuteronomy_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A05___27_Deuteronomy_ENGESVO2DA.mp3"
      },
      {
        "book": "Deuteronomy",
        "number": 5,
        "chapter": 28,
        "length_in_seconds": 691,
        "length": "11:31",
        "file_name": "A05___28_Deuteronomy_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A05___28_Deuteronomy_ENGESVO2DA.mp3"
      },
      {
        "book": "Deuteronomy",
        "number": 5,
        "chapter": 29,
        "length_in_seconds": 301,
        "length": "5:01",
        "file_name": "A05___29_Deuteronomy_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A05___29_Deuteronomy_ENGESVO2DA.mp3"
      },
      {
        "book": "Deuteronomy",
        "number": 5,
        "chapter": 30,
        "length_in_seconds": 210,
        "length": "3:30",
        "file_name": "A05___30_Deuteronomy_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A05___30_Deuteronomy_ENGESVO2DA.mp3"
      },
      {
        "book": "Deuteronomy",
        "number": 5,
        "chapter": 31,
        "length_in_seconds": 356,
        "length": "5:56",
        "file_name": "A05___31_Deuteronomy_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A05___31_Deuteronomy_ENGESVO2DA.mp3"
      },
      {
        "book": "Deuteronomy",
        "number": 5,
        "chapter": 32,
        "length_in_seconds": 475,
        "length": "7:55",
        "file_name": "A05___32_Deuteronomy_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A05___32_Deuteronomy_ENGESVO2DA.mp3"
      },
      {
        "book": "Deuteronomy",
        "number": 5,
        "chapter": 33,
        "length_in_seconds": 329,
        "length": "5:29",
        "file_name": "A05___33_Deuteronomy_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A05___33_Deuteronomy_ENGESVO2DA.mp3"
      },
      {
        "book": "Deuteronomy",
        "number": 5,
        "chapter": 34,
        "length_in_seconds": 133,
        "length": "2:13",
        "file_name": "A05___34_Deuteronomy_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A05___34_Deuteronomy_ENGESVO2DA.mp3"
      }
    ],
    "Joshua": [
      {
        "book": "Joshua",
        "number": 6,
        "chapter": 1,
        "length_in_seconds": 200,
        "length": "3:20",
        "file_name": "A06___01_Joshua______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A06___01_Joshua______ENGESVO2DA.mp3"
      },
      {
        "book": "Joshua",
        "number": 6,
        "chapter": 2,
        "length_in_seconds": 237,
        "length": "3:57",
        "file_name": "A06___02_Joshua______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A06___02_Joshua______ENGESVO2DA.mp3"
      },
      {
        "book": "Joshua",
        "number": 6,
        "chapter": 3,
        "length_in_seconds": 185,
        "length": "3:05",
        "file_name": "A06___03_Joshua______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A06___03_Joshua______ENGESVO2DA.mp3"
      },
      {
        "book": "Joshua",
        "number": 6,
        "chapter": 4,
        "length_in_seconds": 214,
        "length": "3:34",
        "file_name": "A06___04_Joshua______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A06___04_Joshua______ENGESVO2DA.mp3"
      },
      {
        "book": "Joshua",
        "number": 6,
        "chapter": 5,
        "length_in_seconds": 171,
        "length": "2:51",
        "file_name": "A06___05_Joshua______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A06___05_Joshua______ENGESVO2DA.mp3"
      },
      {
        "book": "Joshua",
        "number": 6,
        "chapter": 6,
        "length_in_seconds": 286,
        "length": "4:46",
        "file_name": "A06___06_Joshua______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A06___06_Joshua______ENGESVO2DA.mp3"
      },
      {
        "book": "Joshua",
        "number": 6,
        "chapter": 7,
        "length_in_seconds": 303,
        "length": "5:03",
        "file_name": "A06___07_Joshua______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A06___07_Joshua______ENGESVO2DA.mp3"
      },
      {
        "book": "Joshua",
        "number": 6,
        "chapter": 8,
        "length_in_seconds": 353,
        "length": "5:53",
        "file_name": "A06___08_Joshua______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A06___08_Joshua______ENGESVO2DA.mp3"
      },
      {
        "book": "Joshua",
        "number": 6,
        "chapter": 9,
        "length_in_seconds": 263,
        "length": "4:23",
        "file_name": "A06___09_Joshua______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A06___09_Joshua______ENGESVO2DA.mp3"
      },
      {
        "book": "Joshua",
        "number": 6,
        "chapter": 10,
        "length_in_seconds": 428,
        "length": "7:08",
        "file_name": "A06___10_Joshua______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A06___10_Joshua______ENGESVO2DA.mp3"
      },
      {
        "book": "Joshua",
        "number": 6,
        "chapter": 11,
        "length_in_seconds": 222,
        "length": "3:42",
        "file_name": "A06___11_Joshua______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A06___11_Joshua______ENGESVO2DA.mp3"
      },
      {
        "book": "Joshua",
        "number": 6,
        "chapter": 12,
        "length_in_seconds": 169,
        "length": "2:49",
        "file_name": "A06___12_Joshua______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A06___12_Joshua______ENGESVO2DA.mp3"
      },
      {
        "book": "Joshua",
        "number": 6,
        "chapter": 13,
        "length_in_seconds": 293,
        "length": "4:53",
        "file_name": "A06___13_Joshua______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A06___13_Joshua______ENGESVO2DA.mp3"
      },
      {
        "book": "Joshua",
        "number": 6,
        "chapter": 14,
        "length_in_seconds": 167,
        "length": "2:47",
        "file_name": "A06___14_Joshua______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A06___14_Joshua______ENGESVO2DA.mp3"
      },
      {
        "book": "Joshua",
        "number": 6,
        "chapter": 15,
        "length_in_seconds": 389,
        "length": "6:29",
        "file_name": "A06___15_Joshua______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A06___15_Joshua______ENGESVO2DA.mp3"
      },
      {
        "book": "Joshua",
        "number": 6,
        "chapter": 16,
        "length_in_seconds": 93,
        "length": "1:33",
        "file_name": "A06___16_Joshua______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A06___16_Joshua______ENGESVO2DA.mp3"
      },
      {
        "book": "Joshua",
        "number": 6,
        "chapter": 17,
        "length_in_seconds": 218,
        "length": "3:38",
        "file_name": "A06___17_Joshua______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A06___17_Joshua______ENGESVO2DA.mp3"
      },
      {
        "book": "Joshua",
        "number": 6,
        "chapter": 18,
        "length_in_seconds": 261,
        "length": "4:21",
        "file_name": "A06___18_Joshua______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A06___18_Joshua______ENGESVO2DA.mp3"
      },
      {
        "book": "Joshua",
        "number": 6,
        "chapter": 19,
        "length_in_seconds": 350,
        "length": "5:50",
        "file_name": "A06___19_Joshua______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A06___19_Joshua______ENGESVO2DA.mp3"
      },
      {
        "book": "Joshua",
        "number": 6,
        "chapter": 20,
        "length_in_seconds": 102,
        "length": "1:42",
        "file_name": "A06___20_Joshua______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A06___20_Joshua______ENGESVO2DA.mp3"
      },
      {
        "book": "Joshua",
        "number": 6,
        "chapter": 21,
        "length_in_seconds": 342,
        "length": "5:42",
        "file_name": "A06___21_Joshua______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A06___21_Joshua______ENGESVO2DA.mp3"
      },
      {
        "book": "Joshua",
        "number": 6,
        "chapter": 22,
        "length_in_seconds": 426,
        "length": "7:06",
        "file_name": "A06___22_Joshua______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A06___22_Joshua______ENGESVO2DA.mp3"
      },
      {
        "book": "Joshua",
        "number": 6,
        "chapter": 23,
        "length_in_seconds": 181,
        "length": "3:01",
        "file_name": "A06___23_Joshua______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A06___23_Joshua______ENGESVO2DA.mp3"
      },
      {
        "book": "Joshua",
        "number": 6,
        "chapter": 24,
        "length_in_seconds": 357,
        "length": "5:57",
        "file_name": "A06___24_Joshua______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A06___24_Joshua______ENGESVO2DA.mp3"
      }
    ],
    "Judges": [
      {
        "book": "Judges",
        "number": 7,
        "chapter": 1,
        "length_in_seconds": 317,
        "length": "5:17",
        "file_name": "A07___01_Judges______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A07___01_Judges______ENGESVO2DA.mp3"
      },
      {
        "book": "Judges",
        "number": 7,
        "chapter": 2,
        "length_in_seconds": 215,
        "length": "3:35",
        "file_name": "A07___02_Judges______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A07___02_Judges______ENGESVO2DA.mp3"
      },
      {
        "book": "Judges",
        "number": 7,
        "chapter": 3,
        "length_in_seconds": 274,
        "length": "4:34",
        "file_name": "A07___03_Judges______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A07___03_Judges______ENGESVO2DA.mp3"
      },
      {
        "book": "Judges",
        "number": 7,
        "chapter": 4,
        "length_in_seconds": 253,
        "length": "4:13",
        "file_name": "A07___04_Judges______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A07___04_Judges______ENGESVO2DA.mp3"
      },
      {
        "book": "Judges",
        "number": 7,
        "chapter": 5,
        "length_in_seconds": 309,
        "length": "5:09",
        "file_name": "A07___05_Judges______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A07___05_Judges______ENGESVO2DA.mp3"
      },
      {
        "book": "Judges",
        "number": 7,
        "chapter": 6,
        "length_in_seconds": 418,
        "length": "6:58",
        "file_name": "A07___06_Judges______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A07___06_Judges______ENGESVO2DA.mp3"
      },
      {
        "book": "Judges",
        "number": 7,
        "chapter": 7,
        "length_in_seconds": 294,
        "length": "4:54",
        "file_name": "A07___07_Judges______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A07___07_Judges______ENGESVO2DA.mp3"
      },
      {
        "book": "Judges",
        "number": 7,
        "chapter": 8,
        "length_in_seconds": 316,
        "length": "5:16",
        "file_name": "A07___08_Judges______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A07___08_Judges______ENGESVO2DA.mp3"
      },
      {
        "book": "Judges",
        "number": 7,
        "chapter": 9,
        "length_in_seconds": 533,
        "length": "8:53",
        "file_name": "A07___09_Judges______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A07___09_Judges______ENGESVO2DA.mp3"
      },
      {
        "book": "Judges",
        "number": 7,
        "chapter": 10,
        "length_in_seconds": 159,
        "length": "2:39",
        "file_name": "A07___10_Judges______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A07___10_Judges______ENGESVO2DA.mp3"
      },
      {
        "book": "Judges",
        "number": 7,
        "chapter": 11,
        "length_in_seconds": 405,
        "length": "6:45",
        "file_name": "A07___11_Judges______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A07___11_Judges______ENGESVO2DA.mp3"
      },
      {
        "book": "Judges",
        "number": 7,
        "chapter": 12,
        "length_in_seconds": 147,
        "length": "2:27",
        "file_name": "A07___12_Judges______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A07___12_Judges______ENGESVO2DA.mp3"
      },
      {
        "book": "Judges",
        "number": 7,
        "chapter": 13,
        "length_in_seconds": 246,
        "length": "4:06",
        "file_name": "A07___13_Judges______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A07___13_Judges______ENGESVO2DA.mp3"
      },
      {
        "book": "Judges",
        "number": 7,
        "chapter": 14,
        "length_in_seconds": 237,
        "length": "3:57",
        "file_name": "A07___14_Judges______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A07___14_Judges______ENGESVO2DA.mp3"
      },
      {
        "book": "Judges",
        "number": 7,
        "chapter": 15,
        "length_in_seconds": 237,
        "length": "3:57",
        "file_name": "A07___15_Judges______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A07___15_Judges______ENGESVO2DA.mp3"
      },
      {
        "book": "Judges",
        "number": 7,
        "chapter": 16,
        "length_in_seconds": 424,
        "length": "7:04",
        "file_name": "A07___16_Judges______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A07___16_Judges______ENGESVO2DA.mp3"
      },
      {
        "book": "Judges",
        "number": 7,
        "chapter": 17,
        "length_in_seconds": 135,
        "length": "2:15",
        "file_name": "A07___17_Judges______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A07___17_Judges______ENGESVO2DA.mp3"
      },
      {
        "book": "Judges",
        "number": 7,
        "chapter": 18,
        "length_in_seconds": 326,
        "length": "5:26",
        "file_name": "A07___18_Judges______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A07___18_Judges______ENGESVO2DA.mp3"
      },
      {
        "book": "Judges",
        "number": 7,
        "chapter": 19,
        "length_in_seconds": 352,
        "length": "5:52",
        "file_name": "A07___19_Judges______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A07___19_Judges______ENGESVO2DA.mp3"
      },
      {
        "book": "Judges",
        "number": 7,
        "chapter": 20,
        "length_in_seconds": 459,
        "length": "7:39",
        "file_name": "A07___20_Judges______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A07___20_Judges______ENGESVO2DA.mp3"
      },
      {
        "book": "Judges",
        "number": 7,
        "chapter": 21,
        "length_in_seconds": 248,
        "length": "4:08",
        "file_name": "A07___21_Judges______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A07___21_Judges______ENGESVO2DA.mp3"
      }
    ],
    "Ruth": [
      {
        "book": "Ruth",
        "number": 8,
        "chapter": 1,
        "length_in_seconds": 251,
        "length": "4:11",
        "file_name": "A08___01_Ruth________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A08___01_Ruth________ENGESVO2DA.mp3"
      },
      {
        "book": "Ruth",
        "number": 8,
        "chapter": 2,
        "length_in_seconds": 258,
        "length": "4:18",
        "file_name": "A08___02_Ruth________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A08___02_Ruth________ENGESVO2DA.mp3"
      },
      {
        "book": "Ruth",
        "number": 8,
        "chapter": 3,
        "length_in_seconds": 180,
        "length": "3:00",
        "file_name": "A08___03_Ruth________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A08___03_Ruth________ENGESVO2DA.mp3"
      },
      {
        "book": "Ruth",
        "number": 8,
        "chapter": 4,
        "length_in_seconds": 233,
        "length": "3:53",
        "file_name": "A08___04_Ruth________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A08___04_Ruth________ENGESVO2DA.mp3"
      }
    ],
    "1 Samuel": [
      {
        "book": "1 Samuel",
        "number": 9,
        "chapter": 1,
        "length_in_seconds": 272,
        "length": "4:32",
        "file_name": "A09___01_1Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A09___01_1Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "1 Samuel",
        "number": 9,
        "chapter": 2,
        "length_in_seconds": 404,
        "length": "6:44",
        "file_name": "A09___02_1Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A09___02_1Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "1 Samuel",
        "number": 9,
        "chapter": 3,
        "length_in_seconds": 209,
        "length": "3:29",
        "file_name": "A09___03_1Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A09___03_1Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "1 Samuel",
        "number": 9,
        "chapter": 4,
        "length_in_seconds": 250,
        "length": "4:10",
        "file_name": "A09___04_1Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A09___04_1Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "1 Samuel",
        "number": 9,
        "chapter": 5,
        "length_in_seconds": 135,
        "length": "2:15",
        "file_name": "A09___05_1Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A09___05_1Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "1 Samuel",
        "number": 9,
        "chapter": 6,
        "length_in_seconds": 250,
        "length": "4:10",
        "file_name": "A09___06_1Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A09___06_1Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "1 Samuel",
        "number": 9,
        "chapter": 7,
        "length_in_seconds": 173,
        "length": "2:53",
        "file_name": "A09___07_1Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A09___07_1Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "1 Samuel",
        "number": 9,
        "chapter": 8,
        "length_in_seconds": 178,
        "length": "2:58",
        "file_name": "A09___08_1Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A09___08_1Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "1 Samuel",
        "number": 9,
        "chapter": 9,
        "length_in_seconds": 300,
        "length": "5:00",
        "file_name": "A09___09_1Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A09___09_1Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "1 Samuel",
        "number": 9,
        "chapter": 10,
        "length_in_seconds": 301,
        "length": "5:01",
        "file_name": "A09___10_1Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A09___10_1Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "1 Samuel",
        "number": 9,
        "chapter": 11,
        "length_in_seconds": 176,
        "length": "2:56",
        "file_name": "A09___11_1Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A09___11_1Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "1 Samuel",
        "number": 9,
        "chapter": 12,
        "length_in_seconds": 282,
        "length": "4:42",
        "file_name": "A09___12_1Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A09___12_1Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "1 Samuel",
        "number": 9,
        "chapter": 13,
        "length_in_seconds": 235,
        "length": "3:55",
        "file_name": "A09___13_1Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A09___13_1Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "1 Samuel",
        "number": 9,
        "chapter": 14,
        "length_in_seconds": 540,
        "length": "9:00",
        "file_name": "A09___14_1Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A09___14_1Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "1 Samuel",
        "number": 9,
        "chapter": 15,
        "length_in_seconds": 351,
        "length": "5:51",
        "file_name": "A09___15_1Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A09___15_1Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "1 Samuel",
        "number": 9,
        "chapter": 16,
        "length_in_seconds": 246,
        "length": "4:06",
        "file_name": "A09___16_1Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A09___16_1Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "1 Samuel",
        "number": 9,
        "chapter": 17,
        "length_in_seconds": 586,
        "length": "9:46",
        "file_name": "A09___17_1Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A09___17_1Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "1 Samuel",
        "number": 9,
        "chapter": 18,
        "length_in_seconds": 289,
        "length": "4:49",
        "file_name": "A09___18_1Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A09___18_1Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "1 Samuel",
        "number": 9,
        "chapter": 19,
        "length_in_seconds": 244,
        "length": "4:04",
        "file_name": "A09___19_1Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A09___19_1Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "1 Samuel",
        "number": 9,
        "chapter": 20,
        "length_in_seconds": 411,
        "length": "6:51",
        "file_name": "A09___20_1Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A09___20_1Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "1 Samuel",
        "number": 9,
        "chapter": 21,
        "length_in_seconds": 170,
        "length": "2:50",
        "file_name": "A09___21_1Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A09___21_1Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "1 Samuel",
        "number": 9,
        "chapter": 22,
        "length_in_seconds": 274,
        "length": "4:34",
        "file_name": "A09___22_1Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A09___22_1Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "1 Samuel",
        "number": 9,
        "chapter": 23,
        "length_in_seconds": 295,
        "length": "4:55",
        "file_name": "A09___23_1Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A09___23_1Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "1 Samuel",
        "number": 9,
        "chapter": 24,
        "length_in_seconds": 236,
        "length": "3:56",
        "file_name": "A09___24_1Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A09___24_1Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "1 Samuel",
        "number": 9,
        "chapter": 25,
        "length_in_seconds": 464,
        "length": "7:44",
        "file_name": "A09___25_1Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A09___25_1Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "1 Samuel",
        "number": 9,
        "chapter": 26,
        "length_in_seconds": 311,
        "length": "5:11",
        "file_name": "A09___26_1Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A09___26_1Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "1 Samuel",
        "number": 9,
        "chapter": 27,
        "length_in_seconds": 144,
        "length": "2:24",
        "file_name": "A09___27_1Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A09___27_1Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "1 Samuel",
        "number": 9,
        "chapter": 28,
        "length_in_seconds": 327,
        "length": "5:27",
        "file_name": "A09___28_1Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A09___28_1Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "1 Samuel",
        "number": 9,
        "chapter": 29,
        "length_in_seconds": 159,
        "length": "2:39",
        "file_name": "A09___29_1Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A09___29_1Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "1 Samuel",
        "number": 9,
        "chapter": 30,
        "length_in_seconds": 287,
        "length": "4:47",
        "file_name": "A09___30_1Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A09___30_1Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "1 Samuel",
        "number": 9,
        "chapter": 31,
        "length_in_seconds": 127,
        "length": "2:07",
        "file_name": "A09___31_1Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A09___31_1Samuel_____ENGESVO2DA.mp3"
      }
    ],
    "2 Samuel": [
      {
        "book": "2 Samuel",
        "number": 10,
        "chapter": 1,
        "length_in_seconds": 246,
        "length": "4:06",
        "file_name": "A10___01_2Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A10___01_2Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "2 Samuel",
        "number": 10,
        "chapter": 2,
        "length_in_seconds": 314,
        "length": "5:14",
        "file_name": "A10___02_2Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A10___02_2Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "2 Samuel",
        "number": 10,
        "chapter": 3,
        "length_in_seconds": 385,
        "length": "6:25",
        "file_name": "A10___03_2Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A10___03_2Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "2 Samuel",
        "number": 10,
        "chapter": 4,
        "length_in_seconds": 149,
        "length": "2:29",
        "file_name": "A10___04_2Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A10___04_2Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "2 Samuel",
        "number": 10,
        "chapter": 5,
        "length_in_seconds": 228,
        "length": "3:48",
        "file_name": "A10___05_2Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A10___05_2Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "2 Samuel",
        "number": 10,
        "chapter": 6,
        "length_in_seconds": 225,
        "length": "3:45",
        "file_name": "A10___06_2Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A10___06_2Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "2 Samuel",
        "number": 10,
        "chapter": 7,
        "length_in_seconds": 71,
        "length": "1:11",
        "file_name": "A10___07_2Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A10___07_2Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "2 Samuel",
        "number": 10,
        "chapter": 8,
        "length_in_seconds": 173,
        "length": "2:53",
        "file_name": "A10___08_2Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A10___08_2Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "2 Samuel",
        "number": 10,
        "chapter": 9,
        "length_in_seconds": 147,
        "length": "2:27",
        "file_name": "A10___09_2Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A10___09_2Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "2 Samuel",
        "number": 10,
        "chapter": 10,
        "length_in_seconds": 198,
        "length": "3:18",
        "file_name": "A10___10_2Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A10___10_2Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "2 Samuel",
        "number": 10,
        "chapter": 11,
        "length_in_seconds": 264,
        "length": "4:24",
        "file_name": "A10___11_2Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A10___11_2Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "2 Samuel",
        "number": 10,
        "chapter": 12,
        "length_in_seconds": 369,
        "length": "6:09",
        "file_name": "A10___12_2Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A10___12_2Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "2 Samuel",
        "number": 10,
        "chapter": 13,
        "length_in_seconds": 408,
        "length": "6:48",
        "file_name": "A10___13_2Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A10___13_2Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "2 Samuel",
        "number": 10,
        "chapter": 14,
        "length_in_seconds": 409,
        "length": "6:49",
        "file_name": "A10___14_2Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A10___14_2Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "2 Samuel",
        "number": 10,
        "chapter": 15,
        "length_in_seconds": 367,
        "length": "6:07",
        "file_name": "A10___15_2Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A10___15_2Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "2 Samuel",
        "number": 10,
        "chapter": 16,
        "length_in_seconds": 260,
        "length": "4:20",
        "file_name": "A10___16_2Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A10___16_2Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "2 Samuel",
        "number": 10,
        "chapter": 17,
        "length_in_seconds": 334,
        "length": "5:34",
        "file_name": "A10___17_2Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A10___17_2Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "2 Samuel",
        "number": 10,
        "chapter": 18,
        "length_in_seconds": 376,
        "length": "6:16",
        "file_name": "A10___18_2Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A10___18_2Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "2 Samuel",
        "number": 10,
        "chapter": 19,
        "length_in_seconds": 508,
        "length": "8:28",
        "file_name": "A10___19_2Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A10___19_2Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "2 Samuel",
        "number": 10,
        "chapter": 20,
        "length_in_seconds": 294,
        "length": "4:54",
        "file_name": "A10___20_2Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A10___20_2Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "2 Samuel",
        "number": 10,
        "chapter": 21,
        "length_in_seconds": 261,
        "length": "4:21",
        "file_name": "A10___21_2Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A10___21_2Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "2 Samuel",
        "number": 10,
        "chapter": 22,
        "length_in_seconds": 384,
        "length": "6:24",
        "file_name": "A10___22_2Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A10___22_2Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "2 Samuel",
        "number": 10,
        "chapter": 23,
        "length_in_seconds": 323,
        "length": "5:23",
        "file_name": "A10___23_2Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A10___23_2Samuel_____ENGESVO2DA.mp3"
      },
      {
        "book": "2 Samuel",
        "number": 10,
        "chapter": 24,
        "length_in_seconds": 284,
        "length": "4:44",
        "file_name": "A10___24_2Samuel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A10___24_2Samuel_____ENGESVO2DA.mp3"
      }
    ],
    "1 Kings": [
      {
        "book": "1 Kings",
        "number": 11,
        "chapter": 1,
        "length_in_seconds": 598,
        "length": "9:58",
        "file_name": "A11___01_1Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A11___01_1Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "1 Kings",
        "number": 11,
        "chapter": 2,
        "length_in_seconds": 587,
        "length": "9:47",
        "file_name": "A11___02_1Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A11___02_1Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "1 Kings",
        "number": 11,
        "chapter": 3,
        "length_in_seconds": 296,
        "length": "4:56",
        "file_name": "A11___03_1Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A11___03_1Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "1 Kings",
        "number": 11,
        "chapter": 4,
        "length_in_seconds": 260,
        "length": "4:20",
        "file_name": "A11___04_1Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A11___04_1Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "1 Kings",
        "number": 11,
        "chapter": 5,
        "length_in_seconds": 174,
        "length": "2:54",
        "file_name": "A11___05_1Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A11___05_1Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "1 Kings",
        "number": 11,
        "chapter": 6,
        "length_in_seconds": 326,
        "length": "5:26",
        "file_name": "A11___06_1Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A11___06_1Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "1 Kings",
        "number": 11,
        "chapter": 7,
        "length_in_seconds": 467,
        "length": "7:47",
        "file_name": "A11___07_1Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A11___07_1Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "1 Kings",
        "number": 11,
        "chapter": 8,
        "length_in_seconds": 692,
        "length": "11:32",
        "file_name": "A11___08_1Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A11___08_1Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "1 Kings",
        "number": 11,
        "chapter": 9,
        "length_in_seconds": 272,
        "length": "4:32",
        "file_name": "A11___09_1Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A11___09_1Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "1 Kings",
        "number": 11,
        "chapter": 10,
        "length_in_seconds": 282,
        "length": "4:42",
        "file_name": "A11___10_1Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A11___10_1Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "1 Kings",
        "number": 11,
        "chapter": 11,
        "length_in_seconds": 442,
        "length": "7:22",
        "file_name": "A11___11_1Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A11___11_1Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "1 Kings",
        "number": 11,
        "chapter": 12,
        "length_in_seconds": 341,
        "length": "5:41",
        "file_name": "A11___12_1Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A11___12_1Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "1 Kings",
        "number": 11,
        "chapter": 13,
        "length_in_seconds": 340,
        "length": "5:40",
        "file_name": "A11___13_1Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A11___13_1Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "1 Kings",
        "number": 11,
        "chapter": 14,
        "length_in_seconds": 342,
        "length": "5:42",
        "file_name": "A11___14_1Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A11___14_1Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "1 Kings",
        "number": 11,
        "chapter": 15,
        "length_in_seconds": 313,
        "length": "5:13",
        "file_name": "A11___15_1Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A11___15_1Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "1 Kings",
        "number": 11,
        "chapter": 16,
        "length_in_seconds": 343,
        "length": "5:43",
        "file_name": "A11___16_1Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A11___16_1Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "1 Kings",
        "number": 11,
        "chapter": 17,
        "length_in_seconds": 239,
        "length": "3:59",
        "file_name": "A11___17_1Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A11___17_1Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "1 Kings",
        "number": 11,
        "chapter": 18,
        "length_in_seconds": 547,
        "length": "9:07",
        "file_name": "A11___18_1Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A11___18_1Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "1 Kings",
        "number": 11,
        "chapter": 19,
        "length_in_seconds": 271,
        "length": "4:31",
        "file_name": "A11___19_1Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A11___19_1Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "1 Kings",
        "number": 11,
        "chapter": 20,
        "length_in_seconds": 511,
        "length": "8:31",
        "file_name": "A11___20_1Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A11___20_1Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "1 Kings",
        "number": 11,
        "chapter": 21,
        "length_in_seconds": 324,
        "length": "5:24",
        "file_name": "A11___21_1Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A11___21_1Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "1 Kings",
        "number": 11,
        "chapter": 22,
        "length_in_seconds": 546,
        "length": "9:06",
        "file_name": "A11___22_1Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A11___22_1Kings______ENGESVO2DA.mp3"
      }
    ],
    "2 Kings": [
      {
        "book": "2 Kings",
        "number": 12,
        "chapter": 1,
        "length_in_seconds": 245,
        "length": "4:05",
        "file_name": "A12___01_2Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A12___01_2Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "2 Kings",
        "number": 12,
        "chapter": 2,
        "length_in_seconds": 301,
        "length": "5:01",
        "file_name": "A12___02_2Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A12___02_2Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "2 Kings",
        "number": 12,
        "chapter": 3,
        "length_in_seconds": 280,
        "length": "4:40",
        "file_name": "A12___03_2Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A12___03_2Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "2 Kings",
        "number": 12,
        "chapter": 4,
        "length_in_seconds": 436,
        "length": "7:16",
        "file_name": "A12___04_2Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A12___04_2Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "2 Kings",
        "number": 12,
        "chapter": 5,
        "length_in_seconds": 330,
        "length": "5:30",
        "file_name": "A12___05_2Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A12___05_2Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "2 Kings",
        "number": 12,
        "chapter": 6,
        "length_in_seconds": 348,
        "length": "5:48",
        "file_name": "A12___06_2Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A12___06_2Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "2 Kings",
        "number": 12,
        "chapter": 7,
        "length_in_seconds": 256,
        "length": "4:16",
        "file_name": "A12___07_2Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A12___07_2Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "2 Kings",
        "number": 12,
        "chapter": 8,
        "length_in_seconds": 318,
        "length": "5:18",
        "file_name": "A12___08_2Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A12___08_2Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "2 Kings",
        "number": 12,
        "chapter": 9,
        "length_in_seconds": 415,
        "length": "6:55",
        "file_name": "A12___09_2Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A12___09_2Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "2 Kings",
        "number": 12,
        "chapter": 10,
        "length_in_seconds": 395,
        "length": "6:35",
        "file_name": "A12___10_2Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A12___10_2Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "2 Kings",
        "number": 12,
        "chapter": 11,
        "length_in_seconds": 227,
        "length": "3:47",
        "file_name": "A12___11_2Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A12___11_2Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "2 Kings",
        "number": 12,
        "chapter": 12,
        "length_in_seconds": 210,
        "length": "3:30",
        "file_name": "A12___12_2Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A12___12_2Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "2 Kings",
        "number": 12,
        "chapter": 13,
        "length_in_seconds": 287,
        "length": "4:47",
        "file_name": "A12___13_2Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A12___13_2Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "2 Kings",
        "number": 12,
        "chapter": 14,
        "length_in_seconds": 287,
        "length": "4:47",
        "file_name": "A12___14_2Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A12___14_2Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "2 Kings",
        "number": 12,
        "chapter": 15,
        "length_in_seconds": 356,
        "length": "5:56",
        "file_name": "A12___15_2Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A12___15_2Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "2 Kings",
        "number": 12,
        "chapter": 16,
        "length_in_seconds": 211,
        "length": "3:31",
        "file_name": "A12___16_2Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A12___16_2Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "2 Kings",
        "number": 12,
        "chapter": 17,
        "length_in_seconds": 416,
        "length": "6:56",
        "file_name": "A12___17_2Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A12___17_2Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "2 Kings",
        "number": 12,
        "chapter": 18,
        "length_in_seconds": 429,
        "length": "7:09",
        "file_name": "A12___18_2Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A12___18_2Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "2 Kings",
        "number": 12,
        "chapter": 19,
        "length_in_seconds": 399,
        "length": "6:39",
        "file_name": "A12___19_2Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A12___19_2Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "2 Kings",
        "number": 12,
        "chapter": 20,
        "length_in_seconds": 228,
        "length": "3:48",
        "file_name": "A12___20_2Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A12___20_2Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "2 Kings",
        "number": 12,
        "chapter": 21,
        "length_in_seconds": 237,
        "length": "3:57",
        "file_name": "A12___21_2Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A12___21_2Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "2 Kings",
        "number": 12,
        "chapter": 22,
        "length_in_seconds": 233,
        "length": "3:53",
        "file_name": "A12___22_2Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A12___22_2Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "2 Kings",
        "number": 12,
        "chapter": 23,
        "length_in_seconds": 445,
        "length": "7:25",
        "file_name": "A12___23_2Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A12___23_2Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "2 Kings",
        "number": 12,
        "chapter": 24,
        "length_in_seconds": 191,
        "length": "3:11",
        "file_name": "A12___24_2Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A12___24_2Kings______ENGESVO2DA.mp3"
      },
      {
        "book": "2 Kings",
        "number": 12,
        "chapter": 25,
        "length_in_seconds": 310,
        "length": "5:10",
        "file_name": "A12___25_2Kings______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A12___25_2Kings______ENGESVO2DA.mp3"
      }
    ],
    "1 Chronicles": [
      {
        "book": "1 Chronicles",
        "number": 13,
        "chapter": 1,
        "length_in_seconds": 361,
        "length": "6:01",
        "file_name": "A13___01_1Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A13___01_1Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "1 Chronicles",
        "number": 13,
        "chapter": 2,
        "length_in_seconds": 397,
        "length": "6:37",
        "file_name": "A13___02_1Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A13___02_1Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "1 Chronicles",
        "number": 13,
        "chapter": 3,
        "length_in_seconds": 200,
        "length": "3:20",
        "file_name": "A13___03_1Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A13___03_1Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "1 Chronicles",
        "number": 13,
        "chapter": 4,
        "length_in_seconds": 367,
        "length": "6:07",
        "file_name": "A13___04_1Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A13___04_1Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "1 Chronicles",
        "number": 13,
        "chapter": 5,
        "length_in_seconds": 241,
        "length": "4:01",
        "file_name": "A13___05_1Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A13___05_1Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "1 Chronicles",
        "number": 13,
        "chapter": 6,
        "length_in_seconds": 532,
        "length": "8:52",
        "file_name": "A13___06_1Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A13___06_1Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "1 Chronicles",
        "number": 13,
        "chapter": 7,
        "length_in_seconds": 366,
        "length": "6:06",
        "file_name": "A13___07_1Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A13___07_1Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "1 Chronicles",
        "number": 13,
        "chapter": 8,
        "length_in_seconds": 242,
        "length": "4:02",
        "file_name": "A13___08_1Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A13___08_1Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "1 Chronicles",
        "number": 13,
        "chapter": 9,
        "length_in_seconds": 372,
        "length": "6:12",
        "file_name": "A13___09_1Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A13___09_1Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "1 Chronicles",
        "number": 13,
        "chapter": 10,
        "length_in_seconds": 126,
        "length": "2:06",
        "file_name": "A13___10_1Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A13___10_1Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "1 Chronicles",
        "number": 13,
        "chapter": 11,
        "length_in_seconds": 356,
        "length": "5:56",
        "file_name": "A13___11_1Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A13___11_1Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "1 Chronicles",
        "number": 13,
        "chapter": 12,
        "length_in_seconds": 343,
        "length": "5:43",
        "file_name": "A13___12_1Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A13___12_1Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "1 Chronicles",
        "number": 13,
        "chapter": 13,
        "length_in_seconds": 127,
        "length": "2:07",
        "file_name": "A13___13_1Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A13___13_1Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "1 Chronicles",
        "number": 13,
        "chapter": 14,
        "length_in_seconds": 129,
        "length": "2:09",
        "file_name": "A13___14_1Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A13___14_1Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "1 Chronicles",
        "number": 13,
        "chapter": 15,
        "length_in_seconds": 270,
        "length": "4:30",
        "file_name": "A13___15_1Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A13___15_1Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "1 Chronicles",
        "number": 13,
        "chapter": 16,
        "length_in_seconds": 332,
        "length": "5:32",
        "file_name": "A13___16_1Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A13___16_1Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "1 Chronicles",
        "number": 13,
        "chapter": 17,
        "length_in_seconds": 254,
        "length": "4:14",
        "file_name": "A13___17_1Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A13___17_1Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "1 Chronicles",
        "number": 13,
        "chapter": 18,
        "length_in_seconds": 153,
        "length": "2:33",
        "file_name": "A13___18_1Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A13___18_1Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "1 Chronicles",
        "number": 13,
        "chapter": 19,
        "length_in_seconds": 202,
        "length": "3:22",
        "file_name": "A13___19_1Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A13___19_1Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "1 Chronicles",
        "number": 13,
        "chapter": 20,
        "length_in_seconds": 96,
        "length": "1:36",
        "file_name": "A13___20_1Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A13___20_1Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "1 Chronicles",
        "number": 13,
        "chapter": 21,
        "length_in_seconds": 297,
        "length": "4:57",
        "file_name": "A13___21_1Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A13___21_1Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "1 Chronicles",
        "number": 13,
        "chapter": 22,
        "length_in_seconds": 195,
        "length": "3:15",
        "file_name": "A13___22_1Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A13___22_1Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "1 Chronicles",
        "number": 13,
        "chapter": 23,
        "length_in_seconds": 261,
        "length": "4:21",
        "file_name": "A13___23_1Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A13___23_1Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "1 Chronicles",
        "number": 13,
        "chapter": 24,
        "length_in_seconds": 224,
        "length": "3:44",
        "file_name": "A13___24_1Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A13___24_1Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "1 Chronicles",
        "number": 13,
        "chapter": 25,
        "length_in_seconds": 223,
        "length": "3:43",
        "file_name": "A13___25_1Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A13___25_1Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "1 Chronicles",
        "number": 13,
        "chapter": 26,
        "length_in_seconds": 286,
        "length": "4:46",
        "file_name": "A13___26_1Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A13___26_1Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "1 Chronicles",
        "number": 13,
        "chapter": 27,
        "length_in_seconds": 292,
        "length": "4:52",
        "file_name": "A13___27_1Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A13___27_1Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "1 Chronicles",
        "number": 13,
        "chapter": 28,
        "length_in_seconds": 286,
        "length": "4:46",
        "file_name": "A13___28_1Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A13___28_1Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "1 Chronicles",
        "number": 13,
        "chapter": 29,
        "length_in_seconds": 398,
        "length": "6:38",
        "file_name": "A13___29_1Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A13___29_1Chronicles_ENGESVO2DA.mp3"
      }
    ],
    "2 Chronicles": [
      {
        "book": "2 Chronicles",
        "number": 14,
        "chapter": 1,
        "length_in_seconds": 177,
        "length": "2:57",
        "file_name": "A14___01_2Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A14___01_2Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "2 Chronicles",
        "number": 14,
        "chapter": 2,
        "length_in_seconds": 208,
        "length": "3:28",
        "file_name": "A14___02_2Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A14___02_2Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "2 Chronicles",
        "number": 14,
        "chapter": 3,
        "length_in_seconds": 144,
        "length": "2:24",
        "file_name": "A14___03_2Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A14___03_2Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "2 Chronicles",
        "number": 14,
        "chapter": 4,
        "length_in_seconds": 174,
        "length": "2:54",
        "file_name": "A14___04_2Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A14___04_2Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "2 Chronicles",
        "number": 14,
        "chapter": 5,
        "length_in_seconds": 146,
        "length": "2:26",
        "file_name": "A14___05_2Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A14___05_2Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "2 Chronicles",
        "number": 14,
        "chapter": 6,
        "length_in_seconds": 484,
        "length": "8:04",
        "file_name": "A14___06_2Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A14___06_2Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "2 Chronicles",
        "number": 14,
        "chapter": 7,
        "length_in_seconds": 250,
        "length": "4:10",
        "file_name": "A14___07_2Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A14___07_2Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "2 Chronicles",
        "number": 14,
        "chapter": 8,
        "length_in_seconds": 166,
        "length": "2:46",
        "file_name": "A14___08_2Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A14___08_2Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "2 Chronicles",
        "number": 14,
        "chapter": 9,
        "length_in_seconds": 304,
        "length": "5:04",
        "file_name": "A14___09_2Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A14___09_2Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "2 Chronicles",
        "number": 14,
        "chapter": 10,
        "length_in_seconds": 196,
        "length": "3:16",
        "file_name": "A14___10_2Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A14___10_2Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "2 Chronicles",
        "number": 14,
        "chapter": 11,
        "length_in_seconds": 174,
        "length": "2:54",
        "file_name": "A14___11_2Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A14___11_2Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "2 Chronicles",
        "number": 14,
        "chapter": 12,
        "length_in_seconds": 166,
        "length": "2:46",
        "file_name": "A14___12_2Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A14___12_2Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "2 Chronicles",
        "number": 14,
        "chapter": 13,
        "length_in_seconds": 233,
        "length": "3:53",
        "file_name": "A14___13_2Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A14___13_2Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "2 Chronicles",
        "number": 14,
        "chapter": 14,
        "length_in_seconds": 149,
        "length": "2:29",
        "file_name": "A14___14_2Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A14___14_2Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "2 Chronicles",
        "number": 14,
        "chapter": 15,
        "length_in_seconds": 171,
        "length": "2:51",
        "file_name": "A14___15_2Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A14___15_2Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "2 Chronicles",
        "number": 14,
        "chapter": 16,
        "length_in_seconds": 155,
        "length": "2:35",
        "file_name": "A14___16_2Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A14___16_2Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "2 Chronicles",
        "number": 14,
        "chapter": 17,
        "length_in_seconds": 154,
        "length": "2:34",
        "file_name": "A14___17_2Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A14___17_2Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "2 Chronicles",
        "number": 14,
        "chapter": 18,
        "length_in_seconds": 389,
        "length": "6:29",
        "file_name": "A14___18_2Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A14___18_2Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "2 Chronicles",
        "number": 14,
        "chapter": 19,
        "length_in_seconds": 121,
        "length": "2:01",
        "file_name": "A14___19_2Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A14___19_2Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "2 Chronicles",
        "number": 14,
        "chapter": 20,
        "length_in_seconds": 393,
        "length": "6:33",
        "file_name": "A14___20_2Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A14___20_2Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "2 Chronicles",
        "number": 14,
        "chapter": 21,
        "length_in_seconds": 215,
        "length": "3:35",
        "file_name": "A14___21_2Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A14___21_2Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "2 Chronicles",
        "number": 14,
        "chapter": 22,
        "length_in_seconds": 155,
        "length": "2:35",
        "file_name": "A14___22_2Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A14___22_2Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "2 Chronicles",
        "number": 14,
        "chapter": 23,
        "length_in_seconds": 256,
        "length": "4:16",
        "file_name": "A14___23_2Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A14___23_2Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "2 Chronicles",
        "number": 14,
        "chapter": 24,
        "length_in_seconds": 296,
        "length": "4:56",
        "file_name": "A14___24_2Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A14___24_2Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "2 Chronicles",
        "number": 14,
        "chapter": 25,
        "length_in_seconds": 316,
        "length": "5:16",
        "file_name": "A14___25_2Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A14___25_2Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "2 Chronicles",
        "number": 14,
        "chapter": 26,
        "length_in_seconds": 234,
        "length": "3:54",
        "file_name": "A14___26_2Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A14___26_2Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "2 Chronicles",
        "number": 14,
        "chapter": 27,
        "length_in_seconds": 78,
        "length": "1:18",
        "file_name": "A14___27_2Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A14___27_2Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "2 Chronicles",
        "number": 14,
        "chapter": 28,
        "length_in_seconds": 287,
        "length": "4:47",
        "file_name": "A14___28_2Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A14___28_2Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "2 Chronicles",
        "number": 14,
        "chapter": 29,
        "length_in_seconds": 379,
        "length": "6:19",
        "file_name": "A14___29_2Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A14___29_2Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "2 Chronicles",
        "number": 14,
        "chapter": 30,
        "length_in_seconds": 302,
        "length": "5:02",
        "file_name": "A14___30_2Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A14___30_2Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "2 Chronicles",
        "number": 14,
        "chapter": 31,
        "length_in_seconds": 256,
        "length": "4:16",
        "file_name": "A14___31_2Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A14___31_2Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "2 Chronicles",
        "number": 14,
        "chapter": 32,
        "length_in_seconds": 378,
        "length": "6:18",
        "file_name": "A14___32_2Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A14___32_2Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "2 Chronicles",
        "number": 14,
        "chapter": 33,
        "length_in_seconds": 248,
        "length": "4:08",
        "file_name": "A14___33_2Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A14___33_2Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "2 Chronicles",
        "number": 14,
        "chapter": 34,
        "length_in_seconds": 385,
        "length": "6:25",
        "file_name": "A14___34_2Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A14___34_2Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "2 Chronicles",
        "number": 14,
        "chapter": 35,
        "length_in_seconds": 314,
        "length": "5:14",
        "file_name": "A14___35_2Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A14___35_2Chronicles_ENGESVO2DA.mp3"
      },
      {
        "book": "2 Chronicles",
        "number": 14,
        "chapter": 36,
        "length_in_seconds": 240,
        "length": "4:00",
        "file_name": "A14___36_2Chronicles_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A14___36_2Chronicles_ENGESVO2DA.mp3"
      }
    ],
    "Ezra": [
      {
        "book": "Ezra",
        "number": 15,
        "chapter": 1,
        "length_in_seconds": 135,
        "length": "2:15",
        "file_name": "A15___01_Ezra________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A15___01_Ezra________ENGESVO2DA.mp3"
      },
      {
        "book": "Ezra",
        "number": 15,
        "chapter": 2,
        "length_in_seconds": 408,
        "length": "6:48",
        "file_name": "A15___02_Ezra________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A15___02_Ezra________ENGESVO2DA.mp3"
      },
      {
        "book": "Ezra",
        "number": 15,
        "chapter": 3,
        "length_in_seconds": 194,
        "length": "3:14",
        "file_name": "A15___03_Ezra________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A15___03_Ezra________ENGESVO2DA.mp3"
      },
      {
        "book": "Ezra",
        "number": 15,
        "chapter": 4,
        "length_in_seconds": 283,
        "length": "4:43",
        "file_name": "A15___04_Ezra________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A15___04_Ezra________ENGESVO2DA.mp3"
      },
      {
        "book": "Ezra",
        "number": 15,
        "chapter": 5,
        "length_in_seconds": 195,
        "length": "3:15",
        "file_name": "A15___05_Ezra________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A15___05_Ezra________ENGESVO2DA.mp3"
      },
      {
        "book": "Ezra",
        "number": 15,
        "chapter": 6,
        "length_in_seconds": 275,
        "length": "4:35",
        "file_name": "A15___06_Ezra________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A15___06_Ezra________ENGESVO2DA.mp3"
      },
      {
        "book": "Ezra",
        "number": 15,
        "chapter": 7,
        "length_in_seconds": 334,
        "length": "5:34",
        "file_name": "A15___07_Ezra________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A15___07_Ezra________ENGESVO2DA.mp3"
      },
      {
        "book": "Ezra",
        "number": 15,
        "chapter": 8,
        "length_in_seconds": 368,
        "length": "6:08",
        "file_name": "A15___08_Ezra________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A15___08_Ezra________ENGESVO2DA.mp3"
      },
      {
        "book": "Ezra",
        "number": 15,
        "chapter": 9,
        "length_in_seconds": 233,
        "length": "3:53",
        "file_name": "A15___09_Ezra________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A15___09_Ezra________ENGESVO2DA.mp3"
      },
      {
        "book": "Ezra",
        "number": 15,
        "chapter": 10,
        "length_in_seconds": 401,
        "length": "6:41",
        "file_name": "A15___10_Ezra________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A15___10_Ezra________ENGESVO2DA.mp3"
      }
    ],
    "Nehemiah": [
      {
        "book": "Nehemiah",
        "number": 16,
        "chapter": 1,
        "length_in_seconds": 161,
        "length": "2:41",
        "file_name": "A16___01_Nehemiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A16___01_Nehemiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Nehemiah",
        "number": 16,
        "chapter": 2,
        "length_in_seconds": 269,
        "length": "4:29",
        "file_name": "A16___02_Nehemiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A16___02_Nehemiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Nehemiah",
        "number": 16,
        "chapter": 3,
        "length_in_seconds": 328,
        "length": "5:28",
        "file_name": "A16___03_Nehemiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A16___03_Nehemiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Nehemiah",
        "number": 16,
        "chapter": 4,
        "length_in_seconds": 269,
        "length": "4:29",
        "file_name": "A16___04_Nehemiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A16___04_Nehemiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Nehemiah",
        "number": 16,
        "chapter": 5,
        "length_in_seconds": 248,
        "length": "4:08",
        "file_name": "A16___05_Nehemiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A16___05_Nehemiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Nehemiah",
        "number": 16,
        "chapter": 6,
        "length_in_seconds": 228,
        "length": "3:48",
        "file_name": "A16___06_Nehemiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A16___06_Nehemiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Nehemiah",
        "number": 16,
        "chapter": 7,
        "length_in_seconds": 488,
        "length": "8:08",
        "file_name": "A16___07_Nehemiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A16___07_Nehemiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Nehemiah",
        "number": 16,
        "chapter": 8,
        "length_in_seconds": 259,
        "length": "4:19",
        "file_name": "A16___08_Nehemiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A16___08_Nehemiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Nehemiah",
        "number": 16,
        "chapter": 9,
        "length_in_seconds": 529,
        "length": "8:49",
        "file_name": "A16___09_Nehemiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A16___09_Nehemiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Nehemiah",
        "number": 16,
        "chapter": 10,
        "length_in_seconds": 289,
        "length": "4:49",
        "file_name": "A16___10_Nehemiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A16___10_Nehemiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Nehemiah",
        "number": 16,
        "chapter": 11,
        "length_in_seconds": 305,
        "length": "5:05",
        "file_name": "A16___11_Nehemiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A16___11_Nehemiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Nehemiah",
        "number": 16,
        "chapter": 12,
        "length_in_seconds": 431,
        "length": "7:11",
        "file_name": "A16___12_Nehemiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A16___12_Nehemiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Nehemiah",
        "number": 16,
        "chapter": 13,
        "length_in_seconds": 384,
        "length": "6:24",
        "file_name": "A16___13_Nehemiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A16___13_Nehemiah____ENGESVO2DA.mp3"
      }
    ],
    "Esther": [
      {
        "book": "Esther",
        "number": 17,
        "chapter": 1,
        "length_in_seconds": 264,
        "length": "4:24",
        "file_name": "A17___01_Esther______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A17___01_Esther______ENGESVO2DA.mp3"
      },
      {
        "book": "Esther",
        "number": 17,
        "chapter": 2,
        "length_in_seconds": 278,
        "length": "4:38",
        "file_name": "A17___02_Esther______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A17___02_Esther______ENGESVO2DA.mp3"
      },
      {
        "book": "Esther",
        "number": 17,
        "chapter": 3,
        "length_in_seconds": 214,
        "length": "3:34",
        "file_name": "A17___03_Esther______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A17___03_Esther______ENGESVO2DA.mp3"
      },
      {
        "book": "Esther",
        "number": 17,
        "chapter": 4,
        "length_in_seconds": 188,
        "length": "3:08",
        "file_name": "A17___04_Esther______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A17___04_Esther______ENGESVO2DA.mp3"
      },
      {
        "book": "Esther",
        "number": 17,
        "chapter": 5,
        "length_in_seconds": 184,
        "length": "3:04",
        "file_name": "A17___05_Esther______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A17___05_Esther______ENGESVO2DA.mp3"
      },
      {
        "book": "Esther",
        "number": 17,
        "chapter": 6,
        "length_in_seconds": 192,
        "length": "3:12",
        "file_name": "A17___06_Esther______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A17___06_Esther______ENGESVO2DA.mp3"
      },
      {
        "book": "Esther",
        "number": 17,
        "chapter": 7,
        "length_in_seconds": 148,
        "length": "2:28",
        "file_name": "A17___07_Esther______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A17___07_Esther______ENGESVO2DA.mp3"
      },
      {
        "book": "Esther",
        "number": 17,
        "chapter": 8,
        "length_in_seconds": 245,
        "length": "4:05",
        "file_name": "A17___08_Esther______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A17___08_Esther______ENGESVO2DA.mp3"
      },
      {
        "book": "Esther",
        "number": 17,
        "chapter": 9,
        "length_in_seconds": 353,
        "length": "5:53",
        "file_name": "A17___09_Esther______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A17___09_Esther______ENGESVO2DA.mp3"
      },
      {
        "book": "Esther",
        "number": 17,
        "chapter": 10,
        "length_in_seconds": 40,
        "length": "0:40",
        "file_name": "A17___10_Esther______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A17___10_Esther______ENGESVO2DA.mp3"
      }
    ],
    "Job": [
      {
        "book": "Job",
        "number": 18,
        "chapter": 1,
        "length_in_seconds": 267,
        "length": "4:27",
        "file_name": "A18___01_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___01_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 2,
        "length_in_seconds": 169,
        "length": "2:49",
        "file_name": "A18___02_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___02_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 3,
        "length_in_seconds": 184,
        "length": "3:04",
        "file_name": "A18___03_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___03_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 4,
        "length_in_seconds": 143,
        "length": "2:23",
        "file_name": "A18___04_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___04_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 5,
        "length_in_seconds": 182,
        "length": "3:02",
        "file_name": "A18___05_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___05_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 6,
        "length_in_seconds": 191,
        "length": "3:11",
        "file_name": "A18___06_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___06_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 7,
        "length_in_seconds": 160,
        "length": "2:40",
        "file_name": "A18___07_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___07_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 8,
        "length_in_seconds": 122,
        "length": "2:02",
        "file_name": "A18___08_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___08_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 9,
        "length_in_seconds": 228,
        "length": "3:48",
        "file_name": "A18___09_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___09_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 10,
        "length_in_seconds": 159,
        "length": "2:39",
        "file_name": "A18___10_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___10_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 11,
        "length_in_seconds": 145,
        "length": "2:25",
        "file_name": "A18___11_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___11_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 12,
        "length_in_seconds": 154,
        "length": "2:34",
        "file_name": "A18___12_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___12_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 13,
        "length_in_seconds": 168,
        "length": "2:48",
        "file_name": "A18___13_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___13_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 14,
        "length_in_seconds": 152,
        "length": "2:32",
        "file_name": "A18___14_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___14_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 15,
        "length_in_seconds": 240,
        "length": "4:00",
        "file_name": "A18___15_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___15_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 16,
        "length_in_seconds": 157,
        "length": "2:37",
        "file_name": "A18___16_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___16_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 17,
        "length_in_seconds": 106,
        "length": "1:46",
        "file_name": "A18___17_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___17_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 18,
        "length_in_seconds": 108,
        "length": "1:48",
        "file_name": "A18___18_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___18_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 19,
        "length_in_seconds": 195,
        "length": "3:15",
        "file_name": "A18___19_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___19_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 20,
        "length_in_seconds": 213,
        "length": "3:33",
        "file_name": "A18___20_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___20_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 21,
        "length_in_seconds": 196,
        "length": "3:16",
        "file_name": "A18___21_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___21_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 22,
        "length_in_seconds": 203,
        "length": "3:23",
        "file_name": "A18___22_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___22_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 23,
        "length_in_seconds": 104,
        "length": "1:44",
        "file_name": "A18___23_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___23_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 24,
        "length_in_seconds": 178,
        "length": "2:58",
        "file_name": "A18___24_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___24_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 25,
        "length_in_seconds": 37,
        "length": "0:37",
        "file_name": "A18___25_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___25_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 26,
        "length_in_seconds": 89,
        "length": "1:29",
        "file_name": "A18___26_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___26_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 27,
        "length_in_seconds": 139,
        "length": "2:19",
        "file_name": "A18___27_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___27_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 28,
        "length_in_seconds": 183,
        "length": "3:03",
        "file_name": "A18___28_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___28_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 29,
        "length_in_seconds": 161,
        "length": "2:41",
        "file_name": "A18___29_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___29_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 30,
        "length_in_seconds": 237,
        "length": "3:57",
        "file_name": "A18___30_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___30_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 31,
        "length_in_seconds": 271,
        "length": "4:31",
        "file_name": "A18___31_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___31_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 32,
        "length_in_seconds": 144,
        "length": "2:24",
        "file_name": "A18___32_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___32_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 33,
        "length_in_seconds": 196,
        "length": "3:16",
        "file_name": "A18___33_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___33_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 34,
        "length_in_seconds": 210,
        "length": "3:30",
        "file_name": "A18___34_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___34_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 35,
        "length_in_seconds": 89,
        "length": "1:29",
        "file_name": "A18___35_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___35_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 36,
        "length_in_seconds": 185,
        "length": "3:05",
        "file_name": "A18___36_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___36_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 37,
        "length_in_seconds": 153,
        "length": "2:33",
        "file_name": "A18___37_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___37_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 38,
        "length_in_seconds": 236,
        "length": "3:56",
        "file_name": "A18___38_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___38_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 39,
        "length_in_seconds": 189,
        "length": "3:09",
        "file_name": "A18___39_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___39_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 40,
        "length_in_seconds": 146,
        "length": "2:26",
        "file_name": "A18___40_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___40_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 41,
        "length_in_seconds": 203,
        "length": "3:23",
        "file_name": "A18___41_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___41_Job_________ENGESVO2DA.mp3"
      },
      {
        "book": "Job",
        "number": 18,
        "chapter": 42,
        "length_in_seconds": 176,
        "length": "2:56",
        "file_name": "A18___42_Job_________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A18___42_Job_________ENGESVO2DA.mp3"
      }
    ],
    "Psalms": [
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 1,
        "length_in_seconds": 51,
        "length": "0:51",
        "file_name": "A19__001_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__001_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 2,
        "length_in_seconds": 83,
        "length": "1:23",
        "file_name": "A19__002_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__002_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 3,
        "length_in_seconds": 63,
        "length": "1:03",
        "file_name": "A19__003_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__003_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 4,
        "length_in_seconds": 79,
        "length": "1:19",
        "file_name": "A19__004_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__004_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 5,
        "length_in_seconds": 101,
        "length": "1:41",
        "file_name": "A19__005_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__005_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 6,
        "length_in_seconds": 76,
        "length": "1:16",
        "file_name": "A19__006_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__006_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 7,
        "length_in_seconds": 130,
        "length": "2:10",
        "file_name": "A19__007_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__007_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 8,
        "length_in_seconds": 69,
        "length": "1:09",
        "file_name": "A19__008_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__008_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 9,
        "length_in_seconds": 154,
        "length": "2:34",
        "file_name": "A19__009_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__009_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 10,
        "length_in_seconds": 140,
        "length": "2:20",
        "file_name": "A19__010_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__010_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 11,
        "length_in_seconds": 64,
        "length": "1:04",
        "file_name": "A19__011_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__011_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 12,
        "length_in_seconds": 70,
        "length": "1:10",
        "file_name": "A19__012_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__012_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 13,
        "length_in_seconds": 54,
        "length": "0:54",
        "file_name": "A19__013_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__013_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 14,
        "length_in_seconds": 69,
        "length": "1:09",
        "file_name": "A19__014_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__014_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 15,
        "length_in_seconds": 46,
        "length": "0:46",
        "file_name": "A19__015_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__015_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 16,
        "length_in_seconds": 82,
        "length": "1:22",
        "file_name": "A19__016_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__016_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 17,
        "length_in_seconds": 113,
        "length": "1:53",
        "file_name": "A19__017_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__017_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 18,
        "length_in_seconds": 395,
        "length": "6:35",
        "file_name": "A19__018_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__018_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 19,
        "length_in_seconds": 108,
        "length": "1:48",
        "file_name": "A19__019_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__019_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 20,
        "length_in_seconds": 68,
        "length": "1:08",
        "file_name": "A19__020_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__020_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 21,
        "length_in_seconds": 91,
        "length": "1:31",
        "file_name": "A19__021_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__021_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 22,
        "length_in_seconds": 225,
        "length": "3:45",
        "file_name": "A19__022_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__022_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 23,
        "length_in_seconds": 53,
        "length": "0:53",
        "file_name": "A19__023_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__023_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 24,
        "length_in_seconds": 76,
        "length": "1:16",
        "file_name": "A19__024_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__024_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 25,
        "length_in_seconds": 153,
        "length": "2:33",
        "file_name": "A19__025_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__025_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 26,
        "length_in_seconds": 77,
        "length": "1:17",
        "file_name": "A19__026_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__026_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 27,
        "length_in_seconds": 135,
        "length": "2:15",
        "file_name": "A19__027_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__027_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 28,
        "length_in_seconds": 86,
        "length": "1:26",
        "file_name": "A19__028_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__028_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 29,
        "length_in_seconds": 75,
        "length": "1:15",
        "file_name": "A19__029_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__029_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 30,
        "length_in_seconds": 101,
        "length": "1:41",
        "file_name": "A19__030_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__030_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 31,
        "length_in_seconds": 211,
        "length": "3:31",
        "file_name": "A19__031_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__031_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 32,
        "length_in_seconds": 101,
        "length": "1:41",
        "file_name": "A19__032_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__032_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 33,
        "length_in_seconds": 147,
        "length": "2:27",
        "file_name": "A19__033_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__033_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 34,
        "length_in_seconds": 150,
        "length": "2:30",
        "file_name": "A19__034_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__034_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 35,
        "length_in_seconds": 230,
        "length": "3:50",
        "file_name": "A19__035_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__035_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 36,
        "length_in_seconds": 90,
        "length": "1:30",
        "file_name": "A19__036_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__036_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 37,
        "length_in_seconds": 289,
        "length": "4:49",
        "file_name": "A19__037_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__037_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 38,
        "length_in_seconds": 143,
        "length": "2:23",
        "file_name": "A19__038_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__038_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 39,
        "length_in_seconds": 127,
        "length": "2:07",
        "file_name": "A19__039_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__039_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 40,
        "length_in_seconds": 169,
        "length": "2:49",
        "file_name": "A19__040_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__040_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 41,
        "length_in_seconds": 111,
        "length": "1:51",
        "file_name": "A19__041_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__041_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 42,
        "length_in_seconds": 130,
        "length": "2:10",
        "file_name": "A19__042_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__042_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 43,
        "length_in_seconds": 54,
        "length": "0:54",
        "file_name": "A19__043_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__043_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 44,
        "length_in_seconds": 195,
        "length": "3:15",
        "file_name": "A19__044_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__044_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 45,
        "length_in_seconds": 151,
        "length": "2:31",
        "file_name": "A19__045_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__045_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 46,
        "length_in_seconds": 102,
        "length": "1:42",
        "file_name": "A19__046_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__046_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 47,
        "length_in_seconds": 70,
        "length": "1:10",
        "file_name": "A19__047_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__047_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 48,
        "length_in_seconds": 106,
        "length": "1:46",
        "file_name": "A19__048_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__048_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 49,
        "length_in_seconds": 152,
        "length": "2:32",
        "file_name": "A19__049_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__049_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 50,
        "length_in_seconds": 165,
        "length": "2:45",
        "file_name": "A19__050_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__050_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 51,
        "length_in_seconds": 146,
        "length": "2:26",
        "file_name": "A19__051_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__051_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 52,
        "length_in_seconds": 88,
        "length": "1:28",
        "file_name": "A19__052_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__052_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 53,
        "length_in_seconds": 74,
        "length": "1:14",
        "file_name": "A19__053_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__053_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 54,
        "length_in_seconds": 64,
        "length": "1:04",
        "file_name": "A19__054_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__054_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 55,
        "length_in_seconds": 183,
        "length": "3:03",
        "file_name": "A19__055_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__055_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 56,
        "length_in_seconds": 102,
        "length": "1:42",
        "file_name": "A19__056_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__056_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 57,
        "length_in_seconds": 111,
        "length": "1:51",
        "file_name": "A19__057_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__057_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 58,
        "length_in_seconds": 91,
        "length": "1:31",
        "file_name": "A19__058_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__058_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 59,
        "length_in_seconds": 155,
        "length": "2:35",
        "file_name": "A19__059_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__059_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 60,
        "length_in_seconds": 111,
        "length": "1:51",
        "file_name": "A19__060_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__060_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 61,
        "length_in_seconds": 67,
        "length": "1:07",
        "file_name": "A19__061_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__061_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 62,
        "length_in_seconds": 117,
        "length": "1:57",
        "file_name": "A19__062_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__062_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 63,
        "length_in_seconds": 99,
        "length": "1:39",
        "file_name": "A19__063_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__063_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 64,
        "length_in_seconds": 79,
        "length": "1:19",
        "file_name": "A19__064_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__064_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 65,
        "length_in_seconds": 115,
        "length": "1:55",
        "file_name": "A19__065_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__065_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 66,
        "length_in_seconds": 155,
        "length": "2:35",
        "file_name": "A19__066_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__066_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 67,
        "length_in_seconds": 65,
        "length": "1:05",
        "file_name": "A19__067_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__067_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 68,
        "length_in_seconds": 289,
        "length": "4:49",
        "file_name": "A19__068_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__068_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 69,
        "length_in_seconds": 283,
        "length": "4:43",
        "file_name": "A19__069_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__069_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 70,
        "length_in_seconds": 59,
        "length": "0:59",
        "file_name": "A19__070_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__070_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 71,
        "length_in_seconds": 185,
        "length": "3:05",
        "file_name": "A19__071_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__071_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 72,
        "length_in_seconds": 162,
        "length": "2:42",
        "file_name": "A19__072_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__072_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 73,
        "length_in_seconds": 177,
        "length": "2:57",
        "file_name": "A19__073_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__073_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 74,
        "length_in_seconds": 180,
        "length": "3:00",
        "file_name": "A19__074_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__074_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 75,
        "length_in_seconds": 86,
        "length": "1:26",
        "file_name": "A19__075_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__075_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 76,
        "length_in_seconds": 96,
        "length": "1:36",
        "file_name": "A19__076_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__076_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 77,
        "length_in_seconds": 152,
        "length": "2:32",
        "file_name": "A19__077_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__077_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 78,
        "length_in_seconds": 459,
        "length": "7:39",
        "file_name": "A19__078_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__078_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 79,
        "length_in_seconds": 124,
        "length": "2:04",
        "file_name": "A19__079_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__079_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 80,
        "length_in_seconds": 138,
        "length": "2:18",
        "file_name": "A19__080_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__080_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 81,
        "length_in_seconds": 121,
        "length": "2:01",
        "file_name": "A19__081_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__081_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 82,
        "length_in_seconds": 63,
        "length": "1:03",
        "file_name": "A19__082_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__082_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 83,
        "length_in_seconds": 118,
        "length": "1:58",
        "file_name": "A19__083_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__083_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 84,
        "length_in_seconds": 120,
        "length": "2:00",
        "file_name": "A19__084_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__084_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 85,
        "length_in_seconds": 108,
        "length": "1:48",
        "file_name": "A19__085_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__085_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 86,
        "length_in_seconds": 134,
        "length": "2:14",
        "file_name": "A19__086_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__086_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 87,
        "length_in_seconds": 69,
        "length": "1:09",
        "file_name": "A19__087_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__087_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 88,
        "length_in_seconds": 164,
        "length": "2:44",
        "file_name": "A19__088_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__088_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 89,
        "length_in_seconds": 371,
        "length": "6:11",
        "file_name": "A19__089_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__089_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 90,
        "length_in_seconds": 135,
        "length": "2:15",
        "file_name": "A19__090_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__090_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 91,
        "length_in_seconds": 116,
        "length": "1:56",
        "file_name": "A19__091_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__091_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 92,
        "length_in_seconds": 102,
        "length": "1:42",
        "file_name": "A19__092_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__092_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 93,
        "length_in_seconds": 50,
        "length": "0:50",
        "file_name": "A19__093_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__093_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 94,
        "length_in_seconds": 140,
        "length": "2:20",
        "file_name": "A19__094_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__094_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 95,
        "length_in_seconds": 83,
        "length": "1:23",
        "file_name": "A19__095_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__095_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 96,
        "length_in_seconds": 105,
        "length": "1:45",
        "file_name": "A19__096_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__096_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 97,
        "length_in_seconds": 83,
        "length": "1:23",
        "file_name": "A19__097_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__097_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 98,
        "length_in_seconds": 78,
        "length": "1:18",
        "file_name": "A19__098_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__098_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 99,
        "length_in_seconds": 83,
        "length": "1:23",
        "file_name": "A19__099_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__099_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 100,
        "length_in_seconds": 50,
        "length": "0:50",
        "file_name": "A19__100_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__100_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 101,
        "length_in_seconds": 74,
        "length": "1:14",
        "file_name": "A19__101_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__101_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 102,
        "length_in_seconds": 205,
        "length": "3:25",
        "file_name": "A19__102_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__102_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 103,
        "length_in_seconds": 152,
        "length": "2:32",
        "file_name": "A19__103_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__103_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 104,
        "length_in_seconds": 280,
        "length": "4:40",
        "file_name": "A19__104_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__104_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 105,
        "length_in_seconds": 262,
        "length": "4:22",
        "file_name": "A19__105_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__105_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 106,
        "length_in_seconds": 283,
        "length": "4:43",
        "file_name": "A19__106_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__106_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 107,
        "length_in_seconds": 272,
        "length": "4:32",
        "file_name": "A19__107_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__107_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 108,
        "length_in_seconds": 96,
        "length": "1:36",
        "file_name": "A19__108_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__108_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 109,
        "length_in_seconds": 219,
        "length": "3:39",
        "file_name": "A19__109_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__109_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 110,
        "length_in_seconds": 62,
        "length": "1:02",
        "file_name": "A19__110_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__110_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 111,
        "length_in_seconds": 75,
        "length": "1:15",
        "file_name": "A19__111_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__111_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 112,
        "length_in_seconds": 75,
        "length": "1:15",
        "file_name": "A19__112_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__112_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 113,
        "length_in_seconds": 64,
        "length": "1:04",
        "file_name": "A19__113_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__113_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 114,
        "length_in_seconds": 52,
        "length": "0:52",
        "file_name": "A19__114_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__114_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 115,
        "length_in_seconds": 114,
        "length": "1:54",
        "file_name": "A19__115_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__115_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 116,
        "length_in_seconds": 121,
        "length": "2:01",
        "file_name": "A19__116_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__116_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 117,
        "length_in_seconds": 23,
        "length": "0:23",
        "file_name": "A19__117_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__117_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 118,
        "length_in_seconds": 198,
        "length": "3:18",
        "file_name": "A19__118_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__118_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 119,
        "length_in_seconds": 1061,
        "length": "17:41",
        "file_name": "A19__119_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__119_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 120,
        "length_in_seconds": 46,
        "length": "0:46",
        "file_name": "A19__120_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__120_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 121,
        "length_in_seconds": 49,
        "length": "0:49",
        "file_name": "A19__121_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__121_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 122,
        "length_in_seconds": 63,
        "length": "1:03",
        "file_name": "A19__122_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__122_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 123,
        "length_in_seconds": 47,
        "length": "0:47",
        "file_name": "A19__123_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__123_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 124,
        "length_in_seconds": 52,
        "length": "0:52",
        "file_name": "A19__124_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__124_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 125,
        "length_in_seconds": 49,
        "length": "0:49",
        "file_name": "A19__125_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__125_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 126,
        "length_in_seconds": 49,
        "length": "0:49",
        "file_name": "A19__126_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__126_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 127,
        "length_in_seconds": 56,
        "length": "0:56",
        "file_name": "A19__127_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__127_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 128,
        "length_in_seconds": 50,
        "length": "0:50",
        "file_name": "A19__128_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__128_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 129,
        "length_in_seconds": 52,
        "length": "0:52",
        "file_name": "A19__129_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__129_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 130,
        "length_in_seconds": 59,
        "length": "0:59",
        "file_name": "A19__130_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__130_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 131,
        "length_in_seconds": 36,
        "length": "0:36",
        "file_name": "A19__131_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__131_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 132,
        "length_in_seconds": 121,
        "length": "2:01",
        "file_name": "A19__132_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__132_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 133,
        "length_in_seconds": 37,
        "length": "0:37",
        "file_name": "A19__133_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__133_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 134,
        "length_in_seconds": 29,
        "length": "0:29",
        "file_name": "A19__134_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__134_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 135,
        "length_in_seconds": 119,
        "length": "1:59",
        "file_name": "A19__135_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__135_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 136,
        "length_in_seconds": 172,
        "length": "2:52",
        "file_name": "A19__136_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__136_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 137,
        "length_in_seconds": 68,
        "length": "1:08",
        "file_name": "A19__137_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__137_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 138,
        "length_in_seconds": 76,
        "length": "1:16",
        "file_name": "A19__138_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__138_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 139,
        "length_in_seconds": 165,
        "length": "2:45",
        "file_name": "A19__139_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__139_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 140,
        "length_in_seconds": 107,
        "length": "1:47",
        "file_name": "A19__140_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__140_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 141,
        "length_in_seconds": 99,
        "length": "1:39",
        "file_name": "A19__141_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__141_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 142,
        "length_in_seconds": 68,
        "length": "1:08",
        "file_name": "A19__142_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__142_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 143,
        "length_in_seconds": 111,
        "length": "1:51",
        "file_name": "A19__143_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__143_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 144,
        "length_in_seconds": 125,
        "length": "2:05",
        "file_name": "A19__144_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__144_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 145,
        "length_in_seconds": 151,
        "length": "2:31",
        "file_name": "A19__145_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__145_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 146,
        "length_in_seconds": 77,
        "length": "1:17",
        "file_name": "A19__146_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__146_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 147,
        "length_in_seconds": 127,
        "length": "2:07",
        "file_name": "A19__147_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__147_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 148,
        "length_in_seconds": 103,
        "length": "1:43",
        "file_name": "A19__148_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__148_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 149,
        "length_in_seconds": 62,
        "length": "1:02",
        "file_name": "A19__149_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__149_Psalms______ENGESVO2DA.mp3"
      },
      {
        "book": "Psalms",
        "number": 19,
        "chapter": 150,
        "length_in_seconds": 58,
        "length": "0:58",
        "file_name": "A19__150_Psalms______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A19__150_Psalms______ENGESVO2DA.mp3"
      }
    ],
    "Proverbs": [
      {
        "book": "Proverbs",
        "number": 20,
        "chapter": 1,
        "length_in_seconds": 222,
        "length": "3:42",
        "file_name": "A20___01_Proverbs____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A20___01_Proverbs____ENGESVO2DA.mp3"
      },
      {
        "book": "Proverbs",
        "number": 20,
        "chapter": 2,
        "length_in_seconds": 133,
        "length": "2:13",
        "file_name": "A20___02_Proverbs____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A20___02_Proverbs____ENGESVO2DA.mp3"
      },
      {
        "book": "Proverbs",
        "number": 20,
        "chapter": 3,
        "length_in_seconds": 218,
        "length": "3:38",
        "file_name": "A20___03_Proverbs____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A20___03_Proverbs____ENGESVO2DA.mp3"
      },
      {
        "book": "Proverbs",
        "number": 20,
        "chapter": 4,
        "length_in_seconds": 169,
        "length": "2:49",
        "file_name": "A20___04_Proverbs____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A20___04_Proverbs____ENGESVO2DA.mp3"
      },
      {
        "book": "Proverbs",
        "number": 20,
        "chapter": 5,
        "length_in_seconds": 138,
        "length": "2:18",
        "file_name": "A20___05_Proverbs____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A20___05_Proverbs____ENGESVO2DA.mp3"
      },
      {
        "book": "Proverbs",
        "number": 20,
        "chapter": 6,
        "length_in_seconds": 212,
        "length": "3:32",
        "file_name": "A20___06_Proverbs____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A20___06_Proverbs____ENGESVO2DA.mp3"
      },
      {
        "book": "Proverbs",
        "number": 20,
        "chapter": 7,
        "length_in_seconds": 177,
        "length": "2:57",
        "file_name": "A20___07_Proverbs____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A20___07_Proverbs____ENGESVO2DA.mp3"
      },
      {
        "book": "Proverbs",
        "number": 20,
        "chapter": 8,
        "length_in_seconds": 223,
        "length": "3:43",
        "file_name": "A20___08_Proverbs____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A20___08_Proverbs____ENGESVO2DA.mp3"
      },
      {
        "book": "Proverbs",
        "number": 20,
        "chapter": 9,
        "length_in_seconds": 135,
        "length": "2:15",
        "file_name": "A20___09_Proverbs____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A20___09_Proverbs____ENGESVO2DA.mp3"
      },
      {
        "book": "Proverbs",
        "number": 20,
        "chapter": 10,
        "length_in_seconds": 221,
        "length": "3:41",
        "file_name": "A20___10_Proverbs____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A20___10_Proverbs____ENGESVO2DA.mp3"
      },
      {
        "book": "Proverbs",
        "number": 20,
        "chapter": 11,
        "length_in_seconds": 236,
        "length": "3:56",
        "file_name": "A20___11_Proverbs____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A20___11_Proverbs____ENGESVO2DA.mp3"
      },
      {
        "book": "Proverbs",
        "number": 20,
        "chapter": 12,
        "length_in_seconds": 213,
        "length": "3:33",
        "file_name": "A20___12_Proverbs____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A20___12_Proverbs____ENGESVO2DA.mp3"
      },
      {
        "book": "Proverbs",
        "number": 20,
        "chapter": 13,
        "length_in_seconds": 191,
        "length": "3:11",
        "file_name": "A20___13_Proverbs____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A20___13_Proverbs____ENGESVO2DA.mp3"
      },
      {
        "book": "Proverbs",
        "number": 20,
        "chapter": 14,
        "length_in_seconds": 250,
        "length": "4:10",
        "file_name": "A20___14_Proverbs____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A20___14_Proverbs____ENGESVO2DA.mp3"
      },
      {
        "book": "Proverbs",
        "number": 20,
        "chapter": 15,
        "length_in_seconds": 234,
        "length": "3:54",
        "file_name": "A20___15_Proverbs____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A20___15_Proverbs____ENGESVO2DA.mp3"
      },
      {
        "book": "Proverbs",
        "number": 20,
        "chapter": 16,
        "length_in_seconds": 223,
        "length": "3:43",
        "file_name": "A20___16_Proverbs____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A20___16_Proverbs____ENGESVO2DA.mp3"
      },
      {
        "book": "Proverbs",
        "number": 20,
        "chapter": 17,
        "length_in_seconds": 212,
        "length": "3:32",
        "file_name": "A20___17_Proverbs____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A20___17_Proverbs____ENGESVO2DA.mp3"
      },
      {
        "book": "Proverbs",
        "number": 20,
        "chapter": 18,
        "length_in_seconds": 175,
        "length": "2:55",
        "file_name": "A20___18_Proverbs____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A20___18_Proverbs____ENGESVO2DA.mp3"
      },
      {
        "book": "Proverbs",
        "number": 20,
        "chapter": 19,
        "length_in_seconds": 236,
        "length": "3:56",
        "file_name": "A20___19_Proverbs____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A20___19_Proverbs____ENGESVO2DA.mp3"
      },
      {
        "book": "Proverbs",
        "number": 20,
        "chapter": 20,
        "length_in_seconds": 230,
        "length": "3:50",
        "file_name": "A20___20_Proverbs____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A20___20_Proverbs____ENGESVO2DA.mp3"
      },
      {
        "book": "Proverbs",
        "number": 20,
        "chapter": 21,
        "length_in_seconds": 246,
        "length": "4:06",
        "file_name": "A20___21_Proverbs____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A20___21_Proverbs____ENGESVO2DA.mp3"
      },
      {
        "book": "Proverbs",
        "number": 20,
        "chapter": 22,
        "length_in_seconds": 206,
        "length": "3:26",
        "file_name": "A20___22_Proverbs____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A20___22_Proverbs____ENGESVO2DA.mp3"
      },
      {
        "book": "Proverbs",
        "number": 20,
        "chapter": 23,
        "length_in_seconds": 250,
        "length": "4:10",
        "file_name": "A20___23_Proverbs____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A20___23_Proverbs____ENGESVO2DA.mp3"
      },
      {
        "book": "Proverbs",
        "number": 20,
        "chapter": 24,
        "length_in_seconds": 252,
        "length": "4:12",
        "file_name": "A20___24_Proverbs____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A20___24_Proverbs____ENGESVO2DA.mp3"
      },
      {
        "book": "Proverbs",
        "number": 20,
        "chapter": 25,
        "length_in_seconds": 208,
        "length": "3:28",
        "file_name": "A20___25_Proverbs____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A20___25_Proverbs____ENGESVO2DA.mp3"
      },
      {
        "book": "Proverbs",
        "number": 20,
        "chapter": 26,
        "length_in_seconds": 230,
        "length": "3:50",
        "file_name": "A20___26_Proverbs____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A20___26_Proverbs____ENGESVO2DA.mp3"
      },
      {
        "book": "Proverbs",
        "number": 20,
        "chapter": 27,
        "length_in_seconds": 208,
        "length": "3:28",
        "file_name": "A20___27_Proverbs____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A20___27_Proverbs____ENGESVO2DA.mp3"
      },
      {
        "book": "Proverbs",
        "number": 20,
        "chapter": 28,
        "length_in_seconds": 228,
        "length": "3:48",
        "file_name": "A20___28_Proverbs____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A20___28_Proverbs____ENGESVO2DA.mp3"
      },
      {
        "book": "Proverbs",
        "number": 20,
        "chapter": 29,
        "length_in_seconds": 200,
        "length": "3:20",
        "file_name": "A20___29_Proverbs____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A20___29_Proverbs____ENGESVO2DA.mp3"
      },
      {
        "book": "Proverbs",
        "number": 20,
        "chapter": 30,
        "length_in_seconds": 265,
        "length": "4:25",
        "file_name": "A20___30_Proverbs____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A20___30_Proverbs____ENGESVO2DA.mp3"
      },
      {
        "book": "Proverbs",
        "number": 20,
        "chapter": 31,
        "length_in_seconds": 228,
        "length": "3:48",
        "file_name": "A20___31_Proverbs____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A20___31_Proverbs____ENGESVO2DA.mp3"
      }
    ],
    "Ecclesiastes": [
      {
        "book": "Ecclesiastes",
        "number": 21,
        "chapter": 1,
        "length_in_seconds": 150,
        "length": "2:30",
        "file_name": "A21___01_EcclesiastesENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A21___01_EcclesiastesENGESVO2DA.mp3"
      },
      {
        "book": "Ecclesiastes",
        "number": 21,
        "chapter": 2,
        "length_in_seconds": 277,
        "length": "4:37",
        "file_name": "A21___02_EcclesiastesENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A21___02_EcclesiastesENGESVO2DA.mp3"
      },
      {
        "book": "Ecclesiastes",
        "number": 21,
        "chapter": 3,
        "length_in_seconds": 186,
        "length": "3:06",
        "file_name": "A21___03_EcclesiastesENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A21___03_EcclesiastesENGESVO2DA.mp3"
      },
      {
        "book": "Ecclesiastes",
        "number": 21,
        "chapter": 4,
        "length_in_seconds": 144,
        "length": "2:24",
        "file_name": "A21___04_EcclesiastesENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A21___04_EcclesiastesENGESVO2DA.mp3"
      },
      {
        "book": "Ecclesiastes",
        "number": 21,
        "chapter": 5,
        "length_in_seconds": 197,
        "length": "3:17",
        "file_name": "A21___05_EcclesiastesENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A21___05_EcclesiastesENGESVO2DA.mp3"
      },
      {
        "book": "Ecclesiastes",
        "number": 21,
        "chapter": 6,
        "length_in_seconds": 119,
        "length": "1:59",
        "file_name": "A21___06_EcclesiastesENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A21___06_EcclesiastesENGESVO2DA.mp3"
      },
      {
        "book": "Ecclesiastes",
        "number": 21,
        "chapter": 7,
        "length_in_seconds": 233,
        "length": "3:53",
        "file_name": "A21___07_EcclesiastesENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A21___07_EcclesiastesENGESVO2DA.mp3"
      },
      {
        "book": "Ecclesiastes",
        "number": 21,
        "chapter": 8,
        "length_in_seconds": 167,
        "length": "2:47",
        "file_name": "A21___08_EcclesiastesENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A21___08_EcclesiastesENGESVO2DA.mp3"
      },
      {
        "book": "Ecclesiastes",
        "number": 21,
        "chapter": 9,
        "length_in_seconds": 190,
        "length": "3:10",
        "file_name": "A21___09_EcclesiastesENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A21___09_EcclesiastesENGESVO2DA.mp3"
      },
      {
        "book": "Ecclesiastes",
        "number": 21,
        "chapter": 10,
        "length_in_seconds": 145,
        "length": "2:25",
        "file_name": "A21___10_EcclesiastesENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A21___10_EcclesiastesENGESVO2DA.mp3"
      },
      {
        "book": "Ecclesiastes",
        "number": 21,
        "chapter": 11,
        "length_in_seconds": 92,
        "length": "1:32",
        "file_name": "A21___11_EcclesiastesENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A21___11_EcclesiastesENGESVO2DA.mp3"
      },
      {
        "book": "Ecclesiastes",
        "number": 21,
        "chapter": 12,
        "length_in_seconds": 141,
        "length": "2:21",
        "file_name": "A21___12_EcclesiastesENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A21___12_EcclesiastesENGESVO2DA.mp3"
      }
    ],
    "Songof Songs": [
      {
        "book": "Songof Songs",
        "number": 22,
        "chapter": 1,
        "length_in_seconds": 135,
        "length": "2:15",
        "file_name": "A22___01_SongofSongs_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A22___01_SongofSongs_ENGESVO2DA.mp3"
      },
      {
        "book": "Songof Songs",
        "number": 22,
        "chapter": 2,
        "length_in_seconds": 156,
        "length": "2:36",
        "file_name": "A22___02_SongofSongs_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A22___02_SongofSongs_ENGESVO2DA.mp3"
      },
      {
        "book": "Songof Songs",
        "number": 22,
        "chapter": 3,
        "length_in_seconds": 119,
        "length": "1:59",
        "file_name": "A22___03_SongofSongs_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A22___03_SongofSongs_ENGESVO2DA.mp3"
      },
      {
        "book": "Songof Songs",
        "number": 22,
        "chapter": 4,
        "length_in_seconds": 156,
        "length": "2:36",
        "file_name": "A22___04_SongofSongs_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A22___04_SongofSongs_ENGESVO2DA.mp3"
      },
      {
        "book": "Songof Songs",
        "number": 22,
        "chapter": 5,
        "length_in_seconds": 182,
        "length": "3:02",
        "file_name": "A22___05_SongofSongs_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A22___05_SongofSongs_ENGESVO2DA.mp3"
      },
      {
        "book": "Songof Songs",
        "number": 22,
        "chapter": 6,
        "length_in_seconds": 124,
        "length": "2:04",
        "file_name": "A22___06_SongofSongs_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A22___06_SongofSongs_ENGESVO2DA.mp3"
      },
      {
        "book": "Songof Songs",
        "number": 22,
        "chapter": 7,
        "length_in_seconds": 115,
        "length": "1:55",
        "file_name": "A22___07_SongofSongs_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A22___07_SongofSongs_ENGESVO2DA.mp3"
      },
      {
        "book": "Songof Songs",
        "number": 22,
        "chapter": 8,
        "length_in_seconds": 140,
        "length": "2:20",
        "file_name": "A22___08_SongofSongs_ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A22___08_SongofSongs_ENGESVO2DA.mp3"
      }
    ],
    "Isaiah": [
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 1,
        "length_in_seconds": 311,
        "length": "5:11",
        "file_name": "A23___01_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___01_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 2,
        "length_in_seconds": 196,
        "length": "3:16",
        "file_name": "A23___02_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___02_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 3,
        "length_in_seconds": 214,
        "length": "3:34",
        "file_name": "A23___03_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___03_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 4,
        "length_in_seconds": 69,
        "length": "1:09",
        "file_name": "A23___04_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___04_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 5,
        "length_in_seconds": 302,
        "length": "5:02",
        "file_name": "A23___05_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___05_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 6,
        "length_in_seconds": 144,
        "length": "2:24",
        "file_name": "A23___06_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___06_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 7,
        "length_in_seconds": 240,
        "length": "4:00",
        "file_name": "A23___07_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___07_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 8,
        "length_in_seconds": 205,
        "length": "3:25",
        "file_name": "A23___08_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___08_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 9,
        "length_in_seconds": 222,
        "length": "3:42",
        "file_name": "A23___09_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___09_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 10,
        "length_in_seconds": 337,
        "length": "5:37",
        "file_name": "A23___10_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___10_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 11,
        "length_in_seconds": 179,
        "length": "2:59",
        "file_name": "A23___11_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___11_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 12,
        "length_in_seconds": 57,
        "length": "0:57",
        "file_name": "A23___12_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___12_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 13,
        "length_in_seconds": 204,
        "length": "3:24",
        "file_name": "A23___13_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___13_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 14,
        "length_in_seconds": 308,
        "length": "5:08",
        "file_name": "A23___14_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___14_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 15,
        "length_in_seconds": 101,
        "length": "1:41",
        "file_name": "A23___15_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___15_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 16,
        "length_in_seconds": 169,
        "length": "2:49",
        "file_name": "A23___16_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___16_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 17,
        "length_in_seconds": 149,
        "length": "2:29",
        "file_name": "A23___17_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___17_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 18,
        "length_in_seconds": 89,
        "length": "1:29",
        "file_name": "A23___18_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___18_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 19,
        "length_in_seconds": 250,
        "length": "4:10",
        "file_name": "A23___19_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___19_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 20,
        "length_in_seconds": 77,
        "length": "1:17",
        "file_name": "A23___20_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___20_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 21,
        "length_in_seconds": 166,
        "length": "2:46",
        "file_name": "A23___21_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___21_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 22,
        "length_in_seconds": 231,
        "length": "3:51",
        "file_name": "A23___22_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___22_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 23,
        "length_in_seconds": 178,
        "length": "2:58",
        "file_name": "A23___23_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___23_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 24,
        "length_in_seconds": 223,
        "length": "3:43",
        "file_name": "A23___24_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___24_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 25,
        "length_in_seconds": 150,
        "length": "2:30",
        "file_name": "A23___25_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___25_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 26,
        "length_in_seconds": 208,
        "length": "3:28",
        "file_name": "A23___26_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___26_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 27,
        "length_in_seconds": 143,
        "length": "2:23",
        "file_name": "A23___27_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___27_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 28,
        "length_in_seconds": 323,
        "length": "5:23",
        "file_name": "A23___28_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___28_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 29,
        "length_in_seconds": 282,
        "length": "4:42",
        "file_name": "A23___29_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___29_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 30,
        "length_in_seconds": 388,
        "length": "6:28",
        "file_name": "A23___30_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___30_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 31,
        "length_in_seconds": 115,
        "length": "1:55",
        "file_name": "A23___31_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___31_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 32,
        "length_in_seconds": 172,
        "length": "2:52",
        "file_name": "A23___32_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___32_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 33,
        "length_in_seconds": 252,
        "length": "4:12",
        "file_name": "A23___33_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___33_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 34,
        "length_in_seconds": 190,
        "length": "3:10",
        "file_name": "A23___34_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___34_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 35,
        "length_in_seconds": 110,
        "length": "1:50",
        "file_name": "A23___35_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___35_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 36,
        "length_in_seconds": 264,
        "length": "4:24",
        "file_name": "A23___36_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___36_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 37,
        "length_in_seconds": 417,
        "length": "6:57",
        "file_name": "A23___37_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___37_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 38,
        "length_in_seconds": 220,
        "length": "3:40",
        "file_name": "A23___38_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___38_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 39,
        "length_in_seconds": 101,
        "length": "1:41",
        "file_name": "A23___39_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___39_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 40,
        "length_in_seconds": 309,
        "length": "5:09",
        "file_name": "A23___40_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___40_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 41,
        "length_in_seconds": 308,
        "length": "5:08",
        "file_name": "A23___41_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___41_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 42,
        "length_in_seconds": 265,
        "length": "4:25",
        "file_name": "A23___42_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___42_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 43,
        "length_in_seconds": 271,
        "length": "4:31",
        "file_name": "A23___43_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___43_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 44,
        "length_in_seconds": 331,
        "length": "5:31",
        "file_name": "A23___44_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___44_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 45,
        "length_in_seconds": 286,
        "length": "4:46",
        "file_name": "A23___45_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___45_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 46,
        "length_in_seconds": 132,
        "length": "2:12",
        "file_name": "A23___46_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___46_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 47,
        "length_in_seconds": 194,
        "length": "3:14",
        "file_name": "A23___47_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___47_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 48,
        "length_in_seconds": 234,
        "length": "3:54",
        "file_name": "A23___48_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___48_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 49,
        "length_in_seconds": 319,
        "length": "5:19",
        "file_name": "A23___49_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___49_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 50,
        "length_in_seconds": 145,
        "length": "2:25",
        "file_name": "A23___50_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___50_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 51,
        "length_in_seconds": 266,
        "length": "4:26",
        "file_name": "A23___51_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___51_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 52,
        "length_in_seconds": 165,
        "length": "2:45",
        "file_name": "A23___52_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___52_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 53,
        "length_in_seconds": 160,
        "length": "2:40",
        "file_name": "A23___53_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___53_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 54,
        "length_in_seconds": 183,
        "length": "3:03",
        "file_name": "A23___54_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___54_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 55,
        "length_in_seconds": 147,
        "length": "2:27",
        "file_name": "A23___55_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___55_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 56,
        "length_in_seconds": 138,
        "length": "2:18",
        "file_name": "A23___56_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___56_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 57,
        "length_in_seconds": 227,
        "length": "3:47",
        "file_name": "A23___57_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___57_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 58,
        "length_in_seconds": 191,
        "length": "3:11",
        "file_name": "A23___58_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___58_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 59,
        "length_in_seconds": 243,
        "length": "4:03",
        "file_name": "A23___59_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___59_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 60,
        "length_in_seconds": 256,
        "length": "4:16",
        "file_name": "A23___60_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___60_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 61,
        "length_in_seconds": 154,
        "length": "2:34",
        "file_name": "A23___61_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___61_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 62,
        "length_in_seconds": 135,
        "length": "2:15",
        "file_name": "A23___62_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___62_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 63,
        "length_in_seconds": 215,
        "length": "3:35",
        "file_name": "A23___63_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___63_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 64,
        "length_in_seconds": 134,
        "length": "2:14",
        "file_name": "A23___64_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___64_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 65,
        "length_in_seconds": 295,
        "length": "4:55",
        "file_name": "A23___65_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___65_Isaiah______ENGESVO2DA.mp3"
      },
      {
        "book": "Isaiah",
        "number": 23,
        "chapter": 66,
        "length_in_seconds": 316,
        "length": "5:16",
        "file_name": "A23___66_Isaiah______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A23___66_Isaiah______ENGESVO2DA.mp3"
      }
    ],
    "Jeremiah": [
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 1,
        "length_in_seconds": 210,
        "length": "3:30",
        "file_name": "A24___01_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___01_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 2,
        "length_in_seconds": 380,
        "length": "6:20",
        "file_name": "A24___02_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___02_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 3,
        "length_in_seconds": 315,
        "length": "5:15",
        "file_name": "A24___03_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___03_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 4,
        "length_in_seconds": 334,
        "length": "5:34",
        "file_name": "A24___04_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___04_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 5,
        "length_in_seconds": 328,
        "length": "5:28",
        "file_name": "A24___05_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___05_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 6,
        "length_in_seconds": 335,
        "length": "5:35",
        "file_name": "A24___06_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___06_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 7,
        "length_in_seconds": 356,
        "length": "5:56",
        "file_name": "A24___07_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___07_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 8,
        "length_in_seconds": 262,
        "length": "4:22",
        "file_name": "A24___08_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___08_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 9,
        "length_in_seconds": 300,
        "length": "5:00",
        "file_name": "A24___09_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___09_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 10,
        "length_in_seconds": 243,
        "length": "4:03",
        "file_name": "A24___10_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___10_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 11,
        "length_in_seconds": 267,
        "length": "4:27",
        "file_name": "A24___11_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___11_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 12,
        "length_in_seconds": 205,
        "length": "3:25",
        "file_name": "A24___12_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___12_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 13,
        "length_in_seconds": 273,
        "length": "4:33",
        "file_name": "A24___13_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___13_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 14,
        "length_in_seconds": 280,
        "length": "4:40",
        "file_name": "A24___14_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___14_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 15,
        "length_in_seconds": 265,
        "length": "4:25",
        "file_name": "A24___15_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___15_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 16,
        "length_in_seconds": 254,
        "length": "4:14",
        "file_name": "A24___16_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___16_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 17,
        "length_in_seconds": 298,
        "length": "4:58",
        "file_name": "A24___17_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___17_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 18,
        "length_in_seconds": 235,
        "length": "3:55",
        "file_name": "A24___18_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___18_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 19,
        "length_in_seconds": 194,
        "length": "3:14",
        "file_name": "A24___19_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___19_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 20,
        "length_in_seconds": 228,
        "length": "3:48",
        "file_name": "A24___20_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___20_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 21,
        "length_in_seconds": 179,
        "length": "2:59",
        "file_name": "A24___21_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___21_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 22,
        "length_in_seconds": 321,
        "length": "5:21",
        "file_name": "A24___22_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___22_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 23,
        "length_in_seconds": 452,
        "length": "7:32",
        "file_name": "A24___23_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___23_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 24,
        "length_in_seconds": 128,
        "length": "2:08",
        "file_name": "A24___24_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___24_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 25,
        "length_in_seconds": 388,
        "length": "6:28",
        "file_name": "A24___25_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___25_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 26,
        "length_in_seconds": 280,
        "length": "4:40",
        "file_name": "A24___26_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___26_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 27,
        "length_in_seconds": 252,
        "length": "4:12",
        "file_name": "A24___27_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___27_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 28,
        "length_in_seconds": 203,
        "length": "3:23",
        "file_name": "A24___28_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___28_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 29,
        "length_in_seconds": 352,
        "length": "5:52",
        "file_name": "A24___29_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___29_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 30,
        "length_in_seconds": 261,
        "length": "4:21",
        "file_name": "A24___30_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___30_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 31,
        "length_in_seconds": 472,
        "length": "7:52",
        "file_name": "A24___31_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___31_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 32,
        "length_in_seconds": 476,
        "length": "7:56",
        "file_name": "A24___32_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___32_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 33,
        "length_in_seconds": 303,
        "length": "5:03",
        "file_name": "A24___33_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___33_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 34,
        "length_in_seconds": 275,
        "length": "4:35",
        "file_name": "A24___34_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___34_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 35,
        "length_in_seconds": 232,
        "length": "3:52",
        "file_name": "A24___35_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___35_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 36,
        "length_in_seconds": 366,
        "length": "6:06",
        "file_name": "A24___36_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___36_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 37,
        "length_in_seconds": 224,
        "length": "3:44",
        "file_name": "A24___37_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___37_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 38,
        "length_in_seconds": 331,
        "length": "5:31",
        "file_name": "A24___38_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___38_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 39,
        "length_in_seconds": 196,
        "length": "3:16",
        "file_name": "A24___39_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___39_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 40,
        "length_in_seconds": 237,
        "length": "3:57",
        "file_name": "A24___40_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___40_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 41,
        "length_in_seconds": 221,
        "length": "3:41",
        "file_name": "A24___41_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___41_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 42,
        "length_in_seconds": 242,
        "length": "4:02",
        "file_name": "A24___42_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___42_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 43,
        "length_in_seconds": 158,
        "length": "2:38",
        "file_name": "A24___43_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___43_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 44,
        "length_in_seconds": 428,
        "length": "7:08",
        "file_name": "A24___44_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___44_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 45,
        "length_in_seconds": 61,
        "length": "1:01",
        "file_name": "A24___45_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___45_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 46,
        "length_in_seconds": 295,
        "length": "4:55",
        "file_name": "A24___46_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___46_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 47,
        "length_in_seconds": 84,
        "length": "1:24",
        "file_name": "A24___47_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___47_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 48,
        "length_in_seconds": 459,
        "length": "7:39",
        "file_name": "A24___48_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___48_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 49,
        "length_in_seconds": 431,
        "length": "7:11",
        "file_name": "A24___49_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___49_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 50,
        "length_in_seconds": 540,
        "length": "9:00",
        "file_name": "A24___50_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___50_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 51,
        "length_in_seconds": 675,
        "length": "11:15",
        "file_name": "A24___51_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___51_Jeremiah____ENGESVO2DA.mp3"
      },
      {
        "book": "Jeremiah",
        "number": 24,
        "chapter": 52,
        "length_in_seconds": 370,
        "length": "6:10",
        "file_name": "A24___52_Jeremiah____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A24___52_Jeremiah____ENGESVO2DA.mp3"
      }
    ],
    "Lamentations": [
      {
        "book": "Lamentations",
        "number": 25,
        "chapter": 1,
        "length_in_seconds": 321,
        "length": "5:21",
        "file_name": "A25___01_LamentationsENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A25___01_LamentationsENGESVO2DA.mp3"
      },
      {
        "book": "Lamentations",
        "number": 25,
        "chapter": 2,
        "length_in_seconds": 335,
        "length": "5:35",
        "file_name": "A25___02_LamentationsENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A25___02_LamentationsENGESVO2DA.mp3"
      },
      {
        "book": "Lamentations",
        "number": 25,
        "chapter": 3,
        "length_in_seconds": 331,
        "length": "5:31",
        "file_name": "A25___03_LamentationsENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A25___03_LamentationsENGESVO2DA.mp3"
      },
      {
        "book": "Lamentations",
        "number": 25,
        "chapter": 4,
        "length_in_seconds": 239,
        "length": "3:59",
        "file_name": "A25___04_LamentationsENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A25___04_LamentationsENGESVO2DA.mp3"
      },
      {
        "book": "Lamentations",
        "number": 25,
        "chapter": 5,
        "length_in_seconds": 127,
        "length": "2:07",
        "file_name": "A25___05_LamentationsENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A25___05_LamentationsENGESVO2DA.mp3"
      }
    ],
    "Ezekiel": [
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 1,
        "length_in_seconds": 340,
        "length": "5:40",
        "file_name": "A26___01_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___01_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 2,
        "length_in_seconds": 111,
        "length": "1:51",
        "file_name": "A26___02_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___02_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 3,
        "length_in_seconds": 312,
        "length": "5:12",
        "file_name": "A26___03_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___03_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 4,
        "length_in_seconds": 195,
        "length": "3:15",
        "file_name": "A26___04_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___04_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 5,
        "length_in_seconds": 258,
        "length": "4:18",
        "file_name": "A26___05_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___05_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 6,
        "length_in_seconds": 201,
        "length": "3:21",
        "file_name": "A26___06_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___06_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 7,
        "length_in_seconds": 344,
        "length": "5:44",
        "file_name": "A26___07_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___07_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 8,
        "length_in_seconds": 250,
        "length": "4:10",
        "file_name": "A26___08_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___08_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 9,
        "length_in_seconds": 153,
        "length": "2:33",
        "file_name": "A26___09_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___09_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 10,
        "length_in_seconds": 249,
        "length": "4:09",
        "file_name": "A26___10_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___10_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 11,
        "length_in_seconds": 277,
        "length": "4:37",
        "file_name": "A26___11_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___11_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 12,
        "length_in_seconds": 328,
        "length": "5:28",
        "file_name": "A26___12_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___12_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 13,
        "length_in_seconds": 311,
        "length": "5:11",
        "file_name": "A26___13_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___13_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 14,
        "length_in_seconds": 297,
        "length": "4:57",
        "file_name": "A26___14_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___14_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 15,
        "length_in_seconds": 78,
        "length": "1:18",
        "file_name": "A26___15_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___15_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 16,
        "length_in_seconds": 749,
        "length": "12:29",
        "file_name": "A26___16_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___16_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 17,
        "length_in_seconds": 312,
        "length": "5:12",
        "file_name": "A26___17_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___17_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 18,
        "length_in_seconds": 376,
        "length": "6:16",
        "file_name": "A26___18_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___18_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 19,
        "length_in_seconds": 144,
        "length": "2:24",
        "file_name": "A26___19_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___19_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 20,
        "length_in_seconds": 628,
        "length": "10:28",
        "file_name": "A26___20_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___20_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 21,
        "length_in_seconds": 383,
        "length": "6:23",
        "file_name": "A26___21_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___21_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 22,
        "length_in_seconds": 358,
        "length": "5:58",
        "file_name": "A26___22_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___22_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 23,
        "length_in_seconds": 553,
        "length": "9:13",
        "file_name": "A26___23_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___23_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 24,
        "length_in_seconds": 295,
        "length": "4:55",
        "file_name": "A26___24_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___24_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 25,
        "length_in_seconds": 218,
        "length": "3:38",
        "file_name": "A26___25_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___25_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 26,
        "length_in_seconds": 297,
        "length": "4:57",
        "file_name": "A26___26_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___26_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 27,
        "length_in_seconds": 381,
        "length": "6:21",
        "file_name": "A26___27_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___27_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 28,
        "length_in_seconds": 337,
        "length": "5:37",
        "file_name": "A26___28_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___28_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 29,
        "length_in_seconds": 279,
        "length": "4:39",
        "file_name": "A26___29_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___29_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 30,
        "length_in_seconds": 304,
        "length": "5:04",
        "file_name": "A26___30_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___30_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 31,
        "length_in_seconds": 267,
        "length": "4:27",
        "file_name": "A26___31_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___31_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 32,
        "length_in_seconds": 425,
        "length": "7:05",
        "file_name": "A26___32_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___32_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 33,
        "length_in_seconds": 423,
        "length": "7:03",
        "file_name": "A26___33_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___33_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 34,
        "length_in_seconds": 395,
        "length": "6:35",
        "file_name": "A26___34_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___34_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 35,
        "length_in_seconds": 170,
        "length": "2:50",
        "file_name": "A26___35_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___35_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 36,
        "length_in_seconds": 485,
        "length": "8:05",
        "file_name": "A26___36_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___36_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 37,
        "length_in_seconds": 341,
        "length": "5:41",
        "file_name": "A26___37_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___37_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 38,
        "length_in_seconds": 311,
        "length": "5:11",
        "file_name": "A26___38_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___38_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 39,
        "length_in_seconds": 376,
        "length": "6:16",
        "file_name": "A26___39_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___39_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 40,
        "length_in_seconds": 559,
        "length": "9:19",
        "file_name": "A26___40_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___40_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 41,
        "length_in_seconds": 295,
        "length": "4:55",
        "file_name": "A26___41_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___41_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 42,
        "length_in_seconds": 203,
        "length": "3:23",
        "file_name": "A26___42_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___42_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 43,
        "length_in_seconds": 336,
        "length": "5:36",
        "file_name": "A26___43_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___43_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 44,
        "length_in_seconds": 390,
        "length": "6:30",
        "file_name": "A26___44_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___44_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 45,
        "length_in_seconds": 331,
        "length": "5:31",
        "file_name": "A26___45_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___45_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 46,
        "length_in_seconds": 320,
        "length": "5:20",
        "file_name": "A26___46_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___46_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 47,
        "length_in_seconds": 275,
        "length": "4:35",
        "file_name": "A26___47_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___47_Ezekiel_____ENGESVO2DA.mp3"
      },
      {
        "book": "Ezekiel",
        "number": 26,
        "chapter": 48,
        "length_in_seconds": 396,
        "length": "6:36",
        "file_name": "A26___48_Ezekiel_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A26___48_Ezekiel_____ENGESVO2DA.mp3"
      }
    ],
    "Daniel": [
      {
        "book": "Daniel",
        "number": 27,
        "chapter": 1,
        "length_in_seconds": 185,
        "length": "3:05",
        "file_name": "A27___01_Daniel______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A27___01_Daniel______ENGESVO2DA.mp3"
      },
      {
        "book": "Daniel",
        "number": 27,
        "chapter": 2,
        "length_in_seconds": 557,
        "length": "9:17",
        "file_name": "A27___02_Daniel______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A27___02_Daniel______ENGESVO2DA.mp3"
      },
      {
        "book": "Daniel",
        "number": 27,
        "chapter": 3,
        "length_in_seconds": 387,
        "length": "6:27",
        "file_name": "A27___03_Daniel______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A27___03_Daniel______ENGESVO2DA.mp3"
      },
      {
        "book": "Daniel",
        "number": 27,
        "chapter": 4,
        "length_in_seconds": 524,
        "length": "8:44",
        "file_name": "A27___04_Daniel______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A27___04_Daniel______ENGESVO2DA.mp3"
      },
      {
        "book": "Daniel",
        "number": 27,
        "chapter": 5,
        "length_in_seconds": 360,
        "length": "6:00",
        "file_name": "A27___05_Daniel______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A27___05_Daniel______ENGESVO2DA.mp3"
      },
      {
        "book": "Daniel",
        "number": 27,
        "chapter": 6,
        "length_in_seconds": 321,
        "length": "5:21",
        "file_name": "A27___06_Daniel______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A27___06_Daniel______ENGESVO2DA.mp3"
      },
      {
        "book": "Daniel",
        "number": 27,
        "chapter": 7,
        "length_in_seconds": 384,
        "length": "6:24",
        "file_name": "A27___07_Daniel______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A27___07_Daniel______ENGESVO2DA.mp3"
      },
      {
        "book": "Daniel",
        "number": 27,
        "chapter": 8,
        "length_in_seconds": 332,
        "length": "5:32",
        "file_name": "A27___08_Daniel______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A27___08_Daniel______ENGESVO2DA.mp3"
      },
      {
        "book": "Daniel",
        "number": 27,
        "chapter": 9,
        "length_in_seconds": 394,
        "length": "6:34",
        "file_name": "A27___09_Daniel______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A27___09_Daniel______ENGESVO2DA.mp3"
      },
      {
        "book": "Daniel",
        "number": 27,
        "chapter": 10,
        "length_in_seconds": 281,
        "length": "4:41",
        "file_name": "A27___10_Daniel______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A27___10_Daniel______ENGESVO2DA.mp3"
      },
      {
        "book": "Daniel",
        "number": 27,
        "chapter": 11,
        "length_in_seconds": 612,
        "length": "10:12",
        "file_name": "A27___11_Daniel______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A27___11_Daniel______ENGESVO2DA.mp3"
      },
      {
        "book": "Daniel",
        "number": 27,
        "chapter": 12,
        "length_in_seconds": 181,
        "length": "3:01",
        "file_name": "A27___12_Daniel______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A27___12_Daniel______ENGESVO2DA.mp3"
      }
    ],
    "Hosea": [
      {
        "book": "Hosea",
        "number": 28,
        "chapter": 1,
        "length_in_seconds": 135,
        "length": "2:15",
        "file_name": "A28___01_Hosea_______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A28___01_Hosea_______ENGESVO2DA.mp3"
      },
      {
        "book": "Hosea",
        "number": 28,
        "chapter": 2,
        "length_in_seconds": 259,
        "length": "4:19",
        "file_name": "A28___02_Hosea_______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A28___02_Hosea_______ENGESVO2DA.mp3"
      },
      {
        "book": "Hosea",
        "number": 28,
        "chapter": 3,
        "length_in_seconds": 61,
        "length": "1:01",
        "file_name": "A28___03_Hosea_______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A28___03_Hosea_______ENGESVO2DA.mp3"
      },
      {
        "book": "Hosea",
        "number": 28,
        "chapter": 4,
        "length_in_seconds": 193,
        "length": "3:13",
        "file_name": "A28___04_Hosea_______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A28___04_Hosea_______ENGESVO2DA.mp3"
      },
      {
        "book": "Hosea",
        "number": 28,
        "chapter": 5,
        "length_in_seconds": 150,
        "length": "2:30",
        "file_name": "A28___05_Hosea_______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A28___05_Hosea_______ENGESVO2DA.mp3"
      },
      {
        "book": "Hosea",
        "number": 28,
        "chapter": 6,
        "length_in_seconds": 99,
        "length": "1:39",
        "file_name": "A28___06_Hosea_______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A28___06_Hosea_______ENGESVO2DA.mp3"
      },
      {
        "book": "Hosea",
        "number": 28,
        "chapter": 7,
        "length_in_seconds": 154,
        "length": "2:34",
        "file_name": "A28___07_Hosea_______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A28___07_Hosea_______ENGESVO2DA.mp3"
      },
      {
        "book": "Hosea",
        "number": 28,
        "chapter": 8,
        "length_in_seconds": 140,
        "length": "2:20",
        "file_name": "A28___08_Hosea_______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A28___08_Hosea_______ENGESVO2DA.mp3"
      },
      {
        "book": "Hosea",
        "number": 28,
        "chapter": 9,
        "length_in_seconds": 198,
        "length": "3:18",
        "file_name": "A28___09_Hosea_______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A28___09_Hosea_______ENGESVO2DA.mp3"
      },
      {
        "book": "Hosea",
        "number": 28,
        "chapter": 10,
        "length_in_seconds": 175,
        "length": "2:55",
        "file_name": "A28___10_Hosea_______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A28___10_Hosea_______ENGESVO2DA.mp3"
      },
      {
        "book": "Hosea",
        "number": 28,
        "chapter": 11,
        "length_in_seconds": 128,
        "length": "2:08",
        "file_name": "A28___11_Hosea_______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A28___11_Hosea_______ENGESVO2DA.mp3"
      },
      {
        "book": "Hosea",
        "number": 28,
        "chapter": 12,
        "length_in_seconds": 126,
        "length": "2:06",
        "file_name": "A28___12_Hosea_______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A28___12_Hosea_______ENGESVO2DA.mp3"
      },
      {
        "book": "Hosea",
        "number": 28,
        "chapter": 13,
        "length_in_seconds": 165,
        "length": "2:45",
        "file_name": "A28___13_Hosea_______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A28___13_Hosea_______ENGESVO2DA.mp3"
      },
      {
        "book": "Hosea",
        "number": 28,
        "chapter": 14,
        "length_in_seconds": 100,
        "length": "1:40",
        "file_name": "A28___14_Hosea_______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A28___14_Hosea_______ENGESVO2DA.mp3"
      }
    ],
    "Joel": [
      {
        "book": "Joel",
        "number": 29,
        "chapter": 1,
        "length_in_seconds": 198,
        "length": "3:18",
        "file_name": "A29___01_Joel________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A29___01_Joel________ENGESVO2DA.mp3"
      },
      {
        "book": "Joel",
        "number": 29,
        "chapter": 2,
        "length_in_seconds": 338,
        "length": "5:38",
        "file_name": "A29___02_Joel________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A29___02_Joel________ENGESVO2DA.mp3"
      },
      {
        "book": "Joel",
        "number": 29,
        "chapter": 3,
        "length_in_seconds": 202,
        "length": "3:22",
        "file_name": "A29___03_Joel________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A29___03_Joel________ENGESVO2DA.mp3"
      }
    ],
    "Amos": [
      {
        "book": "Amos",
        "number": 30,
        "chapter": 1,
        "length_in_seconds": 171,
        "length": "2:51",
        "file_name": "A30___01_Amos________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A30___01_Amos________ENGESVO2DA.mp3"
      },
      {
        "book": "Amos",
        "number": 30,
        "chapter": 2,
        "length_in_seconds": 160,
        "length": "2:40",
        "file_name": "A30___02_Amos________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A30___02_Amos________ENGESVO2DA.mp3"
      },
      {
        "book": "Amos",
        "number": 30,
        "chapter": 3,
        "length_in_seconds": 146,
        "length": "2:26",
        "file_name": "A30___03_Amos________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A30___03_Amos________ENGESVO2DA.mp3"
      },
      {
        "book": "Amos",
        "number": 30,
        "chapter": 4,
        "length_in_seconds": 170,
        "length": "2:50",
        "file_name": "A30___04_Amos________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A30___04_Amos________ENGESVO2DA.mp3"
      },
      {
        "book": "Amos",
        "number": 30,
        "chapter": 5,
        "length_in_seconds": 237,
        "length": "3:57",
        "file_name": "A30___05_Amos________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A30___05_Amos________ENGESVO2DA.mp3"
      },
      {
        "book": "Amos",
        "number": 30,
        "chapter": 6,
        "length_in_seconds": 154,
        "length": "2:34",
        "file_name": "A30___06_Amos________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A30___06_Amos________ENGESVO2DA.mp3"
      },
      {
        "book": "Amos",
        "number": 30,
        "chapter": 7,
        "length_in_seconds": 180,
        "length": "3:00",
        "file_name": "A30___07_Amos________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A30___07_Amos________ENGESVO2DA.mp3"
      },
      {
        "book": "Amos",
        "number": 30,
        "chapter": 8,
        "length_in_seconds": 146,
        "length": "2:26",
        "file_name": "A30___08_Amos________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A30___08_Amos________ENGESVO2DA.mp3"
      },
      {
        "book": "Amos",
        "number": 30,
        "chapter": 9,
        "length_in_seconds": 193,
        "length": "3:13",
        "file_name": "A30___09_Amos________ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A30___09_Amos________ENGESVO2DA.mp3"
      }
    ],
    "Obadiah": [
      {
        "book": "Obadiah",
        "number": 31,
        "chapter": 1,
        "length_in_seconds": 273,
        "length": "4:33",
        "file_name": "A31___01_Obadiah_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A31___01_Obadiah_____ENGESVO2DA.mp3"
      }
    ],
    "Jonah": [
      {
        "book": "Jonah",
        "number": 32,
        "chapter": 1,
        "length_in_seconds": 170,
        "length": "2:50",
        "file_name": "A32___01_Jonah_______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A32___01_Jonah_______ENGESVO2DA.mp3"
      },
      {
        "book": "Jonah",
        "number": 32,
        "chapter": 2,
        "length_in_seconds": 92,
        "length": "1:32",
        "file_name": "A32___02_Jonah_______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A32___02_Jonah_______ENGESVO2DA.mp3"
      },
      {
        "book": "Jonah",
        "number": 32,
        "chapter": 3,
        "length_in_seconds": 100,
        "length": "1:40",
        "file_name": "A32___03_Jonah_______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A32___03_Jonah_______ENGESVO2DA.mp3"
      },
      {
        "book": "Jonah",
        "number": 32,
        "chapter": 4,
        "length_in_seconds": 119,
        "length": "1:59",
        "file_name": "A32___04_Jonah_______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A32___04_Jonah_______ENGESVO2DA.mp3"
      }
    ],
    "Micah": [
      {
        "book": "Micah",
        "number": 33,
        "chapter": 1,
        "length_in_seconds": 177,
        "length": "2:57",
        "file_name": "A33___01_Micah_______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A33___01_Micah_______ENGESVO2DA.mp3"
      },
      {
        "book": "Micah",
        "number": 33,
        "chapter": 2,
        "length_in_seconds": 145,
        "length": "2:25",
        "file_name": "A33___02_Micah_______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A33___02_Micah_______ENGESVO2DA.mp3"
      },
      {
        "book": "Micah",
        "number": 33,
        "chapter": 3,
        "length_in_seconds": 128,
        "length": "2:08",
        "file_name": "A33___03_Micah_______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A33___03_Micah_______ENGESVO2DA.mp3"
      },
      {
        "book": "Micah",
        "number": 33,
        "chapter": 4,
        "length_in_seconds": 172,
        "length": "2:52",
        "file_name": "A33___04_Micah_______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A33___04_Micah_______ENGESVO2DA.mp3"
      },
      {
        "book": "Micah",
        "number": 33,
        "chapter": 5,
        "length_in_seconds": 157,
        "length": "2:37",
        "file_name": "A33___05_Micah_______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A33___05_Micah_______ENGESVO2DA.mp3"
      },
      {
        "book": "Micah",
        "number": 33,
        "chapter": 6,
        "length_in_seconds": 165,
        "length": "2:45",
        "file_name": "A33___06_Micah_______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A33___06_Micah_______ENGESVO2DA.mp3"
      },
      {
        "book": "Micah",
        "number": 33,
        "chapter": 7,
        "length_in_seconds": 216,
        "length": "3:36",
        "file_name": "A33___07_Micah_______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A33___07_Micah_______ENGESVO2DA.mp3"
      }
    ],
    "Nahum": [
      {
        "book": "Nahum",
        "number": 34,
        "chapter": 1,
        "length_in_seconds": 172,
        "length": "2:52",
        "file_name": "A34___01_Nahum_______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A34___01_Nahum_______ENGESVO2DA.mp3"
      },
      {
        "book": "Nahum",
        "number": 34,
        "chapter": 2,
        "length_in_seconds": 145,
        "length": "2:25",
        "file_name": "A34___02_Nahum_______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A34___02_Nahum_______ENGESVO2DA.mp3"
      },
      {
        "book": "Nahum",
        "number": 34,
        "chapter": 3,
        "length_in_seconds": 225,
        "length": "3:45",
        "file_name": "A34___03_Nahum_______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A34___03_Nahum_______ENGESVO2DA.mp3"
      }
    ],
    "Habakkuk": [
      {
        "book": "Habakkuk",
        "number": 35,
        "chapter": 1,
        "length_in_seconds": 159,
        "length": "2:39",
        "file_name": "A35___01_Habakkuk____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A35___01_Habakkuk____ENGESVO2DA.mp3"
      },
      {
        "book": "Habakkuk",
        "number": 35,
        "chapter": 2,
        "length_in_seconds": 214,
        "length": "3:34",
        "file_name": "A35___02_Habakkuk____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A35___02_Habakkuk____ENGESVO2DA.mp3"
      },
      {
        "book": "Habakkuk",
        "number": 35,
        "chapter": 3,
        "length_in_seconds": 188,
        "length": "3:08",
        "file_name": "A35___03_Habakkuk____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A35___03_Habakkuk____ENGESVO2DA.mp3"
      }
    ],
    "Zephaniah": [
      {
        "book": "Zephaniah",
        "number": 36,
        "chapter": 1,
        "length_in_seconds": 186,
        "length": "3:06",
        "file_name": "A36___01_Zephaniah___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A36___01_Zephaniah___ENGESVO2DA.mp3"
      },
      {
        "book": "Zephaniah",
        "number": 36,
        "chapter": 2,
        "length_in_seconds": 175,
        "length": "2:55",
        "file_name": "A36___02_Zephaniah___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A36___02_Zephaniah___ENGESVO2DA.mp3"
      },
      {
        "book": "Zephaniah",
        "number": 36,
        "chapter": 3,
        "length_in_seconds": 216,
        "length": "3:36",
        "file_name": "A36___03_Zephaniah___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A36___03_Zephaniah___ENGESVO2DA.mp3"
      }
    ],
    "Haggai": [
      {
        "book": "Haggai",
        "number": 37,
        "chapter": 1,
        "length_in_seconds": 158,
        "length": "2:38",
        "file_name": "A37___01_Haggai______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A37___01_Haggai______ENGESVO2DA.mp3"
      },
      {
        "book": "Haggai",
        "number": 37,
        "chapter": 2,
        "length_in_seconds": 235,
        "length": "3:55",
        "file_name": "A37___02_Haggai______ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A37___02_Haggai______ENGESVO2DA.mp3"
      }
    ],
    "Zechariah": [
      {
        "book": "Zechariah",
        "number": 38,
        "chapter": 1,
        "length_in_seconds": 237,
        "length": "3:57",
        "file_name": "A38___01_Zechariah___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A38___01_Zechariah___ENGESVO2DA.mp3"
      },
      {
        "book": "Zechariah",
        "number": 38,
        "chapter": 2,
        "length_in_seconds": 133,
        "length": "2:13",
        "file_name": "A38___02_Zechariah___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A38___02_Zechariah___ENGESVO2DA.mp3"
      },
      {
        "book": "Zechariah",
        "number": 38,
        "chapter": 3,
        "length_in_seconds": 111,
        "length": "1:51",
        "file_name": "A38___03_Zechariah___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A38___03_Zechariah___ENGESVO2DA.mp3"
      },
      {
        "book": "Zechariah",
        "number": 38,
        "chapter": 4,
        "length_in_seconds": 139,
        "length": "2:19",
        "file_name": "A38___04_Zechariah___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A38___04_Zechariah___ENGESVO2DA.mp3"
      },
      {
        "book": "Zechariah",
        "number": 38,
        "chapter": 5,
        "length_in_seconds": 123,
        "length": "2:03",
        "file_name": "A38___05_Zechariah___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A38___05_Zechariah___ENGESVO2DA.mp3"
      },
      {
        "book": "Zechariah",
        "number": 38,
        "chapter": 6,
        "length_in_seconds": 154,
        "length": "2:34",
        "file_name": "A38___06_Zechariah___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A38___06_Zechariah___ENGESVO2DA.mp3"
      },
      {
        "book": "Zechariah",
        "number": 38,
        "chapter": 7,
        "length_in_seconds": 137,
        "length": "2:17",
        "file_name": "A38___07_Zechariah___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A38___07_Zechariah___ENGESVO2DA.mp3"
      },
      {
        "book": "Zechariah",
        "number": 38,
        "chapter": 8,
        "length_in_seconds": 274,
        "length": "4:34",
        "file_name": "A38___08_Zechariah___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A38___08_Zechariah___ENGESVO2DA.mp3"
      },
      {
        "book": "Zechariah",
        "number": 38,
        "chapter": 9,
        "length_in_seconds": 216,
        "length": "3:36",
        "file_name": "A38___09_Zechariah___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A38___09_Zechariah___ENGESVO2DA.mp3"
      },
      {
        "book": "Zechariah",
        "number": 38,
        "chapter": 10,
        "length_in_seconds": 155,
        "length": "2:35",
        "file_name": "A38___10_Zechariah___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A38___10_Zechariah___ENGESVO2DA.mp3"
      },
      {
        "book": "Zechariah",
        "number": 38,
        "chapter": 11,
        "length_in_seconds": 197,
        "length": "3:17",
        "file_name": "A38___11_Zechariah___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A38___11_Zechariah___ENGESVO2DA.mp3"
      },
      {
        "book": "Zechariah",
        "number": 38,
        "chapter": 12,
        "length_in_seconds": 193,
        "length": "3:13",
        "file_name": "A38___12_Zechariah___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A38___12_Zechariah___ENGESVO2DA.mp3"
      },
      {
        "book": "Zechariah",
        "number": 38,
        "chapter": 13,
        "length_in_seconds": 120,
        "length": "2:00",
        "file_name": "A38___13_Zechariah___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A38___13_Zechariah___ENGESVO2DA.mp3"
      },
      {
        "book": "Zechariah",
        "number": 38,
        "chapter": 14,
        "length_in_seconds": 275,
        "length": "4:35",
        "file_name": "A38___14_Zechariah___ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A38___14_Zechariah___ENGESVO2DA.mp3"
      }
    ],
    "Malachi": [
      {
        "book": "Malachi",
        "number": 39,
        "chapter": 1,
        "length_in_seconds": 180,
        "length": "3:00",
        "file_name": "A39___01_Malachi_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A39___01_Malachi_____ENGESVO2DA.mp3"
      },
      {
        "book": "Malachi",
        "number": 39,
        "chapter": 2,
        "length_in_seconds": 189,
        "length": "3:09",
        "file_name": "A39___02_Malachi_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A39___02_Malachi_____ENGESVO2DA.mp3"
      },
      {
        "book": "Malachi",
        "number": 39,
        "chapter": 3,
        "length_in_seconds": 200,
        "length": "3:20",
        "file_name": "A39___03_Malachi_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A39___03_Malachi_____ENGESVO2DA.mp3"
      },
      {
        "book": "Malachi",
        "number": 39,
        "chapter": 4,
        "length_in_seconds": 67,
        "length": "1:07",
        "file_name": "A39___04_Malachi_____ENGESVO2DA.mp3",
        "dam_id": "ENGESVO2DA",
        "path": "/ENGESVO2DA/A39___04_Malachi_____ENGESVO2DA.mp3"
      }
    ],
    "Matthew": [
      {
        "book": "Matthew",
        "number": 40,
        "chapter": 1,
        "length_in_seconds": 209,
        "length": "3:29",
        "file_name": "B01___01_Matthew_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B01___01_Matthew_____ENGESVN2DA.mp3"
      },
      {
        "book": "Matthew",
        "number": 40,
        "chapter": 2,
        "length_in_seconds": 209,
        "length": "3:29",
        "file_name": "B01___02_Matthew_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B01___02_Matthew_____ENGESVN2DA.mp3"
      },
      {
        "book": "Matthew",
        "number": 40,
        "chapter": 3,
        "length_in_seconds": 151,
        "length": "2:31",
        "file_name": "B01___03_Matthew_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B01___03_Matthew_____ENGESVN2DA.mp3"
      },
      {
        "book": "Matthew",
        "number": 40,
        "chapter": 4,
        "length_in_seconds": 224,
        "length": "3:44",
        "file_name": "B01___04_Matthew_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B01___04_Matthew_____ENGESVN2DA.mp3"
      },
      {
        "book": "Matthew",
        "number": 40,
        "chapter": 5,
        "length_in_seconds": 368,
        "length": "6:08",
        "file_name": "B01___05_Matthew_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B01___05_Matthew_____ENGESVN2DA.mp3"
      },
      {
        "book": "Matthew",
        "number": 40,
        "chapter": 6,
        "length_in_seconds": 295,
        "length": "4:55",
        "file_name": "B01___06_Matthew_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B01___06_Matthew_____ENGESVN2DA.mp3"
      },
      {
        "book": "Matthew",
        "number": 40,
        "chapter": 7,
        "length_in_seconds": 220,
        "length": "3:40",
        "file_name": "B01___07_Matthew_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B01___07_Matthew_____ENGESVN2DA.mp3"
      },
      {
        "book": "Matthew",
        "number": 40,
        "chapter": 8,
        "length_in_seconds": 276,
        "length": "4:36",
        "file_name": "B01___08_Matthew_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B01___08_Matthew_____ENGESVN2DA.mp3"
      },
      {
        "book": "Matthew",
        "number": 40,
        "chapter": 9,
        "length_in_seconds": 325,
        "length": "5:25",
        "file_name": "B01___09_Matthew_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B01___09_Matthew_____ENGESVN2DA.mp3"
      },
      {
        "book": "Matthew",
        "number": 40,
        "chapter": 10,
        "length_in_seconds": 317,
        "length": "5:17",
        "file_name": "B01___10_Matthew_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B01___10_Matthew_____ENGESVN2DA.mp3"
      },
      {
        "book": "Matthew",
        "number": 40,
        "chapter": 11,
        "length_in_seconds": 258,
        "length": "4:18",
        "file_name": "B01___11_Matthew_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B01___11_Matthew_____ENGESVN2DA.mp3"
      },
      {
        "book": "Matthew",
        "number": 40,
        "chapter": 12,
        "length_in_seconds": 400,
        "length": "6:40",
        "file_name": "B01___12_Matthew_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B01___12_Matthew_____ENGESVN2DA.mp3"
      },
      {
        "book": "Matthew",
        "number": 40,
        "chapter": 13,
        "length_in_seconds": 465,
        "length": "7:45",
        "file_name": "B01___13_Matthew_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B01___13_Matthew_____ENGESVN2DA.mp3"
      },
      {
        "book": "Matthew",
        "number": 40,
        "chapter": 14,
        "length_in_seconds": 254,
        "length": "4:14",
        "file_name": "B01___14_Matthew_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B01___14_Matthew_____ENGESVN2DA.mp3"
      },
      {
        "book": "Matthew",
        "number": 40,
        "chapter": 15,
        "length_in_seconds": 285,
        "length": "4:45",
        "file_name": "B01___15_Matthew_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B01___15_Matthew_____ENGESVN2DA.mp3"
      },
      {
        "book": "Matthew",
        "number": 40,
        "chapter": 16,
        "length_in_seconds": 248,
        "length": "4:08",
        "file_name": "B01___16_Matthew_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B01___16_Matthew_____ENGESVN2DA.mp3"
      },
      {
        "book": "Matthew",
        "number": 40,
        "chapter": 17,
        "length_in_seconds": 226,
        "length": "3:46",
        "file_name": "B01___17_Matthew_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B01___17_Matthew_____ENGESVN2DA.mp3"
      },
      {
        "book": "Matthew",
        "number": 40,
        "chapter": 18,
        "length_in_seconds": 281,
        "length": "4:41",
        "file_name": "B01___18_Matthew_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B01___18_Matthew_____ENGESVN2DA.mp3"
      },
      {
        "book": "Matthew",
        "number": 40,
        "chapter": 19,
        "length_in_seconds": 259,
        "length": "4:19",
        "file_name": "B01___19_Matthew_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B01___19_Matthew_____ENGESVN2DA.mp3"
      },
      {
        "book": "Matthew",
        "number": 40,
        "chapter": 20,
        "length_in_seconds": 261,
        "length": "4:21",
        "file_name": "B01___20_Matthew_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B01___20_Matthew_____ENGESVN2DA.mp3"
      },
      {
        "book": "Matthew",
        "number": 40,
        "chapter": 21,
        "length_in_seconds": 418,
        "length": "6:58",
        "file_name": "B01___21_Matthew_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B01___21_Matthew_____ENGESVN2DA.mp3"
      },
      {
        "book": "Matthew",
        "number": 40,
        "chapter": 22,
        "length_in_seconds": 329,
        "length": "5:29",
        "file_name": "B01___22_Matthew_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B01___22_Matthew_____ENGESVN2DA.mp3"
      },
      {
        "book": "Matthew",
        "number": 40,
        "chapter": 23,
        "length_in_seconds": 340,
        "length": "5:40",
        "file_name": "B01___23_Matthew_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B01___23_Matthew_____ENGESVN2DA.mp3"
      },
      {
        "book": "Matthew",
        "number": 40,
        "chapter": 24,
        "length_in_seconds": 380,
        "length": "6:20",
        "file_name": "B01___24_Matthew_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B01___24_Matthew_____ENGESVN2DA.mp3"
      },
      {
        "book": "Matthew",
        "number": 40,
        "chapter": 25,
        "length_in_seconds": 368,
        "length": "6:08",
        "file_name": "B01___25_Matthew_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B01___25_Matthew_____ENGESVN2DA.mp3"
      },
      {
        "book": "Matthew",
        "number": 40,
        "chapter": 26,
        "length_in_seconds": 606,
        "length": "10:06",
        "file_name": "B01___26_Matthew_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B01___26_Matthew_____ENGESVN2DA.mp3"
      },
      {
        "book": "Matthew",
        "number": 40,
        "chapter": 27,
        "length_in_seconds": 542,
        "length": "9:02",
        "file_name": "B01___27_Matthew_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B01___27_Matthew_____ENGESVN2DA.mp3"
      },
      {
        "book": "Matthew",
        "number": 40,
        "chapter": 28,
        "length_in_seconds": 165,
        "length": "2:45",
        "file_name": "B01___28_Matthew_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B01___28_Matthew_____ENGESVN2DA.mp3"
      }
    ],
    "Mark": [
      {
        "book": "Mark",
        "number": 41,
        "chapter": 1,
        "length_in_seconds": 329,
        "length": "5:29",
        "file_name": "B02___01_Mark________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B02___01_Mark________ENGESVN2DA.mp3"
      },
      {
        "book": "Mark",
        "number": 41,
        "chapter": 2,
        "length_in_seconds": 241,
        "length": "4:01",
        "file_name": "B02___02_Mark________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B02___02_Mark________ENGESVN2DA.mp3"
      },
      {
        "book": "Mark",
        "number": 41,
        "chapter": 3,
        "length_in_seconds": 228,
        "length": "3:48",
        "file_name": "B02___03_Mark________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B02___03_Mark________ENGESVN2DA.mp3"
      },
      {
        "book": "Mark",
        "number": 41,
        "chapter": 4,
        "length_in_seconds": 300,
        "length": "5:00",
        "file_name": "B02___04_Mark________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B02___04_Mark________ENGESVN2DA.mp3"
      },
      {
        "book": "Mark",
        "number": 41,
        "chapter": 5,
        "length_in_seconds": 330,
        "length": "5:30",
        "file_name": "B02___05_Mark________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B02___05_Mark________ENGESVN2DA.mp3"
      },
      {
        "book": "Mark",
        "number": 41,
        "chapter": 6,
        "length_in_seconds": 424,
        "length": "7:04",
        "file_name": "B02___06_Mark________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B02___06_Mark________ENGESVN2DA.mp3"
      },
      {
        "book": "Mark",
        "number": 41,
        "chapter": 7,
        "length_in_seconds": 265,
        "length": "4:25",
        "file_name": "B02___07_Mark________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B02___07_Mark________ENGESVN2DA.mp3"
      },
      {
        "book": "Mark",
        "number": 41,
        "chapter": 8,
        "length_in_seconds": 301,
        "length": "5:01",
        "file_name": "B02___08_Mark________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B02___08_Mark________ENGESVN2DA.mp3"
      },
      {
        "book": "Mark",
        "number": 41,
        "chapter": 9,
        "length_in_seconds": 382,
        "length": "6:22",
        "file_name": "B02___09_Mark________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B02___09_Mark________ENGESVN2DA.mp3"
      },
      {
        "book": "Mark",
        "number": 41,
        "chapter": 10,
        "length_in_seconds": 417,
        "length": "6:57",
        "file_name": "B02___10_Mark________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B02___10_Mark________ENGESVN2DA.mp3"
      },
      {
        "book": "Mark",
        "number": 41,
        "chapter": 11,
        "length_in_seconds": 241,
        "length": "4:01",
        "file_name": "B02___11_Mark________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B02___11_Mark________ENGESVN2DA.mp3"
      },
      {
        "book": "Mark",
        "number": 41,
        "chapter": 12,
        "length_in_seconds": 390,
        "length": "6:30",
        "file_name": "B02___12_Mark________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B02___12_Mark________ENGESVN2DA.mp3"
      },
      {
        "book": "Mark",
        "number": 41,
        "chapter": 13,
        "length_in_seconds": 285,
        "length": "4:45",
        "file_name": "B02___13_Mark________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B02___13_Mark________ENGESVN2DA.mp3"
      },
      {
        "book": "Mark",
        "number": 41,
        "chapter": 14,
        "length_in_seconds": 538,
        "length": "8:58",
        "file_name": "B02___14_Mark________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B02___14_Mark________ENGESVN2DA.mp3"
      },
      {
        "book": "Mark",
        "number": 41,
        "chapter": 15,
        "length_in_seconds": 355,
        "length": "5:55",
        "file_name": "B02___15_Mark________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B02___15_Mark________ENGESVN2DA.mp3"
      },
      {
        "book": "Mark",
        "number": 41,
        "chapter": 16,
        "length_in_seconds": 161,
        "length": "2:41",
        "file_name": "B02___16_Mark________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B02___16_Mark________ENGESVN2DA.mp3"
      }
    ],
    "Luke": [
      {
        "book": "Luke",
        "number": 42,
        "chapter": 1,
        "length_in_seconds": 560,
        "length": "9:20",
        "file_name": "B03___01_Luke________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B03___01_Luke________ENGESVN2DA.mp3"
      },
      {
        "book": "Luke",
        "number": 42,
        "chapter": 2,
        "length_in_seconds": 375,
        "length": "6:15",
        "file_name": "B03___02_Luke________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B03___02_Luke________ENGESVN2DA.mp3"
      },
      {
        "book": "Luke",
        "number": 42,
        "chapter": 3,
        "length_in_seconds": 329,
        "length": "5:29",
        "file_name": "B03___03_Luke________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B03___03_Luke________ENGESVN2DA.mp3"
      },
      {
        "book": "Luke",
        "number": 42,
        "chapter": 4,
        "length_in_seconds": 400,
        "length": "6:40",
        "file_name": "B03___04_Luke________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B03___04_Luke________ENGESVN2DA.mp3"
      },
      {
        "book": "Luke",
        "number": 42,
        "chapter": 5,
        "length_in_seconds": 325,
        "length": "5:25",
        "file_name": "B03___05_Luke________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B03___05_Luke________ENGESVN2DA.mp3"
      },
      {
        "book": "Luke",
        "number": 42,
        "chapter": 6,
        "length_in_seconds": 411,
        "length": "6:51",
        "file_name": "B03___06_Luke________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B03___06_Luke________ENGESVN2DA.mp3"
      },
      {
        "book": "Luke",
        "number": 42,
        "chapter": 7,
        "length_in_seconds": 445,
        "length": "7:25",
        "file_name": "B03___07_Luke________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B03___07_Luke________ENGESVN2DA.mp3"
      },
      {
        "book": "Luke",
        "number": 42,
        "chapter": 8,
        "length_in_seconds": 507,
        "length": "8:27",
        "file_name": "B03___08_Luke________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B03___08_Luke________ENGESVN2DA.mp3"
      },
      {
        "book": "Luke",
        "number": 42,
        "chapter": 9,
        "length_in_seconds": 510,
        "length": "8:30",
        "file_name": "B03___09_Luke________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B03___09_Luke________ENGESVN2DA.mp3"
      },
      {
        "book": "Luke",
        "number": 42,
        "chapter": 10,
        "length_in_seconds": 357,
        "length": "5:57",
        "file_name": "B03___10_Luke________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B03___10_Luke________ENGESVN2DA.mp3"
      },
      {
        "book": "Luke",
        "number": 42,
        "chapter": 11,
        "length_in_seconds": 453,
        "length": "7:33",
        "file_name": "B03___11_Luke________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B03___11_Luke________ENGESVN2DA.mp3"
      },
      {
        "book": "Luke",
        "number": 42,
        "chapter": 12,
        "length_in_seconds": 482,
        "length": "8:02",
        "file_name": "B03___12_Luke________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B03___12_Luke________ENGESVN2DA.mp3"
      },
      {
        "book": "Luke",
        "number": 42,
        "chapter": 13,
        "length_in_seconds": 301,
        "length": "5:01",
        "file_name": "B03___13_Luke________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B03___13_Luke________ENGESVN2DA.mp3"
      },
      {
        "book": "Luke",
        "number": 42,
        "chapter": 14,
        "length_in_seconds": 278,
        "length": "4:38",
        "file_name": "B03___14_Luke________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B03___14_Luke________ENGESVN2DA.mp3"
      },
      {
        "book": "Luke",
        "number": 42,
        "chapter": 15,
        "length_in_seconds": 253,
        "length": "4:13",
        "file_name": "B03___15_Luke________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B03___15_Luke________ENGESVN2DA.mp3"
      },
      {
        "book": "Luke",
        "number": 42,
        "chapter": 16,
        "length_in_seconds": 285,
        "length": "4:45",
        "file_name": "B03___16_Luke________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B03___16_Luke________ENGESVN2DA.mp3"
      },
      {
        "book": "Luke",
        "number": 42,
        "chapter": 17,
        "length_in_seconds": 277,
        "length": "4:37",
        "file_name": "B03___17_Luke________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B03___17_Luke________ENGESVN2DA.mp3"
      },
      {
        "book": "Luke",
        "number": 42,
        "chapter": 18,
        "length_in_seconds": 334,
        "length": "5:34",
        "file_name": "B03___18_Luke________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B03___18_Luke________ENGESVN2DA.mp3"
      },
      {
        "book": "Luke",
        "number": 42,
        "chapter": 19,
        "length_in_seconds": 351,
        "length": "5:51",
        "file_name": "B03___19_Luke________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B03___19_Luke________ENGESVN2DA.mp3"
      },
      {
        "book": "Luke",
        "number": 42,
        "chapter": 20,
        "length_in_seconds": 353,
        "length": "5:53",
        "file_name": "B03___20_Luke________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B03___20_Luke________ENGESVN2DA.mp3"
      },
      {
        "book": "Luke",
        "number": 42,
        "chapter": 21,
        "length_in_seconds": 284,
        "length": "4:44",
        "file_name": "B03___21_Luke________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B03___21_Luke________ENGESVN2DA.mp3"
      },
      {
        "book": "Luke",
        "number": 42,
        "chapter": 22,
        "length_in_seconds": 517,
        "length": "8:37",
        "file_name": "B03___22_Luke________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B03___22_Luke________ENGESVN2DA.mp3"
      },
      {
        "book": "Luke",
        "number": 42,
        "chapter": 23,
        "length_in_seconds": 457,
        "length": "7:37",
        "file_name": "B03___23_Luke________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B03___23_Luke________ENGESVN2DA.mp3"
      },
      {
        "book": "Luke",
        "number": 42,
        "chapter": 24,
        "length_in_seconds": 353,
        "length": "5:53",
        "file_name": "B03___24_Luke________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B03___24_Luke________ENGESVN2DA.mp3"
      }
    ],
    "John": [
      {
        "book": "John",
        "number": 43,
        "chapter": 1,
        "length_in_seconds": 368,
        "length": "6:08",
        "file_name": "B04___01_John________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B04___01_John________ENGESVN2DA.mp3"
      },
      {
        "book": "John",
        "number": 43,
        "chapter": 2,
        "length_in_seconds": 177,
        "length": "2:57",
        "file_name": "B04___02_John________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B04___02_John________ENGESVN2DA.mp3"
      },
      {
        "book": "John",
        "number": 43,
        "chapter": 3,
        "length_in_seconds": 275,
        "length": "4:35",
        "file_name": "B04___03_John________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B04___03_John________ENGESVN2DA.mp3"
      },
      {
        "book": "John",
        "number": 43,
        "chapter": 4,
        "length_in_seconds": 397,
        "length": "6:37",
        "file_name": "B04___04_John________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B04___04_John________ENGESVN2DA.mp3"
      },
      {
        "book": "John",
        "number": 43,
        "chapter": 5,
        "length_in_seconds": 351,
        "length": "5:51",
        "file_name": "B04___05_John________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B04___05_John________ENGESVN2DA.mp3"
      },
      {
        "book": "John",
        "number": 43,
        "chapter": 6,
        "length_in_seconds": 517,
        "length": "8:37",
        "file_name": "B04___06_John________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B04___06_John________ENGESVN2DA.mp3"
      },
      {
        "book": "John",
        "number": 43,
        "chapter": 7,
        "length_in_seconds": 361,
        "length": "6:01",
        "file_name": "B04___07_John________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B04___07_John________ENGESVN2DA.mp3"
      },
      {
        "book": "John",
        "number": 43,
        "chapter": 8,
        "length_in_seconds": 491,
        "length": "8:11",
        "file_name": "B04___08_John________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B04___08_John________ENGESVN2DA.mp3"
      },
      {
        "book": "John",
        "number": 43,
        "chapter": 9,
        "length_in_seconds": 315,
        "length": "5:15",
        "file_name": "B04___09_John________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B04___09_John________ENGESVN2DA.mp3"
      },
      {
        "book": "John",
        "number": 43,
        "chapter": 10,
        "length_in_seconds": 294,
        "length": "4:54",
        "file_name": "B04___10_John________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B04___10_John________ENGESVN2DA.mp3"
      },
      {
        "book": "John",
        "number": 43,
        "chapter": 11,
        "length_in_seconds": 432,
        "length": "7:12",
        "file_name": "B04___11_John________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B04___11_John________ENGESVN2DA.mp3"
      },
      {
        "book": "John",
        "number": 43,
        "chapter": 12,
        "length_in_seconds": 393,
        "length": "6:33",
        "file_name": "B04___12_John________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B04___12_John________ENGESVN2DA.mp3"
      },
      {
        "book": "John",
        "number": 43,
        "chapter": 13,
        "length_in_seconds": 313,
        "length": "5:13",
        "file_name": "B04___13_John________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B04___13_John________ENGESVN2DA.mp3"
      },
      {
        "book": "John",
        "number": 43,
        "chapter": 14,
        "length_in_seconds": 262,
        "length": "4:22",
        "file_name": "B04___14_John________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B04___14_John________ENGESVN2DA.mp3"
      },
      {
        "book": "John",
        "number": 43,
        "chapter": 15,
        "length_in_seconds": 213,
        "length": "3:33",
        "file_name": "B04___15_John________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B04___15_John________ENGESVN2DA.mp3"
      },
      {
        "book": "John",
        "number": 43,
        "chapter": 16,
        "length_in_seconds": 273,
        "length": "4:33",
        "file_name": "B04___16_John________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B04___16_John________ENGESVN2DA.mp3"
      },
      {
        "book": "John",
        "number": 43,
        "chapter": 17,
        "length_in_seconds": 208,
        "length": "3:28",
        "file_name": "B04___17_John________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B04___17_John________ENGESVN2DA.mp3"
      },
      {
        "book": "John",
        "number": 43,
        "chapter": 18,
        "length_in_seconds": 363,
        "length": "6:03",
        "file_name": "B04___18_John________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B04___18_John________ENGESVN2DA.mp3"
      },
      {
        "book": "John",
        "number": 43,
        "chapter": 19,
        "length_in_seconds": 421,
        "length": "7:01",
        "file_name": "B04___19_John________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B04___19_John________ENGESVN2DA.mp3"
      },
      {
        "book": "John",
        "number": 43,
        "chapter": 20,
        "length_in_seconds": 279,
        "length": "4:39",
        "file_name": "B04___20_John________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B04___20_John________ENGESVN2DA.mp3"
      },
      {
        "book": "John",
        "number": 43,
        "chapter": 21,
        "length_in_seconds": 276,
        "length": "4:36",
        "file_name": "B04___21_John________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B04___21_John________ENGESVN2DA.mp3"
      }
    ],
    "Acts": [
      {
        "book": "Acts",
        "number": 44,
        "chapter": 1,
        "length_in_seconds": 225,
        "length": "3:45",
        "file_name": "B05___01_Acts________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B05___01_Acts________ENGESVN2DA.mp3"
      },
      {
        "book": "Acts",
        "number": 44,
        "chapter": 2,
        "length_in_seconds": 386,
        "length": "6:26",
        "file_name": "B05___02_Acts________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B05___02_Acts________ENGESVN2DA.mp3"
      },
      {
        "book": "Acts",
        "number": 44,
        "chapter": 3,
        "length_in_seconds": 226,
        "length": "3:46",
        "file_name": "B05___03_Acts________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B05___03_Acts________ENGESVN2DA.mp3"
      },
      {
        "book": "Acts",
        "number": 44,
        "chapter": 4,
        "length_in_seconds": 302,
        "length": "5:02",
        "file_name": "B05___04_Acts________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B05___04_Acts________ENGESVN2DA.mp3"
      },
      {
        "book": "Acts",
        "number": 44,
        "chapter": 5,
        "length_in_seconds": 360,
        "length": "6:00",
        "file_name": "B05___05_Acts________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B05___05_Acts________ENGESVN2DA.mp3"
      },
      {
        "book": "Acts",
        "number": 44,
        "chapter": 6,
        "length_in_seconds": 134,
        "length": "2:14",
        "file_name": "B05___06_Acts________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B05___06_Acts________ENGESVN2DA.mp3"
      },
      {
        "book": "Acts",
        "number": 44,
        "chapter": 7,
        "length_in_seconds": 474,
        "length": "7:54",
        "file_name": "B05___07_Acts________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B05___07_Acts________ENGESVN2DA.mp3"
      },
      {
        "book": "Acts",
        "number": 44,
        "chapter": 8,
        "length_in_seconds": 320,
        "length": "5:20",
        "file_name": "B05___08_Acts________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B05___08_Acts________ENGESVN2DA.mp3"
      },
      {
        "book": "Acts",
        "number": 44,
        "chapter": 9,
        "length_in_seconds": 358,
        "length": "5:58",
        "file_name": "B05___09_Acts________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B05___09_Acts________ENGESVN2DA.mp3"
      },
      {
        "book": "Acts",
        "number": 44,
        "chapter": 10,
        "length_in_seconds": 392,
        "length": "6:32",
        "file_name": "B05___10_Acts________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B05___10_Acts________ENGESVN2DA.mp3"
      },
      {
        "book": "Acts",
        "number": 44,
        "chapter": 11,
        "length_in_seconds": 237,
        "length": "3:57",
        "file_name": "B05___11_Acts________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B05___11_Acts________ENGESVN2DA.mp3"
      },
      {
        "book": "Acts",
        "number": 44,
        "chapter": 12,
        "length_in_seconds": 235,
        "length": "3:55",
        "file_name": "B05___12_Acts________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B05___12_Acts________ENGESVN2DA.mp3"
      },
      {
        "book": "Acts",
        "number": 44,
        "chapter": 13,
        "length_in_seconds": 475,
        "length": "7:55",
        "file_name": "B05___13_Acts________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B05___13_Acts________ENGESVN2DA.mp3"
      },
      {
        "book": "Acts",
        "number": 44,
        "chapter": 14,
        "length_in_seconds": 225,
        "length": "3:45",
        "file_name": "B05___14_Acts________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B05___14_Acts________ENGESVN2DA.mp3"
      },
      {
        "book": "Acts",
        "number": 44,
        "chapter": 15,
        "length_in_seconds": 349,
        "length": "5:49",
        "file_name": "B05___15_Acts________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B05___15_Acts________ENGESVN2DA.mp3"
      },
      {
        "book": "Acts",
        "number": 44,
        "chapter": 16,
        "length_in_seconds": 340,
        "length": "5:40",
        "file_name": "B05___16_Acts________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B05___16_Acts________ENGESVN2DA.mp3"
      },
      {
        "book": "Acts",
        "number": 44,
        "chapter": 17,
        "length_in_seconds": 328,
        "length": "5:28",
        "file_name": "B05___17_Acts________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B05___17_Acts________ENGESVN2DA.mp3"
      },
      {
        "book": "Acts",
        "number": 44,
        "chapter": 18,
        "length_in_seconds": 236,
        "length": "3:56",
        "file_name": "B05___18_Acts________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B05___18_Acts________ENGESVN2DA.mp3"
      },
      {
        "book": "Acts",
        "number": 44,
        "chapter": 19,
        "length_in_seconds": 362,
        "length": "6:02",
        "file_name": "B05___19_Acts________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B05___19_Acts________ENGESVN2DA.mp3"
      },
      {
        "book": "Acts",
        "number": 44,
        "chapter": 20,
        "length_in_seconds": 320,
        "length": "5:20",
        "file_name": "B05___20_Acts________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B05___20_Acts________ENGESVN2DA.mp3"
      },
      {
        "book": "Acts",
        "number": 44,
        "chapter": 21,
        "length_in_seconds": 353,
        "length": "5:53",
        "file_name": "B05___21_Acts________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B05___21_Acts________ENGESVN2DA.mp3"
      },
      {
        "book": "Acts",
        "number": 44,
        "chapter": 22,
        "length_in_seconds": 290,
        "length": "4:50",
        "file_name": "B05___22_Acts________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B05___22_Acts________ENGESVN2DA.mp3"
      },
      {
        "book": "Acts",
        "number": 44,
        "chapter": 23,
        "length_in_seconds": 294,
        "length": "4:54",
        "file_name": "B05___23_Acts________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B05___23_Acts________ENGESVN2DA.mp3"
      },
      {
        "book": "Acts",
        "number": 44,
        "chapter": 24,
        "length_in_seconds": 227,
        "length": "3:47",
        "file_name": "B05___24_Acts________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B05___24_Acts________ENGESVN2DA.mp3"
      },
      {
        "book": "Acts",
        "number": 44,
        "chapter": 25,
        "length_in_seconds": 261,
        "length": "4:21",
        "file_name": "B05___25_Acts________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B05___25_Acts________ENGESVN2DA.mp3"
      },
      {
        "book": "Acts",
        "number": 44,
        "chapter": 26,
        "length_in_seconds": 322,
        "length": "5:22",
        "file_name": "B05___26_Acts________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B05___26_Acts________ENGESVN2DA.mp3"
      },
      {
        "book": "Acts",
        "number": 44,
        "chapter": 27,
        "length_in_seconds": 351,
        "length": "5:51",
        "file_name": "B05___27_Acts________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B05___27_Acts________ENGESVN2DA.mp3"
      },
      {
        "book": "Acts",
        "number": 44,
        "chapter": 28,
        "length_in_seconds": 278,
        "length": "4:38",
        "file_name": "B05___28_Acts________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B05___28_Acts________ENGESVN2DA.mp3"
      }
    ],
    "Romans": [
      {
        "book": "Romans",
        "number": 45,
        "chapter": 1,
        "length_in_seconds": 297,
        "length": "4:57",
        "file_name": "B06___01_Romans______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B06___01_Romans______ENGESVN2DA.mp3"
      },
      {
        "book": "Romans",
        "number": 45,
        "chapter": 2,
        "length_in_seconds": 250,
        "length": "4:10",
        "file_name": "B06___02_Romans______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B06___02_Romans______ENGESVN2DA.mp3"
      },
      {
        "book": "Romans",
        "number": 45,
        "chapter": 3,
        "length_in_seconds": 247,
        "length": "4:07",
        "file_name": "B06___03_Romans______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B06___03_Romans______ENGESVN2DA.mp3"
      },
      {
        "book": "Romans",
        "number": 45,
        "chapter": 4,
        "length_in_seconds": 234,
        "length": "3:54",
        "file_name": "B06___04_Romans______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B06___04_Romans______ENGESVN2DA.mp3"
      },
      {
        "book": "Romans",
        "number": 45,
        "chapter": 5,
        "length_in_seconds": 221,
        "length": "3:41",
        "file_name": "B06___05_Romans______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B06___05_Romans______ENGESVN2DA.mp3"
      },
      {
        "book": "Romans",
        "number": 45,
        "chapter": 6,
        "length_in_seconds": 209,
        "length": "3:29",
        "file_name": "B06___06_Romans______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B06___06_Romans______ENGESVN2DA.mp3"
      },
      {
        "book": "Romans",
        "number": 45,
        "chapter": 7,
        "length_in_seconds": 241,
        "length": "4:01",
        "file_name": "B06___07_Romans______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B06___07_Romans______ENGESVN2DA.mp3"
      },
      {
        "book": "Romans",
        "number": 45,
        "chapter": 8,
        "length_in_seconds": 365,
        "length": "6:05",
        "file_name": "B06___08_Romans______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B06___08_Romans______ENGESVN2DA.mp3"
      },
      {
        "book": "Romans",
        "number": 45,
        "chapter": 9,
        "length_in_seconds": 298,
        "length": "4:58",
        "file_name": "B06___09_Romans______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B06___09_Romans______ENGESVN2DA.mp3"
      },
      {
        "book": "Romans",
        "number": 45,
        "chapter": 10,
        "length_in_seconds": 194,
        "length": "3:14",
        "file_name": "B06___10_Romans______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B06___10_Romans______ENGESVN2DA.mp3"
      },
      {
        "book": "Romans",
        "number": 45,
        "chapter": 11,
        "length_in_seconds": 321,
        "length": "5:21",
        "file_name": "B06___11_Romans______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B06___11_Romans______ENGESVN2DA.mp3"
      },
      {
        "book": "Romans",
        "number": 45,
        "chapter": 12,
        "length_in_seconds": 180,
        "length": "3:00",
        "file_name": "B06___12_Romans______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B06___12_Romans______ENGESVN2DA.mp3"
      },
      {
        "book": "Romans",
        "number": 45,
        "chapter": 13,
        "length_in_seconds": 146,
        "length": "2:26",
        "file_name": "B06___13_Romans______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B06___13_Romans______ENGESVN2DA.mp3"
      },
      {
        "book": "Romans",
        "number": 45,
        "chapter": 14,
        "length_in_seconds": 203,
        "length": "3:23",
        "file_name": "B06___14_Romans______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B06___14_Romans______ENGESVN2DA.mp3"
      },
      {
        "book": "Romans",
        "number": 45,
        "chapter": 15,
        "length_in_seconds": 279,
        "length": "4:39",
        "file_name": "B06___15_Romans______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B06___15_Romans______ENGESVN2DA.mp3"
      },
      {
        "book": "Romans",
        "number": 45,
        "chapter": 16,
        "length_in_seconds": 208,
        "length": "3:28",
        "file_name": "B06___16_Romans______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B06___16_Romans______ENGESVN2DA.mp3"
      }
    ],
    "1 Corinthians": [
      {
        "book": "1 Corinthians",
        "number": 46,
        "chapter": 1,
        "length_in_seconds": 229,
        "length": "3:49",
        "file_name": "B07___01_1CorinthiansENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B07___01_1CorinthiansENGESVN2DA.mp3"
      },
      {
        "book": "1 Corinthians",
        "number": 46,
        "chapter": 2,
        "length_in_seconds": 121,
        "length": "2:01",
        "file_name": "B07___02_1CorinthiansENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B07___02_1CorinthiansENGESVN2DA.mp3"
      },
      {
        "book": "1 Corinthians",
        "number": 46,
        "chapter": 3,
        "length_in_seconds": 176,
        "length": "2:56",
        "file_name": "B07___03_1CorinthiansENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B07___03_1CorinthiansENGESVN2DA.mp3"
      },
      {
        "book": "1 Corinthians",
        "number": 46,
        "chapter": 4,
        "length_in_seconds": 186,
        "length": "3:06",
        "file_name": "B07___04_1CorinthiansENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B07___04_1CorinthiansENGESVN2DA.mp3"
      },
      {
        "book": "1 Corinthians",
        "number": 46,
        "chapter": 5,
        "length_in_seconds": 120,
        "length": "2:00",
        "file_name": "B07___05_1CorinthiansENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B07___05_1CorinthiansENGESVN2DA.mp3"
      },
      {
        "book": "1 Corinthians",
        "number": 46,
        "chapter": 6,
        "length_in_seconds": 182,
        "length": "3:02",
        "file_name": "B07___06_1CorinthiansENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B07___06_1CorinthiansENGESVN2DA.mp3"
      },
      {
        "book": "1 Corinthians",
        "number": 46,
        "chapter": 7,
        "length_in_seconds": 360,
        "length": "6:00",
        "file_name": "B07___07_1CorinthiansENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B07___07_1CorinthiansENGESVN2DA.mp3"
      },
      {
        "book": "1 Corinthians",
        "number": 46,
        "chapter": 8,
        "length_in_seconds": 110,
        "length": "1:50",
        "file_name": "B07___08_1CorinthiansENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B07___08_1CorinthiansENGESVN2DA.mp3"
      },
      {
        "book": "1 Corinthians",
        "number": 46,
        "chapter": 9,
        "length_in_seconds": 234,
        "length": "3:54",
        "file_name": "B07___09_1CorinthiansENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B07___09_1CorinthiansENGESVN2DA.mp3"
      },
      {
        "book": "1 Corinthians",
        "number": 46,
        "chapter": 10,
        "length_in_seconds": 227,
        "length": "3:47",
        "file_name": "B07___10_1CorinthiansENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B07___10_1CorinthiansENGESVN2DA.mp3"
      },
      {
        "book": "1 Corinthians",
        "number": 46,
        "chapter": 11,
        "length_in_seconds": 247,
        "length": "4:07",
        "file_name": "B07___11_1CorinthiansENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B07___11_1CorinthiansENGESVN2DA.mp3"
      },
      {
        "book": "1 Corinthians",
        "number": 46,
        "chapter": 12,
        "length_in_seconds": 225,
        "length": "3:45",
        "file_name": "B07___12_1CorinthiansENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B07___12_1CorinthiansENGESVN2DA.mp3"
      },
      {
        "book": "1 Corinthians",
        "number": 46,
        "chapter": 13,
        "length_in_seconds": 119,
        "length": "1:59",
        "file_name": "B07___13_1CorinthiansENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B07___13_1CorinthiansENGESVN2DA.mp3"
      },
      {
        "book": "1 Corinthians",
        "number": 46,
        "chapter": 14,
        "length_in_seconds": 315,
        "length": "5:15",
        "file_name": "B07___14_1CorinthiansENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B07___14_1CorinthiansENGESVN2DA.mp3"
      },
      {
        "book": "1 Corinthians",
        "number": 46,
        "chapter": 15,
        "length_in_seconds": 436,
        "length": "7:16",
        "file_name": "B07___15_1CorinthiansENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B07___15_1CorinthiansENGESVN2DA.mp3"
      },
      {
        "book": "1 Corinthians",
        "number": 46,
        "chapter": 16,
        "length_in_seconds": 160,
        "length": "2:40",
        "file_name": "B07___16_1CorinthiansENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B07___16_1CorinthiansENGESVN2DA.mp3"
      }
    ],
    "2 Corinthians": [
      {
        "book": "2 Corinthians",
        "number": 47,
        "chapter": 1,
        "length_in_seconds": 213,
        "length": "3:33",
        "file_name": "B08___01_2CorinthiansENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B08___01_2CorinthiansENGESVN2DA.mp3"
      },
      {
        "book": "2 Corinthians",
        "number": 47,
        "chapter": 2,
        "length_in_seconds": 130,
        "length": "2:10",
        "file_name": "B08___02_2CorinthiansENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B08___02_2CorinthiansENGESVN2DA.mp3"
      },
      {
        "book": "2 Corinthians",
        "number": 47,
        "chapter": 3,
        "length_in_seconds": 147,
        "length": "2:27",
        "file_name": "B08___03_2CorinthiansENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B08___03_2CorinthiansENGESVN2DA.mp3"
      },
      {
        "book": "2 Corinthians",
        "number": 47,
        "chapter": 4,
        "length_in_seconds": 163,
        "length": "2:43",
        "file_name": "B08___04_2CorinthiansENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B08___04_2CorinthiansENGESVN2DA.mp3"
      },
      {
        "book": "2 Corinthians",
        "number": 47,
        "chapter": 5,
        "length_in_seconds": 185,
        "length": "3:05",
        "file_name": "B08___05_2CorinthiansENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B08___05_2CorinthiansENGESVN2DA.mp3"
      },
      {
        "book": "2 Corinthians",
        "number": 47,
        "chapter": 6,
        "length_in_seconds": 160,
        "length": "2:40",
        "file_name": "B08___06_2CorinthiansENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B08___06_2CorinthiansENGESVN2DA.mp3"
      },
      {
        "book": "2 Corinthians",
        "number": 47,
        "chapter": 7,
        "length_in_seconds": 170,
        "length": "2:50",
        "file_name": "B08___07_2CorinthiansENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B08___07_2CorinthiansENGESVN2DA.mp3"
      },
      {
        "book": "2 Corinthians",
        "number": 47,
        "chapter": 8,
        "length_in_seconds": 198,
        "length": "3:18",
        "file_name": "B08___08_2CorinthiansENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B08___08_2CorinthiansENGESVN2DA.mp3"
      },
      {
        "book": "2 Corinthians",
        "number": 47,
        "chapter": 9,
        "length_in_seconds": 136,
        "length": "2:16",
        "file_name": "B08___09_2CorinthiansENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B08___09_2CorinthiansENGESVN2DA.mp3"
      },
      {
        "book": "2 Corinthians",
        "number": 47,
        "chapter": 10,
        "length_in_seconds": 155,
        "length": "2:35",
        "file_name": "B08___10_2CorinthiansENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B08___10_2CorinthiansENGESVN2DA.mp3"
      },
      {
        "book": "2 Corinthians",
        "number": 47,
        "chapter": 11,
        "length_in_seconds": 273,
        "length": "4:33",
        "file_name": "B08___11_2CorinthiansENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B08___11_2CorinthiansENGESVN2DA.mp3"
      },
      {
        "book": "2 Corinthians",
        "number": 47,
        "chapter": 12,
        "length_in_seconds": 221,
        "length": "3:41",
        "file_name": "B08___12_2CorinthiansENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B08___12_2CorinthiansENGESVN2DA.mp3"
      },
      {
        "book": "2 Corinthians",
        "number": 47,
        "chapter": 13,
        "length_in_seconds": 129,
        "length": "2:09",
        "file_name": "B08___13_2CorinthiansENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B08___13_2CorinthiansENGESVN2DA.mp3"
      }
    ],
    "Galatians": [
      {
        "book": "Galatians",
        "number": 48,
        "chapter": 1,
        "length_in_seconds": 183,
        "length": "3:03",
        "file_name": "B09___01_Galatians___ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B09___01_Galatians___ENGESVN2DA.mp3"
      },
      {
        "book": "Galatians",
        "number": 48,
        "chapter": 2,
        "length_in_seconds": 209,
        "length": "3:29",
        "file_name": "B09___02_Galatians___ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B09___02_Galatians___ENGESVN2DA.mp3"
      },
      {
        "book": "Galatians",
        "number": 48,
        "chapter": 3,
        "length_in_seconds": 263,
        "length": "4:23",
        "file_name": "B09___03_Galatians___ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B09___03_Galatians___ENGESVN2DA.mp3"
      },
      {
        "book": "Galatians",
        "number": 48,
        "chapter": 4,
        "length_in_seconds": 260,
        "length": "4:20",
        "file_name": "B09___04_Galatians___ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B09___04_Galatians___ENGESVN2DA.mp3"
      },
      {
        "book": "Galatians",
        "number": 48,
        "chapter": 5,
        "length_in_seconds": 225,
        "length": "3:45",
        "file_name": "B09___05_Galatians___ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B09___05_Galatians___ENGESVN2DA.mp3"
      },
      {
        "book": "Galatians",
        "number": 48,
        "chapter": 6,
        "length_in_seconds": 161,
        "length": "2:41",
        "file_name": "B09___06_Galatians___ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B09___06_Galatians___ENGESVN2DA.mp3"
      }
    ],
    "Ephesians": [
      {
        "book": "Ephesians",
        "number": 49,
        "chapter": 1,
        "length_in_seconds": 182,
        "length": "3:02",
        "file_name": "B10___01_Ephesians___ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B10___01_Ephesians___ENGESVN2DA.mp3"
      },
      {
        "book": "Ephesians",
        "number": 49,
        "chapter": 2,
        "length_in_seconds": 184,
        "length": "3:04",
        "file_name": "B10___02_Ephesians___ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B10___02_Ephesians___ENGESVN2DA.mp3"
      },
      {
        "book": "Ephesians",
        "number": 49,
        "chapter": 3,
        "length_in_seconds": 160,
        "length": "2:40",
        "file_name": "B10___03_Ephesians___ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B10___03_Ephesians___ENGESVN2DA.mp3"
      },
      {
        "book": "Ephesians",
        "number": 49,
        "chapter": 4,
        "length_in_seconds": 261,
        "length": "4:21",
        "file_name": "B10___04_Ephesians___ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B10___04_Ephesians___ENGESVN2DA.mp3"
      },
      {
        "book": "Ephesians",
        "number": 49,
        "chapter": 5,
        "length_in_seconds": 234,
        "length": "3:54",
        "file_name": "B10___05_Ephesians___ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B10___05_Ephesians___ENGESVN2DA.mp3"
      },
      {
        "book": "Ephesians",
        "number": 49,
        "chapter": 6,
        "length_in_seconds": 206,
        "length": "3:26",
        "file_name": "B10___06_Ephesians___ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B10___06_Ephesians___ENGESVN2DA.mp3"
      }
    ],
    "Philippians": [
      {
        "book": "Philippians",
        "number": 50,
        "chapter": 1,
        "length_in_seconds": 237,
        "length": "3:57",
        "file_name": "B11___01_Philippians_ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B11___01_Philippians_ENGESVN2DA.mp3"
      },
      {
        "book": "Philippians",
        "number": 50,
        "chapter": 2,
        "length_in_seconds": 234,
        "length": "3:54",
        "file_name": "B11___02_Philippians_ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B11___02_Philippians_ENGESVN2DA.mp3"
      },
      {
        "book": "Philippians",
        "number": 50,
        "chapter": 3,
        "length_in_seconds": 185,
        "length": "3:05",
        "file_name": "B11___03_Philippians_ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B11___03_Philippians_ENGESVN2DA.mp3"
      },
      {
        "book": "Philippians",
        "number": 50,
        "chapter": 4,
        "length_in_seconds": 194,
        "length": "3:14",
        "file_name": "B11___04_Philippians_ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B11___04_Philippians_ENGESVN2DA.mp3"
      }
    ],
    "Colossians": [
      {
        "book": "Colossians",
        "number": 51,
        "chapter": 1,
        "length_in_seconds": 237,
        "length": "3:57",
        "file_name": "B12___01_Colossians__ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B12___01_Colossians__ENGESVN2DA.mp3"
      },
      {
        "book": "Colossians",
        "number": 51,
        "chapter": 2,
        "length_in_seconds": 201,
        "length": "3:21",
        "file_name": "B12___02_Colossians__ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B12___02_Colossians__ENGESVN2DA.mp3"
      },
      {
        "book": "Colossians",
        "number": 51,
        "chapter": 3,
        "length_in_seconds": 197,
        "length": "3:17",
        "file_name": "B12___03_Colossians__ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B12___03_Colossians__ENGESVN2DA.mp3"
      },
      {
        "book": "Colossians",
        "number": 51,
        "chapter": 4,
        "length_in_seconds": 151,
        "length": "2:31",
        "file_name": "B12___04_Colossians__ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B12___04_Colossians__ENGESVN2DA.mp3"
      }
    ],
    "1 Thessalonians": [
      {
        "book": "1 Thessalonians",
        "number": 52,
        "chapter": 1,
        "length_in_seconds": 90,
        "length": "1:30",
        "file_name": "B13___01_1Thess______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B13___01_1Thess______ENGESVN2DA.mp3"
      },
      {
        "book": "1 Thessalonians",
        "number": 52,
        "chapter": 2,
        "length_in_seconds": 168,
        "length": "2:48",
        "file_name": "B13___02_1Thess______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B13___02_1Thess______ENGESVN2DA.mp3"
      },
      {
        "book": "1 Thessalonians",
        "number": 52,
        "chapter": 3,
        "length_in_seconds": 101,
        "length": "1:41",
        "file_name": "B13___03_1Thess______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B13___03_1Thess______ENGESVN2DA.mp3"
      },
      {
        "book": "1 Thessalonians",
        "number": 52,
        "chapter": 4,
        "length_in_seconds": 148,
        "length": "2:28",
        "file_name": "B13___04_1Thess______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B13___04_1Thess______ENGESVN2DA.mp3"
      },
      {
        "book": "1 Thessalonians",
        "number": 52,
        "chapter": 5,
        "length_in_seconds": 170,
        "length": "2:50",
        "file_name": "B13___05_1Thess______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B13___05_1Thess______ENGESVN2DA.mp3"
      }
    ],
    "2 Thessalonians": [
      {
        "book": "2 Thessalonians",
        "number": 53,
        "chapter": 1,
        "length_in_seconds": 109,
        "length": "1:49",
        "file_name": "B14___01_2Thess______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B14___01_2Thess______ENGESVN2DA.mp3"
      },
      {
        "book": "2 Thessalonians",
        "number": 53,
        "chapter": 2,
        "length_in_seconds": 151,
        "length": "2:31",
        "file_name": "B14___02_2Thess______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B14___02_2Thess______ENGESVN2DA.mp3"
      },
      {
        "book": "2 Thessalonians",
        "number": 53,
        "chapter": 3,
        "length_in_seconds": 135,
        "length": "2:15",
        "file_name": "B14___03_2Thess______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B14___03_2Thess______ENGESVN2DA.mp3"
      }
    ],
    "1 Timothy": [
      {
        "book": "1 Timothy",
        "number": 54,
        "chapter": 1,
        "length_in_seconds": 189,
        "length": "3:09",
        "file_name": "B15___01_1Timothy____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B15___01_1Timothy____ENGESVN2DA.mp3"
      },
      {
        "book": "1 Timothy",
        "number": 54,
        "chapter": 2,
        "length_in_seconds": 114,
        "length": "1:54",
        "file_name": "B15___02_1Timothy____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B15___02_1Timothy____ENGESVN2DA.mp3"
      },
      {
        "book": "1 Timothy",
        "number": 54,
        "chapter": 3,
        "length_in_seconds": 141,
        "length": "2:21",
        "file_name": "B15___03_1Timothy____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B15___03_1Timothy____ENGESVN2DA.mp3"
      },
      {
        "book": "1 Timothy",
        "number": 54,
        "chapter": 4,
        "length_in_seconds": 136,
        "length": "2:16",
        "file_name": "B15___04_1Timothy____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B15___04_1Timothy____ENGESVN2DA.mp3"
      },
      {
        "book": "1 Timothy",
        "number": 54,
        "chapter": 5,
        "length_in_seconds": 213,
        "length": "3:33",
        "file_name": "B15___05_1Timothy____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B15___05_1Timothy____ENGESVN2DA.mp3"
      },
      {
        "book": "1 Timothy",
        "number": 54,
        "chapter": 6,
        "length_in_seconds": 224,
        "length": "3:44",
        "file_name": "B15___06_1Timothy____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B15___06_1Timothy____ENGESVN2DA.mp3"
      }
    ],
    "2 Timothy": [
      {
        "book": "2 Timothy",
        "number": 55,
        "chapter": 1,
        "length_in_seconds": 162,
        "length": "2:42",
        "file_name": "B16___01_2Timothy____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B16___01_2Timothy____ENGESVN2DA.mp3"
      },
      {
        "book": "2 Timothy",
        "number": 55,
        "chapter": 2,
        "length_in_seconds": 215,
        "length": "3:35",
        "file_name": "B16___02_2Timothy____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B16___02_2Timothy____ENGESVN2DA.mp3"
      },
      {
        "book": "2 Timothy",
        "number": 55,
        "chapter": 3,
        "length_in_seconds": 150,
        "length": "2:30",
        "file_name": "B16___03_2Timothy____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B16___03_2Timothy____ENGESVN2DA.mp3"
      },
      {
        "book": "2 Timothy",
        "number": 55,
        "chapter": 4,
        "length_in_seconds": 181,
        "length": "3:01",
        "file_name": "B16___04_2Timothy____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B16___04_2Timothy____ENGESVN2DA.mp3"
      }
    ],
    "Titus": [
      {
        "book": "Titus",
        "number": 56,
        "chapter": 1,
        "length_in_seconds": 138,
        "length": "2:18",
        "file_name": "B17___01_Titus_______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B17___01_Titus_______ENGESVN2DA.mp3"
      },
      {
        "book": "Titus",
        "number": 56,
        "chapter": 2,
        "length_in_seconds": 116,
        "length": "1:56",
        "file_name": "B17___02_Titus_______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B17___02_Titus_______ENGESVN2DA.mp3"
      },
      {
        "book": "Titus",
        "number": 56,
        "chapter": 3,
        "length_in_seconds": 137,
        "length": "2:17",
        "file_name": "B17___03_Titus_______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B17___03_Titus_______ENGESVN2DA.mp3"
      }
    ],
    "Philemon": [
      {
        "book": "Philemon",
        "number": 57,
        "chapter": 1,
        "length_in_seconds": 187,
        "length": "3:07",
        "file_name": "B18___01_Philemon____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B18___01_Philemon____ENGESVN2DA.mp3"
      }
    ],
    "Hebrews": [
      {
        "book": "Hebrews",
        "number": 58,
        "chapter": 1,
        "length_in_seconds": 141,
        "length": "2:21",
        "file_name": "B19___01_Hebrews_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B19___01_Hebrews_____ENGESVN2DA.mp3"
      },
      {
        "book": "Hebrews",
        "number": 58,
        "chapter": 2,
        "length_in_seconds": 192,
        "length": "3:12",
        "file_name": "B19___02_Hebrews_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B19___02_Hebrews_____ENGESVN2DA.mp3"
      },
      {
        "book": "Hebrews",
        "number": 58,
        "chapter": 3,
        "length_in_seconds": 154,
        "length": "2:34",
        "file_name": "B19___03_Hebrews_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B19___03_Hebrews_____ENGESVN2DA.mp3"
      },
      {
        "book": "Hebrews",
        "number": 58,
        "chapter": 4,
        "length_in_seconds": 169,
        "length": "2:49",
        "file_name": "B19___04_Hebrews_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B19___04_Hebrews_____ENGESVN2DA.mp3"
      },
      {
        "book": "Hebrews",
        "number": 58,
        "chapter": 5,
        "length_in_seconds": 137,
        "length": "2:17",
        "file_name": "B19___05_Hebrews_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B19___05_Hebrews_____ENGESVN2DA.mp3"
      },
      {
        "book": "Hebrews",
        "number": 58,
        "chapter": 6,
        "length_in_seconds": 190,
        "length": "3:10",
        "file_name": "B19___06_Hebrews_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B19___06_Hebrews_____ENGESVN2DA.mp3"
      },
      {
        "book": "Hebrews",
        "number": 58,
        "chapter": 7,
        "length_in_seconds": 276,
        "length": "4:36",
        "file_name": "B19___07_Hebrews_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B19___07_Hebrews_____ENGESVN2DA.mp3"
      },
      {
        "book": "Hebrews",
        "number": 58,
        "chapter": 8,
        "length_in_seconds": 153,
        "length": "2:33",
        "file_name": "B19___08_Hebrews_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B19___08_Hebrews_____ENGESVN2DA.mp3"
      },
      {
        "book": "Hebrews",
        "number": 58,
        "chapter": 9,
        "length_in_seconds": 313,
        "length": "5:13",
        "file_name": "B19___09_Hebrews_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B19___09_Hebrews_____ENGESVN2DA.mp3"
      },
      {
        "book": "Hebrews",
        "number": 58,
        "chapter": 10,
        "length_in_seconds": 350,
        "length": "5:50",
        "file_name": "B19___10_Hebrews_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B19___10_Hebrews_____ENGESVN2DA.mp3"
      },
      {
        "book": "Hebrews",
        "number": 58,
        "chapter": 11,
        "length_in_seconds": 404,
        "length": "6:44",
        "file_name": "B19___11_Hebrews_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B19___11_Hebrews_____ENGESVN2DA.mp3"
      },
      {
        "book": "Hebrews",
        "number": 58,
        "chapter": 12,
        "length_in_seconds": 308,
        "length": "5:08",
        "file_name": "B19___12_Hebrews_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B19___12_Hebrews_____ENGESVN2DA.mp3"
      },
      {
        "book": "Hebrews",
        "number": 58,
        "chapter": 13,
        "length_in_seconds": 238,
        "length": "3:58",
        "file_name": "B19___13_Hebrews_____ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B19___13_Hebrews_____ENGESVN2DA.mp3"
      }
    ],
    "James": [
      {
        "book": "James",
        "number": 59,
        "chapter": 1,
        "length_in_seconds": 217,
        "length": "3:37",
        "file_name": "B20___01_James_______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B20___01_James_______ENGESVN2DA.mp3"
      },
      {
        "book": "James",
        "number": 59,
        "chapter": 2,
        "length_in_seconds": 223,
        "length": "3:43",
        "file_name": "B20___02_James_______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B20___02_James_______ENGESVN2DA.mp3"
      },
      {
        "book": "James",
        "number": 59,
        "chapter": 3,
        "length_in_seconds": 166,
        "length": "2:46",
        "file_name": "B20___03_James_______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B20___03_James_______ENGESVN2DA.mp3"
      },
      {
        "book": "James",
        "number": 59,
        "chapter": 4,
        "length_in_seconds": 164,
        "length": "2:44",
        "file_name": "B20___04_James_______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B20___04_James_______ENGESVN2DA.mp3"
      },
      {
        "book": "James",
        "number": 59,
        "chapter": 5,
        "length_in_seconds": 199,
        "length": "3:19",
        "file_name": "B20___05_James_______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B20___05_James_______ENGESVN2DA.mp3"
      }
    ],
    "1 Peter": [
      {
        "book": "1 Peter",
        "number": 60,
        "chapter": 1,
        "length_in_seconds": 238,
        "length": "3:58",
        "file_name": "B21___01_1Peter______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B21___01_1Peter______ENGESVN2DA.mp3"
      },
      {
        "book": "1 Peter",
        "number": 60,
        "chapter": 2,
        "length_in_seconds": 230,
        "length": "3:50",
        "file_name": "B21___02_1Peter______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B21___02_1Peter______ENGESVN2DA.mp3"
      },
      {
        "book": "1 Peter",
        "number": 60,
        "chapter": 3,
        "length_in_seconds": 209,
        "length": "3:29",
        "file_name": "B21___03_1Peter______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B21___03_1Peter______ENGESVN2DA.mp3"
      },
      {
        "book": "1 Peter",
        "number": 60,
        "chapter": 4,
        "length_in_seconds": 176,
        "length": "2:56",
        "file_name": "B21___04_1Peter______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B21___04_1Peter______ENGESVN2DA.mp3"
      },
      {
        "book": "1 Peter",
        "number": 60,
        "chapter": 5,
        "length_in_seconds": 137,
        "length": "2:17",
        "file_name": "B21___05_1Peter______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B21___05_1Peter______ENGESVN2DA.mp3"
      }
    ],
    "2 Peter": [
      {
        "book": "2 Peter",
        "number": 61,
        "chapter": 1,
        "length_in_seconds": 203,
        "length": "3:23",
        "file_name": "B22___01_2Peter______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B22___01_2Peter______ENGESVN2DA.mp3"
      },
      {
        "book": "2 Peter",
        "number": 61,
        "chapter": 2,
        "length_in_seconds": 238,
        "length": "3:58",
        "file_name": "B22___02_2Peter______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B22___02_2Peter______ENGESVN2DA.mp3"
      },
      {
        "book": "2 Peter",
        "number": 61,
        "chapter": 3,
        "length_in_seconds": 191,
        "length": "3:11",
        "file_name": "B22___03_2Peter______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B22___03_2Peter______ENGESVN2DA.mp3"
      }
    ],
    "1 John": [
      {
        "book": "1 John",
        "number": 62,
        "chapter": 1,
        "length_in_seconds": 98,
        "length": "1:38",
        "file_name": "B23___01_1John_______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B23___01_1John_______ENGESVN2DA.mp3"
      },
      {
        "book": "1 John",
        "number": 62,
        "chapter": 2,
        "length_in_seconds": 270,
        "length": "4:30",
        "file_name": "B23___02_1John_______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B23___02_1John_______ENGESVN2DA.mp3"
      },
      {
        "book": "1 John",
        "number": 62,
        "chapter": 3,
        "length_in_seconds": 209,
        "length": "3:29",
        "file_name": "B23___03_1John_______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B23___03_1John_______ENGESVN2DA.mp3"
      },
      {
        "book": "1 John",
        "number": 62,
        "chapter": 4,
        "length_in_seconds": 189,
        "length": "3:09",
        "file_name": "B23___04_1John_______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B23___04_1John_______ENGESVN2DA.mp3"
      },
      {
        "book": "1 John",
        "number": 62,
        "chapter": 5,
        "length_in_seconds": 190,
        "length": "3:10",
        "file_name": "B23___05_1John_______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B23___05_1John_______ENGESVN2DA.mp3"
      }
    ],
    "2 John": [
      {
        "book": "2 John",
        "number": 63,
        "chapter": 1,
        "length_in_seconds": 110,
        "length": "1:50",
        "file_name": "B24___01_2John_______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B24___01_2John_______ENGESVN2DA.mp3"
      }
    ],
    "3 John": [
      {
        "book": "3 John",
        "number": 64,
        "chapter": 1,
        "length_in_seconds": 123,
        "length": "2:03",
        "file_name": "B25___01_3John_______ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B25___01_3John_______ENGESVN2DA.mp3"
      }
    ],
    "Jude": [
      {
        "book": "Jude",
        "number": 65,
        "chapter": 1,
        "length_in_seconds": 244,
        "length": "4:04",
        "file_name": "B26___01_Jude________ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B26___01_Jude________ENGESVN2DA.mp3"
      }
    ],
    "Revelation": [
      {
        "book": "Revelation",
        "number": 66,
        "chapter": 1,
        "length_in_seconds": 220,
        "length": "3:40",
        "file_name": "B27___01_Revelation__ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B27___01_Revelation__ENGESVN2DA.mp3"
      },
      {
        "book": "Revelation",
        "number": 66,
        "chapter": 2,
        "length_in_seconds": 316,
        "length": "5:16",
        "file_name": "B27___02_Revelation__ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B27___02_Revelation__ENGESVN2DA.mp3"
      },
      {
        "book": "Revelation",
        "number": 66,
        "chapter": 3,
        "length_in_seconds": 258,
        "length": "4:18",
        "file_name": "B27___03_Revelation__ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B27___03_Revelation__ENGESVN2DA.mp3"
      },
      {
        "book": "Revelation",
        "number": 66,
        "chapter": 4,
        "length_in_seconds": 140,
        "length": "2:20",
        "file_name": "B27___04_Revelation__ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B27___04_Revelation__ENGESVN2DA.mp3"
      },
      {
        "book": "Revelation",
        "number": 66,
        "chapter": 5,
        "length_in_seconds": 161,
        "length": "2:41",
        "file_name": "B27___05_Revelation__ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B27___05_Revelation__ENGESVN2DA.mp3"
      },
      {
        "book": "Revelation",
        "number": 66,
        "chapter": 6,
        "length_in_seconds": 194,
        "length": "3:14",
        "file_name": "B27___06_Revelation__ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B27___06_Revelation__ENGESVN2DA.mp3"
      },
      {
        "book": "Revelation",
        "number": 66,
        "chapter": 7,
        "length_in_seconds": 195,
        "length": "3:15",
        "file_name": "B27___07_Revelation__ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B27___07_Revelation__ENGESVN2DA.mp3"
      },
      {
        "book": "Revelation",
        "number": 66,
        "chapter": 8,
        "length_in_seconds": 151,
        "length": "2:31",
        "file_name": "B27___08_Revelation__ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B27___08_Revelation__ENGESVN2DA.mp3"
      },
      {
        "book": "Revelation",
        "number": 66,
        "chapter": 9,
        "length_in_seconds": 203,
        "length": "3:23",
        "file_name": "B27___09_Revelation__ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B27___09_Revelation__ENGESVN2DA.mp3"
      },
      {
        "book": "Revelation",
        "number": 66,
        "chapter": 10,
        "length_in_seconds": 123,
        "length": "2:03",
        "file_name": "B27___10_Revelation__ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B27___10_Revelation__ENGESVN2DA.mp3"
      },
      {
        "book": "Revelation",
        "number": 66,
        "chapter": 11,
        "length_in_seconds": 215,
        "length": "3:35",
        "file_name": "B27___11_Revelation__ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B27___11_Revelation__ENGESVN2DA.mp3"
      },
      {
        "book": "Revelation",
        "number": 66,
        "chapter": 12,
        "length_in_seconds": 193,
        "length": "3:13",
        "file_name": "B27___12_Revelation__ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B27___12_Revelation__ENGESVN2DA.mp3"
      },
      {
        "book": "Revelation",
        "number": 66,
        "chapter": 13,
        "length_in_seconds": 194,
        "length": "3:14",
        "file_name": "B27___13_Revelation__ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B27___13_Revelation__ENGESVN2DA.mp3"
      },
      {
        "book": "Revelation",
        "number": 66,
        "chapter": 14,
        "length_in_seconds": 254,
        "length": "4:14",
        "file_name": "B27___14_Revelation__ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B27___14_Revelation__ENGESVN2DA.mp3"
      },
      {
        "book": "Revelation",
        "number": 66,
        "chapter": 15,
        "length_in_seconds": 91,
        "length": "1:31",
        "file_name": "B27___15_Revelation__ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B27___15_Revelation__ENGESVN2DA.mp3"
      },
      {
        "book": "Revelation",
        "number": 66,
        "chapter": 16,
        "length_in_seconds": 205,
        "length": "3:25",
        "file_name": "B27___16_Revelation__ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B27___16_Revelation__ENGESVN2DA.mp3"
      },
      {
        "book": "Revelation",
        "number": 66,
        "chapter": 17,
        "length_in_seconds": 192,
        "length": "3:12",
        "file_name": "B27___17_Revelation__ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B27___17_Revelation__ENGESVN2DA.mp3"
      },
      {
        "book": "Revelation",
        "number": 66,
        "chapter": 18,
        "length_in_seconds": 347,
        "length": "5:47",
        "file_name": "B27___18_Revelation__ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B27___18_Revelation__ENGESVN2DA.mp3"
      },
      {
        "book": "Revelation",
        "number": 66,
        "chapter": 19,
        "length_in_seconds": 261,
        "length": "4:21",
        "file_name": "B27___19_Revelation__ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B27___19_Revelation__ENGESVN2DA.mp3"
      },
      {
        "book": "Revelation",
        "number": 66,
        "chapter": 20,
        "length_in_seconds": 170,
        "length": "2:50",
        "file_name": "B27___20_Revelation__ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B27___20_Revelation__ENGESVN2DA.mp3"
      },
      {
        "book": "Revelation",
        "number": 66,
        "chapter": 21,
        "length_in_seconds": 298,
        "length": "4:58",
        "file_name": "B27___21_Revelation__ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B27___21_Revelation__ENGESVN2DA.mp3"
      },
      {
        "book": "Revelation",
        "number": 66,
        "chapter": 22,
        "length_in_seconds": 281,
        "length": "4:41",
        "file_name": "B27___22_Revelation__ENGESVN2DA.mp3",
        "dam_id": "ENGESVN2DA",
        "path": "/ENGESVN2DA/B27___22_Revelation__ENGESVN2DA.mp3"
      }
    ]
  },
  "chapters": [
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 1,
      "length_in_seconds": 342,
      "length": "5:42",
      "file_name": "A01___01_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___01_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 2,
      "length_in_seconds": 255,
      "length": "4:15",
      "file_name": "A01___02_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___02_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 3,
      "length_in_seconds": 294,
      "length": "4:54",
      "file_name": "A01___03_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___03_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 4,
      "length_in_seconds": 268,
      "length": "4:28",
      "file_name": "A01___04_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___04_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 5,
      "length_in_seconds": 208,
      "length": "3:28",
      "file_name": "A01___05_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___05_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 6,
      "length_in_seconds": 222,
      "length": "3:42",
      "file_name": "A01___06_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___06_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 7,
      "length_in_seconds": 236,
      "length": "3:56",
      "file_name": "A01___07_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___07_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 8,
      "length_in_seconds": 225,
      "length": "3:45",
      "file_name": "A01___08_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___08_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 9,
      "length_in_seconds": 251,
      "length": "4:11",
      "file_name": "A01___09_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___09_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 10,
      "length_in_seconds": 226,
      "length": "3:46",
      "file_name": "A01___10_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___10_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 11,
      "length_in_seconds": 250,
      "length": "4:10",
      "file_name": "A01___11_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___11_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 12,
      "length_in_seconds": 194,
      "length": "3:14",
      "file_name": "A01___12_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___12_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 13,
      "length_in_seconds": 166,
      "length": "2:46",
      "file_name": "A01___13_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___13_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 14,
      "length_in_seconds": 249,
      "length": "4:09",
      "file_name": "A01___14_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___14_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 15,
      "length_in_seconds": 185,
      "length": "3:05",
      "file_name": "A01___15_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___15_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 16,
      "length_in_seconds": 179,
      "length": "2:59",
      "file_name": "A01___16_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___16_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 17,
      "length_in_seconds": 274,
      "length": "4:34",
      "file_name": "A01___17_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___17_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 18,
      "length_in_seconds": 344,
      "length": "5:44",
      "file_name": "A01___18_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___18_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 19,
      "length_in_seconds": 387,
      "length": "6:27",
      "file_name": "A01___19_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___19_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 20,
      "length_in_seconds": 198,
      "length": "3:18",
      "file_name": "A01___20_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___20_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 21,
      "length_in_seconds": 295,
      "length": "4:55",
      "file_name": "A01___21_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___21_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 22,
      "length_in_seconds": 249,
      "length": "4:09",
      "file_name": "A01___22_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___22_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 23,
      "length_in_seconds": 195,
      "length": "3:15",
      "file_name": "A01___23_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___23_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 24,
      "length_in_seconds": 691,
      "length": "11:31",
      "file_name": "A01___24_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___24_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 25,
      "length_in_seconds": 301,
      "length": "5:01",
      "file_name": "A01___25_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___25_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 26,
      "length_in_seconds": 326,
      "length": "5:26",
      "file_name": "A01___26_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___26_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 27,
      "length_in_seconds": 515,
      "length": "8:35",
      "file_name": "A01___27_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___27_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 28,
      "length_in_seconds": 230,
      "length": "3:50",
      "file_name": "A01___28_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___28_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 29,
      "length_in_seconds": 323,
      "length": "5:23",
      "file_name": "A01___29_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___29_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 30,
      "length_in_seconds": 396,
      "length": "6:36",
      "file_name": "A01___30_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___30_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 31,
      "length_in_seconds": 544,
      "length": "9:04",
      "file_name": "A01___31_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___31_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 32,
      "length_in_seconds": 304,
      "length": "5:04",
      "file_name": "A01___32_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___32_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 33,
      "length_in_seconds": 200,
      "length": "3:20",
      "file_name": "A01___33_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___33_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 34,
      "length_in_seconds": 281,
      "length": "4:41",
      "file_name": "A01___34_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___34_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 35,
      "length_in_seconds": 265,
      "length": "4:25",
      "file_name": "A01___35_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___35_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 36,
      "length_in_seconds": 362,
      "length": "6:02",
      "file_name": "A01___36_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___36_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 37,
      "length_in_seconds": 349,
      "length": "5:49",
      "file_name": "A01___37_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___37_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 38,
      "length_in_seconds": 308,
      "length": "5:08",
      "file_name": "A01___38_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___38_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 39,
      "length_in_seconds": 233,
      "length": "3:53",
      "file_name": "A01___39_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___39_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 40,
      "length_in_seconds": 204,
      "length": "3:24",
      "file_name": "A01___40_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___40_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 41,
      "length_in_seconds": 522,
      "length": "8:42",
      "file_name": "A01___41_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___41_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 42,
      "length_in_seconds": 377,
      "length": "6:17",
      "file_name": "A01___42_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___42_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 43,
      "length_in_seconds": 352,
      "length": "5:52",
      "file_name": "A01___43_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___43_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 44,
      "length_in_seconds": 309,
      "length": "5:09",
      "file_name": "A01___44_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___44_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 45,
      "length_in_seconds": 284,
      "length": "4:44",
      "file_name": "A01___45_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___45_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 46,
      "length_in_seconds": 324,
      "length": "5:24",
      "file_name": "A01___46_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___46_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 47,
      "length_in_seconds": 385,
      "length": "6:25",
      "file_name": "A01___47_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___47_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 48,
      "length_in_seconds": 372,
      "length": "6:12",
      "file_name": "A01___48_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___48_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 49,
      "length_in_seconds": 540,
      "length": "9:00",
      "file_name": "A01___49_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___49_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Genesis",
      "number": 1,
      "chapter": 50,
      "length_in_seconds": 286,
      "length": "4:46",
      "file_name": "A01___50_Genesis_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A01___50_Genesis_____ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 1,
      "length_in_seconds": 179,
      "length": "2:59",
      "file_name": "A02___01_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___01_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 2,
      "length_in_seconds": 232,
      "length": "3:52",
      "file_name": "A02___02_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___02_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 3,
      "length_in_seconds": 284,
      "length": "4:44",
      "file_name": "A02___03_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___03_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 4,
      "length_in_seconds": 315,
      "length": "5:15",
      "file_name": "A02___04_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___04_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 5,
      "length_in_seconds": 234,
      "length": "3:54",
      "file_name": "A02___05_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___05_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 6,
      "length_in_seconds": 311,
      "length": "5:11",
      "file_name": "A02___06_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___06_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 7,
      "length_in_seconds": 246,
      "length": "4:06",
      "file_name": "A02___07_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___07_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 8,
      "length_in_seconds": 352,
      "length": "5:52",
      "file_name": "A02___08_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___08_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 9,
      "length_in_seconds": 355,
      "length": "5:55",
      "file_name": "A02___09_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___09_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 10,
      "length_in_seconds": 330,
      "length": "5:30",
      "file_name": "A02___10_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___10_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 11,
      "length_in_seconds": 129,
      "length": "2:09",
      "file_name": "A02___11_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___11_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 12,
      "length_in_seconds": 511,
      "length": "8:31",
      "file_name": "A02___12_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___12_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 13,
      "length_in_seconds": 235,
      "length": "3:55",
      "file_name": "A02___13_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___13_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 14,
      "length_in_seconds": 339,
      "length": "5:39",
      "file_name": "A02___14_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___14_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 15,
      "length_in_seconds": 264,
      "length": "4:24",
      "file_name": "A02___15_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___15_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 16,
      "length_in_seconds": 373,
      "length": "6:13",
      "file_name": "A02___16_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___16_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 17,
      "length_in_seconds": 178,
      "length": "2:58",
      "file_name": "A02___17_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___17_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 18,
      "length_in_seconds": 278,
      "length": "4:38",
      "file_name": "A02___18_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___18_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 19,
      "length_in_seconds": 251,
      "length": "4:11",
      "file_name": "A02___19_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___19_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 20,
      "length_in_seconds": 214,
      "length": "3:34",
      "file_name": "A02___20_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___20_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 21,
      "length_in_seconds": 305,
      "length": "5:05",
      "file_name": "A02___21_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___21_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 22,
      "length_in_seconds": 270,
      "length": "4:30",
      "file_name": "A02___22_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___22_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 23,
      "length_in_seconds": 295,
      "length": "4:55",
      "file_name": "A02___23_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___23_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 24,
      "length_in_seconds": 184,
      "length": "3:04",
      "file_name": "A02___24_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___24_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 25,
      "length_in_seconds": 294,
      "length": "4:54",
      "file_name": "A02___25_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___25_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 26,
      "length_in_seconds": 303,
      "length": "5:03",
      "file_name": "A02___26_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___26_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 27,
      "length_in_seconds": 190,
      "length": "3:10",
      "file_name": "A02___27_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___27_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 28,
      "length_in_seconds": 395,
      "length": "6:35",
      "file_name": "A02___28_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___28_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 29,
      "length_in_seconds": 423,
      "length": "7:03",
      "file_name": "A02___29_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___29_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 30,
      "length_in_seconds": 319,
      "length": "5:19",
      "file_name": "A02___30_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___30_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 31,
      "length_in_seconds": 155,
      "length": "2:35",
      "file_name": "A02___31_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___31_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 32,
      "length_in_seconds": 407,
      "length": "6:47",
      "file_name": "A02___32_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___32_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 33,
      "length_in_seconds": 258,
      "length": "4:18",
      "file_name": "A02___33_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___33_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 34,
      "length_in_seconds": 369,
      "length": "6:09",
      "file_name": "A02___34_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___34_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 35,
      "length_in_seconds": 298,
      "length": "4:58",
      "file_name": "A02___35_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___35_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 36,
      "length_in_seconds": 333,
      "length": "5:33",
      "file_name": "A02___36_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___36_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 37,
      "length_in_seconds": 260,
      "length": "4:20",
      "file_name": "A02___37_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___37_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 38,
      "length_in_seconds": 314,
      "length": "5:14",
      "file_name": "A02___38_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___38_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 39,
      "length_in_seconds": 403,
      "length": "6:43",
      "file_name": "A02___39_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___39_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Exodus",
      "number": 2,
      "chapter": 40,
      "length_in_seconds": 292,
      "length": "4:52",
      "file_name": "A02___40_Exodus______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A02___40_Exodus______ENGESVO2DA.mp3"
    },
    {
      "book": "Leviticus",
      "number": 3,
      "chapter": 1,
      "length_in_seconds": 159,
      "length": "2:39",
      "file_name": "A03___01_Leviticus___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A03___01_Leviticus___ENGESVO2DA.mp3"
    },
    {
      "book": "Leviticus",
      "number": 3,
      "chapter": 2,
      "length_in_seconds": 152,
      "length": "2:32",
      "file_name": "A03___02_Leviticus___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A03___02_Leviticus___ENGESVO2DA.mp3"
    },
    {
      "book": "Leviticus",
      "number": 3,
      "chapter": 3,
      "length_in_seconds": 153,
      "length": "2:33",
      "file_name": "A03___03_Leviticus___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A03___03_Leviticus___ENGESVO2DA.mp3"
    },
    {
      "book": "Leviticus",
      "number": 3,
      "chapter": 4,
      "length_in_seconds": 336,
      "length": "5:36",
      "file_name": "A03___04_Leviticus___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A03___04_Leviticus___ENGESVO2DA.mp3"
    },
    {
      "book": "Leviticus",
      "number": 3,
      "chapter": 5,
      "length_in_seconds": 231,
      "length": "3:51",
      "file_name": "A03___05_Leviticus___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A03___05_Leviticus___ENGESVO2DA.mp3"
    },
    {
      "book": "Leviticus",
      "number": 3,
      "chapter": 6,
      "length_in_seconds": 295,
      "length": "4:55",
      "file_name": "A03___06_Leviticus___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A03___06_Leviticus___ENGESVO2DA.mp3"
    },
    {
      "book": "Leviticus",
      "number": 3,
      "chapter": 7,
      "length_in_seconds": 341,
      "length": "5:41",
      "file_name": "A03___07_Leviticus___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A03___07_Leviticus___ENGESVO2DA.mp3"
    },
    {
      "book": "Leviticus",
      "number": 3,
      "chapter": 8,
      "length_in_seconds": 359,
      "length": "5:59",
      "file_name": "A03___08_Leviticus___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A03___08_Leviticus___ENGESVO2DA.mp3"
    },
    {
      "book": "Leviticus",
      "number": 3,
      "chapter": 9,
      "length_in_seconds": 226,
      "length": "3:46",
      "file_name": "A03___09_Leviticus___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A03___09_Leviticus___ENGESVO2DA.mp3"
    },
    {
      "book": "Leviticus",
      "number": 3,
      "chapter": 10,
      "length_in_seconds": 248,
      "length": "4:08",
      "file_name": "A03___10_Leviticus___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A03___10_Leviticus___ENGESVO2DA.mp3"
    },
    {
      "book": "Leviticus",
      "number": 3,
      "chapter": 11,
      "length_in_seconds": 380,
      "length": "6:20",
      "file_name": "A03___11_Leviticus___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A03___11_Leviticus___ENGESVO2DA.mp3"
    },
    {
      "book": "Leviticus",
      "number": 3,
      "chapter": 12,
      "length_in_seconds": 91,
      "length": "1:31",
      "file_name": "A03___12_Leviticus___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A03___12_Leviticus___ENGESVO2DA.mp3"
    },
    {
      "book": "Leviticus",
      "number": 3,
      "chapter": 13,
      "length_in_seconds": 598,
      "length": "9:58",
      "file_name": "A03___13_Leviticus___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A03___13_Leviticus___ENGESVO2DA.mp3"
    },
    {
      "book": "Leviticus",
      "number": 3,
      "chapter": 14,
      "length_in_seconds": 540,
      "length": "9:00",
      "file_name": "A03___14_Leviticus___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A03___14_Leviticus___ENGESVO2DA.mp3"
    },
    {
      "book": "Leviticus",
      "number": 3,
      "chapter": 15,
      "length_in_seconds": 349,
      "length": "5:49",
      "file_name": "A03___15_Leviticus___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A03___15_Leviticus___ENGESVO2DA.mp3"
    },
    {
      "book": "Leviticus",
      "number": 3,
      "chapter": 16,
      "length_in_seconds": 380,
      "length": "6:20",
      "file_name": "A03___16_Leviticus___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A03___16_Leviticus___ENGESVO2DA.mp3"
    },
    {
      "book": "Leviticus",
      "number": 3,
      "chapter": 17,
      "length_in_seconds": 187,
      "length": "3:07",
      "file_name": "A03___17_Leviticus___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A03___17_Leviticus___ENGESVO2DA.mp3"
    },
    {
      "book": "Leviticus",
      "number": 3,
      "chapter": 18,
      "length_in_seconds": 248,
      "length": "4:08",
      "file_name": "A03___18_Leviticus___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A03___18_Leviticus___ENGESVO2DA.mp3"
    },
    {
      "book": "Leviticus",
      "number": 3,
      "chapter": 19,
      "length_in_seconds": 328,
      "length": "5:28",
      "file_name": "A03___19_Leviticus___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A03___19_Leviticus___ENGESVO2DA.mp3"
    },
    {
      "book": "Leviticus",
      "number": 3,
      "chapter": 20,
      "length_in_seconds": 294,
      "length": "4:54",
      "file_name": "A03___20_Leviticus___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A03___20_Leviticus___ENGESVO2DA.mp3"
    },
    {
      "book": "Leviticus",
      "number": 3,
      "chapter": 21,
      "length_in_seconds": 212,
      "length": "3:32",
      "file_name": "A03___21_Leviticus___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A03___21_Leviticus___ENGESVO2DA.mp3"
    },
    {
      "book": "Leviticus",
      "number": 3,
      "chapter": 22,
      "length_in_seconds": 308,
      "length": "5:08",
      "file_name": "A03___22_Leviticus___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A03___22_Leviticus___ENGESVO2DA.mp3"
    },
    {
      "book": "Leviticus",
      "number": 3,
      "chapter": 23,
      "length_in_seconds": 424,
      "length": "7:04",
      "file_name": "A03___23_Leviticus___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A03___23_Leviticus___ENGESVO2DA.mp3"
    },
    {
      "book": "Leviticus",
      "number": 3,
      "chapter": 24,
      "length_in_seconds": 190,
      "length": "3:10",
      "file_name": "A03___24_Leviticus___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A03___24_Leviticus___ENGESVO2DA.mp3"
    },
    {
      "book": "Leviticus",
      "number": 3,
      "chapter": 25,
      "length_in_seconds": 489,
      "length": "8:09",
      "file_name": "A03___25_Leviticus___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A03___25_Leviticus___ENGESVO2DA.mp3"
    },
    {
      "book": "Leviticus",
      "number": 3,
      "chapter": 26,
      "length_in_seconds": 426,
      "length": "7:06",
      "file_name": "A03___26_Leviticus___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A03___26_Leviticus___ENGESVO2DA.mp3"
    },
    {
      "book": "Leviticus",
      "number": 3,
      "chapter": 27,
      "length_in_seconds": 319,
      "length": "5:19",
      "file_name": "A03___27_Leviticus___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A03___27_Leviticus___ENGESVO2DA.mp3"
    },
    {
      "book": "Numbers",
      "number": 4,
      "chapter": 1,
      "length_in_seconds": 493,
      "length": "8:13",
      "file_name": "A04___01_Numbers_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A04___01_Numbers_____ENGESVO2DA.mp3"
    },
    {
      "book": "Numbers",
      "number": 4,
      "chapter": 2,
      "length_in_seconds": 288,
      "length": "4:48",
      "file_name": "A04___02_Numbers_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A04___02_Numbers_____ENGESVO2DA.mp3"
    },
    {
      "book": "Numbers",
      "number": 4,
      "chapter": 3,
      "length_in_seconds": 476,
      "length": "7:56",
      "file_name": "A04___03_Numbers_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A04___03_Numbers_____ENGESVO2DA.mp3"
    },
    {
      "book": "Numbers",
      "number": 4,
      "chapter": 4,
      "length_in_seconds": 448,
      "length": "7:28",
      "file_name": "A04___04_Numbers_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A04___04_Numbers_____ENGESVO2DA.mp3"
    },
    {
      "book": "Numbers",
      "number": 4,
      "chapter": 5,
      "length_in_seconds": 316,
      "length": "5:16",
      "file_name": "A04___05_Numbers_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A04___05_Numbers_____ENGESVO2DA.mp3"
    },
    {
      "book": "Numbers",
      "number": 4,
      "chapter": 6,
      "length_in_seconds": 257,
      "length": "4:17",
      "file_name": "A04___06_Numbers_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A04___06_Numbers_____ENGESVO2DA.mp3"
    },
    {
      "book": "Numbers",
      "number": 4,
      "chapter": 7,
      "length_in_seconds": 749,
      "length": "12:29",
      "file_name": "A04___07_Numbers_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A04___07_Numbers_____ENGESVO2DA.mp3"
    },
    {
      "book": "Numbers",
      "number": 4,
      "chapter": 8,
      "length_in_seconds": 237,
      "length": "3:57",
      "file_name": "A04___08_Numbers_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A04___08_Numbers_____ENGESVO2DA.mp3"
    },
    {
      "book": "Numbers",
      "number": 4,
      "chapter": 9,
      "length_in_seconds": 247,
      "length": "4:07",
      "file_name": "A04___09_Numbers_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A04___09_Numbers_____ENGESVO2DA.mp3"
    },
    {
      "book": "Numbers",
      "number": 4,
      "chapter": 10,
      "length_in_seconds": 311,
      "length": "5:11",
      "file_name": "A04___10_Numbers_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A04___10_Numbers_____ENGESVO2DA.mp3"
    },
    {
      "book": "Numbers",
      "number": 4,
      "chapter": 11,
      "length_in_seconds": 382,
      "length": "6:22",
      "file_name": "A04___11_Numbers_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A04___11_Numbers_____ENGESVO2DA.mp3"
    },
    {
      "book": "Numbers",
      "number": 4,
      "chapter": 12,
      "length_in_seconds": 152,
      "length": "2:32",
      "file_name": "A04___12_Numbers_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A04___12_Numbers_____ENGESVO2DA.mp3"
    },
    {
      "book": "Numbers",
      "number": 4,
      "chapter": 13,
      "length_in_seconds": 280,
      "length": "4:40",
      "file_name": "A04___13_Numbers_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A04___13_Numbers_____ENGESVO2DA.mp3"
    },
    {
      "book": "Numbers",
      "number": 4,
      "chapter": 14,
      "length_in_seconds": 430,
      "length": "7:10",
      "file_name": "A04___14_Numbers_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A04___14_Numbers_____ENGESVO2DA.mp3"
    },
    {
      "book": "Numbers",
      "number": 4,
      "chapter": 15,
      "length_in_seconds": 355,
      "length": "5:55",
      "file_name": "A04___15_Numbers_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A04___15_Numbers_____ENGESVO2DA.mp3"
    },
    {
      "book": "Numbers",
      "number": 4,
      "chapter": 16,
      "length_in_seconds": 511,
      "length": "8:31",
      "file_name": "A04___16_Numbers_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A04___16_Numbers_____ENGESVO2DA.mp3"
    },
    {
      "book": "Numbers",
      "number": 4,
      "chapter": 17,
      "length_in_seconds": 129,
      "length": "2:09",
      "file_name": "A04___17_Numbers_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A04___17_Numbers_____ENGESVO2DA.mp3"
    },
    {
      "book": "Numbers",
      "number": 4,
      "chapter": 18,
      "length_in_seconds": 368,
      "length": "6:08",
      "file_name": "A04___18_Numbers_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A04___18_Numbers_____ENGESVO2DA.mp3"
    },
    {
      "book": "Numbers",
      "number": 4,
      "chapter": 19,
      "length_in_seconds": 230,
      "length": "3:50",
      "file_name": "A04___19_Numbers_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A04___19_Numbers_____ENGESVO2DA.mp3"
    },
    {
      "book": "Numbers",
      "number": 4,
      "chapter": 20,
      "length_in_seconds": 295,
      "length": "4:55",
      "file_name": "A04___20_Numbers_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A04___20_Numbers_____ENGESVO2DA.mp3"
    },
    {
      "book": "Numbers",
      "number": 4,
      "chapter": 21,
      "length_in_seconds": 352,
      "length": "5:52",
      "file_name": "A04___21_Numbers_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A04___21_Numbers_____ENGESVO2DA.mp3"
    },
    {
      "book": "Numbers",
      "number": 4,
      "chapter": 22,
      "length_in_seconds": 441,
      "length": "7:21",
      "file_name": "A04___22_Numbers_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A04___22_Numbers_____ENGESVO2DA.mp3"
    },
    {
      "book": "Numbers",
      "number": 4,
      "chapter": 23,
      "length_in_seconds": 332,
      "length": "5:32",
      "file_name": "A04___23_Numbers_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A04___23_Numbers_____ENGESVO2DA.mp3"
    },
    {
      "book": "Numbers",
      "number": 4,
      "chapter": 24,
      "length_in_seconds": 293,
      "length": "4:53",
      "file_name": "A04___24_Numbers_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A04___24_Numbers_____ENGESVO2DA.mp3"
    },
    {
      "book": "Numbers",
      "number": 4,
      "chapter": 25,
      "length_in_seconds": 174,
      "length": "2:54",
      "file_name": "A04___25_Numbers_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A04___25_Numbers_____ENGESVO2DA.mp3"
    },
    {
      "book": "Numbers",
      "number": 4,
      "chapter": 26,
      "length_in_seconds": 580,
      "length": "9:40",
      "file_name": "A04___26_Numbers_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A04___26_Numbers_____ENGESVO2DA.mp3"
    },
    {
      "book": "Numbers",
      "number": 4,
      "chapter": 27,
      "length_in_seconds": 218,
      "length": "3:38",
      "file_name": "A04___27_Numbers_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A04___27_Numbers_____ENGESVO2DA.mp3"
    },
    {
      "book": "Numbers",
      "number": 4,
      "chapter": 28,
      "length_in_seconds": 248,
      "length": "4:08",
      "file_name": "A04___28_Numbers_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A04___28_Numbers_____ENGESVO2DA.mp3"
    },
    {
      "book": "Numbers",
      "number": 4,
      "chapter": 29,
      "length_in_seconds": 299,
      "length": "4:59",
      "file_name": "A04___29_Numbers_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A04___29_Numbers_____ENGESVO2DA.mp3"
    },
    {
      "book": "Numbers",
      "number": 4,
      "chapter": 30,
      "length_in_seconds": 175,
      "length": "2:55",
      "file_name": "A04___30_Numbers_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A04___30_Numbers_____ENGESVO2DA.mp3"
    },
    {
      "book": "Numbers",
      "number": 4,
      "chapter": 31,
      "length_in_seconds": 443,
      "length": "7:23",
      "file_name": "A04___31_Numbers_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A04___31_Numbers_____ENGESVO2DA.mp3"
    },
    {
      "book": "Numbers",
      "number": 4,
      "chapter": 32,
      "length_in_seconds": 369,
      "length": "6:09",
      "file_name": "A04___32_Numbers_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A04___32_Numbers_____ENGESVO2DA.mp3"
    },
    {
      "book": "Numbers",
      "number": 4,
      "chapter": 33,
      "length_in_seconds": 383,
      "length": "6:23",
      "file_name": "A04___33_Numbers_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A04___33_Numbers_____ENGESVO2DA.mp3"
    },
    {
      "book": "Numbers",
      "number": 4,
      "chapter": 34,
      "length_in_seconds": 225,
      "length": "3:45",
      "file_name": "A04___34_Numbers_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A04___34_Numbers_____ENGESVO2DA.mp3"
    },
    {
      "book": "Numbers",
      "number": 4,
      "chapter": 35,
      "length_in_seconds": 319,
      "length": "5:19",
      "file_name": "A04___35_Numbers_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A04___35_Numbers_____ENGESVO2DA.mp3"
    },
    {
      "book": "Numbers",
      "number": 4,
      "chapter": 36,
      "length_in_seconds": 166,
      "length": "2:46",
      "file_name": "A04___36_Numbers_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A04___36_Numbers_____ENGESVO2DA.mp3"
    },
    {
      "book": "Deuteronomy",
      "number": 5,
      "chapter": 1,
      "length_in_seconds": 433,
      "length": "7:13",
      "file_name": "A05___01_Deuteronomy_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A05___01_Deuteronomy_ENGESVO2DA.mp3"
    },
    {
      "book": "Deuteronomy",
      "number": 5,
      "chapter": 2,
      "length_in_seconds": 360,
      "length": "6:00",
      "file_name": "A05___02_Deuteronomy_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A05___02_Deuteronomy_ENGESVO2DA.mp3"
    },
    {
      "book": "Deuteronomy",
      "number": 5,
      "chapter": 3,
      "length_in_seconds": 303,
      "length": "5:03",
      "file_name": "A05___03_Deuteronomy_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A05___03_Deuteronomy_ENGESVO2DA.mp3"
    },
    {
      "book": "Deuteronomy",
      "number": 5,
      "chapter": 4,
      "length_in_seconds": 520,
      "length": "8:40",
      "file_name": "A05___04_Deuteronomy_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A05___04_Deuteronomy_ENGESVO2DA.mp3"
    },
    {
      "book": "Deuteronomy",
      "number": 5,
      "chapter": 5,
      "length_in_seconds": 297,
      "length": "4:57",
      "file_name": "A05___05_Deuteronomy_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A05___05_Deuteronomy_ENGESVO2DA.mp3"
    },
    {
      "book": "Deuteronomy",
      "number": 5,
      "chapter": 6,
      "length_in_seconds": 223,
      "length": "3:43",
      "file_name": "A05___06_Deuteronomy_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A05___06_Deuteronomy_ENGESVO2DA.mp3"
    },
    {
      "book": "Deuteronomy",
      "number": 5,
      "chapter": 7,
      "length_in_seconds": 286,
      "length": "4:46",
      "file_name": "A05___07_Deuteronomy_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A05___07_Deuteronomy_ENGESVO2DA.mp3"
    },
    {
      "book": "Deuteronomy",
      "number": 5,
      "chapter": 8,
      "length_in_seconds": 185,
      "length": "3:05",
      "file_name": "A05___08_Deuteronomy_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A05___08_Deuteronomy_ENGESVO2DA.mp3"
    },
    {
      "book": "Deuteronomy",
      "number": 5,
      "chapter": 9,
      "length_in_seconds": 334,
      "length": "5:34",
      "file_name": "A05___09_Deuteronomy_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A05___09_Deuteronomy_ENGESVO2DA.mp3"
    },
    {
      "book": "Deuteronomy",
      "number": 5,
      "chapter": 10,
      "length_in_seconds": 214,
      "length": "3:34",
      "file_name": "A05___10_Deuteronomy_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A05___10_Deuteronomy_ENGESVO2DA.mp3"
    },
    {
      "book": "Deuteronomy",
      "number": 5,
      "chapter": 11,
      "length_in_seconds": 302,
      "length": "5:02",
      "file_name": "A05___11_Deuteronomy_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A05___11_Deuteronomy_ENGESVO2DA.mp3"
    },
    {
      "book": "Deuteronomy",
      "number": 5,
      "chapter": 12,
      "length_in_seconds": 339,
      "length": "5:39",
      "file_name": "A05___12_Deuteronomy_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A05___12_Deuteronomy_ENGESVO2DA.mp3"
    },
    {
      "book": "Deuteronomy",
      "number": 5,
      "chapter": 13,
      "length_in_seconds": 208,
      "length": "3:28",
      "file_name": "A05___13_Deuteronomy_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A05___13_Deuteronomy_ENGESVO2DA.mp3"
    },
    {
      "book": "Deuteronomy",
      "number": 5,
      "chapter": 14,
      "length_in_seconds": 223,
      "length": "3:43",
      "file_name": "A05___14_Deuteronomy_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A05___14_Deuteronomy_ENGESVO2DA.mp3"
    },
    {
      "book": "Deuteronomy",
      "number": 5,
      "chapter": 15,
      "length_in_seconds": 232,
      "length": "3:52",
      "file_name": "A05___15_Deuteronomy_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A05___15_Deuteronomy_ENGESVO2DA.mp3"
    },
    {
      "book": "Deuteronomy",
      "number": 5,
      "chapter": 16,
      "length_in_seconds": 241,
      "length": "4:01",
      "file_name": "A05___16_Deuteronomy_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A05___16_Deuteronomy_ENGESVO2DA.mp3"
    },
    {
      "book": "Deuteronomy",
      "number": 5,
      "chapter": 17,
      "length_in_seconds": 238,
      "length": "3:58",
      "file_name": "A05___17_Deuteronomy_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A05___17_Deuteronomy_ENGESVO2DA.mp3"
    },
    {
      "book": "Deuteronomy",
      "number": 5,
      "chapter": 18,
      "length_in_seconds": 208,
      "length": "3:28",
      "file_name": "A05___18_Deuteronomy_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A05___18_Deuteronomy_ENGESVO2DA.mp3"
    },
    {
      "book": "Deuteronomy",
      "number": 5,
      "chapter": 19,
      "length_in_seconds": 202,
      "length": "3:22",
      "file_name": "A05___19_Deuteronomy_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A05___19_Deuteronomy_ENGESVO2DA.mp3"
    },
    {
      "book": "Deuteronomy",
      "number": 5,
      "chapter": 20,
      "length_in_seconds": 218,
      "length": "3:38",
      "file_name": "A05___20_Deuteronomy_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A05___20_Deuteronomy_ENGESVO2DA.mp3"
    },
    {
      "book": "Deuteronomy",
      "number": 5,
      "chapter": 21,
      "length_in_seconds": 249,
      "length": "4:09",
      "file_name": "A05___21_Deuteronomy_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A05___21_Deuteronomy_ENGESVO2DA.mp3"
    },
    {
      "book": "Deuteronomy",
      "number": 5,
      "chapter": 22,
      "length_in_seconds": 304,
      "length": "5:04",
      "file_name": "A05___22_Deuteronomy_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A05___22_Deuteronomy_ENGESVO2DA.mp3"
    },
    {
      "book": "Deuteronomy",
      "number": 5,
      "chapter": 23,
      "length_in_seconds": 237,
      "length": "3:57",
      "file_name": "A05___23_Deuteronomy_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A05___23_Deuteronomy_ENGESVO2DA.mp3"
    },
    {
      "book": "Deuteronomy",
      "number": 5,
      "chapter": 24,
      "length_in_seconds": 212,
      "length": "3:32",
      "file_name": "A05___24_Deuteronomy_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A05___24_Deuteronomy_ENGESVO2DA.mp3"
    },
    {
      "book": "Deuteronomy",
      "number": 5,
      "chapter": 25,
      "length_in_seconds": 184,
      "length": "3:04",
      "file_name": "A05___25_Deuteronomy_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A05___25_Deuteronomy_ENGESVO2DA.mp3"
    },
    {
      "book": "Deuteronomy",
      "number": 5,
      "chapter": 26,
      "length_in_seconds": 214,
      "length": "3:34",
      "file_name": "A05___26_Deuteronomy_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A05___26_Deuteronomy_ENGESVO2DA.mp3"
    },
    {
      "book": "Deuteronomy",
      "number": 5,
      "chapter": 27,
      "length_in_seconds": 258,
      "length": "4:18",
      "file_name": "A05___27_Deuteronomy_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A05___27_Deuteronomy_ENGESVO2DA.mp3"
    },
    {
      "book": "Deuteronomy",
      "number": 5,
      "chapter": 28,
      "length_in_seconds": 691,
      "length": "11:31",
      "file_name": "A05___28_Deuteronomy_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A05___28_Deuteronomy_ENGESVO2DA.mp3"
    },
    {
      "book": "Deuteronomy",
      "number": 5,
      "chapter": 29,
      "length_in_seconds": 301,
      "length": "5:01",
      "file_name": "A05___29_Deuteronomy_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A05___29_Deuteronomy_ENGESVO2DA.mp3"
    },
    {
      "book": "Deuteronomy",
      "number": 5,
      "chapter": 30,
      "length_in_seconds": 210,
      "length": "3:30",
      "file_name": "A05___30_Deuteronomy_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A05___30_Deuteronomy_ENGESVO2DA.mp3"
    },
    {
      "book": "Deuteronomy",
      "number": 5,
      "chapter": 31,
      "length_in_seconds": 356,
      "length": "5:56",
      "file_name": "A05___31_Deuteronomy_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A05___31_Deuteronomy_ENGESVO2DA.mp3"
    },
    {
      "book": "Deuteronomy",
      "number": 5,
      "chapter": 32,
      "length_in_seconds": 475,
      "length": "7:55",
      "file_name": "A05___32_Deuteronomy_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A05___32_Deuteronomy_ENGESVO2DA.mp3"
    },
    {
      "book": "Deuteronomy",
      "number": 5,
      "chapter": 33,
      "length_in_seconds": 329,
      "length": "5:29",
      "file_name": "A05___33_Deuteronomy_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A05___33_Deuteronomy_ENGESVO2DA.mp3"
    },
    {
      "book": "Deuteronomy",
      "number": 5,
      "chapter": 34,
      "length_in_seconds": 133,
      "length": "2:13",
      "file_name": "A05___34_Deuteronomy_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A05___34_Deuteronomy_ENGESVO2DA.mp3"
    },
    {
      "book": "Joshua",
      "number": 6,
      "chapter": 1,
      "length_in_seconds": 200,
      "length": "3:20",
      "file_name": "A06___01_Joshua______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A06___01_Joshua______ENGESVO2DA.mp3"
    },
    {
      "book": "Joshua",
      "number": 6,
      "chapter": 2,
      "length_in_seconds": 237,
      "length": "3:57",
      "file_name": "A06___02_Joshua______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A06___02_Joshua______ENGESVO2DA.mp3"
    },
    {
      "book": "Joshua",
      "number": 6,
      "chapter": 3,
      "length_in_seconds": 185,
      "length": "3:05",
      "file_name": "A06___03_Joshua______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A06___03_Joshua______ENGESVO2DA.mp3"
    },
    {
      "book": "Joshua",
      "number": 6,
      "chapter": 4,
      "length_in_seconds": 214,
      "length": "3:34",
      "file_name": "A06___04_Joshua______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A06___04_Joshua______ENGESVO2DA.mp3"
    },
    {
      "book": "Joshua",
      "number": 6,
      "chapter": 5,
      "length_in_seconds": 171,
      "length": "2:51",
      "file_name": "A06___05_Joshua______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A06___05_Joshua______ENGESVO2DA.mp3"
    },
    {
      "book": "Joshua",
      "number": 6,
      "chapter": 6,
      "length_in_seconds": 286,
      "length": "4:46",
      "file_name": "A06___06_Joshua______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A06___06_Joshua______ENGESVO2DA.mp3"
    },
    {
      "book": "Joshua",
      "number": 6,
      "chapter": 7,
      "length_in_seconds": 303,
      "length": "5:03",
      "file_name": "A06___07_Joshua______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A06___07_Joshua______ENGESVO2DA.mp3"
    },
    {
      "book": "Joshua",
      "number": 6,
      "chapter": 8,
      "length_in_seconds": 353,
      "length": "5:53",
      "file_name": "A06___08_Joshua______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A06___08_Joshua______ENGESVO2DA.mp3"
    },
    {
      "book": "Joshua",
      "number": 6,
      "chapter": 9,
      "length_in_seconds": 263,
      "length": "4:23",
      "file_name": "A06___09_Joshua______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A06___09_Joshua______ENGESVO2DA.mp3"
    },
    {
      "book": "Joshua",
      "number": 6,
      "chapter": 10,
      "length_in_seconds": 428,
      "length": "7:08",
      "file_name": "A06___10_Joshua______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A06___10_Joshua______ENGESVO2DA.mp3"
    },
    {
      "book": "Joshua",
      "number": 6,
      "chapter": 11,
      "length_in_seconds": 222,
      "length": "3:42",
      "file_name": "A06___11_Joshua______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A06___11_Joshua______ENGESVO2DA.mp3"
    },
    {
      "book": "Joshua",
      "number": 6,
      "chapter": 12,
      "length_in_seconds": 169,
      "length": "2:49",
      "file_name": "A06___12_Joshua______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A06___12_Joshua______ENGESVO2DA.mp3"
    },
    {
      "book": "Joshua",
      "number": 6,
      "chapter": 13,
      "length_in_seconds": 293,
      "length": "4:53",
      "file_name": "A06___13_Joshua______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A06___13_Joshua______ENGESVO2DA.mp3"
    },
    {
      "book": "Joshua",
      "number": 6,
      "chapter": 14,
      "length_in_seconds": 167,
      "length": "2:47",
      "file_name": "A06___14_Joshua______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A06___14_Joshua______ENGESVO2DA.mp3"
    },
    {
      "book": "Joshua",
      "number": 6,
      "chapter": 15,
      "length_in_seconds": 389,
      "length": "6:29",
      "file_name": "A06___15_Joshua______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A06___15_Joshua______ENGESVO2DA.mp3"
    },
    {
      "book": "Joshua",
      "number": 6,
      "chapter": 16,
      "length_in_seconds": 93,
      "length": "1:33",
      "file_name": "A06___16_Joshua______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A06___16_Joshua______ENGESVO2DA.mp3"
    },
    {
      "book": "Joshua",
      "number": 6,
      "chapter": 17,
      "length_in_seconds": 218,
      "length": "3:38",
      "file_name": "A06___17_Joshua______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A06___17_Joshua______ENGESVO2DA.mp3"
    },
    {
      "book": "Joshua",
      "number": 6,
      "chapter": 18,
      "length_in_seconds": 261,
      "length": "4:21",
      "file_name": "A06___18_Joshua______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A06___18_Joshua______ENGESVO2DA.mp3"
    },
    {
      "book": "Joshua",
      "number": 6,
      "chapter": 19,
      "length_in_seconds": 350,
      "length": "5:50",
      "file_name": "A06___19_Joshua______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A06___19_Joshua______ENGESVO2DA.mp3"
    },
    {
      "book": "Joshua",
      "number": 6,
      "chapter": 20,
      "length_in_seconds": 102,
      "length": "1:42",
      "file_name": "A06___20_Joshua______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A06___20_Joshua______ENGESVO2DA.mp3"
    },
    {
      "book": "Joshua",
      "number": 6,
      "chapter": 21,
      "length_in_seconds": 342,
      "length": "5:42",
      "file_name": "A06___21_Joshua______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A06___21_Joshua______ENGESVO2DA.mp3"
    },
    {
      "book": "Joshua",
      "number": 6,
      "chapter": 22,
      "length_in_seconds": 426,
      "length": "7:06",
      "file_name": "A06___22_Joshua______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A06___22_Joshua______ENGESVO2DA.mp3"
    },
    {
      "book": "Joshua",
      "number": 6,
      "chapter": 23,
      "length_in_seconds": 181,
      "length": "3:01",
      "file_name": "A06___23_Joshua______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A06___23_Joshua______ENGESVO2DA.mp3"
    },
    {
      "book": "Joshua",
      "number": 6,
      "chapter": 24,
      "length_in_seconds": 357,
      "length": "5:57",
      "file_name": "A06___24_Joshua______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A06___24_Joshua______ENGESVO2DA.mp3"
    },
    {
      "book": "Judges",
      "number": 7,
      "chapter": 1,
      "length_in_seconds": 317,
      "length": "5:17",
      "file_name": "A07___01_Judges______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A07___01_Judges______ENGESVO2DA.mp3"
    },
    {
      "book": "Judges",
      "number": 7,
      "chapter": 2,
      "length_in_seconds": 215,
      "length": "3:35",
      "file_name": "A07___02_Judges______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A07___02_Judges______ENGESVO2DA.mp3"
    },
    {
      "book": "Judges",
      "number": 7,
      "chapter": 3,
      "length_in_seconds": 274,
      "length": "4:34",
      "file_name": "A07___03_Judges______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A07___03_Judges______ENGESVO2DA.mp3"
    },
    {
      "book": "Judges",
      "number": 7,
      "chapter": 4,
      "length_in_seconds": 253,
      "length": "4:13",
      "file_name": "A07___04_Judges______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A07___04_Judges______ENGESVO2DA.mp3"
    },
    {
      "book": "Judges",
      "number": 7,
      "chapter": 5,
      "length_in_seconds": 309,
      "length": "5:09",
      "file_name": "A07___05_Judges______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A07___05_Judges______ENGESVO2DA.mp3"
    },
    {
      "book": "Judges",
      "number": 7,
      "chapter": 6,
      "length_in_seconds": 418,
      "length": "6:58",
      "file_name": "A07___06_Judges______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A07___06_Judges______ENGESVO2DA.mp3"
    },
    {
      "book": "Judges",
      "number": 7,
      "chapter": 7,
      "length_in_seconds": 294,
      "length": "4:54",
      "file_name": "A07___07_Judges______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A07___07_Judges______ENGESVO2DA.mp3"
    },
    {
      "book": "Judges",
      "number": 7,
      "chapter": 8,
      "length_in_seconds": 316,
      "length": "5:16",
      "file_name": "A07___08_Judges______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A07___08_Judges______ENGESVO2DA.mp3"
    },
    {
      "book": "Judges",
      "number": 7,
      "chapter": 9,
      "length_in_seconds": 533,
      "length": "8:53",
      "file_name": "A07___09_Judges______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A07___09_Judges______ENGESVO2DA.mp3"
    },
    {
      "book": "Judges",
      "number": 7,
      "chapter": 10,
      "length_in_seconds": 159,
      "length": "2:39",
      "file_name": "A07___10_Judges______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A07___10_Judges______ENGESVO2DA.mp3"
    },
    {
      "book": "Judges",
      "number": 7,
      "chapter": 11,
      "length_in_seconds": 405,
      "length": "6:45",
      "file_name": "A07___11_Judges______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A07___11_Judges______ENGESVO2DA.mp3"
    },
    {
      "book": "Judges",
      "number": 7,
      "chapter": 12,
      "length_in_seconds": 147,
      "length": "2:27",
      "file_name": "A07___12_Judges______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A07___12_Judges______ENGESVO2DA.mp3"
    },
    {
      "book": "Judges",
      "number": 7,
      "chapter": 13,
      "length_in_seconds": 246,
      "length": "4:06",
      "file_name": "A07___13_Judges______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A07___13_Judges______ENGESVO2DA.mp3"
    },
    {
      "book": "Judges",
      "number": 7,
      "chapter": 14,
      "length_in_seconds": 237,
      "length": "3:57",
      "file_name": "A07___14_Judges______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A07___14_Judges______ENGESVO2DA.mp3"
    },
    {
      "book": "Judges",
      "number": 7,
      "chapter": 15,
      "length_in_seconds": 237,
      "length": "3:57",
      "file_name": "A07___15_Judges______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A07___15_Judges______ENGESVO2DA.mp3"
    },
    {
      "book": "Judges",
      "number": 7,
      "chapter": 16,
      "length_in_seconds": 424,
      "length": "7:04",
      "file_name": "A07___16_Judges______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A07___16_Judges______ENGESVO2DA.mp3"
    },
    {
      "book": "Judges",
      "number": 7,
      "chapter": 17,
      "length_in_seconds": 135,
      "length": "2:15",
      "file_name": "A07___17_Judges______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A07___17_Judges______ENGESVO2DA.mp3"
    },
    {
      "book": "Judges",
      "number": 7,
      "chapter": 18,
      "length_in_seconds": 326,
      "length": "5:26",
      "file_name": "A07___18_Judges______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A07___18_Judges______ENGESVO2DA.mp3"
    },
    {
      "book": "Judges",
      "number": 7,
      "chapter": 19,
      "length_in_seconds": 352,
      "length": "5:52",
      "file_name": "A07___19_Judges______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A07___19_Judges______ENGESVO2DA.mp3"
    },
    {
      "book": "Judges",
      "number": 7,
      "chapter": 20,
      "length_in_seconds": 459,
      "length": "7:39",
      "file_name": "A07___20_Judges______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A07___20_Judges______ENGESVO2DA.mp3"
    },
    {
      "book": "Judges",
      "number": 7,
      "chapter": 21,
      "length_in_seconds": 248,
      "length": "4:08",
      "file_name": "A07___21_Judges______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A07___21_Judges______ENGESVO2DA.mp3"
    },
    {
      "book": "Ruth",
      "number": 8,
      "chapter": 1,
      "length_in_seconds": 251,
      "length": "4:11",
      "file_name": "A08___01_Ruth________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A08___01_Ruth________ENGESVO2DA.mp3"
    },
    {
      "book": "Ruth",
      "number": 8,
      "chapter": 2,
      "length_in_seconds": 258,
      "length": "4:18",
      "file_name": "A08___02_Ruth________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A08___02_Ruth________ENGESVO2DA.mp3"
    },
    {
      "book": "Ruth",
      "number": 8,
      "chapter": 3,
      "length_in_seconds": 180,
      "length": "3:00",
      "file_name": "A08___03_Ruth________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A08___03_Ruth________ENGESVO2DA.mp3"
    },
    {
      "book": "Ruth",
      "number": 8,
      "chapter": 4,
      "length_in_seconds": 233,
      "length": "3:53",
      "file_name": "A08___04_Ruth________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A08___04_Ruth________ENGESVO2DA.mp3"
    },
    {
      "book": "1 Samuel",
      "number": 9,
      "chapter": 1,
      "length_in_seconds": 272,
      "length": "4:32",
      "file_name": "A09___01_1Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A09___01_1Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "1 Samuel",
      "number": 9,
      "chapter": 2,
      "length_in_seconds": 404,
      "length": "6:44",
      "file_name": "A09___02_1Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A09___02_1Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "1 Samuel",
      "number": 9,
      "chapter": 3,
      "length_in_seconds": 209,
      "length": "3:29",
      "file_name": "A09___03_1Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A09___03_1Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "1 Samuel",
      "number": 9,
      "chapter": 4,
      "length_in_seconds": 250,
      "length": "4:10",
      "file_name": "A09___04_1Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A09___04_1Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "1 Samuel",
      "number": 9,
      "chapter": 5,
      "length_in_seconds": 135,
      "length": "2:15",
      "file_name": "A09___05_1Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A09___05_1Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "1 Samuel",
      "number": 9,
      "chapter": 6,
      "length_in_seconds": 250,
      "length": "4:10",
      "file_name": "A09___06_1Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A09___06_1Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "1 Samuel",
      "number": 9,
      "chapter": 7,
      "length_in_seconds": 173,
      "length": "2:53",
      "file_name": "A09___07_1Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A09___07_1Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "1 Samuel",
      "number": 9,
      "chapter": 8,
      "length_in_seconds": 178,
      "length": "2:58",
      "file_name": "A09___08_1Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A09___08_1Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "1 Samuel",
      "number": 9,
      "chapter": 9,
      "length_in_seconds": 300,
      "length": "5:00",
      "file_name": "A09___09_1Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A09___09_1Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "1 Samuel",
      "number": 9,
      "chapter": 10,
      "length_in_seconds": 301,
      "length": "5:01",
      "file_name": "A09___10_1Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A09___10_1Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "1 Samuel",
      "number": 9,
      "chapter": 11,
      "length_in_seconds": 176,
      "length": "2:56",
      "file_name": "A09___11_1Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A09___11_1Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "1 Samuel",
      "number": 9,
      "chapter": 12,
      "length_in_seconds": 282,
      "length": "4:42",
      "file_name": "A09___12_1Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A09___12_1Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "1 Samuel",
      "number": 9,
      "chapter": 13,
      "length_in_seconds": 235,
      "length": "3:55",
      "file_name": "A09___13_1Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A09___13_1Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "1 Samuel",
      "number": 9,
      "chapter": 14,
      "length_in_seconds": 540,
      "length": "9:00",
      "file_name": "A09___14_1Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A09___14_1Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "1 Samuel",
      "number": 9,
      "chapter": 15,
      "length_in_seconds": 351,
      "length": "5:51",
      "file_name": "A09___15_1Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A09___15_1Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "1 Samuel",
      "number": 9,
      "chapter": 16,
      "length_in_seconds": 246,
      "length": "4:06",
      "file_name": "A09___16_1Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A09___16_1Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "1 Samuel",
      "number": 9,
      "chapter": 17,
      "length_in_seconds": 586,
      "length": "9:46",
      "file_name": "A09___17_1Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A09___17_1Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "1 Samuel",
      "number": 9,
      "chapter": 18,
      "length_in_seconds": 289,
      "length": "4:49",
      "file_name": "A09___18_1Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A09___18_1Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "1 Samuel",
      "number": 9,
      "chapter": 19,
      "length_in_seconds": 244,
      "length": "4:04",
      "file_name": "A09___19_1Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A09___19_1Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "1 Samuel",
      "number": 9,
      "chapter": 20,
      "length_in_seconds": 411,
      "length": "6:51",
      "file_name": "A09___20_1Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A09___20_1Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "1 Samuel",
      "number": 9,
      "chapter": 21,
      "length_in_seconds": 170,
      "length": "2:50",
      "file_name": "A09___21_1Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A09___21_1Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "1 Samuel",
      "number": 9,
      "chapter": 22,
      "length_in_seconds": 274,
      "length": "4:34",
      "file_name": "A09___22_1Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A09___22_1Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "1 Samuel",
      "number": 9,
      "chapter": 23,
      "length_in_seconds": 295,
      "length": "4:55",
      "file_name": "A09___23_1Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A09___23_1Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "1 Samuel",
      "number": 9,
      "chapter": 24,
      "length_in_seconds": 236,
      "length": "3:56",
      "file_name": "A09___24_1Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A09___24_1Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "1 Samuel",
      "number": 9,
      "chapter": 25,
      "length_in_seconds": 464,
      "length": "7:44",
      "file_name": "A09___25_1Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A09___25_1Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "1 Samuel",
      "number": 9,
      "chapter": 26,
      "length_in_seconds": 311,
      "length": "5:11",
      "file_name": "A09___26_1Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A09___26_1Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "1 Samuel",
      "number": 9,
      "chapter": 27,
      "length_in_seconds": 144,
      "length": "2:24",
      "file_name": "A09___27_1Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A09___27_1Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "1 Samuel",
      "number": 9,
      "chapter": 28,
      "length_in_seconds": 327,
      "length": "5:27",
      "file_name": "A09___28_1Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A09___28_1Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "1 Samuel",
      "number": 9,
      "chapter": 29,
      "length_in_seconds": 159,
      "length": "2:39",
      "file_name": "A09___29_1Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A09___29_1Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "1 Samuel",
      "number": 9,
      "chapter": 30,
      "length_in_seconds": 287,
      "length": "4:47",
      "file_name": "A09___30_1Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A09___30_1Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "1 Samuel",
      "number": 9,
      "chapter": 31,
      "length_in_seconds": 127,
      "length": "2:07",
      "file_name": "A09___31_1Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A09___31_1Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "2 Samuel",
      "number": 10,
      "chapter": 1,
      "length_in_seconds": 246,
      "length": "4:06",
      "file_name": "A10___01_2Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A10___01_2Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "2 Samuel",
      "number": 10,
      "chapter": 2,
      "length_in_seconds": 314,
      "length": "5:14",
      "file_name": "A10___02_2Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A10___02_2Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "2 Samuel",
      "number": 10,
      "chapter": 3,
      "length_in_seconds": 385,
      "length": "6:25",
      "file_name": "A10___03_2Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A10___03_2Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "2 Samuel",
      "number": 10,
      "chapter": 4,
      "length_in_seconds": 149,
      "length": "2:29",
      "file_name": "A10___04_2Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A10___04_2Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "2 Samuel",
      "number": 10,
      "chapter": 5,
      "length_in_seconds": 228,
      "length": "3:48",
      "file_name": "A10___05_2Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A10___05_2Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "2 Samuel",
      "number": 10,
      "chapter": 6,
      "length_in_seconds": 225,
      "length": "3:45",
      "file_name": "A10___06_2Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A10___06_2Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "2 Samuel",
      "number": 10,
      "chapter": 7,
      "length_in_seconds": 71,
      "length": "1:11",
      "file_name": "A10___07_2Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A10___07_2Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "2 Samuel",
      "number": 10,
      "chapter": 8,
      "length_in_seconds": 173,
      "length": "2:53",
      "file_name": "A10___08_2Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A10___08_2Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "2 Samuel",
      "number": 10,
      "chapter": 9,
      "length_in_seconds": 147,
      "length": "2:27",
      "file_name": "A10___09_2Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A10___09_2Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "2 Samuel",
      "number": 10,
      "chapter": 10,
      "length_in_seconds": 198,
      "length": "3:18",
      "file_name": "A10___10_2Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A10___10_2Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "2 Samuel",
      "number": 10,
      "chapter": 11,
      "length_in_seconds": 264,
      "length": "4:24",
      "file_name": "A10___11_2Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A10___11_2Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "2 Samuel",
      "number": 10,
      "chapter": 12,
      "length_in_seconds": 369,
      "length": "6:09",
      "file_name": "A10___12_2Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A10___12_2Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "2 Samuel",
      "number": 10,
      "chapter": 13,
      "length_in_seconds": 408,
      "length": "6:48",
      "file_name": "A10___13_2Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A10___13_2Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "2 Samuel",
      "number": 10,
      "chapter": 14,
      "length_in_seconds": 409,
      "length": "6:49",
      "file_name": "A10___14_2Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A10___14_2Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "2 Samuel",
      "number": 10,
      "chapter": 15,
      "length_in_seconds": 367,
      "length": "6:07",
      "file_name": "A10___15_2Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A10___15_2Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "2 Samuel",
      "number": 10,
      "chapter": 16,
      "length_in_seconds": 260,
      "length": "4:20",
      "file_name": "A10___16_2Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A10___16_2Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "2 Samuel",
      "number": 10,
      "chapter": 17,
      "length_in_seconds": 334,
      "length": "5:34",
      "file_name": "A10___17_2Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A10___17_2Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "2 Samuel",
      "number": 10,
      "chapter": 18,
      "length_in_seconds": 376,
      "length": "6:16",
      "file_name": "A10___18_2Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A10___18_2Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "2 Samuel",
      "number": 10,
      "chapter": 19,
      "length_in_seconds": 508,
      "length": "8:28",
      "file_name": "A10___19_2Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A10___19_2Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "2 Samuel",
      "number": 10,
      "chapter": 20,
      "length_in_seconds": 294,
      "length": "4:54",
      "file_name": "A10___20_2Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A10___20_2Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "2 Samuel",
      "number": 10,
      "chapter": 21,
      "length_in_seconds": 261,
      "length": "4:21",
      "file_name": "A10___21_2Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A10___21_2Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "2 Samuel",
      "number": 10,
      "chapter": 22,
      "length_in_seconds": 384,
      "length": "6:24",
      "file_name": "A10___22_2Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A10___22_2Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "2 Samuel",
      "number": 10,
      "chapter": 23,
      "length_in_seconds": 323,
      "length": "5:23",
      "file_name": "A10___23_2Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A10___23_2Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "2 Samuel",
      "number": 10,
      "chapter": 24,
      "length_in_seconds": 284,
      "length": "4:44",
      "file_name": "A10___24_2Samuel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A10___24_2Samuel_____ENGESVO2DA.mp3"
    },
    {
      "book": "1 Kings",
      "number": 11,
      "chapter": 1,
      "length_in_seconds": 598,
      "length": "9:58",
      "file_name": "A11___01_1Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A11___01_1Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "1 Kings",
      "number": 11,
      "chapter": 2,
      "length_in_seconds": 587,
      "length": "9:47",
      "file_name": "A11___02_1Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A11___02_1Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "1 Kings",
      "number": 11,
      "chapter": 3,
      "length_in_seconds": 296,
      "length": "4:56",
      "file_name": "A11___03_1Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A11___03_1Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "1 Kings",
      "number": 11,
      "chapter": 4,
      "length_in_seconds": 260,
      "length": "4:20",
      "file_name": "A11___04_1Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A11___04_1Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "1 Kings",
      "number": 11,
      "chapter": 5,
      "length_in_seconds": 174,
      "length": "2:54",
      "file_name": "A11___05_1Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A11___05_1Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "1 Kings",
      "number": 11,
      "chapter": 6,
      "length_in_seconds": 326,
      "length": "5:26",
      "file_name": "A11___06_1Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A11___06_1Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "1 Kings",
      "number": 11,
      "chapter": 7,
      "length_in_seconds": 467,
      "length": "7:47",
      "file_name": "A11___07_1Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A11___07_1Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "1 Kings",
      "number": 11,
      "chapter": 8,
      "length_in_seconds": 692,
      "length": "11:32",
      "file_name": "A11___08_1Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A11___08_1Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "1 Kings",
      "number": 11,
      "chapter": 9,
      "length_in_seconds": 272,
      "length": "4:32",
      "file_name": "A11___09_1Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A11___09_1Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "1 Kings",
      "number": 11,
      "chapter": 10,
      "length_in_seconds": 282,
      "length": "4:42",
      "file_name": "A11___10_1Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A11___10_1Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "1 Kings",
      "number": 11,
      "chapter": 11,
      "length_in_seconds": 442,
      "length": "7:22",
      "file_name": "A11___11_1Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A11___11_1Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "1 Kings",
      "number": 11,
      "chapter": 12,
      "length_in_seconds": 341,
      "length": "5:41",
      "file_name": "A11___12_1Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A11___12_1Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "1 Kings",
      "number": 11,
      "chapter": 13,
      "length_in_seconds": 340,
      "length": "5:40",
      "file_name": "A11___13_1Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A11___13_1Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "1 Kings",
      "number": 11,
      "chapter": 14,
      "length_in_seconds": 342,
      "length": "5:42",
      "file_name": "A11___14_1Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A11___14_1Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "1 Kings",
      "number": 11,
      "chapter": 15,
      "length_in_seconds": 313,
      "length": "5:13",
      "file_name": "A11___15_1Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A11___15_1Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "1 Kings",
      "number": 11,
      "chapter": 16,
      "length_in_seconds": 343,
      "length": "5:43",
      "file_name": "A11___16_1Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A11___16_1Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "1 Kings",
      "number": 11,
      "chapter": 17,
      "length_in_seconds": 239,
      "length": "3:59",
      "file_name": "A11___17_1Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A11___17_1Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "1 Kings",
      "number": 11,
      "chapter": 18,
      "length_in_seconds": 547,
      "length": "9:07",
      "file_name": "A11___18_1Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A11___18_1Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "1 Kings",
      "number": 11,
      "chapter": 19,
      "length_in_seconds": 271,
      "length": "4:31",
      "file_name": "A11___19_1Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A11___19_1Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "1 Kings",
      "number": 11,
      "chapter": 20,
      "length_in_seconds": 511,
      "length": "8:31",
      "file_name": "A11___20_1Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A11___20_1Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "1 Kings",
      "number": 11,
      "chapter": 21,
      "length_in_seconds": 324,
      "length": "5:24",
      "file_name": "A11___21_1Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A11___21_1Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "1 Kings",
      "number": 11,
      "chapter": 22,
      "length_in_seconds": 546,
      "length": "9:06",
      "file_name": "A11___22_1Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A11___22_1Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "2 Kings",
      "number": 12,
      "chapter": 1,
      "length_in_seconds": 245,
      "length": "4:05",
      "file_name": "A12___01_2Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A12___01_2Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "2 Kings",
      "number": 12,
      "chapter": 2,
      "length_in_seconds": 301,
      "length": "5:01",
      "file_name": "A12___02_2Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A12___02_2Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "2 Kings",
      "number": 12,
      "chapter": 3,
      "length_in_seconds": 280,
      "length": "4:40",
      "file_name": "A12___03_2Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A12___03_2Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "2 Kings",
      "number": 12,
      "chapter": 4,
      "length_in_seconds": 436,
      "length": "7:16",
      "file_name": "A12___04_2Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A12___04_2Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "2 Kings",
      "number": 12,
      "chapter": 5,
      "length_in_seconds": 330,
      "length": "5:30",
      "file_name": "A12___05_2Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A12___05_2Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "2 Kings",
      "number": 12,
      "chapter": 6,
      "length_in_seconds": 348,
      "length": "5:48",
      "file_name": "A12___06_2Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A12___06_2Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "2 Kings",
      "number": 12,
      "chapter": 7,
      "length_in_seconds": 256,
      "length": "4:16",
      "file_name": "A12___07_2Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A12___07_2Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "2 Kings",
      "number": 12,
      "chapter": 8,
      "length_in_seconds": 318,
      "length": "5:18",
      "file_name": "A12___08_2Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A12___08_2Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "2 Kings",
      "number": 12,
      "chapter": 9,
      "length_in_seconds": 415,
      "length": "6:55",
      "file_name": "A12___09_2Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A12___09_2Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "2 Kings",
      "number": 12,
      "chapter": 10,
      "length_in_seconds": 395,
      "length": "6:35",
      "file_name": "A12___10_2Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A12___10_2Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "2 Kings",
      "number": 12,
      "chapter": 11,
      "length_in_seconds": 227,
      "length": "3:47",
      "file_name": "A12___11_2Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A12___11_2Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "2 Kings",
      "number": 12,
      "chapter": 12,
      "length_in_seconds": 210,
      "length": "3:30",
      "file_name": "A12___12_2Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A12___12_2Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "2 Kings",
      "number": 12,
      "chapter": 13,
      "length_in_seconds": 287,
      "length": "4:47",
      "file_name": "A12___13_2Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A12___13_2Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "2 Kings",
      "number": 12,
      "chapter": 14,
      "length_in_seconds": 287,
      "length": "4:47",
      "file_name": "A12___14_2Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A12___14_2Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "2 Kings",
      "number": 12,
      "chapter": 15,
      "length_in_seconds": 356,
      "length": "5:56",
      "file_name": "A12___15_2Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A12___15_2Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "2 Kings",
      "number": 12,
      "chapter": 16,
      "length_in_seconds": 211,
      "length": "3:31",
      "file_name": "A12___16_2Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A12___16_2Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "2 Kings",
      "number": 12,
      "chapter": 17,
      "length_in_seconds": 416,
      "length": "6:56",
      "file_name": "A12___17_2Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A12___17_2Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "2 Kings",
      "number": 12,
      "chapter": 18,
      "length_in_seconds": 429,
      "length": "7:09",
      "file_name": "A12___18_2Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A12___18_2Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "2 Kings",
      "number": 12,
      "chapter": 19,
      "length_in_seconds": 399,
      "length": "6:39",
      "file_name": "A12___19_2Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A12___19_2Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "2 Kings",
      "number": 12,
      "chapter": 20,
      "length_in_seconds": 228,
      "length": "3:48",
      "file_name": "A12___20_2Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A12___20_2Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "2 Kings",
      "number": 12,
      "chapter": 21,
      "length_in_seconds": 237,
      "length": "3:57",
      "file_name": "A12___21_2Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A12___21_2Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "2 Kings",
      "number": 12,
      "chapter": 22,
      "length_in_seconds": 233,
      "length": "3:53",
      "file_name": "A12___22_2Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A12___22_2Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "2 Kings",
      "number": 12,
      "chapter": 23,
      "length_in_seconds": 445,
      "length": "7:25",
      "file_name": "A12___23_2Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A12___23_2Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "2 Kings",
      "number": 12,
      "chapter": 24,
      "length_in_seconds": 191,
      "length": "3:11",
      "file_name": "A12___24_2Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A12___24_2Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "2 Kings",
      "number": 12,
      "chapter": 25,
      "length_in_seconds": 310,
      "length": "5:10",
      "file_name": "A12___25_2Kings______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A12___25_2Kings______ENGESVO2DA.mp3"
    },
    {
      "book": "1 Chronicles",
      "number": 13,
      "chapter": 1,
      "length_in_seconds": 361,
      "length": "6:01",
      "file_name": "A13___01_1Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A13___01_1Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "1 Chronicles",
      "number": 13,
      "chapter": 2,
      "length_in_seconds": 397,
      "length": "6:37",
      "file_name": "A13___02_1Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A13___02_1Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "1 Chronicles",
      "number": 13,
      "chapter": 3,
      "length_in_seconds": 200,
      "length": "3:20",
      "file_name": "A13___03_1Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A13___03_1Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "1 Chronicles",
      "number": 13,
      "chapter": 4,
      "length_in_seconds": 367,
      "length": "6:07",
      "file_name": "A13___04_1Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A13___04_1Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "1 Chronicles",
      "number": 13,
      "chapter": 5,
      "length_in_seconds": 241,
      "length": "4:01",
      "file_name": "A13___05_1Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A13___05_1Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "1 Chronicles",
      "number": 13,
      "chapter": 6,
      "length_in_seconds": 532,
      "length": "8:52",
      "file_name": "A13___06_1Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A13___06_1Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "1 Chronicles",
      "number": 13,
      "chapter": 7,
      "length_in_seconds": 366,
      "length": "6:06",
      "file_name": "A13___07_1Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A13___07_1Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "1 Chronicles",
      "number": 13,
      "chapter": 8,
      "length_in_seconds": 242,
      "length": "4:02",
      "file_name": "A13___08_1Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A13___08_1Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "1 Chronicles",
      "number": 13,
      "chapter": 9,
      "length_in_seconds": 372,
      "length": "6:12",
      "file_name": "A13___09_1Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A13___09_1Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "1 Chronicles",
      "number": 13,
      "chapter": 10,
      "length_in_seconds": 126,
      "length": "2:06",
      "file_name": "A13___10_1Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A13___10_1Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "1 Chronicles",
      "number": 13,
      "chapter": 11,
      "length_in_seconds": 356,
      "length": "5:56",
      "file_name": "A13___11_1Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A13___11_1Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "1 Chronicles",
      "number": 13,
      "chapter": 12,
      "length_in_seconds": 343,
      "length": "5:43",
      "file_name": "A13___12_1Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A13___12_1Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "1 Chronicles",
      "number": 13,
      "chapter": 13,
      "length_in_seconds": 127,
      "length": "2:07",
      "file_name": "A13___13_1Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A13___13_1Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "1 Chronicles",
      "number": 13,
      "chapter": 14,
      "length_in_seconds": 129,
      "length": "2:09",
      "file_name": "A13___14_1Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A13___14_1Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "1 Chronicles",
      "number": 13,
      "chapter": 15,
      "length_in_seconds": 270,
      "length": "4:30",
      "file_name": "A13___15_1Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A13___15_1Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "1 Chronicles",
      "number": 13,
      "chapter": 16,
      "length_in_seconds": 332,
      "length": "5:32",
      "file_name": "A13___16_1Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A13___16_1Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "1 Chronicles",
      "number": 13,
      "chapter": 17,
      "length_in_seconds": 254,
      "length": "4:14",
      "file_name": "A13___17_1Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A13___17_1Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "1 Chronicles",
      "number": 13,
      "chapter": 18,
      "length_in_seconds": 153,
      "length": "2:33",
      "file_name": "A13___18_1Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A13___18_1Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "1 Chronicles",
      "number": 13,
      "chapter": 19,
      "length_in_seconds": 202,
      "length": "3:22",
      "file_name": "A13___19_1Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A13___19_1Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "1 Chronicles",
      "number": 13,
      "chapter": 20,
      "length_in_seconds": 96,
      "length": "1:36",
      "file_name": "A13___20_1Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A13___20_1Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "1 Chronicles",
      "number": 13,
      "chapter": 21,
      "length_in_seconds": 297,
      "length": "4:57",
      "file_name": "A13___21_1Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A13___21_1Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "1 Chronicles",
      "number": 13,
      "chapter": 22,
      "length_in_seconds": 195,
      "length": "3:15",
      "file_name": "A13___22_1Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A13___22_1Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "1 Chronicles",
      "number": 13,
      "chapter": 23,
      "length_in_seconds": 261,
      "length": "4:21",
      "file_name": "A13___23_1Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A13___23_1Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "1 Chronicles",
      "number": 13,
      "chapter": 24,
      "length_in_seconds": 224,
      "length": "3:44",
      "file_name": "A13___24_1Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A13___24_1Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "1 Chronicles",
      "number": 13,
      "chapter": 25,
      "length_in_seconds": 223,
      "length": "3:43",
      "file_name": "A13___25_1Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A13___25_1Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "1 Chronicles",
      "number": 13,
      "chapter": 26,
      "length_in_seconds": 286,
      "length": "4:46",
      "file_name": "A13___26_1Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A13___26_1Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "1 Chronicles",
      "number": 13,
      "chapter": 27,
      "length_in_seconds": 292,
      "length": "4:52",
      "file_name": "A13___27_1Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A13___27_1Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "1 Chronicles",
      "number": 13,
      "chapter": 28,
      "length_in_seconds": 286,
      "length": "4:46",
      "file_name": "A13___28_1Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A13___28_1Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "1 Chronicles",
      "number": 13,
      "chapter": 29,
      "length_in_seconds": 398,
      "length": "6:38",
      "file_name": "A13___29_1Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A13___29_1Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "2 Chronicles",
      "number": 14,
      "chapter": 1,
      "length_in_seconds": 177,
      "length": "2:57",
      "file_name": "A14___01_2Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A14___01_2Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "2 Chronicles",
      "number": 14,
      "chapter": 2,
      "length_in_seconds": 208,
      "length": "3:28",
      "file_name": "A14___02_2Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A14___02_2Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "2 Chronicles",
      "number": 14,
      "chapter": 3,
      "length_in_seconds": 144,
      "length": "2:24",
      "file_name": "A14___03_2Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A14___03_2Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "2 Chronicles",
      "number": 14,
      "chapter": 4,
      "length_in_seconds": 174,
      "length": "2:54",
      "file_name": "A14___04_2Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A14___04_2Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "2 Chronicles",
      "number": 14,
      "chapter": 5,
      "length_in_seconds": 146,
      "length": "2:26",
      "file_name": "A14___05_2Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A14___05_2Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "2 Chronicles",
      "number": 14,
      "chapter": 6,
      "length_in_seconds": 484,
      "length": "8:04",
      "file_name": "A14___06_2Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A14___06_2Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "2 Chronicles",
      "number": 14,
      "chapter": 7,
      "length_in_seconds": 250,
      "length": "4:10",
      "file_name": "A14___07_2Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A14___07_2Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "2 Chronicles",
      "number": 14,
      "chapter": 8,
      "length_in_seconds": 166,
      "length": "2:46",
      "file_name": "A14___08_2Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A14___08_2Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "2 Chronicles",
      "number": 14,
      "chapter": 9,
      "length_in_seconds": 304,
      "length": "5:04",
      "file_name": "A14___09_2Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A14___09_2Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "2 Chronicles",
      "number": 14,
      "chapter": 10,
      "length_in_seconds": 196,
      "length": "3:16",
      "file_name": "A14___10_2Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A14___10_2Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "2 Chronicles",
      "number": 14,
      "chapter": 11,
      "length_in_seconds": 174,
      "length": "2:54",
      "file_name": "A14___11_2Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A14___11_2Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "2 Chronicles",
      "number": 14,
      "chapter": 12,
      "length_in_seconds": 166,
      "length": "2:46",
      "file_name": "A14___12_2Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A14___12_2Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "2 Chronicles",
      "number": 14,
      "chapter": 13,
      "length_in_seconds": 233,
      "length": "3:53",
      "file_name": "A14___13_2Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A14___13_2Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "2 Chronicles",
      "number": 14,
      "chapter": 14,
      "length_in_seconds": 149,
      "length": "2:29",
      "file_name": "A14___14_2Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A14___14_2Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "2 Chronicles",
      "number": 14,
      "chapter": 15,
      "length_in_seconds": 171,
      "length": "2:51",
      "file_name": "A14___15_2Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A14___15_2Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "2 Chronicles",
      "number": 14,
      "chapter": 16,
      "length_in_seconds": 155,
      "length": "2:35",
      "file_name": "A14___16_2Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A14___16_2Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "2 Chronicles",
      "number": 14,
      "chapter": 17,
      "length_in_seconds": 154,
      "length": "2:34",
      "file_name": "A14___17_2Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A14___17_2Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "2 Chronicles",
      "number": 14,
      "chapter": 18,
      "length_in_seconds": 389,
      "length": "6:29",
      "file_name": "A14___18_2Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A14___18_2Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "2 Chronicles",
      "number": 14,
      "chapter": 19,
      "length_in_seconds": 121,
      "length": "2:01",
      "file_name": "A14___19_2Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A14___19_2Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "2 Chronicles",
      "number": 14,
      "chapter": 20,
      "length_in_seconds": 393,
      "length": "6:33",
      "file_name": "A14___20_2Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A14___20_2Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "2 Chronicles",
      "number": 14,
      "chapter": 21,
      "length_in_seconds": 215,
      "length": "3:35",
      "file_name": "A14___21_2Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A14___21_2Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "2 Chronicles",
      "number": 14,
      "chapter": 22,
      "length_in_seconds": 155,
      "length": "2:35",
      "file_name": "A14___22_2Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A14___22_2Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "2 Chronicles",
      "number": 14,
      "chapter": 23,
      "length_in_seconds": 256,
      "length": "4:16",
      "file_name": "A14___23_2Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A14___23_2Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "2 Chronicles",
      "number": 14,
      "chapter": 24,
      "length_in_seconds": 296,
      "length": "4:56",
      "file_name": "A14___24_2Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A14___24_2Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "2 Chronicles",
      "number": 14,
      "chapter": 25,
      "length_in_seconds": 316,
      "length": "5:16",
      "file_name": "A14___25_2Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A14___25_2Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "2 Chronicles",
      "number": 14,
      "chapter": 26,
      "length_in_seconds": 234,
      "length": "3:54",
      "file_name": "A14___26_2Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A14___26_2Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "2 Chronicles",
      "number": 14,
      "chapter": 27,
      "length_in_seconds": 78,
      "length": "1:18",
      "file_name": "A14___27_2Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A14___27_2Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "2 Chronicles",
      "number": 14,
      "chapter": 28,
      "length_in_seconds": 287,
      "length": "4:47",
      "file_name": "A14___28_2Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A14___28_2Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "2 Chronicles",
      "number": 14,
      "chapter": 29,
      "length_in_seconds": 379,
      "length": "6:19",
      "file_name": "A14___29_2Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A14___29_2Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "2 Chronicles",
      "number": 14,
      "chapter": 30,
      "length_in_seconds": 302,
      "length": "5:02",
      "file_name": "A14___30_2Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A14___30_2Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "2 Chronicles",
      "number": 14,
      "chapter": 31,
      "length_in_seconds": 256,
      "length": "4:16",
      "file_name": "A14___31_2Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A14___31_2Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "2 Chronicles",
      "number": 14,
      "chapter": 32,
      "length_in_seconds": 378,
      "length": "6:18",
      "file_name": "A14___32_2Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A14___32_2Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "2 Chronicles",
      "number": 14,
      "chapter": 33,
      "length_in_seconds": 248,
      "length": "4:08",
      "file_name": "A14___33_2Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A14___33_2Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "2 Chronicles",
      "number": 14,
      "chapter": 34,
      "length_in_seconds": 385,
      "length": "6:25",
      "file_name": "A14___34_2Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A14___34_2Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "2 Chronicles",
      "number": 14,
      "chapter": 35,
      "length_in_seconds": 314,
      "length": "5:14",
      "file_name": "A14___35_2Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A14___35_2Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "2 Chronicles",
      "number": 14,
      "chapter": 36,
      "length_in_seconds": 240,
      "length": "4:00",
      "file_name": "A14___36_2Chronicles_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A14___36_2Chronicles_ENGESVO2DA.mp3"
    },
    {
      "book": "Ezra",
      "number": 15,
      "chapter": 1,
      "length_in_seconds": 135,
      "length": "2:15",
      "file_name": "A15___01_Ezra________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A15___01_Ezra________ENGESVO2DA.mp3"
    },
    {
      "book": "Ezra",
      "number": 15,
      "chapter": 2,
      "length_in_seconds": 408,
      "length": "6:48",
      "file_name": "A15___02_Ezra________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A15___02_Ezra________ENGESVO2DA.mp3"
    },
    {
      "book": "Ezra",
      "number": 15,
      "chapter": 3,
      "length_in_seconds": 194,
      "length": "3:14",
      "file_name": "A15___03_Ezra________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A15___03_Ezra________ENGESVO2DA.mp3"
    },
    {
      "book": "Ezra",
      "number": 15,
      "chapter": 4,
      "length_in_seconds": 283,
      "length": "4:43",
      "file_name": "A15___04_Ezra________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A15___04_Ezra________ENGESVO2DA.mp3"
    },
    {
      "book": "Ezra",
      "number": 15,
      "chapter": 5,
      "length_in_seconds": 195,
      "length": "3:15",
      "file_name": "A15___05_Ezra________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A15___05_Ezra________ENGESVO2DA.mp3"
    },
    {
      "book": "Ezra",
      "number": 15,
      "chapter": 6,
      "length_in_seconds": 275,
      "length": "4:35",
      "file_name": "A15___06_Ezra________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A15___06_Ezra________ENGESVO2DA.mp3"
    },
    {
      "book": "Ezra",
      "number": 15,
      "chapter": 7,
      "length_in_seconds": 334,
      "length": "5:34",
      "file_name": "A15___07_Ezra________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A15___07_Ezra________ENGESVO2DA.mp3"
    },
    {
      "book": "Ezra",
      "number": 15,
      "chapter": 8,
      "length_in_seconds": 368,
      "length": "6:08",
      "file_name": "A15___08_Ezra________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A15___08_Ezra________ENGESVO2DA.mp3"
    },
    {
      "book": "Ezra",
      "number": 15,
      "chapter": 9,
      "length_in_seconds": 233,
      "length": "3:53",
      "file_name": "A15___09_Ezra________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A15___09_Ezra________ENGESVO2DA.mp3"
    },
    {
      "book": "Ezra",
      "number": 15,
      "chapter": 10,
      "length_in_seconds": 401,
      "length": "6:41",
      "file_name": "A15___10_Ezra________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A15___10_Ezra________ENGESVO2DA.mp3"
    },
    {
      "book": "Nehemiah",
      "number": 16,
      "chapter": 1,
      "length_in_seconds": 161,
      "length": "2:41",
      "file_name": "A16___01_Nehemiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A16___01_Nehemiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Nehemiah",
      "number": 16,
      "chapter": 2,
      "length_in_seconds": 269,
      "length": "4:29",
      "file_name": "A16___02_Nehemiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A16___02_Nehemiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Nehemiah",
      "number": 16,
      "chapter": 3,
      "length_in_seconds": 328,
      "length": "5:28",
      "file_name": "A16___03_Nehemiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A16___03_Nehemiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Nehemiah",
      "number": 16,
      "chapter": 4,
      "length_in_seconds": 269,
      "length": "4:29",
      "file_name": "A16___04_Nehemiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A16___04_Nehemiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Nehemiah",
      "number": 16,
      "chapter": 5,
      "length_in_seconds": 248,
      "length": "4:08",
      "file_name": "A16___05_Nehemiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A16___05_Nehemiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Nehemiah",
      "number": 16,
      "chapter": 6,
      "length_in_seconds": 228,
      "length": "3:48",
      "file_name": "A16___06_Nehemiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A16___06_Nehemiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Nehemiah",
      "number": 16,
      "chapter": 7,
      "length_in_seconds": 488,
      "length": "8:08",
      "file_name": "A16___07_Nehemiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A16___07_Nehemiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Nehemiah",
      "number": 16,
      "chapter": 8,
      "length_in_seconds": 259,
      "length": "4:19",
      "file_name": "A16___08_Nehemiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A16___08_Nehemiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Nehemiah",
      "number": 16,
      "chapter": 9,
      "length_in_seconds": 529,
      "length": "8:49",
      "file_name": "A16___09_Nehemiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A16___09_Nehemiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Nehemiah",
      "number": 16,
      "chapter": 10,
      "length_in_seconds": 289,
      "length": "4:49",
      "file_name": "A16___10_Nehemiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A16___10_Nehemiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Nehemiah",
      "number": 16,
      "chapter": 11,
      "length_in_seconds": 305,
      "length": "5:05",
      "file_name": "A16___11_Nehemiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A16___11_Nehemiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Nehemiah",
      "number": 16,
      "chapter": 12,
      "length_in_seconds": 431,
      "length": "7:11",
      "file_name": "A16___12_Nehemiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A16___12_Nehemiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Nehemiah",
      "number": 16,
      "chapter": 13,
      "length_in_seconds": 384,
      "length": "6:24",
      "file_name": "A16___13_Nehemiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A16___13_Nehemiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Esther",
      "number": 17,
      "chapter": 1,
      "length_in_seconds": 264,
      "length": "4:24",
      "file_name": "A17___01_Esther______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A17___01_Esther______ENGESVO2DA.mp3"
    },
    {
      "book": "Esther",
      "number": 17,
      "chapter": 2,
      "length_in_seconds": 278,
      "length": "4:38",
      "file_name": "A17___02_Esther______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A17___02_Esther______ENGESVO2DA.mp3"
    },
    {
      "book": "Esther",
      "number": 17,
      "chapter": 3,
      "length_in_seconds": 214,
      "length": "3:34",
      "file_name": "A17___03_Esther______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A17___03_Esther______ENGESVO2DA.mp3"
    },
    {
      "book": "Esther",
      "number": 17,
      "chapter": 4,
      "length_in_seconds": 188,
      "length": "3:08",
      "file_name": "A17___04_Esther______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A17___04_Esther______ENGESVO2DA.mp3"
    },
    {
      "book": "Esther",
      "number": 17,
      "chapter": 5,
      "length_in_seconds": 184,
      "length": "3:04",
      "file_name": "A17___05_Esther______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A17___05_Esther______ENGESVO2DA.mp3"
    },
    {
      "book": "Esther",
      "number": 17,
      "chapter": 6,
      "length_in_seconds": 192,
      "length": "3:12",
      "file_name": "A17___06_Esther______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A17___06_Esther______ENGESVO2DA.mp3"
    },
    {
      "book": "Esther",
      "number": 17,
      "chapter": 7,
      "length_in_seconds": 148,
      "length": "2:28",
      "file_name": "A17___07_Esther______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A17___07_Esther______ENGESVO2DA.mp3"
    },
    {
      "book": "Esther",
      "number": 17,
      "chapter": 8,
      "length_in_seconds": 245,
      "length": "4:05",
      "file_name": "A17___08_Esther______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A17___08_Esther______ENGESVO2DA.mp3"
    },
    {
      "book": "Esther",
      "number": 17,
      "chapter": 9,
      "length_in_seconds": 353,
      "length": "5:53",
      "file_name": "A17___09_Esther______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A17___09_Esther______ENGESVO2DA.mp3"
    },
    {
      "book": "Esther",
      "number": 17,
      "chapter": 10,
      "length_in_seconds": 40,
      "length": "0:40",
      "file_name": "A17___10_Esther______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A17___10_Esther______ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 1,
      "length_in_seconds": 267,
      "length": "4:27",
      "file_name": "A18___01_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___01_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 2,
      "length_in_seconds": 169,
      "length": "2:49",
      "file_name": "A18___02_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___02_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 3,
      "length_in_seconds": 184,
      "length": "3:04",
      "file_name": "A18___03_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___03_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 4,
      "length_in_seconds": 143,
      "length": "2:23",
      "file_name": "A18___04_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___04_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 5,
      "length_in_seconds": 182,
      "length": "3:02",
      "file_name": "A18___05_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___05_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 6,
      "length_in_seconds": 191,
      "length": "3:11",
      "file_name": "A18___06_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___06_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 7,
      "length_in_seconds": 160,
      "length": "2:40",
      "file_name": "A18___07_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___07_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 8,
      "length_in_seconds": 122,
      "length": "2:02",
      "file_name": "A18___08_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___08_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 9,
      "length_in_seconds": 228,
      "length": "3:48",
      "file_name": "A18___09_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___09_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 10,
      "length_in_seconds": 159,
      "length": "2:39",
      "file_name": "A18___10_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___10_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 11,
      "length_in_seconds": 145,
      "length": "2:25",
      "file_name": "A18___11_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___11_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 12,
      "length_in_seconds": 154,
      "length": "2:34",
      "file_name": "A18___12_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___12_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 13,
      "length_in_seconds": 168,
      "length": "2:48",
      "file_name": "A18___13_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___13_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 14,
      "length_in_seconds": 152,
      "length": "2:32",
      "file_name": "A18___14_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___14_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 15,
      "length_in_seconds": 240,
      "length": "4:00",
      "file_name": "A18___15_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___15_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 16,
      "length_in_seconds": 157,
      "length": "2:37",
      "file_name": "A18___16_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___16_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 17,
      "length_in_seconds": 106,
      "length": "1:46",
      "file_name": "A18___17_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___17_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 18,
      "length_in_seconds": 108,
      "length": "1:48",
      "file_name": "A18___18_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___18_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 19,
      "length_in_seconds": 195,
      "length": "3:15",
      "file_name": "A18___19_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___19_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 20,
      "length_in_seconds": 213,
      "length": "3:33",
      "file_name": "A18___20_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___20_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 21,
      "length_in_seconds": 196,
      "length": "3:16",
      "file_name": "A18___21_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___21_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 22,
      "length_in_seconds": 203,
      "length": "3:23",
      "file_name": "A18___22_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___22_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 23,
      "length_in_seconds": 104,
      "length": "1:44",
      "file_name": "A18___23_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___23_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 24,
      "length_in_seconds": 178,
      "length": "2:58",
      "file_name": "A18___24_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___24_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 25,
      "length_in_seconds": 37,
      "length": "0:37",
      "file_name": "A18___25_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___25_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 26,
      "length_in_seconds": 89,
      "length": "1:29",
      "file_name": "A18___26_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___26_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 27,
      "length_in_seconds": 139,
      "length": "2:19",
      "file_name": "A18___27_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___27_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 28,
      "length_in_seconds": 183,
      "length": "3:03",
      "file_name": "A18___28_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___28_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 29,
      "length_in_seconds": 161,
      "length": "2:41",
      "file_name": "A18___29_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___29_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 30,
      "length_in_seconds": 237,
      "length": "3:57",
      "file_name": "A18___30_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___30_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 31,
      "length_in_seconds": 271,
      "length": "4:31",
      "file_name": "A18___31_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___31_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 32,
      "length_in_seconds": 144,
      "length": "2:24",
      "file_name": "A18___32_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___32_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 33,
      "length_in_seconds": 196,
      "length": "3:16",
      "file_name": "A18___33_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___33_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 34,
      "length_in_seconds": 210,
      "length": "3:30",
      "file_name": "A18___34_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___34_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 35,
      "length_in_seconds": 89,
      "length": "1:29",
      "file_name": "A18___35_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___35_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 36,
      "length_in_seconds": 185,
      "length": "3:05",
      "file_name": "A18___36_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___36_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 37,
      "length_in_seconds": 153,
      "length": "2:33",
      "file_name": "A18___37_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___37_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 38,
      "length_in_seconds": 236,
      "length": "3:56",
      "file_name": "A18___38_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___38_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 39,
      "length_in_seconds": 189,
      "length": "3:09",
      "file_name": "A18___39_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___39_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 40,
      "length_in_seconds": 146,
      "length": "2:26",
      "file_name": "A18___40_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___40_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 41,
      "length_in_seconds": 203,
      "length": "3:23",
      "file_name": "A18___41_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___41_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Job",
      "number": 18,
      "chapter": 42,
      "length_in_seconds": 176,
      "length": "2:56",
      "file_name": "A18___42_Job_________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A18___42_Job_________ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 1,
      "length_in_seconds": 51,
      "length": "0:51",
      "file_name": "A19__001_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__001_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 2,
      "length_in_seconds": 83,
      "length": "1:23",
      "file_name": "A19__002_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__002_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 3,
      "length_in_seconds": 63,
      "length": "1:03",
      "file_name": "A19__003_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__003_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 4,
      "length_in_seconds": 79,
      "length": "1:19",
      "file_name": "A19__004_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__004_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 5,
      "length_in_seconds": 101,
      "length": "1:41",
      "file_name": "A19__005_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__005_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 6,
      "length_in_seconds": 76,
      "length": "1:16",
      "file_name": "A19__006_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__006_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 7,
      "length_in_seconds": 130,
      "length": "2:10",
      "file_name": "A19__007_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__007_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 8,
      "length_in_seconds": 69,
      "length": "1:09",
      "file_name": "A19__008_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__008_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 9,
      "length_in_seconds": 154,
      "length": "2:34",
      "file_name": "A19__009_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__009_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 10,
      "length_in_seconds": 140,
      "length": "2:20",
      "file_name": "A19__010_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__010_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 11,
      "length_in_seconds": 64,
      "length": "1:04",
      "file_name": "A19__011_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__011_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 12,
      "length_in_seconds": 70,
      "length": "1:10",
      "file_name": "A19__012_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__012_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 13,
      "length_in_seconds": 54,
      "length": "0:54",
      "file_name": "A19__013_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__013_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 14,
      "length_in_seconds": 69,
      "length": "1:09",
      "file_name": "A19__014_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__014_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 15,
      "length_in_seconds": 46,
      "length": "0:46",
      "file_name": "A19__015_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__015_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 16,
      "length_in_seconds": 82,
      "length": "1:22",
      "file_name": "A19__016_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__016_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 17,
      "length_in_seconds": 113,
      "length": "1:53",
      "file_name": "A19__017_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__017_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 18,
      "length_in_seconds": 395,
      "length": "6:35",
      "file_name": "A19__018_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__018_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 19,
      "length_in_seconds": 108,
      "length": "1:48",
      "file_name": "A19__019_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__019_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 20,
      "length_in_seconds": 68,
      "length": "1:08",
      "file_name": "A19__020_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__020_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 21,
      "length_in_seconds": 91,
      "length": "1:31",
      "file_name": "A19__021_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__021_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 22,
      "length_in_seconds": 225,
      "length": "3:45",
      "file_name": "A19__022_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__022_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 23,
      "length_in_seconds": 53,
      "length": "0:53",
      "file_name": "A19__023_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__023_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 24,
      "length_in_seconds": 76,
      "length": "1:16",
      "file_name": "A19__024_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__024_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 25,
      "length_in_seconds": 153,
      "length": "2:33",
      "file_name": "A19__025_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__025_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 26,
      "length_in_seconds": 77,
      "length": "1:17",
      "file_name": "A19__026_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__026_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 27,
      "length_in_seconds": 135,
      "length": "2:15",
      "file_name": "A19__027_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__027_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 28,
      "length_in_seconds": 86,
      "length": "1:26",
      "file_name": "A19__028_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__028_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 29,
      "length_in_seconds": 75,
      "length": "1:15",
      "file_name": "A19__029_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__029_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 30,
      "length_in_seconds": 101,
      "length": "1:41",
      "file_name": "A19__030_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__030_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 31,
      "length_in_seconds": 211,
      "length": "3:31",
      "file_name": "A19__031_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__031_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 32,
      "length_in_seconds": 101,
      "length": "1:41",
      "file_name": "A19__032_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__032_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 33,
      "length_in_seconds": 147,
      "length": "2:27",
      "file_name": "A19__033_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__033_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 34,
      "length_in_seconds": 150,
      "length": "2:30",
      "file_name": "A19__034_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__034_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 35,
      "length_in_seconds": 230,
      "length": "3:50",
      "file_name": "A19__035_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__035_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 36,
      "length_in_seconds": 90,
      "length": "1:30",
      "file_name": "A19__036_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__036_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 37,
      "length_in_seconds": 289,
      "length": "4:49",
      "file_name": "A19__037_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__037_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 38,
      "length_in_seconds": 143,
      "length": "2:23",
      "file_name": "A19__038_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__038_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 39,
      "length_in_seconds": 127,
      "length": "2:07",
      "file_name": "A19__039_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__039_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 40,
      "length_in_seconds": 169,
      "length": "2:49",
      "file_name": "A19__040_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__040_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 41,
      "length_in_seconds": 111,
      "length": "1:51",
      "file_name": "A19__041_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__041_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 42,
      "length_in_seconds": 130,
      "length": "2:10",
      "file_name": "A19__042_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__042_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 43,
      "length_in_seconds": 54,
      "length": "0:54",
      "file_name": "A19__043_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__043_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 44,
      "length_in_seconds": 195,
      "length": "3:15",
      "file_name": "A19__044_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__044_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 45,
      "length_in_seconds": 151,
      "length": "2:31",
      "file_name": "A19__045_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__045_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 46,
      "length_in_seconds": 102,
      "length": "1:42",
      "file_name": "A19__046_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__046_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 47,
      "length_in_seconds": 70,
      "length": "1:10",
      "file_name": "A19__047_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__047_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 48,
      "length_in_seconds": 106,
      "length": "1:46",
      "file_name": "A19__048_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__048_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 49,
      "length_in_seconds": 152,
      "length": "2:32",
      "file_name": "A19__049_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__049_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 50,
      "length_in_seconds": 165,
      "length": "2:45",
      "file_name": "A19__050_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__050_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 51,
      "length_in_seconds": 146,
      "length": "2:26",
      "file_name": "A19__051_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__051_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 52,
      "length_in_seconds": 88,
      "length": "1:28",
      "file_name": "A19__052_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__052_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 53,
      "length_in_seconds": 74,
      "length": "1:14",
      "file_name": "A19__053_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__053_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 54,
      "length_in_seconds": 64,
      "length": "1:04",
      "file_name": "A19__054_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__054_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 55,
      "length_in_seconds": 183,
      "length": "3:03",
      "file_name": "A19__055_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__055_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 56,
      "length_in_seconds": 102,
      "length": "1:42",
      "file_name": "A19__056_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__056_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 57,
      "length_in_seconds": 111,
      "length": "1:51",
      "file_name": "A19__057_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__057_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 58,
      "length_in_seconds": 91,
      "length": "1:31",
      "file_name": "A19__058_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__058_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 59,
      "length_in_seconds": 155,
      "length": "2:35",
      "file_name": "A19__059_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__059_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 60,
      "length_in_seconds": 111,
      "length": "1:51",
      "file_name": "A19__060_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__060_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 61,
      "length_in_seconds": 67,
      "length": "1:07",
      "file_name": "A19__061_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__061_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 62,
      "length_in_seconds": 117,
      "length": "1:57",
      "file_name": "A19__062_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__062_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 63,
      "length_in_seconds": 99,
      "length": "1:39",
      "file_name": "A19__063_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__063_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 64,
      "length_in_seconds": 79,
      "length": "1:19",
      "file_name": "A19__064_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__064_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 65,
      "length_in_seconds": 115,
      "length": "1:55",
      "file_name": "A19__065_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__065_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 66,
      "length_in_seconds": 155,
      "length": "2:35",
      "file_name": "A19__066_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__066_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 67,
      "length_in_seconds": 65,
      "length": "1:05",
      "file_name": "A19__067_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__067_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 68,
      "length_in_seconds": 289,
      "length": "4:49",
      "file_name": "A19__068_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__068_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 69,
      "length_in_seconds": 283,
      "length": "4:43",
      "file_name": "A19__069_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__069_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 70,
      "length_in_seconds": 59,
      "length": "0:59",
      "file_name": "A19__070_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__070_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 71,
      "length_in_seconds": 185,
      "length": "3:05",
      "file_name": "A19__071_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__071_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 72,
      "length_in_seconds": 162,
      "length": "2:42",
      "file_name": "A19__072_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__072_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 73,
      "length_in_seconds": 177,
      "length": "2:57",
      "file_name": "A19__073_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__073_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 74,
      "length_in_seconds": 180,
      "length": "3:00",
      "file_name": "A19__074_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__074_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 75,
      "length_in_seconds": 86,
      "length": "1:26",
      "file_name": "A19__075_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__075_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 76,
      "length_in_seconds": 96,
      "length": "1:36",
      "file_name": "A19__076_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__076_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 77,
      "length_in_seconds": 152,
      "length": "2:32",
      "file_name": "A19__077_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__077_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 78,
      "length_in_seconds": 459,
      "length": "7:39",
      "file_name": "A19__078_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__078_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 79,
      "length_in_seconds": 124,
      "length": "2:04",
      "file_name": "A19__079_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__079_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 80,
      "length_in_seconds": 138,
      "length": "2:18",
      "file_name": "A19__080_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__080_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 81,
      "length_in_seconds": 121,
      "length": "2:01",
      "file_name": "A19__081_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__081_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 82,
      "length_in_seconds": 63,
      "length": "1:03",
      "file_name": "A19__082_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__082_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 83,
      "length_in_seconds": 118,
      "length": "1:58",
      "file_name": "A19__083_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__083_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 84,
      "length_in_seconds": 120,
      "length": "2:00",
      "file_name": "A19__084_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__084_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 85,
      "length_in_seconds": 108,
      "length": "1:48",
      "file_name": "A19__085_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__085_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 86,
      "length_in_seconds": 134,
      "length": "2:14",
      "file_name": "A19__086_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__086_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 87,
      "length_in_seconds": 69,
      "length": "1:09",
      "file_name": "A19__087_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__087_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 88,
      "length_in_seconds": 164,
      "length": "2:44",
      "file_name": "A19__088_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__088_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 89,
      "length_in_seconds": 371,
      "length": "6:11",
      "file_name": "A19__089_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__089_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 90,
      "length_in_seconds": 135,
      "length": "2:15",
      "file_name": "A19__090_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__090_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 91,
      "length_in_seconds": 116,
      "length": "1:56",
      "file_name": "A19__091_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__091_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 92,
      "length_in_seconds": 102,
      "length": "1:42",
      "file_name": "A19__092_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__092_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 93,
      "length_in_seconds": 50,
      "length": "0:50",
      "file_name": "A19__093_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__093_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 94,
      "length_in_seconds": 140,
      "length": "2:20",
      "file_name": "A19__094_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__094_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 95,
      "length_in_seconds": 83,
      "length": "1:23",
      "file_name": "A19__095_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__095_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 96,
      "length_in_seconds": 105,
      "length": "1:45",
      "file_name": "A19__096_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__096_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 97,
      "length_in_seconds": 83,
      "length": "1:23",
      "file_name": "A19__097_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__097_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 98,
      "length_in_seconds": 78,
      "length": "1:18",
      "file_name": "A19__098_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__098_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 99,
      "length_in_seconds": 83,
      "length": "1:23",
      "file_name": "A19__099_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__099_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 100,
      "length_in_seconds": 50,
      "length": "0:50",
      "file_name": "A19__100_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__100_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 101,
      "length_in_seconds": 74,
      "length": "1:14",
      "file_name": "A19__101_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__101_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 102,
      "length_in_seconds": 205,
      "length": "3:25",
      "file_name": "A19__102_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__102_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 103,
      "length_in_seconds": 152,
      "length": "2:32",
      "file_name": "A19__103_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__103_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 104,
      "length_in_seconds": 280,
      "length": "4:40",
      "file_name": "A19__104_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__104_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 105,
      "length_in_seconds": 262,
      "length": "4:22",
      "file_name": "A19__105_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__105_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 106,
      "length_in_seconds": 283,
      "length": "4:43",
      "file_name": "A19__106_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__106_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 107,
      "length_in_seconds": 272,
      "length": "4:32",
      "file_name": "A19__107_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__107_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 108,
      "length_in_seconds": 96,
      "length": "1:36",
      "file_name": "A19__108_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__108_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 109,
      "length_in_seconds": 219,
      "length": "3:39",
      "file_name": "A19__109_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__109_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 110,
      "length_in_seconds": 62,
      "length": "1:02",
      "file_name": "A19__110_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__110_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 111,
      "length_in_seconds": 75,
      "length": "1:15",
      "file_name": "A19__111_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__111_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 112,
      "length_in_seconds": 75,
      "length": "1:15",
      "file_name": "A19__112_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__112_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 113,
      "length_in_seconds": 64,
      "length": "1:04",
      "file_name": "A19__113_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__113_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 114,
      "length_in_seconds": 52,
      "length": "0:52",
      "file_name": "A19__114_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__114_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 115,
      "length_in_seconds": 114,
      "length": "1:54",
      "file_name": "A19__115_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__115_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 116,
      "length_in_seconds": 121,
      "length": "2:01",
      "file_name": "A19__116_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__116_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 117,
      "length_in_seconds": 23,
      "length": "0:23",
      "file_name": "A19__117_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__117_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 118,
      "length_in_seconds": 198,
      "length": "3:18",
      "file_name": "A19__118_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__118_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 119,
      "length_in_seconds": 1061,
      "length": "17:41",
      "file_name": "A19__119_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__119_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 120,
      "length_in_seconds": 46,
      "length": "0:46",
      "file_name": "A19__120_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__120_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 121,
      "length_in_seconds": 49,
      "length": "0:49",
      "file_name": "A19__121_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__121_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 122,
      "length_in_seconds": 63,
      "length": "1:03",
      "file_name": "A19__122_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__122_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 123,
      "length_in_seconds": 47,
      "length": "0:47",
      "file_name": "A19__123_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__123_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 124,
      "length_in_seconds": 52,
      "length": "0:52",
      "file_name": "A19__124_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__124_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 125,
      "length_in_seconds": 49,
      "length": "0:49",
      "file_name": "A19__125_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__125_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 126,
      "length_in_seconds": 49,
      "length": "0:49",
      "file_name": "A19__126_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__126_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 127,
      "length_in_seconds": 56,
      "length": "0:56",
      "file_name": "A19__127_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__127_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 128,
      "length_in_seconds": 50,
      "length": "0:50",
      "file_name": "A19__128_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__128_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 129,
      "length_in_seconds": 52,
      "length": "0:52",
      "file_name": "A19__129_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__129_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 130,
      "length_in_seconds": 59,
      "length": "0:59",
      "file_name": "A19__130_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__130_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 131,
      "length_in_seconds": 36,
      "length": "0:36",
      "file_name": "A19__131_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__131_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 132,
      "length_in_seconds": 121,
      "length": "2:01",
      "file_name": "A19__132_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__132_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 133,
      "length_in_seconds": 37,
      "length": "0:37",
      "file_name": "A19__133_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__133_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 134,
      "length_in_seconds": 29,
      "length": "0:29",
      "file_name": "A19__134_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__134_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 135,
      "length_in_seconds": 119,
      "length": "1:59",
      "file_name": "A19__135_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__135_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 136,
      "length_in_seconds": 172,
      "length": "2:52",
      "file_name": "A19__136_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__136_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 137,
      "length_in_seconds": 68,
      "length": "1:08",
      "file_name": "A19__137_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__137_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 138,
      "length_in_seconds": 76,
      "length": "1:16",
      "file_name": "A19__138_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__138_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 139,
      "length_in_seconds": 165,
      "length": "2:45",
      "file_name": "A19__139_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__139_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 140,
      "length_in_seconds": 107,
      "length": "1:47",
      "file_name": "A19__140_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__140_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 141,
      "length_in_seconds": 99,
      "length": "1:39",
      "file_name": "A19__141_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__141_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 142,
      "length_in_seconds": 68,
      "length": "1:08",
      "file_name": "A19__142_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__142_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 143,
      "length_in_seconds": 111,
      "length": "1:51",
      "file_name": "A19__143_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__143_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 144,
      "length_in_seconds": 125,
      "length": "2:05",
      "file_name": "A19__144_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__144_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 145,
      "length_in_seconds": 151,
      "length": "2:31",
      "file_name": "A19__145_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__145_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 146,
      "length_in_seconds": 77,
      "length": "1:17",
      "file_name": "A19__146_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__146_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 147,
      "length_in_seconds": 127,
      "length": "2:07",
      "file_name": "A19__147_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__147_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 148,
      "length_in_seconds": 103,
      "length": "1:43",
      "file_name": "A19__148_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__148_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 149,
      "length_in_seconds": 62,
      "length": "1:02",
      "file_name": "A19__149_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__149_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Psalms",
      "number": 19,
      "chapter": 150,
      "length_in_seconds": 58,
      "length": "0:58",
      "file_name": "A19__150_Psalms______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A19__150_Psalms______ENGESVO2DA.mp3"
    },
    {
      "book": "Proverbs",
      "number": 20,
      "chapter": 1,
      "length_in_seconds": 222,
      "length": "3:42",
      "file_name": "A20___01_Proverbs____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A20___01_Proverbs____ENGESVO2DA.mp3"
    },
    {
      "book": "Proverbs",
      "number": 20,
      "chapter": 2,
      "length_in_seconds": 133,
      "length": "2:13",
      "file_name": "A20___02_Proverbs____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A20___02_Proverbs____ENGESVO2DA.mp3"
    },
    {
      "book": "Proverbs",
      "number": 20,
      "chapter": 3,
      "length_in_seconds": 218,
      "length": "3:38",
      "file_name": "A20___03_Proverbs____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A20___03_Proverbs____ENGESVO2DA.mp3"
    },
    {
      "book": "Proverbs",
      "number": 20,
      "chapter": 4,
      "length_in_seconds": 169,
      "length": "2:49",
      "file_name": "A20___04_Proverbs____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A20___04_Proverbs____ENGESVO2DA.mp3"
    },
    {
      "book": "Proverbs",
      "number": 20,
      "chapter": 5,
      "length_in_seconds": 138,
      "length": "2:18",
      "file_name": "A20___05_Proverbs____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A20___05_Proverbs____ENGESVO2DA.mp3"
    },
    {
      "book": "Proverbs",
      "number": 20,
      "chapter": 6,
      "length_in_seconds": 212,
      "length": "3:32",
      "file_name": "A20___06_Proverbs____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A20___06_Proverbs____ENGESVO2DA.mp3"
    },
    {
      "book": "Proverbs",
      "number": 20,
      "chapter": 7,
      "length_in_seconds": 177,
      "length": "2:57",
      "file_name": "A20___07_Proverbs____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A20___07_Proverbs____ENGESVO2DA.mp3"
    },
    {
      "book": "Proverbs",
      "number": 20,
      "chapter": 8,
      "length_in_seconds": 223,
      "length": "3:43",
      "file_name": "A20___08_Proverbs____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A20___08_Proverbs____ENGESVO2DA.mp3"
    },
    {
      "book": "Proverbs",
      "number": 20,
      "chapter": 9,
      "length_in_seconds": 135,
      "length": "2:15",
      "file_name": "A20___09_Proverbs____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A20___09_Proverbs____ENGESVO2DA.mp3"
    },
    {
      "book": "Proverbs",
      "number": 20,
      "chapter": 10,
      "length_in_seconds": 221,
      "length": "3:41",
      "file_name": "A20___10_Proverbs____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A20___10_Proverbs____ENGESVO2DA.mp3"
    },
    {
      "book": "Proverbs",
      "number": 20,
      "chapter": 11,
      "length_in_seconds": 236,
      "length": "3:56",
      "file_name": "A20___11_Proverbs____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A20___11_Proverbs____ENGESVO2DA.mp3"
    },
    {
      "book": "Proverbs",
      "number": 20,
      "chapter": 12,
      "length_in_seconds": 213,
      "length": "3:33",
      "file_name": "A20___12_Proverbs____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A20___12_Proverbs____ENGESVO2DA.mp3"
    },
    {
      "book": "Proverbs",
      "number": 20,
      "chapter": 13,
      "length_in_seconds": 191,
      "length": "3:11",
      "file_name": "A20___13_Proverbs____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A20___13_Proverbs____ENGESVO2DA.mp3"
    },
    {
      "book": "Proverbs",
      "number": 20,
      "chapter": 14,
      "length_in_seconds": 250,
      "length": "4:10",
      "file_name": "A20___14_Proverbs____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A20___14_Proverbs____ENGESVO2DA.mp3"
    },
    {
      "book": "Proverbs",
      "number": 20,
      "chapter": 15,
      "length_in_seconds": 234,
      "length": "3:54",
      "file_name": "A20___15_Proverbs____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A20___15_Proverbs____ENGESVO2DA.mp3"
    },
    {
      "book": "Proverbs",
      "number": 20,
      "chapter": 16,
      "length_in_seconds": 223,
      "length": "3:43",
      "file_name": "A20___16_Proverbs____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A20___16_Proverbs____ENGESVO2DA.mp3"
    },
    {
      "book": "Proverbs",
      "number": 20,
      "chapter": 17,
      "length_in_seconds": 212,
      "length": "3:32",
      "file_name": "A20___17_Proverbs____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A20___17_Proverbs____ENGESVO2DA.mp3"
    },
    {
      "book": "Proverbs",
      "number": 20,
      "chapter": 18,
      "length_in_seconds": 175,
      "length": "2:55",
      "file_name": "A20___18_Proverbs____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A20___18_Proverbs____ENGESVO2DA.mp3"
    },
    {
      "book": "Proverbs",
      "number": 20,
      "chapter": 19,
      "length_in_seconds": 236,
      "length": "3:56",
      "file_name": "A20___19_Proverbs____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A20___19_Proverbs____ENGESVO2DA.mp3"
    },
    {
      "book": "Proverbs",
      "number": 20,
      "chapter": 20,
      "length_in_seconds": 230,
      "length": "3:50",
      "file_name": "A20___20_Proverbs____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A20___20_Proverbs____ENGESVO2DA.mp3"
    },
    {
      "book": "Proverbs",
      "number": 20,
      "chapter": 21,
      "length_in_seconds": 246,
      "length": "4:06",
      "file_name": "A20___21_Proverbs____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A20___21_Proverbs____ENGESVO2DA.mp3"
    },
    {
      "book": "Proverbs",
      "number": 20,
      "chapter": 22,
      "length_in_seconds": 206,
      "length": "3:26",
      "file_name": "A20___22_Proverbs____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A20___22_Proverbs____ENGESVO2DA.mp3"
    },
    {
      "book": "Proverbs",
      "number": 20,
      "chapter": 23,
      "length_in_seconds": 250,
      "length": "4:10",
      "file_name": "A20___23_Proverbs____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A20___23_Proverbs____ENGESVO2DA.mp3"
    },
    {
      "book": "Proverbs",
      "number": 20,
      "chapter": 24,
      "length_in_seconds": 252,
      "length": "4:12",
      "file_name": "A20___24_Proverbs____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A20___24_Proverbs____ENGESVO2DA.mp3"
    },
    {
      "book": "Proverbs",
      "number": 20,
      "chapter": 25,
      "length_in_seconds": 208,
      "length": "3:28",
      "file_name": "A20___25_Proverbs____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A20___25_Proverbs____ENGESVO2DA.mp3"
    },
    {
      "book": "Proverbs",
      "number": 20,
      "chapter": 26,
      "length_in_seconds": 230,
      "length": "3:50",
      "file_name": "A20___26_Proverbs____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A20___26_Proverbs____ENGESVO2DA.mp3"
    },
    {
      "book": "Proverbs",
      "number": 20,
      "chapter": 27,
      "length_in_seconds": 208,
      "length": "3:28",
      "file_name": "A20___27_Proverbs____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A20___27_Proverbs____ENGESVO2DA.mp3"
    },
    {
      "book": "Proverbs",
      "number": 20,
      "chapter": 28,
      "length_in_seconds": 228,
      "length": "3:48",
      "file_name": "A20___28_Proverbs____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A20___28_Proverbs____ENGESVO2DA.mp3"
    },
    {
      "book": "Proverbs",
      "number": 20,
      "chapter": 29,
      "length_in_seconds": 200,
      "length": "3:20",
      "file_name": "A20___29_Proverbs____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A20___29_Proverbs____ENGESVO2DA.mp3"
    },
    {
      "book": "Proverbs",
      "number": 20,
      "chapter": 30,
      "length_in_seconds": 265,
      "length": "4:25",
      "file_name": "A20___30_Proverbs____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A20___30_Proverbs____ENGESVO2DA.mp3"
    },
    {
      "book": "Proverbs",
      "number": 20,
      "chapter": 31,
      "length_in_seconds": 228,
      "length": "3:48",
      "file_name": "A20___31_Proverbs____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A20___31_Proverbs____ENGESVO2DA.mp3"
    },
    {
      "book": "Ecclesiastes",
      "number": 21,
      "chapter": 1,
      "length_in_seconds": 150,
      "length": "2:30",
      "file_name": "A21___01_EcclesiastesENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A21___01_EcclesiastesENGESVO2DA.mp3"
    },
    {
      "book": "Ecclesiastes",
      "number": 21,
      "chapter": 2,
      "length_in_seconds": 277,
      "length": "4:37",
      "file_name": "A21___02_EcclesiastesENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A21___02_EcclesiastesENGESVO2DA.mp3"
    },
    {
      "book": "Ecclesiastes",
      "number": 21,
      "chapter": 3,
      "length_in_seconds": 186,
      "length": "3:06",
      "file_name": "A21___03_EcclesiastesENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A21___03_EcclesiastesENGESVO2DA.mp3"
    },
    {
      "book": "Ecclesiastes",
      "number": 21,
      "chapter": 4,
      "length_in_seconds": 144,
      "length": "2:24",
      "file_name": "A21___04_EcclesiastesENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A21___04_EcclesiastesENGESVO2DA.mp3"
    },
    {
      "book": "Ecclesiastes",
      "number": 21,
      "chapter": 5,
      "length_in_seconds": 197,
      "length": "3:17",
      "file_name": "A21___05_EcclesiastesENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A21___05_EcclesiastesENGESVO2DA.mp3"
    },
    {
      "book": "Ecclesiastes",
      "number": 21,
      "chapter": 6,
      "length_in_seconds": 119,
      "length": "1:59",
      "file_name": "A21___06_EcclesiastesENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A21___06_EcclesiastesENGESVO2DA.mp3"
    },
    {
      "book": "Ecclesiastes",
      "number": 21,
      "chapter": 7,
      "length_in_seconds": 233,
      "length": "3:53",
      "file_name": "A21___07_EcclesiastesENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A21___07_EcclesiastesENGESVO2DA.mp3"
    },
    {
      "book": "Ecclesiastes",
      "number": 21,
      "chapter": 8,
      "length_in_seconds": 167,
      "length": "2:47",
      "file_name": "A21___08_EcclesiastesENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A21___08_EcclesiastesENGESVO2DA.mp3"
    },
    {
      "book": "Ecclesiastes",
      "number": 21,
      "chapter": 9,
      "length_in_seconds": 190,
      "length": "3:10",
      "file_name": "A21___09_EcclesiastesENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A21___09_EcclesiastesENGESVO2DA.mp3"
    },
    {
      "book": "Ecclesiastes",
      "number": 21,
      "chapter": 10,
      "length_in_seconds": 145,
      "length": "2:25",
      "file_name": "A21___10_EcclesiastesENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A21___10_EcclesiastesENGESVO2DA.mp3"
    },
    {
      "book": "Ecclesiastes",
      "number": 21,
      "chapter": 11,
      "length_in_seconds": 92,
      "length": "1:32",
      "file_name": "A21___11_EcclesiastesENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A21___11_EcclesiastesENGESVO2DA.mp3"
    },
    {
      "book": "Ecclesiastes",
      "number": 21,
      "chapter": 12,
      "length_in_seconds": 141,
      "length": "2:21",
      "file_name": "A21___12_EcclesiastesENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A21___12_EcclesiastesENGESVO2DA.mp3"
    },
    {
      "book": "Songof Songs",
      "number": 22,
      "chapter": 1,
      "length_in_seconds": 135,
      "length": "2:15",
      "file_name": "A22___01_SongofSongs_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A22___01_SongofSongs_ENGESVO2DA.mp3"
    },
    {
      "book": "Songof Songs",
      "number": 22,
      "chapter": 2,
      "length_in_seconds": 156,
      "length": "2:36",
      "file_name": "A22___02_SongofSongs_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A22___02_SongofSongs_ENGESVO2DA.mp3"
    },
    {
      "book": "Songof Songs",
      "number": 22,
      "chapter": 3,
      "length_in_seconds": 119,
      "length": "1:59",
      "file_name": "A22___03_SongofSongs_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A22___03_SongofSongs_ENGESVO2DA.mp3"
    },
    {
      "book": "Songof Songs",
      "number": 22,
      "chapter": 4,
      "length_in_seconds": 156,
      "length": "2:36",
      "file_name": "A22___04_SongofSongs_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A22___04_SongofSongs_ENGESVO2DA.mp3"
    },
    {
      "book": "Songof Songs",
      "number": 22,
      "chapter": 5,
      "length_in_seconds": 182,
      "length": "3:02",
      "file_name": "A22___05_SongofSongs_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A22___05_SongofSongs_ENGESVO2DA.mp3"
    },
    {
      "book": "Songof Songs",
      "number": 22,
      "chapter": 6,
      "length_in_seconds": 124,
      "length": "2:04",
      "file_name": "A22___06_SongofSongs_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A22___06_SongofSongs_ENGESVO2DA.mp3"
    },
    {
      "book": "Songof Songs",
      "number": 22,
      "chapter": 7,
      "length_in_seconds": 115,
      "length": "1:55",
      "file_name": "A22___07_SongofSongs_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A22___07_SongofSongs_ENGESVO2DA.mp3"
    },
    {
      "book": "Songof Songs",
      "number": 22,
      "chapter": 8,
      "length_in_seconds": 140,
      "length": "2:20",
      "file_name": "A22___08_SongofSongs_ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A22___08_SongofSongs_ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 1,
      "length_in_seconds": 311,
      "length": "5:11",
      "file_name": "A23___01_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___01_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 2,
      "length_in_seconds": 196,
      "length": "3:16",
      "file_name": "A23___02_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___02_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 3,
      "length_in_seconds": 214,
      "length": "3:34",
      "file_name": "A23___03_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___03_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 4,
      "length_in_seconds": 69,
      "length": "1:09",
      "file_name": "A23___04_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___04_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 5,
      "length_in_seconds": 302,
      "length": "5:02",
      "file_name": "A23___05_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___05_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 6,
      "length_in_seconds": 144,
      "length": "2:24",
      "file_name": "A23___06_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___06_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 7,
      "length_in_seconds": 240,
      "length": "4:00",
      "file_name": "A23___07_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___07_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 8,
      "length_in_seconds": 205,
      "length": "3:25",
      "file_name": "A23___08_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___08_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 9,
      "length_in_seconds": 222,
      "length": "3:42",
      "file_name": "A23___09_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___09_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 10,
      "length_in_seconds": 337,
      "length": "5:37",
      "file_name": "A23___10_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___10_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 11,
      "length_in_seconds": 179,
      "length": "2:59",
      "file_name": "A23___11_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___11_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 12,
      "length_in_seconds": 57,
      "length": "0:57",
      "file_name": "A23___12_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___12_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 13,
      "length_in_seconds": 204,
      "length": "3:24",
      "file_name": "A23___13_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___13_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 14,
      "length_in_seconds": 308,
      "length": "5:08",
      "file_name": "A23___14_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___14_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 15,
      "length_in_seconds": 101,
      "length": "1:41",
      "file_name": "A23___15_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___15_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 16,
      "length_in_seconds": 169,
      "length": "2:49",
      "file_name": "A23___16_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___16_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 17,
      "length_in_seconds": 149,
      "length": "2:29",
      "file_name": "A23___17_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___17_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 18,
      "length_in_seconds": 89,
      "length": "1:29",
      "file_name": "A23___18_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___18_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 19,
      "length_in_seconds": 250,
      "length": "4:10",
      "file_name": "A23___19_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___19_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 20,
      "length_in_seconds": 77,
      "length": "1:17",
      "file_name": "A23___20_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___20_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 21,
      "length_in_seconds": 166,
      "length": "2:46",
      "file_name": "A23___21_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___21_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 22,
      "length_in_seconds": 231,
      "length": "3:51",
      "file_name": "A23___22_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___22_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 23,
      "length_in_seconds": 178,
      "length": "2:58",
      "file_name": "A23___23_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___23_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 24,
      "length_in_seconds": 223,
      "length": "3:43",
      "file_name": "A23___24_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___24_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 25,
      "length_in_seconds": 150,
      "length": "2:30",
      "file_name": "A23___25_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___25_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 26,
      "length_in_seconds": 208,
      "length": "3:28",
      "file_name": "A23___26_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___26_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 27,
      "length_in_seconds": 143,
      "length": "2:23",
      "file_name": "A23___27_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___27_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 28,
      "length_in_seconds": 323,
      "length": "5:23",
      "file_name": "A23___28_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___28_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 29,
      "length_in_seconds": 282,
      "length": "4:42",
      "file_name": "A23___29_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___29_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 30,
      "length_in_seconds": 388,
      "length": "6:28",
      "file_name": "A23___30_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___30_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 31,
      "length_in_seconds": 115,
      "length": "1:55",
      "file_name": "A23___31_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___31_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 32,
      "length_in_seconds": 172,
      "length": "2:52",
      "file_name": "A23___32_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___32_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 33,
      "length_in_seconds": 252,
      "length": "4:12",
      "file_name": "A23___33_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___33_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 34,
      "length_in_seconds": 190,
      "length": "3:10",
      "file_name": "A23___34_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___34_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 35,
      "length_in_seconds": 110,
      "length": "1:50",
      "file_name": "A23___35_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___35_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 36,
      "length_in_seconds": 264,
      "length": "4:24",
      "file_name": "A23___36_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___36_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 37,
      "length_in_seconds": 417,
      "length": "6:57",
      "file_name": "A23___37_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___37_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 38,
      "length_in_seconds": 220,
      "length": "3:40",
      "file_name": "A23___38_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___38_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 39,
      "length_in_seconds": 101,
      "length": "1:41",
      "file_name": "A23___39_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___39_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 40,
      "length_in_seconds": 309,
      "length": "5:09",
      "file_name": "A23___40_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___40_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 41,
      "length_in_seconds": 308,
      "length": "5:08",
      "file_name": "A23___41_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___41_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 42,
      "length_in_seconds": 265,
      "length": "4:25",
      "file_name": "A23___42_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___42_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 43,
      "length_in_seconds": 271,
      "length": "4:31",
      "file_name": "A23___43_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___43_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 44,
      "length_in_seconds": 331,
      "length": "5:31",
      "file_name": "A23___44_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___44_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 45,
      "length_in_seconds": 286,
      "length": "4:46",
      "file_name": "A23___45_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___45_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 46,
      "length_in_seconds": 132,
      "length": "2:12",
      "file_name": "A23___46_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___46_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 47,
      "length_in_seconds": 194,
      "length": "3:14",
      "file_name": "A23___47_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___47_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 48,
      "length_in_seconds": 234,
      "length": "3:54",
      "file_name": "A23___48_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___48_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 49,
      "length_in_seconds": 319,
      "length": "5:19",
      "file_name": "A23___49_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___49_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 50,
      "length_in_seconds": 145,
      "length": "2:25",
      "file_name": "A23___50_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___50_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 51,
      "length_in_seconds": 266,
      "length": "4:26",
      "file_name": "A23___51_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___51_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 52,
      "length_in_seconds": 165,
      "length": "2:45",
      "file_name": "A23___52_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___52_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 53,
      "length_in_seconds": 160,
      "length": "2:40",
      "file_name": "A23___53_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___53_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 54,
      "length_in_seconds": 183,
      "length": "3:03",
      "file_name": "A23___54_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___54_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 55,
      "length_in_seconds": 147,
      "length": "2:27",
      "file_name": "A23___55_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___55_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 56,
      "length_in_seconds": 138,
      "length": "2:18",
      "file_name": "A23___56_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___56_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 57,
      "length_in_seconds": 227,
      "length": "3:47",
      "file_name": "A23___57_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___57_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 58,
      "length_in_seconds": 191,
      "length": "3:11",
      "file_name": "A23___58_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___58_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 59,
      "length_in_seconds": 243,
      "length": "4:03",
      "file_name": "A23___59_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___59_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 60,
      "length_in_seconds": 256,
      "length": "4:16",
      "file_name": "A23___60_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___60_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 61,
      "length_in_seconds": 154,
      "length": "2:34",
      "file_name": "A23___61_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___61_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 62,
      "length_in_seconds": 135,
      "length": "2:15",
      "file_name": "A23___62_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___62_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 63,
      "length_in_seconds": 215,
      "length": "3:35",
      "file_name": "A23___63_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___63_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 64,
      "length_in_seconds": 134,
      "length": "2:14",
      "file_name": "A23___64_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___64_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 65,
      "length_in_seconds": 295,
      "length": "4:55",
      "file_name": "A23___65_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___65_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Isaiah",
      "number": 23,
      "chapter": 66,
      "length_in_seconds": 316,
      "length": "5:16",
      "file_name": "A23___66_Isaiah______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A23___66_Isaiah______ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 1,
      "length_in_seconds": 210,
      "length": "3:30",
      "file_name": "A24___01_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___01_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 2,
      "length_in_seconds": 380,
      "length": "6:20",
      "file_name": "A24___02_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___02_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 3,
      "length_in_seconds": 315,
      "length": "5:15",
      "file_name": "A24___03_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___03_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 4,
      "length_in_seconds": 334,
      "length": "5:34",
      "file_name": "A24___04_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___04_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 5,
      "length_in_seconds": 328,
      "length": "5:28",
      "file_name": "A24___05_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___05_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 6,
      "length_in_seconds": 335,
      "length": "5:35",
      "file_name": "A24___06_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___06_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 7,
      "length_in_seconds": 356,
      "length": "5:56",
      "file_name": "A24___07_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___07_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 8,
      "length_in_seconds": 262,
      "length": "4:22",
      "file_name": "A24___08_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___08_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 9,
      "length_in_seconds": 300,
      "length": "5:00",
      "file_name": "A24___09_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___09_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 10,
      "length_in_seconds": 243,
      "length": "4:03",
      "file_name": "A24___10_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___10_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 11,
      "length_in_seconds": 267,
      "length": "4:27",
      "file_name": "A24___11_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___11_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 12,
      "length_in_seconds": 205,
      "length": "3:25",
      "file_name": "A24___12_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___12_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 13,
      "length_in_seconds": 273,
      "length": "4:33",
      "file_name": "A24___13_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___13_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 14,
      "length_in_seconds": 280,
      "length": "4:40",
      "file_name": "A24___14_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___14_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 15,
      "length_in_seconds": 265,
      "length": "4:25",
      "file_name": "A24___15_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___15_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 16,
      "length_in_seconds": 254,
      "length": "4:14",
      "file_name": "A24___16_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___16_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 17,
      "length_in_seconds": 298,
      "length": "4:58",
      "file_name": "A24___17_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___17_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 18,
      "length_in_seconds": 235,
      "length": "3:55",
      "file_name": "A24___18_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___18_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 19,
      "length_in_seconds": 194,
      "length": "3:14",
      "file_name": "A24___19_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___19_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 20,
      "length_in_seconds": 228,
      "length": "3:48",
      "file_name": "A24___20_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___20_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 21,
      "length_in_seconds": 179,
      "length": "2:59",
      "file_name": "A24___21_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___21_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 22,
      "length_in_seconds": 321,
      "length": "5:21",
      "file_name": "A24___22_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___22_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 23,
      "length_in_seconds": 452,
      "length": "7:32",
      "file_name": "A24___23_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___23_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 24,
      "length_in_seconds": 128,
      "length": "2:08",
      "file_name": "A24___24_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___24_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 25,
      "length_in_seconds": 388,
      "length": "6:28",
      "file_name": "A24___25_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___25_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 26,
      "length_in_seconds": 280,
      "length": "4:40",
      "file_name": "A24___26_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___26_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 27,
      "length_in_seconds": 252,
      "length": "4:12",
      "file_name": "A24___27_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___27_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 28,
      "length_in_seconds": 203,
      "length": "3:23",
      "file_name": "A24___28_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___28_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 29,
      "length_in_seconds": 352,
      "length": "5:52",
      "file_name": "A24___29_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___29_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 30,
      "length_in_seconds": 261,
      "length": "4:21",
      "file_name": "A24___30_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___30_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 31,
      "length_in_seconds": 472,
      "length": "7:52",
      "file_name": "A24___31_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___31_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 32,
      "length_in_seconds": 476,
      "length": "7:56",
      "file_name": "A24___32_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___32_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 33,
      "length_in_seconds": 303,
      "length": "5:03",
      "file_name": "A24___33_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___33_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 34,
      "length_in_seconds": 275,
      "length": "4:35",
      "file_name": "A24___34_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___34_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 35,
      "length_in_seconds": 232,
      "length": "3:52",
      "file_name": "A24___35_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___35_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 36,
      "length_in_seconds": 366,
      "length": "6:06",
      "file_name": "A24___36_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___36_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 37,
      "length_in_seconds": 224,
      "length": "3:44",
      "file_name": "A24___37_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___37_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 38,
      "length_in_seconds": 331,
      "length": "5:31",
      "file_name": "A24___38_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___38_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 39,
      "length_in_seconds": 196,
      "length": "3:16",
      "file_name": "A24___39_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___39_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 40,
      "length_in_seconds": 237,
      "length": "3:57",
      "file_name": "A24___40_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___40_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 41,
      "length_in_seconds": 221,
      "length": "3:41",
      "file_name": "A24___41_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___41_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 42,
      "length_in_seconds": 242,
      "length": "4:02",
      "file_name": "A24___42_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___42_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 43,
      "length_in_seconds": 158,
      "length": "2:38",
      "file_name": "A24___43_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___43_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 44,
      "length_in_seconds": 428,
      "length": "7:08",
      "file_name": "A24___44_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___44_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 45,
      "length_in_seconds": 61,
      "length": "1:01",
      "file_name": "A24___45_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___45_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 46,
      "length_in_seconds": 295,
      "length": "4:55",
      "file_name": "A24___46_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___46_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 47,
      "length_in_seconds": 84,
      "length": "1:24",
      "file_name": "A24___47_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___47_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 48,
      "length_in_seconds": 459,
      "length": "7:39",
      "file_name": "A24___48_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___48_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 49,
      "length_in_seconds": 431,
      "length": "7:11",
      "file_name": "A24___49_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___49_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 50,
      "length_in_seconds": 540,
      "length": "9:00",
      "file_name": "A24___50_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___50_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 51,
      "length_in_seconds": 675,
      "length": "11:15",
      "file_name": "A24___51_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___51_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Jeremiah",
      "number": 24,
      "chapter": 52,
      "length_in_seconds": 370,
      "length": "6:10",
      "file_name": "A24___52_Jeremiah____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A24___52_Jeremiah____ENGESVO2DA.mp3"
    },
    {
      "book": "Lamentations",
      "number": 25,
      "chapter": 1,
      "length_in_seconds": 321,
      "length": "5:21",
      "file_name": "A25___01_LamentationsENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A25___01_LamentationsENGESVO2DA.mp3"
    },
    {
      "book": "Lamentations",
      "number": 25,
      "chapter": 2,
      "length_in_seconds": 335,
      "length": "5:35",
      "file_name": "A25___02_LamentationsENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A25___02_LamentationsENGESVO2DA.mp3"
    },
    {
      "book": "Lamentations",
      "number": 25,
      "chapter": 3,
      "length_in_seconds": 331,
      "length": "5:31",
      "file_name": "A25___03_LamentationsENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A25___03_LamentationsENGESVO2DA.mp3"
    },
    {
      "book": "Lamentations",
      "number": 25,
      "chapter": 4,
      "length_in_seconds": 239,
      "length": "3:59",
      "file_name": "A25___04_LamentationsENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A25___04_LamentationsENGESVO2DA.mp3"
    },
    {
      "book": "Lamentations",
      "number": 25,
      "chapter": 5,
      "length_in_seconds": 127,
      "length": "2:07",
      "file_name": "A25___05_LamentationsENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A25___05_LamentationsENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 1,
      "length_in_seconds": 340,
      "length": "5:40",
      "file_name": "A26___01_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___01_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 2,
      "length_in_seconds": 111,
      "length": "1:51",
      "file_name": "A26___02_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___02_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 3,
      "length_in_seconds": 312,
      "length": "5:12",
      "file_name": "A26___03_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___03_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 4,
      "length_in_seconds": 195,
      "length": "3:15",
      "file_name": "A26___04_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___04_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 5,
      "length_in_seconds": 258,
      "length": "4:18",
      "file_name": "A26___05_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___05_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 6,
      "length_in_seconds": 201,
      "length": "3:21",
      "file_name": "A26___06_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___06_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 7,
      "length_in_seconds": 344,
      "length": "5:44",
      "file_name": "A26___07_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___07_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 8,
      "length_in_seconds": 250,
      "length": "4:10",
      "file_name": "A26___08_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___08_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 9,
      "length_in_seconds": 153,
      "length": "2:33",
      "file_name": "A26___09_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___09_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 10,
      "length_in_seconds": 249,
      "length": "4:09",
      "file_name": "A26___10_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___10_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 11,
      "length_in_seconds": 277,
      "length": "4:37",
      "file_name": "A26___11_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___11_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 12,
      "length_in_seconds": 328,
      "length": "5:28",
      "file_name": "A26___12_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___12_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 13,
      "length_in_seconds": 311,
      "length": "5:11",
      "file_name": "A26___13_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___13_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 14,
      "length_in_seconds": 297,
      "length": "4:57",
      "file_name": "A26___14_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___14_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 15,
      "length_in_seconds": 78,
      "length": "1:18",
      "file_name": "A26___15_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___15_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 16,
      "length_in_seconds": 749,
      "length": "12:29",
      "file_name": "A26___16_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___16_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 17,
      "length_in_seconds": 312,
      "length": "5:12",
      "file_name": "A26___17_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___17_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 18,
      "length_in_seconds": 376,
      "length": "6:16",
      "file_name": "A26___18_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___18_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 19,
      "length_in_seconds": 144,
      "length": "2:24",
      "file_name": "A26___19_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___19_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 20,
      "length_in_seconds": 628,
      "length": "10:28",
      "file_name": "A26___20_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___20_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 21,
      "length_in_seconds": 383,
      "length": "6:23",
      "file_name": "A26___21_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___21_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 22,
      "length_in_seconds": 358,
      "length": "5:58",
      "file_name": "A26___22_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___22_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 23,
      "length_in_seconds": 553,
      "length": "9:13",
      "file_name": "A26___23_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___23_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 24,
      "length_in_seconds": 295,
      "length": "4:55",
      "file_name": "A26___24_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___24_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 25,
      "length_in_seconds": 218,
      "length": "3:38",
      "file_name": "A26___25_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___25_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 26,
      "length_in_seconds": 297,
      "length": "4:57",
      "file_name": "A26___26_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___26_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 27,
      "length_in_seconds": 381,
      "length": "6:21",
      "file_name": "A26___27_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___27_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 28,
      "length_in_seconds": 337,
      "length": "5:37",
      "file_name": "A26___28_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___28_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 29,
      "length_in_seconds": 279,
      "length": "4:39",
      "file_name": "A26___29_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___29_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 30,
      "length_in_seconds": 304,
      "length": "5:04",
      "file_name": "A26___30_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___30_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 31,
      "length_in_seconds": 267,
      "length": "4:27",
      "file_name": "A26___31_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___31_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 32,
      "length_in_seconds": 425,
      "length": "7:05",
      "file_name": "A26___32_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___32_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 33,
      "length_in_seconds": 423,
      "length": "7:03",
      "file_name": "A26___33_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___33_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 34,
      "length_in_seconds": 395,
      "length": "6:35",
      "file_name": "A26___34_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___34_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 35,
      "length_in_seconds": 170,
      "length": "2:50",
      "file_name": "A26___35_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___35_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 36,
      "length_in_seconds": 485,
      "length": "8:05",
      "file_name": "A26___36_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___36_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 37,
      "length_in_seconds": 341,
      "length": "5:41",
      "file_name": "A26___37_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___37_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 38,
      "length_in_seconds": 311,
      "length": "5:11",
      "file_name": "A26___38_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___38_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 39,
      "length_in_seconds": 376,
      "length": "6:16",
      "file_name": "A26___39_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___39_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 40,
      "length_in_seconds": 559,
      "length": "9:19",
      "file_name": "A26___40_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___40_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 41,
      "length_in_seconds": 295,
      "length": "4:55",
      "file_name": "A26___41_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___41_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 42,
      "length_in_seconds": 203,
      "length": "3:23",
      "file_name": "A26___42_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___42_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 43,
      "length_in_seconds": 336,
      "length": "5:36",
      "file_name": "A26___43_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___43_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 44,
      "length_in_seconds": 390,
      "length": "6:30",
      "file_name": "A26___44_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___44_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 45,
      "length_in_seconds": 331,
      "length": "5:31",
      "file_name": "A26___45_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___45_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 46,
      "length_in_seconds": 320,
      "length": "5:20",
      "file_name": "A26___46_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___46_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 47,
      "length_in_seconds": 275,
      "length": "4:35",
      "file_name": "A26___47_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___47_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Ezekiel",
      "number": 26,
      "chapter": 48,
      "length_in_seconds": 396,
      "length": "6:36",
      "file_name": "A26___48_Ezekiel_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A26___48_Ezekiel_____ENGESVO2DA.mp3"
    },
    {
      "book": "Daniel",
      "number": 27,
      "chapter": 1,
      "length_in_seconds": 185,
      "length": "3:05",
      "file_name": "A27___01_Daniel______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A27___01_Daniel______ENGESVO2DA.mp3"
    },
    {
      "book": "Daniel",
      "number": 27,
      "chapter": 2,
      "length_in_seconds": 557,
      "length": "9:17",
      "file_name": "A27___02_Daniel______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A27___02_Daniel______ENGESVO2DA.mp3"
    },
    {
      "book": "Daniel",
      "number": 27,
      "chapter": 3,
      "length_in_seconds": 387,
      "length": "6:27",
      "file_name": "A27___03_Daniel______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A27___03_Daniel______ENGESVO2DA.mp3"
    },
    {
      "book": "Daniel",
      "number": 27,
      "chapter": 4,
      "length_in_seconds": 524,
      "length": "8:44",
      "file_name": "A27___04_Daniel______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A27___04_Daniel______ENGESVO2DA.mp3"
    },
    {
      "book": "Daniel",
      "number": 27,
      "chapter": 5,
      "length_in_seconds": 360,
      "length": "6:00",
      "file_name": "A27___05_Daniel______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A27___05_Daniel______ENGESVO2DA.mp3"
    },
    {
      "book": "Daniel",
      "number": 27,
      "chapter": 6,
      "length_in_seconds": 321,
      "length": "5:21",
      "file_name": "A27___06_Daniel______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A27___06_Daniel______ENGESVO2DA.mp3"
    },
    {
      "book": "Daniel",
      "number": 27,
      "chapter": 7,
      "length_in_seconds": 384,
      "length": "6:24",
      "file_name": "A27___07_Daniel______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A27___07_Daniel______ENGESVO2DA.mp3"
    },
    {
      "book": "Daniel",
      "number": 27,
      "chapter": 8,
      "length_in_seconds": 332,
      "length": "5:32",
      "file_name": "A27___08_Daniel______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A27___08_Daniel______ENGESVO2DA.mp3"
    },
    {
      "book": "Daniel",
      "number": 27,
      "chapter": 9,
      "length_in_seconds": 394,
      "length": "6:34",
      "file_name": "A27___09_Daniel______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A27___09_Daniel______ENGESVO2DA.mp3"
    },
    {
      "book": "Daniel",
      "number": 27,
      "chapter": 10,
      "length_in_seconds": 281,
      "length": "4:41",
      "file_name": "A27___10_Daniel______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A27___10_Daniel______ENGESVO2DA.mp3"
    },
    {
      "book": "Daniel",
      "number": 27,
      "chapter": 11,
      "length_in_seconds": 612,
      "length": "10:12",
      "file_name": "A27___11_Daniel______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A27___11_Daniel______ENGESVO2DA.mp3"
    },
    {
      "book": "Daniel",
      "number": 27,
      "chapter": 12,
      "length_in_seconds": 181,
      "length": "3:01",
      "file_name": "A27___12_Daniel______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A27___12_Daniel______ENGESVO2DA.mp3"
    },
    {
      "book": "Hosea",
      "number": 28,
      "chapter": 1,
      "length_in_seconds": 135,
      "length": "2:15",
      "file_name": "A28___01_Hosea_______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A28___01_Hosea_______ENGESVO2DA.mp3"
    },
    {
      "book": "Hosea",
      "number": 28,
      "chapter": 2,
      "length_in_seconds": 259,
      "length": "4:19",
      "file_name": "A28___02_Hosea_______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A28___02_Hosea_______ENGESVO2DA.mp3"
    },
    {
      "book": "Hosea",
      "number": 28,
      "chapter": 3,
      "length_in_seconds": 61,
      "length": "1:01",
      "file_name": "A28___03_Hosea_______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A28___03_Hosea_______ENGESVO2DA.mp3"
    },
    {
      "book": "Hosea",
      "number": 28,
      "chapter": 4,
      "length_in_seconds": 193,
      "length": "3:13",
      "file_name": "A28___04_Hosea_______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A28___04_Hosea_______ENGESVO2DA.mp3"
    },
    {
      "book": "Hosea",
      "number": 28,
      "chapter": 5,
      "length_in_seconds": 150,
      "length": "2:30",
      "file_name": "A28___05_Hosea_______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A28___05_Hosea_______ENGESVO2DA.mp3"
    },
    {
      "book": "Hosea",
      "number": 28,
      "chapter": 6,
      "length_in_seconds": 99,
      "length": "1:39",
      "file_name": "A28___06_Hosea_______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A28___06_Hosea_______ENGESVO2DA.mp3"
    },
    {
      "book": "Hosea",
      "number": 28,
      "chapter": 7,
      "length_in_seconds": 154,
      "length": "2:34",
      "file_name": "A28___07_Hosea_______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A28___07_Hosea_______ENGESVO2DA.mp3"
    },
    {
      "book": "Hosea",
      "number": 28,
      "chapter": 8,
      "length_in_seconds": 140,
      "length": "2:20",
      "file_name": "A28___08_Hosea_______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A28___08_Hosea_______ENGESVO2DA.mp3"
    },
    {
      "book": "Hosea",
      "number": 28,
      "chapter": 9,
      "length_in_seconds": 198,
      "length": "3:18",
      "file_name": "A28___09_Hosea_______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A28___09_Hosea_______ENGESVO2DA.mp3"
    },
    {
      "book": "Hosea",
      "number": 28,
      "chapter": 10,
      "length_in_seconds": 175,
      "length": "2:55",
      "file_name": "A28___10_Hosea_______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A28___10_Hosea_______ENGESVO2DA.mp3"
    },
    {
      "book": "Hosea",
      "number": 28,
      "chapter": 11,
      "length_in_seconds": 128,
      "length": "2:08",
      "file_name": "A28___11_Hosea_______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A28___11_Hosea_______ENGESVO2DA.mp3"
    },
    {
      "book": "Hosea",
      "number": 28,
      "chapter": 12,
      "length_in_seconds": 126,
      "length": "2:06",
      "file_name": "A28___12_Hosea_______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A28___12_Hosea_______ENGESVO2DA.mp3"
    },
    {
      "book": "Hosea",
      "number": 28,
      "chapter": 13,
      "length_in_seconds": 165,
      "length": "2:45",
      "file_name": "A28___13_Hosea_______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A28___13_Hosea_______ENGESVO2DA.mp3"
    },
    {
      "book": "Hosea",
      "number": 28,
      "chapter": 14,
      "length_in_seconds": 100,
      "length": "1:40",
      "file_name": "A28___14_Hosea_______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A28___14_Hosea_______ENGESVO2DA.mp3"
    },
    {
      "book": "Joel",
      "number": 29,
      "chapter": 1,
      "length_in_seconds": 198,
      "length": "3:18",
      "file_name": "A29___01_Joel________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A29___01_Joel________ENGESVO2DA.mp3"
    },
    {
      "book": "Joel",
      "number": 29,
      "chapter": 2,
      "length_in_seconds": 338,
      "length": "5:38",
      "file_name": "A29___02_Joel________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A29___02_Joel________ENGESVO2DA.mp3"
    },
    {
      "book": "Joel",
      "number": 29,
      "chapter": 3,
      "length_in_seconds": 202,
      "length": "3:22",
      "file_name": "A29___03_Joel________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A29___03_Joel________ENGESVO2DA.mp3"
    },
    {
      "book": "Amos",
      "number": 30,
      "chapter": 1,
      "length_in_seconds": 171,
      "length": "2:51",
      "file_name": "A30___01_Amos________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A30___01_Amos________ENGESVO2DA.mp3"
    },
    {
      "book": "Amos",
      "number": 30,
      "chapter": 2,
      "length_in_seconds": 160,
      "length": "2:40",
      "file_name": "A30___02_Amos________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A30___02_Amos________ENGESVO2DA.mp3"
    },
    {
      "book": "Amos",
      "number": 30,
      "chapter": 3,
      "length_in_seconds": 146,
      "length": "2:26",
      "file_name": "A30___03_Amos________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A30___03_Amos________ENGESVO2DA.mp3"
    },
    {
      "book": "Amos",
      "number": 30,
      "chapter": 4,
      "length_in_seconds": 170,
      "length": "2:50",
      "file_name": "A30___04_Amos________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A30___04_Amos________ENGESVO2DA.mp3"
    },
    {
      "book": "Amos",
      "number": 30,
      "chapter": 5,
      "length_in_seconds": 237,
      "length": "3:57",
      "file_name": "A30___05_Amos________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A30___05_Amos________ENGESVO2DA.mp3"
    },
    {
      "book": "Amos",
      "number": 30,
      "chapter": 6,
      "length_in_seconds": 154,
      "length": "2:34",
      "file_name": "A30___06_Amos________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A30___06_Amos________ENGESVO2DA.mp3"
    },
    {
      "book": "Amos",
      "number": 30,
      "chapter": 7,
      "length_in_seconds": 180,
      "length": "3:00",
      "file_name": "A30___07_Amos________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A30___07_Amos________ENGESVO2DA.mp3"
    },
    {
      "book": "Amos",
      "number": 30,
      "chapter": 8,
      "length_in_seconds": 146,
      "length": "2:26",
      "file_name": "A30___08_Amos________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A30___08_Amos________ENGESVO2DA.mp3"
    },
    {
      "book": "Amos",
      "number": 30,
      "chapter": 9,
      "length_in_seconds": 193,
      "length": "3:13",
      "file_name": "A30___09_Amos________ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A30___09_Amos________ENGESVO2DA.mp3"
    },
    {
      "book": "Obadiah",
      "number": 31,
      "chapter": 1,
      "length_in_seconds": 273,
      "length": "4:33",
      "file_name": "A31___01_Obadiah_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A31___01_Obadiah_____ENGESVO2DA.mp3"
    },
    {
      "book": "Jonah",
      "number": 32,
      "chapter": 1,
      "length_in_seconds": 170,
      "length": "2:50",
      "file_name": "A32___01_Jonah_______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A32___01_Jonah_______ENGESVO2DA.mp3"
    },
    {
      "book": "Jonah",
      "number": 32,
      "chapter": 2,
      "length_in_seconds": 92,
      "length": "1:32",
      "file_name": "A32___02_Jonah_______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A32___02_Jonah_______ENGESVO2DA.mp3"
    },
    {
      "book": "Jonah",
      "number": 32,
      "chapter": 3,
      "length_in_seconds": 100,
      "length": "1:40",
      "file_name": "A32___03_Jonah_______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A32___03_Jonah_______ENGESVO2DA.mp3"
    },
    {
      "book": "Jonah",
      "number": 32,
      "chapter": 4,
      "length_in_seconds": 119,
      "length": "1:59",
      "file_name": "A32___04_Jonah_______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A32___04_Jonah_______ENGESVO2DA.mp3"
    },
    {
      "book": "Micah",
      "number": 33,
      "chapter": 1,
      "length_in_seconds": 177,
      "length": "2:57",
      "file_name": "A33___01_Micah_______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A33___01_Micah_______ENGESVO2DA.mp3"
    },
    {
      "book": "Micah",
      "number": 33,
      "chapter": 2,
      "length_in_seconds": 145,
      "length": "2:25",
      "file_name": "A33___02_Micah_______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A33___02_Micah_______ENGESVO2DA.mp3"
    },
    {
      "book": "Micah",
      "number": 33,
      "chapter": 3,
      "length_in_seconds": 128,
      "length": "2:08",
      "file_name": "A33___03_Micah_______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A33___03_Micah_______ENGESVO2DA.mp3"
    },
    {
      "book": "Micah",
      "number": 33,
      "chapter": 4,
      "length_in_seconds": 172,
      "length": "2:52",
      "file_name": "A33___04_Micah_______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A33___04_Micah_______ENGESVO2DA.mp3"
    },
    {
      "book": "Micah",
      "number": 33,
      "chapter": 5,
      "length_in_seconds": 157,
      "length": "2:37",
      "file_name": "A33___05_Micah_______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A33___05_Micah_______ENGESVO2DA.mp3"
    },
    {
      "book": "Micah",
      "number": 33,
      "chapter": 6,
      "length_in_seconds": 165,
      "length": "2:45",
      "file_name": "A33___06_Micah_______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A33___06_Micah_______ENGESVO2DA.mp3"
    },
    {
      "book": "Micah",
      "number": 33,
      "chapter": 7,
      "length_in_seconds": 216,
      "length": "3:36",
      "file_name": "A33___07_Micah_______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A33___07_Micah_______ENGESVO2DA.mp3"
    },
    {
      "book": "Nahum",
      "number": 34,
      "chapter": 1,
      "length_in_seconds": 172,
      "length": "2:52",
      "file_name": "A34___01_Nahum_______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A34___01_Nahum_______ENGESVO2DA.mp3"
    },
    {
      "book": "Nahum",
      "number": 34,
      "chapter": 2,
      "length_in_seconds": 145,
      "length": "2:25",
      "file_name": "A34___02_Nahum_______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A34___02_Nahum_______ENGESVO2DA.mp3"
    },
    {
      "book": "Nahum",
      "number": 34,
      "chapter": 3,
      "length_in_seconds": 225,
      "length": "3:45",
      "file_name": "A34___03_Nahum_______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A34___03_Nahum_______ENGESVO2DA.mp3"
    },
    {
      "book": "Habakkuk",
      "number": 35,
      "chapter": 1,
      "length_in_seconds": 159,
      "length": "2:39",
      "file_name": "A35___01_Habakkuk____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A35___01_Habakkuk____ENGESVO2DA.mp3"
    },
    {
      "book": "Habakkuk",
      "number": 35,
      "chapter": 2,
      "length_in_seconds": 214,
      "length": "3:34",
      "file_name": "A35___02_Habakkuk____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A35___02_Habakkuk____ENGESVO2DA.mp3"
    },
    {
      "book": "Habakkuk",
      "number": 35,
      "chapter": 3,
      "length_in_seconds": 188,
      "length": "3:08",
      "file_name": "A35___03_Habakkuk____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A35___03_Habakkuk____ENGESVO2DA.mp3"
    },
    {
      "book": "Zephaniah",
      "number": 36,
      "chapter": 1,
      "length_in_seconds": 186,
      "length": "3:06",
      "file_name": "A36___01_Zephaniah___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A36___01_Zephaniah___ENGESVO2DA.mp3"
    },
    {
      "book": "Zephaniah",
      "number": 36,
      "chapter": 2,
      "length_in_seconds": 175,
      "length": "2:55",
      "file_name": "A36___02_Zephaniah___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A36___02_Zephaniah___ENGESVO2DA.mp3"
    },
    {
      "book": "Zephaniah",
      "number": 36,
      "chapter": 3,
      "length_in_seconds": 216,
      "length": "3:36",
      "file_name": "A36___03_Zephaniah___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A36___03_Zephaniah___ENGESVO2DA.mp3"
    },
    {
      "book": "Haggai",
      "number": 37,
      "chapter": 1,
      "length_in_seconds": 158,
      "length": "2:38",
      "file_name": "A37___01_Haggai______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A37___01_Haggai______ENGESVO2DA.mp3"
    },
    {
      "book": "Haggai",
      "number": 37,
      "chapter": 2,
      "length_in_seconds": 235,
      "length": "3:55",
      "file_name": "A37___02_Haggai______ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A37___02_Haggai______ENGESVO2DA.mp3"
    },
    {
      "book": "Zechariah",
      "number": 38,
      "chapter": 1,
      "length_in_seconds": 237,
      "length": "3:57",
      "file_name": "A38___01_Zechariah___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A38___01_Zechariah___ENGESVO2DA.mp3"
    },
    {
      "book": "Zechariah",
      "number": 38,
      "chapter": 2,
      "length_in_seconds": 133,
      "length": "2:13",
      "file_name": "A38___02_Zechariah___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A38___02_Zechariah___ENGESVO2DA.mp3"
    },
    {
      "book": "Zechariah",
      "number": 38,
      "chapter": 3,
      "length_in_seconds": 111,
      "length": "1:51",
      "file_name": "A38___03_Zechariah___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A38___03_Zechariah___ENGESVO2DA.mp3"
    },
    {
      "book": "Zechariah",
      "number": 38,
      "chapter": 4,
      "length_in_seconds": 139,
      "length": "2:19",
      "file_name": "A38___04_Zechariah___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A38___04_Zechariah___ENGESVO2DA.mp3"
    },
    {
      "book": "Zechariah",
      "number": 38,
      "chapter": 5,
      "length_in_seconds": 123,
      "length": "2:03",
      "file_name": "A38___05_Zechariah___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A38___05_Zechariah___ENGESVO2DA.mp3"
    },
    {
      "book": "Zechariah",
      "number": 38,
      "chapter": 6,
      "length_in_seconds": 154,
      "length": "2:34",
      "file_name": "A38___06_Zechariah___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A38___06_Zechariah___ENGESVO2DA.mp3"
    },
    {
      "book": "Zechariah",
      "number": 38,
      "chapter": 7,
      "length_in_seconds": 137,
      "length": "2:17",
      "file_name": "A38___07_Zechariah___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A38___07_Zechariah___ENGESVO2DA.mp3"
    },
    {
      "book": "Zechariah",
      "number": 38,
      "chapter": 8,
      "length_in_seconds": 274,
      "length": "4:34",
      "file_name": "A38___08_Zechariah___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A38___08_Zechariah___ENGESVO2DA.mp3"
    },
    {
      "book": "Zechariah",
      "number": 38,
      "chapter": 9,
      "length_in_seconds": 216,
      "length": "3:36",
      "file_name": "A38___09_Zechariah___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A38___09_Zechariah___ENGESVO2DA.mp3"
    },
    {
      "book": "Zechariah",
      "number": 38,
      "chapter": 10,
      "length_in_seconds": 155,
      "length": "2:35",
      "file_name": "A38___10_Zechariah___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A38___10_Zechariah___ENGESVO2DA.mp3"
    },
    {
      "book": "Zechariah",
      "number": 38,
      "chapter": 11,
      "length_in_seconds": 197,
      "length": "3:17",
      "file_name": "A38___11_Zechariah___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A38___11_Zechariah___ENGESVO2DA.mp3"
    },
    {
      "book": "Zechariah",
      "number": 38,
      "chapter": 12,
      "length_in_seconds": 193,
      "length": "3:13",
      "file_name": "A38___12_Zechariah___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A38___12_Zechariah___ENGESVO2DA.mp3"
    },
    {
      "book": "Zechariah",
      "number": 38,
      "chapter": 13,
      "length_in_seconds": 120,
      "length": "2:00",
      "file_name": "A38___13_Zechariah___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A38___13_Zechariah___ENGESVO2DA.mp3"
    },
    {
      "book": "Zechariah",
      "number": 38,
      "chapter": 14,
      "length_in_seconds": 275,
      "length": "4:35",
      "file_name": "A38___14_Zechariah___ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A38___14_Zechariah___ENGESVO2DA.mp3"
    },
    {
      "book": "Malachi",
      "number": 39,
      "chapter": 1,
      "length_in_seconds": 180,
      "length": "3:00",
      "file_name": "A39___01_Malachi_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A39___01_Malachi_____ENGESVO2DA.mp3"
    },
    {
      "book": "Malachi",
      "number": 39,
      "chapter": 2,
      "length_in_seconds": 189,
      "length": "3:09",
      "file_name": "A39___02_Malachi_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A39___02_Malachi_____ENGESVO2DA.mp3"
    },
    {
      "book": "Malachi",
      "number": 39,
      "chapter": 3,
      "length_in_seconds": 200,
      "length": "3:20",
      "file_name": "A39___03_Malachi_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A39___03_Malachi_____ENGESVO2DA.mp3"
    },
    {
      "book": "Malachi",
      "number": 39,
      "chapter": 4,
      "length_in_seconds": 67,
      "length": "1:07",
      "file_name": "A39___04_Malachi_____ENGESVO2DA.mp3",
      "dam_id": "ENGESVO2DA",
      "path": "/ENGESVO2DA/A39___04_Malachi_____ENGESVO2DA.mp3"
    },
    {
      "book": "Matthew",
      "number": 40,
      "chapter": 1,
      "length_in_seconds": 209,
      "length": "3:29",
      "file_name": "B01___01_Matthew_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B01___01_Matthew_____ENGESVN2DA.mp3"
    },
    {
      "book": "Matthew",
      "number": 40,
      "chapter": 2,
      "length_in_seconds": 209,
      "length": "3:29",
      "file_name": "B01___02_Matthew_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B01___02_Matthew_____ENGESVN2DA.mp3"
    },
    {
      "book": "Matthew",
      "number": 40,
      "chapter": 3,
      "length_in_seconds": 151,
      "length": "2:31",
      "file_name": "B01___03_Matthew_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B01___03_Matthew_____ENGESVN2DA.mp3"
    },
    {
      "book": "Matthew",
      "number": 40,
      "chapter": 4,
      "length_in_seconds": 224,
      "length": "3:44",
      "file_name": "B01___04_Matthew_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B01___04_Matthew_____ENGESVN2DA.mp3"
    },
    {
      "book": "Matthew",
      "number": 40,
      "chapter": 5,
      "length_in_seconds": 368,
      "length": "6:08",
      "file_name": "B01___05_Matthew_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B01___05_Matthew_____ENGESVN2DA.mp3"
    },
    {
      "book": "Matthew",
      "number": 40,
      "chapter": 6,
      "length_in_seconds": 295,
      "length": "4:55",
      "file_name": "B01___06_Matthew_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B01___06_Matthew_____ENGESVN2DA.mp3"
    },
    {
      "book": "Matthew",
      "number": 40,
      "chapter": 7,
      "length_in_seconds": 220,
      "length": "3:40",
      "file_name": "B01___07_Matthew_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B01___07_Matthew_____ENGESVN2DA.mp3"
    },
    {
      "book": "Matthew",
      "number": 40,
      "chapter": 8,
      "length_in_seconds": 276,
      "length": "4:36",
      "file_name": "B01___08_Matthew_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B01___08_Matthew_____ENGESVN2DA.mp3"
    },
    {
      "book": "Matthew",
      "number": 40,
      "chapter": 9,
      "length_in_seconds": 325,
      "length": "5:25",
      "file_name": "B01___09_Matthew_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B01___09_Matthew_____ENGESVN2DA.mp3"
    },
    {
      "book": "Matthew",
      "number": 40,
      "chapter": 10,
      "length_in_seconds": 317,
      "length": "5:17",
      "file_name": "B01___10_Matthew_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B01___10_Matthew_____ENGESVN2DA.mp3"
    },
    {
      "book": "Matthew",
      "number": 40,
      "chapter": 11,
      "length_in_seconds": 258,
      "length": "4:18",
      "file_name": "B01___11_Matthew_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B01___11_Matthew_____ENGESVN2DA.mp3"
    },
    {
      "book": "Matthew",
      "number": 40,
      "chapter": 12,
      "length_in_seconds": 400,
      "length": "6:40",
      "file_name": "B01___12_Matthew_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B01___12_Matthew_____ENGESVN2DA.mp3"
    },
    {
      "book": "Matthew",
      "number": 40,
      "chapter": 13,
      "length_in_seconds": 465,
      "length": "7:45",
      "file_name": "B01___13_Matthew_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B01___13_Matthew_____ENGESVN2DA.mp3"
    },
    {
      "book": "Matthew",
      "number": 40,
      "chapter": 14,
      "length_in_seconds": 254,
      "length": "4:14",
      "file_name": "B01___14_Matthew_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B01___14_Matthew_____ENGESVN2DA.mp3"
    },
    {
      "book": "Matthew",
      "number": 40,
      "chapter": 15,
      "length_in_seconds": 285,
      "length": "4:45",
      "file_name": "B01___15_Matthew_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B01___15_Matthew_____ENGESVN2DA.mp3"
    },
    {
      "book": "Matthew",
      "number": 40,
      "chapter": 16,
      "length_in_seconds": 248,
      "length": "4:08",
      "file_name": "B01___16_Matthew_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B01___16_Matthew_____ENGESVN2DA.mp3"
    },
    {
      "book": "Matthew",
      "number": 40,
      "chapter": 17,
      "length_in_seconds": 226,
      "length": "3:46",
      "file_name": "B01___17_Matthew_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B01___17_Matthew_____ENGESVN2DA.mp3"
    },
    {
      "book": "Matthew",
      "number": 40,
      "chapter": 18,
      "length_in_seconds": 281,
      "length": "4:41",
      "file_name": "B01___18_Matthew_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B01___18_Matthew_____ENGESVN2DA.mp3"
    },
    {
      "book": "Matthew",
      "number": 40,
      "chapter": 19,
      "length_in_seconds": 259,
      "length": "4:19",
      "file_name": "B01___19_Matthew_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B01___19_Matthew_____ENGESVN2DA.mp3"
    },
    {
      "book": "Matthew",
      "number": 40,
      "chapter": 20,
      "length_in_seconds": 261,
      "length": "4:21",
      "file_name": "B01___20_Matthew_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B01___20_Matthew_____ENGESVN2DA.mp3"
    },
    {
      "book": "Matthew",
      "number": 40,
      "chapter": 21,
      "length_in_seconds": 418,
      "length": "6:58",
      "file_name": "B01___21_Matthew_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B01___21_Matthew_____ENGESVN2DA.mp3"
    },
    {
      "book": "Matthew",
      "number": 40,
      "chapter": 22,
      "length_in_seconds": 329,
      "length": "5:29",
      "file_name": "B01___22_Matthew_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B01___22_Matthew_____ENGESVN2DA.mp3"
    },
    {
      "book": "Matthew",
      "number": 40,
      "chapter": 23,
      "length_in_seconds": 340,
      "length": "5:40",
      "file_name": "B01___23_Matthew_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B01___23_Matthew_____ENGESVN2DA.mp3"
    },
    {
      "book": "Matthew",
      "number": 40,
      "chapter": 24,
      "length_in_seconds": 380,
      "length": "6:20",
      "file_name": "B01___24_Matthew_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B01___24_Matthew_____ENGESVN2DA.mp3"
    },
    {
      "book": "Matthew",
      "number": 40,
      "chapter": 25,
      "length_in_seconds": 368,
      "length": "6:08",
      "file_name": "B01___25_Matthew_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B01___25_Matthew_____ENGESVN2DA.mp3"
    },
    {
      "book": "Matthew",
      "number": 40,
      "chapter": 26,
      "length_in_seconds": 606,
      "length": "10:06",
      "file_name": "B01___26_Matthew_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B01___26_Matthew_____ENGESVN2DA.mp3"
    },
    {
      "book": "Matthew",
      "number": 40,
      "chapter": 27,
      "length_in_seconds": 542,
      "length": "9:02",
      "file_name": "B01___27_Matthew_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B01___27_Matthew_____ENGESVN2DA.mp3"
    },
    {
      "book": "Matthew",
      "number": 40,
      "chapter": 28,
      "length_in_seconds": 165,
      "length": "2:45",
      "file_name": "B01___28_Matthew_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B01___28_Matthew_____ENGESVN2DA.mp3"
    },
    {
      "book": "Mark",
      "number": 41,
      "chapter": 1,
      "length_in_seconds": 329,
      "length": "5:29",
      "file_name": "B02___01_Mark________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B02___01_Mark________ENGESVN2DA.mp3"
    },
    {
      "book": "Mark",
      "number": 41,
      "chapter": 2,
      "length_in_seconds": 241,
      "length": "4:01",
      "file_name": "B02___02_Mark________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B02___02_Mark________ENGESVN2DA.mp3"
    },
    {
      "book": "Mark",
      "number": 41,
      "chapter": 3,
      "length_in_seconds": 228,
      "length": "3:48",
      "file_name": "B02___03_Mark________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B02___03_Mark________ENGESVN2DA.mp3"
    },
    {
      "book": "Mark",
      "number": 41,
      "chapter": 4,
      "length_in_seconds": 300,
      "length": "5:00",
      "file_name": "B02___04_Mark________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B02___04_Mark________ENGESVN2DA.mp3"
    },
    {
      "book": "Mark",
      "number": 41,
      "chapter": 5,
      "length_in_seconds": 330,
      "length": "5:30",
      "file_name": "B02___05_Mark________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B02___05_Mark________ENGESVN2DA.mp3"
    },
    {
      "book": "Mark",
      "number": 41,
      "chapter": 6,
      "length_in_seconds": 424,
      "length": "7:04",
      "file_name": "B02___06_Mark________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B02___06_Mark________ENGESVN2DA.mp3"
    },
    {
      "book": "Mark",
      "number": 41,
      "chapter": 7,
      "length_in_seconds": 265,
      "length": "4:25",
      "file_name": "B02___07_Mark________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B02___07_Mark________ENGESVN2DA.mp3"
    },
    {
      "book": "Mark",
      "number": 41,
      "chapter": 8,
      "length_in_seconds": 301,
      "length": "5:01",
      "file_name": "B02___08_Mark________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B02___08_Mark________ENGESVN2DA.mp3"
    },
    {
      "book": "Mark",
      "number": 41,
      "chapter": 9,
      "length_in_seconds": 382,
      "length": "6:22",
      "file_name": "B02___09_Mark________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B02___09_Mark________ENGESVN2DA.mp3"
    },
    {
      "book": "Mark",
      "number": 41,
      "chapter": 10,
      "length_in_seconds": 417,
      "length": "6:57",
      "file_name": "B02___10_Mark________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B02___10_Mark________ENGESVN2DA.mp3"
    },
    {
      "book": "Mark",
      "number": 41,
      "chapter": 11,
      "length_in_seconds": 241,
      "length": "4:01",
      "file_name": "B02___11_Mark________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B02___11_Mark________ENGESVN2DA.mp3"
    },
    {
      "book": "Mark",
      "number": 41,
      "chapter": 12,
      "length_in_seconds": 390,
      "length": "6:30",
      "file_name": "B02___12_Mark________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B02___12_Mark________ENGESVN2DA.mp3"
    },
    {
      "book": "Mark",
      "number": 41,
      "chapter": 13,
      "length_in_seconds": 285,
      "length": "4:45",
      "file_name": "B02___13_Mark________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B02___13_Mark________ENGESVN2DA.mp3"
    },
    {
      "book": "Mark",
      "number": 41,
      "chapter": 14,
      "length_in_seconds": 538,
      "length": "8:58",
      "file_name": "B02___14_Mark________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B02___14_Mark________ENGESVN2DA.mp3"
    },
    {
      "book": "Mark",
      "number": 41,
      "chapter": 15,
      "length_in_seconds": 355,
      "length": "5:55",
      "file_name": "B02___15_Mark________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B02___15_Mark________ENGESVN2DA.mp3"
    },
    {
      "book": "Mark",
      "number": 41,
      "chapter": 16,
      "length_in_seconds": 161,
      "length": "2:41",
      "file_name": "B02___16_Mark________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B02___16_Mark________ENGESVN2DA.mp3"
    },
    {
      "book": "Luke",
      "number": 42,
      "chapter": 1,
      "length_in_seconds": 560,
      "length": "9:20",
      "file_name": "B03___01_Luke________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B03___01_Luke________ENGESVN2DA.mp3"
    },
    {
      "book": "Luke",
      "number": 42,
      "chapter": 2,
      "length_in_seconds": 375,
      "length": "6:15",
      "file_name": "B03___02_Luke________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B03___02_Luke________ENGESVN2DA.mp3"
    },
    {
      "book": "Luke",
      "number": 42,
      "chapter": 3,
      "length_in_seconds": 329,
      "length": "5:29",
      "file_name": "B03___03_Luke________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B03___03_Luke________ENGESVN2DA.mp3"
    },
    {
      "book": "Luke",
      "number": 42,
      "chapter": 4,
      "length_in_seconds": 400,
      "length": "6:40",
      "file_name": "B03___04_Luke________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B03___04_Luke________ENGESVN2DA.mp3"
    },
    {
      "book": "Luke",
      "number": 42,
      "chapter": 5,
      "length_in_seconds": 325,
      "length": "5:25",
      "file_name": "B03___05_Luke________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B03___05_Luke________ENGESVN2DA.mp3"
    },
    {
      "book": "Luke",
      "number": 42,
      "chapter": 6,
      "length_in_seconds": 411,
      "length": "6:51",
      "file_name": "B03___06_Luke________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B03___06_Luke________ENGESVN2DA.mp3"
    },
    {
      "book": "Luke",
      "number": 42,
      "chapter": 7,
      "length_in_seconds": 445,
      "length": "7:25",
      "file_name": "B03___07_Luke________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B03___07_Luke________ENGESVN2DA.mp3"
    },
    {
      "book": "Luke",
      "number": 42,
      "chapter": 8,
      "length_in_seconds": 507,
      "length": "8:27",
      "file_name": "B03___08_Luke________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B03___08_Luke________ENGESVN2DA.mp3"
    },
    {
      "book": "Luke",
      "number": 42,
      "chapter": 9,
      "length_in_seconds": 510,
      "length": "8:30",
      "file_name": "B03___09_Luke________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B03___09_Luke________ENGESVN2DA.mp3"
    },
    {
      "book": "Luke",
      "number": 42,
      "chapter": 10,
      "length_in_seconds": 357,
      "length": "5:57",
      "file_name": "B03___10_Luke________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B03___10_Luke________ENGESVN2DA.mp3"
    },
    {
      "book": "Luke",
      "number": 42,
      "chapter": 11,
      "length_in_seconds": 453,
      "length": "7:33",
      "file_name": "B03___11_Luke________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B03___11_Luke________ENGESVN2DA.mp3"
    },
    {
      "book": "Luke",
      "number": 42,
      "chapter": 12,
      "length_in_seconds": 482,
      "length": "8:02",
      "file_name": "B03___12_Luke________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B03___12_Luke________ENGESVN2DA.mp3"
    },
    {
      "book": "Luke",
      "number": 42,
      "chapter": 13,
      "length_in_seconds": 301,
      "length": "5:01",
      "file_name": "B03___13_Luke________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B03___13_Luke________ENGESVN2DA.mp3"
    },
    {
      "book": "Luke",
      "number": 42,
      "chapter": 14,
      "length_in_seconds": 278,
      "length": "4:38",
      "file_name": "B03___14_Luke________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B03___14_Luke________ENGESVN2DA.mp3"
    },
    {
      "book": "Luke",
      "number": 42,
      "chapter": 15,
      "length_in_seconds": 253,
      "length": "4:13",
      "file_name": "B03___15_Luke________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B03___15_Luke________ENGESVN2DA.mp3"
    },
    {
      "book": "Luke",
      "number": 42,
      "chapter": 16,
      "length_in_seconds": 285,
      "length": "4:45",
      "file_name": "B03___16_Luke________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B03___16_Luke________ENGESVN2DA.mp3"
    },
    {
      "book": "Luke",
      "number": 42,
      "chapter": 17,
      "length_in_seconds": 277,
      "length": "4:37",
      "file_name": "B03___17_Luke________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B03___17_Luke________ENGESVN2DA.mp3"
    },
    {
      "book": "Luke",
      "number": 42,
      "chapter": 18,
      "length_in_seconds": 334,
      "length": "5:34",
      "file_name": "B03___18_Luke________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B03___18_Luke________ENGESVN2DA.mp3"
    },
    {
      "book": "Luke",
      "number": 42,
      "chapter": 19,
      "length_in_seconds": 351,
      "length": "5:51",
      "file_name": "B03___19_Luke________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B03___19_Luke________ENGESVN2DA.mp3"
    },
    {
      "book": "Luke",
      "number": 42,
      "chapter": 20,
      "length_in_seconds": 353,
      "length": "5:53",
      "file_name": "B03___20_Luke________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B03___20_Luke________ENGESVN2DA.mp3"
    },
    {
      "book": "Luke",
      "number": 42,
      "chapter": 21,
      "length_in_seconds": 284,
      "length": "4:44",
      "file_name": "B03___21_Luke________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B03___21_Luke________ENGESVN2DA.mp3"
    },
    {
      "book": "Luke",
      "number": 42,
      "chapter": 22,
      "length_in_seconds": 517,
      "length": "8:37",
      "file_name": "B03___22_Luke________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B03___22_Luke________ENGESVN2DA.mp3"
    },
    {
      "book": "Luke",
      "number": 42,
      "chapter": 23,
      "length_in_seconds": 457,
      "length": "7:37",
      "file_name": "B03___23_Luke________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B03___23_Luke________ENGESVN2DA.mp3"
    },
    {
      "book": "Luke",
      "number": 42,
      "chapter": 24,
      "length_in_seconds": 353,
      "length": "5:53",
      "file_name": "B03___24_Luke________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B03___24_Luke________ENGESVN2DA.mp3"
    },
    {
      "book": "John",
      "number": 43,
      "chapter": 1,
      "length_in_seconds": 368,
      "length": "6:08",
      "file_name": "B04___01_John________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B04___01_John________ENGESVN2DA.mp3"
    },
    {
      "book": "John",
      "number": 43,
      "chapter": 2,
      "length_in_seconds": 177,
      "length": "2:57",
      "file_name": "B04___02_John________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B04___02_John________ENGESVN2DA.mp3"
    },
    {
      "book": "John",
      "number": 43,
      "chapter": 3,
      "length_in_seconds": 275,
      "length": "4:35",
      "file_name": "B04___03_John________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B04___03_John________ENGESVN2DA.mp3"
    },
    {
      "book": "John",
      "number": 43,
      "chapter": 4,
      "length_in_seconds": 397,
      "length": "6:37",
      "file_name": "B04___04_John________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B04___04_John________ENGESVN2DA.mp3"
    },
    {
      "book": "John",
      "number": 43,
      "chapter": 5,
      "length_in_seconds": 351,
      "length": "5:51",
      "file_name": "B04___05_John________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B04___05_John________ENGESVN2DA.mp3"
    },
    {
      "book": "John",
      "number": 43,
      "chapter": 6,
      "length_in_seconds": 517,
      "length": "8:37",
      "file_name": "B04___06_John________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B04___06_John________ENGESVN2DA.mp3"
    },
    {
      "book": "John",
      "number": 43,
      "chapter": 7,
      "length_in_seconds": 361,
      "length": "6:01",
      "file_name": "B04___07_John________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B04___07_John________ENGESVN2DA.mp3"
    },
    {
      "book": "John",
      "number": 43,
      "chapter": 8,
      "length_in_seconds": 491,
      "length": "8:11",
      "file_name": "B04___08_John________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B04___08_John________ENGESVN2DA.mp3"
    },
    {
      "book": "John",
      "number": 43,
      "chapter": 9,
      "length_in_seconds": 315,
      "length": "5:15",
      "file_name": "B04___09_John________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B04___09_John________ENGESVN2DA.mp3"
    },
    {
      "book": "John",
      "number": 43,
      "chapter": 10,
      "length_in_seconds": 294,
      "length": "4:54",
      "file_name": "B04___10_John________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B04___10_John________ENGESVN2DA.mp3"
    },
    {
      "book": "John",
      "number": 43,
      "chapter": 11,
      "length_in_seconds": 432,
      "length": "7:12",
      "file_name": "B04___11_John________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B04___11_John________ENGESVN2DA.mp3"
    },
    {
      "book": "John",
      "number": 43,
      "chapter": 12,
      "length_in_seconds": 393,
      "length": "6:33",
      "file_name": "B04___12_John________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B04___12_John________ENGESVN2DA.mp3"
    },
    {
      "book": "John",
      "number": 43,
      "chapter": 13,
      "length_in_seconds": 313,
      "length": "5:13",
      "file_name": "B04___13_John________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B04___13_John________ENGESVN2DA.mp3"
    },
    {
      "book": "John",
      "number": 43,
      "chapter": 14,
      "length_in_seconds": 262,
      "length": "4:22",
      "file_name": "B04___14_John________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B04___14_John________ENGESVN2DA.mp3"
    },
    {
      "book": "John",
      "number": 43,
      "chapter": 15,
      "length_in_seconds": 213,
      "length": "3:33",
      "file_name": "B04___15_John________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B04___15_John________ENGESVN2DA.mp3"
    },
    {
      "book": "John",
      "number": 43,
      "chapter": 16,
      "length_in_seconds": 273,
      "length": "4:33",
      "file_name": "B04___16_John________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B04___16_John________ENGESVN2DA.mp3"
    },
    {
      "book": "John",
      "number": 43,
      "chapter": 17,
      "length_in_seconds": 208,
      "length": "3:28",
      "file_name": "B04___17_John________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B04___17_John________ENGESVN2DA.mp3"
    },
    {
      "book": "John",
      "number": 43,
      "chapter": 18,
      "length_in_seconds": 363,
      "length": "6:03",
      "file_name": "B04___18_John________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B04___18_John________ENGESVN2DA.mp3"
    },
    {
      "book": "John",
      "number": 43,
      "chapter": 19,
      "length_in_seconds": 421,
      "length": "7:01",
      "file_name": "B04___19_John________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B04___19_John________ENGESVN2DA.mp3"
    },
    {
      "book": "John",
      "number": 43,
      "chapter": 20,
      "length_in_seconds": 279,
      "length": "4:39",
      "file_name": "B04___20_John________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B04___20_John________ENGESVN2DA.mp3"
    },
    {
      "book": "John",
      "number": 43,
      "chapter": 21,
      "length_in_seconds": 276,
      "length": "4:36",
      "file_name": "B04___21_John________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B04___21_John________ENGESVN2DA.mp3"
    },
    {
      "book": "Acts",
      "number": 44,
      "chapter": 1,
      "length_in_seconds": 225,
      "length": "3:45",
      "file_name": "B05___01_Acts________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B05___01_Acts________ENGESVN2DA.mp3"
    },
    {
      "book": "Acts",
      "number": 44,
      "chapter": 2,
      "length_in_seconds": 386,
      "length": "6:26",
      "file_name": "B05___02_Acts________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B05___02_Acts________ENGESVN2DA.mp3"
    },
    {
      "book": "Acts",
      "number": 44,
      "chapter": 3,
      "length_in_seconds": 226,
      "length": "3:46",
      "file_name": "B05___03_Acts________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B05___03_Acts________ENGESVN2DA.mp3"
    },
    {
      "book": "Acts",
      "number": 44,
      "chapter": 4,
      "length_in_seconds": 302,
      "length": "5:02",
      "file_name": "B05___04_Acts________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B05___04_Acts________ENGESVN2DA.mp3"
    },
    {
      "book": "Acts",
      "number": 44,
      "chapter": 5,
      "length_in_seconds": 360,
      "length": "6:00",
      "file_name": "B05___05_Acts________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B05___05_Acts________ENGESVN2DA.mp3"
    },
    {
      "book": "Acts",
      "number": 44,
      "chapter": 6,
      "length_in_seconds": 134,
      "length": "2:14",
      "file_name": "B05___06_Acts________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B05___06_Acts________ENGESVN2DA.mp3"
    },
    {
      "book": "Acts",
      "number": 44,
      "chapter": 7,
      "length_in_seconds": 474,
      "length": "7:54",
      "file_name": "B05___07_Acts________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B05___07_Acts________ENGESVN2DA.mp3"
    },
    {
      "book": "Acts",
      "number": 44,
      "chapter": 8,
      "length_in_seconds": 320,
      "length": "5:20",
      "file_name": "B05___08_Acts________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B05___08_Acts________ENGESVN2DA.mp3"
    },
    {
      "book": "Acts",
      "number": 44,
      "chapter": 9,
      "length_in_seconds": 358,
      "length": "5:58",
      "file_name": "B05___09_Acts________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B05___09_Acts________ENGESVN2DA.mp3"
    },
    {
      "book": "Acts",
      "number": 44,
      "chapter": 10,
      "length_in_seconds": 392,
      "length": "6:32",
      "file_name": "B05___10_Acts________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B05___10_Acts________ENGESVN2DA.mp3"
    },
    {
      "book": "Acts",
      "number": 44,
      "chapter": 11,
      "length_in_seconds": 237,
      "length": "3:57",
      "file_name": "B05___11_Acts________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B05___11_Acts________ENGESVN2DA.mp3"
    },
    {
      "book": "Acts",
      "number": 44,
      "chapter": 12,
      "length_in_seconds": 235,
      "length": "3:55",
      "file_name": "B05___12_Acts________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B05___12_Acts________ENGESVN2DA.mp3"
    },
    {
      "book": "Acts",
      "number": 44,
      "chapter": 13,
      "length_in_seconds": 475,
      "length": "7:55",
      "file_name": "B05___13_Acts________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B05___13_Acts________ENGESVN2DA.mp3"
    },
    {
      "book": "Acts",
      "number": 44,
      "chapter": 14,
      "length_in_seconds": 225,
      "length": "3:45",
      "file_name": "B05___14_Acts________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B05___14_Acts________ENGESVN2DA.mp3"
    },
    {
      "book": "Acts",
      "number": 44,
      "chapter": 15,
      "length_in_seconds": 349,
      "length": "5:49",
      "file_name": "B05___15_Acts________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B05___15_Acts________ENGESVN2DA.mp3"
    },
    {
      "book": "Acts",
      "number": 44,
      "chapter": 16,
      "length_in_seconds": 340,
      "length": "5:40",
      "file_name": "B05___16_Acts________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B05___16_Acts________ENGESVN2DA.mp3"
    },
    {
      "book": "Acts",
      "number": 44,
      "chapter": 17,
      "length_in_seconds": 328,
      "length": "5:28",
      "file_name": "B05___17_Acts________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B05___17_Acts________ENGESVN2DA.mp3"
    },
    {
      "book": "Acts",
      "number": 44,
      "chapter": 18,
      "length_in_seconds": 236,
      "length": "3:56",
      "file_name": "B05___18_Acts________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B05___18_Acts________ENGESVN2DA.mp3"
    },
    {
      "book": "Acts",
      "number": 44,
      "chapter": 19,
      "length_in_seconds": 362,
      "length": "6:02",
      "file_name": "B05___19_Acts________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B05___19_Acts________ENGESVN2DA.mp3"
    },
    {
      "book": "Acts",
      "number": 44,
      "chapter": 20,
      "length_in_seconds": 320,
      "length": "5:20",
      "file_name": "B05___20_Acts________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B05___20_Acts________ENGESVN2DA.mp3"
    },
    {
      "book": "Acts",
      "number": 44,
      "chapter": 21,
      "length_in_seconds": 353,
      "length": "5:53",
      "file_name": "B05___21_Acts________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B05___21_Acts________ENGESVN2DA.mp3"
    },
    {
      "book": "Acts",
      "number": 44,
      "chapter": 22,
      "length_in_seconds": 290,
      "length": "4:50",
      "file_name": "B05___22_Acts________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B05___22_Acts________ENGESVN2DA.mp3"
    },
    {
      "book": "Acts",
      "number": 44,
      "chapter": 23,
      "length_in_seconds": 294,
      "length": "4:54",
      "file_name": "B05___23_Acts________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B05___23_Acts________ENGESVN2DA.mp3"
    },
    {
      "book": "Acts",
      "number": 44,
      "chapter": 24,
      "length_in_seconds": 227,
      "length": "3:47",
      "file_name": "B05___24_Acts________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B05___24_Acts________ENGESVN2DA.mp3"
    },
    {
      "book": "Acts",
      "number": 44,
      "chapter": 25,
      "length_in_seconds": 261,
      "length": "4:21",
      "file_name": "B05___25_Acts________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B05___25_Acts________ENGESVN2DA.mp3"
    },
    {
      "book": "Acts",
      "number": 44,
      "chapter": 26,
      "length_in_seconds": 322,
      "length": "5:22",
      "file_name": "B05___26_Acts________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B05___26_Acts________ENGESVN2DA.mp3"
    },
    {
      "book": "Acts",
      "number": 44,
      "chapter": 27,
      "length_in_seconds": 351,
      "length": "5:51",
      "file_name": "B05___27_Acts________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B05___27_Acts________ENGESVN2DA.mp3"
    },
    {
      "book": "Acts",
      "number": 44,
      "chapter": 28,
      "length_in_seconds": 278,
      "length": "4:38",
      "file_name": "B05___28_Acts________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B05___28_Acts________ENGESVN2DA.mp3"
    },
    {
      "book": "Romans",
      "number": 45,
      "chapter": 1,
      "length_in_seconds": 297,
      "length": "4:57",
      "file_name": "B06___01_Romans______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B06___01_Romans______ENGESVN2DA.mp3"
    },
    {
      "book": "Romans",
      "number": 45,
      "chapter": 2,
      "length_in_seconds": 250,
      "length": "4:10",
      "file_name": "B06___02_Romans______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B06___02_Romans______ENGESVN2DA.mp3"
    },
    {
      "book": "Romans",
      "number": 45,
      "chapter": 3,
      "length_in_seconds": 247,
      "length": "4:07",
      "file_name": "B06___03_Romans______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B06___03_Romans______ENGESVN2DA.mp3"
    },
    {
      "book": "Romans",
      "number": 45,
      "chapter": 4,
      "length_in_seconds": 234,
      "length": "3:54",
      "file_name": "B06___04_Romans______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B06___04_Romans______ENGESVN2DA.mp3"
    },
    {
      "book": "Romans",
      "number": 45,
      "chapter": 5,
      "length_in_seconds": 221,
      "length": "3:41",
      "file_name": "B06___05_Romans______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B06___05_Romans______ENGESVN2DA.mp3"
    },
    {
      "book": "Romans",
      "number": 45,
      "chapter": 6,
      "length_in_seconds": 209,
      "length": "3:29",
      "file_name": "B06___06_Romans______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B06___06_Romans______ENGESVN2DA.mp3"
    },
    {
      "book": "Romans",
      "number": 45,
      "chapter": 7,
      "length_in_seconds": 241,
      "length": "4:01",
      "file_name": "B06___07_Romans______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B06___07_Romans______ENGESVN2DA.mp3"
    },
    {
      "book": "Romans",
      "number": 45,
      "chapter": 8,
      "length_in_seconds": 365,
      "length": "6:05",
      "file_name": "B06___08_Romans______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B06___08_Romans______ENGESVN2DA.mp3"
    },
    {
      "book": "Romans",
      "number": 45,
      "chapter": 9,
      "length_in_seconds": 298,
      "length": "4:58",
      "file_name": "B06___09_Romans______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B06___09_Romans______ENGESVN2DA.mp3"
    },
    {
      "book": "Romans",
      "number": 45,
      "chapter": 10,
      "length_in_seconds": 194,
      "length": "3:14",
      "file_name": "B06___10_Romans______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B06___10_Romans______ENGESVN2DA.mp3"
    },
    {
      "book": "Romans",
      "number": 45,
      "chapter": 11,
      "length_in_seconds": 321,
      "length": "5:21",
      "file_name": "B06___11_Romans______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B06___11_Romans______ENGESVN2DA.mp3"
    },
    {
      "book": "Romans",
      "number": 45,
      "chapter": 12,
      "length_in_seconds": 180,
      "length": "3:00",
      "file_name": "B06___12_Romans______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B06___12_Romans______ENGESVN2DA.mp3"
    },
    {
      "book": "Romans",
      "number": 45,
      "chapter": 13,
      "length_in_seconds": 146,
      "length": "2:26",
      "file_name": "B06___13_Romans______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B06___13_Romans______ENGESVN2DA.mp3"
    },
    {
      "book": "Romans",
      "number": 45,
      "chapter": 14,
      "length_in_seconds": 203,
      "length": "3:23",
      "file_name": "B06___14_Romans______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B06___14_Romans______ENGESVN2DA.mp3"
    },
    {
      "book": "Romans",
      "number": 45,
      "chapter": 15,
      "length_in_seconds": 279,
      "length": "4:39",
      "file_name": "B06___15_Romans______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B06___15_Romans______ENGESVN2DA.mp3"
    },
    {
      "book": "Romans",
      "number": 45,
      "chapter": 16,
      "length_in_seconds": 208,
      "length": "3:28",
      "file_name": "B06___16_Romans______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B06___16_Romans______ENGESVN2DA.mp3"
    },
    {
      "book": "1 Corinthians",
      "number": 46,
      "chapter": 1,
      "length_in_seconds": 229,
      "length": "3:49",
      "file_name": "B07___01_1CorinthiansENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B07___01_1CorinthiansENGESVN2DA.mp3"
    },
    {
      "book": "1 Corinthians",
      "number": 46,
      "chapter": 2,
      "length_in_seconds": 121,
      "length": "2:01",
      "file_name": "B07___02_1CorinthiansENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B07___02_1CorinthiansENGESVN2DA.mp3"
    },
    {
      "book": "1 Corinthians",
      "number": 46,
      "chapter": 3,
      "length_in_seconds": 176,
      "length": "2:56",
      "file_name": "B07___03_1CorinthiansENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B07___03_1CorinthiansENGESVN2DA.mp3"
    },
    {
      "book": "1 Corinthians",
      "number": 46,
      "chapter": 4,
      "length_in_seconds": 186,
      "length": "3:06",
      "file_name": "B07___04_1CorinthiansENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B07___04_1CorinthiansENGESVN2DA.mp3"
    },
    {
      "book": "1 Corinthians",
      "number": 46,
      "chapter": 5,
      "length_in_seconds": 120,
      "length": "2:00",
      "file_name": "B07___05_1CorinthiansENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B07___05_1CorinthiansENGESVN2DA.mp3"
    },
    {
      "book": "1 Corinthians",
      "number": 46,
      "chapter": 6,
      "length_in_seconds": 182,
      "length": "3:02",
      "file_name": "B07___06_1CorinthiansENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B07___06_1CorinthiansENGESVN2DA.mp3"
    },
    {
      "book": "1 Corinthians",
      "number": 46,
      "chapter": 7,
      "length_in_seconds": 360,
      "length": "6:00",
      "file_name": "B07___07_1CorinthiansENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B07___07_1CorinthiansENGESVN2DA.mp3"
    },
    {
      "book": "1 Corinthians",
      "number": 46,
      "chapter": 8,
      "length_in_seconds": 110,
      "length": "1:50",
      "file_name": "B07___08_1CorinthiansENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B07___08_1CorinthiansENGESVN2DA.mp3"
    },
    {
      "book": "1 Corinthians",
      "number": 46,
      "chapter": 9,
      "length_in_seconds": 234,
      "length": "3:54",
      "file_name": "B07___09_1CorinthiansENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B07___09_1CorinthiansENGESVN2DA.mp3"
    },
    {
      "book": "1 Corinthians",
      "number": 46,
      "chapter": 10,
      "length_in_seconds": 227,
      "length": "3:47",
      "file_name": "B07___10_1CorinthiansENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B07___10_1CorinthiansENGESVN2DA.mp3"
    },
    {
      "book": "1 Corinthians",
      "number": 46,
      "chapter": 11,
      "length_in_seconds": 247,
      "length": "4:07",
      "file_name": "B07___11_1CorinthiansENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B07___11_1CorinthiansENGESVN2DA.mp3"
    },
    {
      "book": "1 Corinthians",
      "number": 46,
      "chapter": 12,
      "length_in_seconds": 225,
      "length": "3:45",
      "file_name": "B07___12_1CorinthiansENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B07___12_1CorinthiansENGESVN2DA.mp3"
    },
    {
      "book": "1 Corinthians",
      "number": 46,
      "chapter": 13,
      "length_in_seconds": 119,
      "length": "1:59",
      "file_name": "B07___13_1CorinthiansENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B07___13_1CorinthiansENGESVN2DA.mp3"
    },
    {
      "book": "1 Corinthians",
      "number": 46,
      "chapter": 14,
      "length_in_seconds": 315,
      "length": "5:15",
      "file_name": "B07___14_1CorinthiansENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B07___14_1CorinthiansENGESVN2DA.mp3"
    },
    {
      "book": "1 Corinthians",
      "number": 46,
      "chapter": 15,
      "length_in_seconds": 436,
      "length": "7:16",
      "file_name": "B07___15_1CorinthiansENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B07___15_1CorinthiansENGESVN2DA.mp3"
    },
    {
      "book": "1 Corinthians",
      "number": 46,
      "chapter": 16,
      "length_in_seconds": 160,
      "length": "2:40",
      "file_name": "B07___16_1CorinthiansENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B07___16_1CorinthiansENGESVN2DA.mp3"
    },
    {
      "book": "2 Corinthians",
      "number": 47,
      "chapter": 1,
      "length_in_seconds": 213,
      "length": "3:33",
      "file_name": "B08___01_2CorinthiansENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B08___01_2CorinthiansENGESVN2DA.mp3"
    },
    {
      "book": "2 Corinthians",
      "number": 47,
      "chapter": 2,
      "length_in_seconds": 130,
      "length": "2:10",
      "file_name": "B08___02_2CorinthiansENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B08___02_2CorinthiansENGESVN2DA.mp3"
    },
    {
      "book": "2 Corinthians",
      "number": 47,
      "chapter": 3,
      "length_in_seconds": 147,
      "length": "2:27",
      "file_name": "B08___03_2CorinthiansENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B08___03_2CorinthiansENGESVN2DA.mp3"
    },
    {
      "book": "2 Corinthians",
      "number": 47,
      "chapter": 4,
      "length_in_seconds": 163,
      "length": "2:43",
      "file_name": "B08___04_2CorinthiansENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B08___04_2CorinthiansENGESVN2DA.mp3"
    },
    {
      "book": "2 Corinthians",
      "number": 47,
      "chapter": 5,
      "length_in_seconds": 185,
      "length": "3:05",
      "file_name": "B08___05_2CorinthiansENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B08___05_2CorinthiansENGESVN2DA.mp3"
    },
    {
      "book": "2 Corinthians",
      "number": 47,
      "chapter": 6,
      "length_in_seconds": 160,
      "length": "2:40",
      "file_name": "B08___06_2CorinthiansENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B08___06_2CorinthiansENGESVN2DA.mp3"
    },
    {
      "book": "2 Corinthians",
      "number": 47,
      "chapter": 7,
      "length_in_seconds": 170,
      "length": "2:50",
      "file_name": "B08___07_2CorinthiansENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B08___07_2CorinthiansENGESVN2DA.mp3"
    },
    {
      "book": "2 Corinthians",
      "number": 47,
      "chapter": 8,
      "length_in_seconds": 198,
      "length": "3:18",
      "file_name": "B08___08_2CorinthiansENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B08___08_2CorinthiansENGESVN2DA.mp3"
    },
    {
      "book": "2 Corinthians",
      "number": 47,
      "chapter": 9,
      "length_in_seconds": 136,
      "length": "2:16",
      "file_name": "B08___09_2CorinthiansENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B08___09_2CorinthiansENGESVN2DA.mp3"
    },
    {
      "book": "2 Corinthians",
      "number": 47,
      "chapter": 10,
      "length_in_seconds": 155,
      "length": "2:35",
      "file_name": "B08___10_2CorinthiansENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B08___10_2CorinthiansENGESVN2DA.mp3"
    },
    {
      "book": "2 Corinthians",
      "number": 47,
      "chapter": 11,
      "length_in_seconds": 273,
      "length": "4:33",
      "file_name": "B08___11_2CorinthiansENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B08___11_2CorinthiansENGESVN2DA.mp3"
    },
    {
      "book": "2 Corinthians",
      "number": 47,
      "chapter": 12,
      "length_in_seconds": 221,
      "length": "3:41",
      "file_name": "B08___12_2CorinthiansENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B08___12_2CorinthiansENGESVN2DA.mp3"
    },
    {
      "book": "2 Corinthians",
      "number": 47,
      "chapter": 13,
      "length_in_seconds": 129,
      "length": "2:09",
      "file_name": "B08___13_2CorinthiansENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B08___13_2CorinthiansENGESVN2DA.mp3"
    },
    {
      "book": "Galatians",
      "number": 48,
      "chapter": 1,
      "length_in_seconds": 183,
      "length": "3:03",
      "file_name": "B09___01_Galatians___ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B09___01_Galatians___ENGESVN2DA.mp3"
    },
    {
      "book": "Galatians",
      "number": 48,
      "chapter": 2,
      "length_in_seconds": 209,
      "length": "3:29",
      "file_name": "B09___02_Galatians___ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B09___02_Galatians___ENGESVN2DA.mp3"
    },
    {
      "book": "Galatians",
      "number": 48,
      "chapter": 3,
      "length_in_seconds": 263,
      "length": "4:23",
      "file_name": "B09___03_Galatians___ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B09___03_Galatians___ENGESVN2DA.mp3"
    },
    {
      "book": "Galatians",
      "number": 48,
      "chapter": 4,
      "length_in_seconds": 260,
      "length": "4:20",
      "file_name": "B09___04_Galatians___ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B09___04_Galatians___ENGESVN2DA.mp3"
    },
    {
      "book": "Galatians",
      "number": 48,
      "chapter": 5,
      "length_in_seconds": 225,
      "length": "3:45",
      "file_name": "B09___05_Galatians___ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B09___05_Galatians___ENGESVN2DA.mp3"
    },
    {
      "book": "Galatians",
      "number": 48,
      "chapter": 6,
      "length_in_seconds": 161,
      "length": "2:41",
      "file_name": "B09___06_Galatians___ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B09___06_Galatians___ENGESVN2DA.mp3"
    },
    {
      "book": "Ephesians",
      "number": 49,
      "chapter": 1,
      "length_in_seconds": 182,
      "length": "3:02",
      "file_name": "B10___01_Ephesians___ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B10___01_Ephesians___ENGESVN2DA.mp3"
    },
    {
      "book": "Ephesians",
      "number": 49,
      "chapter": 2,
      "length_in_seconds": 184,
      "length": "3:04",
      "file_name": "B10___02_Ephesians___ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B10___02_Ephesians___ENGESVN2DA.mp3"
    },
    {
      "book": "Ephesians",
      "number": 49,
      "chapter": 3,
      "length_in_seconds": 160,
      "length": "2:40",
      "file_name": "B10___03_Ephesians___ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B10___03_Ephesians___ENGESVN2DA.mp3"
    },
    {
      "book": "Ephesians",
      "number": 49,
      "chapter": 4,
      "length_in_seconds": 261,
      "length": "4:21",
      "file_name": "B10___04_Ephesians___ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B10___04_Ephesians___ENGESVN2DA.mp3"
    },
    {
      "book": "Ephesians",
      "number": 49,
      "chapter": 5,
      "length_in_seconds": 234,
      "length": "3:54",
      "file_name": "B10___05_Ephesians___ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B10___05_Ephesians___ENGESVN2DA.mp3"
    },
    {
      "book": "Ephesians",
      "number": 49,
      "chapter": 6,
      "length_in_seconds": 206,
      "length": "3:26",
      "file_name": "B10___06_Ephesians___ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B10___06_Ephesians___ENGESVN2DA.mp3"
    },
    {
      "book": "Philippians",
      "number": 50,
      "chapter": 1,
      "length_in_seconds": 237,
      "length": "3:57",
      "file_name": "B11___01_Philippians_ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B11___01_Philippians_ENGESVN2DA.mp3"
    },
    {
      "book": "Philippians",
      "number": 50,
      "chapter": 2,
      "length_in_seconds": 234,
      "length": "3:54",
      "file_name": "B11___02_Philippians_ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B11___02_Philippians_ENGESVN2DA.mp3"
    },
    {
      "book": "Philippians",
      "number": 50,
      "chapter": 3,
      "length_in_seconds": 185,
      "length": "3:05",
      "file_name": "B11___03_Philippians_ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B11___03_Philippians_ENGESVN2DA.mp3"
    },
    {
      "book": "Philippians",
      "number": 50,
      "chapter": 4,
      "length_in_seconds": 194,
      "length": "3:14",
      "file_name": "B11___04_Philippians_ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B11___04_Philippians_ENGESVN2DA.mp3"
    },
    {
      "book": "Colossians",
      "number": 51,
      "chapter": 1,
      "length_in_seconds": 237,
      "length": "3:57",
      "file_name": "B12___01_Colossians__ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B12___01_Colossians__ENGESVN2DA.mp3"
    },
    {
      "book": "Colossians",
      "number": 51,
      "chapter": 2,
      "length_in_seconds": 201,
      "length": "3:21",
      "file_name": "B12___02_Colossians__ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B12___02_Colossians__ENGESVN2DA.mp3"
    },
    {
      "book": "Colossians",
      "number": 51,
      "chapter": 3,
      "length_in_seconds": 197,
      "length": "3:17",
      "file_name": "B12___03_Colossians__ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B12___03_Colossians__ENGESVN2DA.mp3"
    },
    {
      "book": "Colossians",
      "number": 51,
      "chapter": 4,
      "length_in_seconds": 151,
      "length": "2:31",
      "file_name": "B12___04_Colossians__ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B12___04_Colossians__ENGESVN2DA.mp3"
    },
    {
      "book": "1 Thessalonians",
      "number": 52,
      "chapter": 1,
      "length_in_seconds": 90,
      "length": "1:30",
      "file_name": "B13___01_1Thess______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B13___01_1Thess______ENGESVN2DA.mp3"
    },
    {
      "book": "1 Thessalonians",
      "number": 52,
      "chapter": 2,
      "length_in_seconds": 168,
      "length": "2:48",
      "file_name": "B13___02_1Thess______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B13___02_1Thess______ENGESVN2DA.mp3"
    },
    {
      "book": "1 Thessalonians",
      "number": 52,
      "chapter": 3,
      "length_in_seconds": 101,
      "length": "1:41",
      "file_name": "B13___03_1Thess______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B13___03_1Thess______ENGESVN2DA.mp3"
    },
    {
      "book": "1 Thessalonians",
      "number": 52,
      "chapter": 4,
      "length_in_seconds": 148,
      "length": "2:28",
      "file_name": "B13___04_1Thess______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B13___04_1Thess______ENGESVN2DA.mp3"
    },
    {
      "book": "1 Thessalonians",
      "number": 52,
      "chapter": 5,
      "length_in_seconds": 170,
      "length": "2:50",
      "file_name": "B13___05_1Thess______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B13___05_1Thess______ENGESVN2DA.mp3"
    },
    {
      "book": "2 Thessalonians",
      "number": 53,
      "chapter": 1,
      "length_in_seconds": 109,
      "length": "1:49",
      "file_name": "B14___01_2Thess______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B14___01_2Thess______ENGESVN2DA.mp3"
    },
    {
      "book": "2 Thessalonians",
      "number": 53,
      "chapter": 2,
      "length_in_seconds": 151,
      "length": "2:31",
      "file_name": "B14___02_2Thess______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B14___02_2Thess______ENGESVN2DA.mp3"
    },
    {
      "book": "2 Thessalonians",
      "number": 53,
      "chapter": 3,
      "length_in_seconds": 135,
      "length": "2:15",
      "file_name": "B14___03_2Thess______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B14___03_2Thess______ENGESVN2DA.mp3"
    },
    {
      "book": "1 Timothy",
      "number": 54,
      "chapter": 1,
      "length_in_seconds": 189,
      "length": "3:09",
      "file_name": "B15___01_1Timothy____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B15___01_1Timothy____ENGESVN2DA.mp3"
    },
    {
      "book": "1 Timothy",
      "number": 54,
      "chapter": 2,
      "length_in_seconds": 114,
      "length": "1:54",
      "file_name": "B15___02_1Timothy____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B15___02_1Timothy____ENGESVN2DA.mp3"
    },
    {
      "book": "1 Timothy",
      "number": 54,
      "chapter": 3,
      "length_in_seconds": 141,
      "length": "2:21",
      "file_name": "B15___03_1Timothy____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B15___03_1Timothy____ENGESVN2DA.mp3"
    },
    {
      "book": "1 Timothy",
      "number": 54,
      "chapter": 4,
      "length_in_seconds": 136,
      "length": "2:16",
      "file_name": "B15___04_1Timothy____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B15___04_1Timothy____ENGESVN2DA.mp3"
    },
    {
      "book": "1 Timothy",
      "number": 54,
      "chapter": 5,
      "length_in_seconds": 213,
      "length": "3:33",
      "file_name": "B15___05_1Timothy____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B15___05_1Timothy____ENGESVN2DA.mp3"
    },
    {
      "book": "1 Timothy",
      "number": 54,
      "chapter": 6,
      "length_in_seconds": 224,
      "length": "3:44",
      "file_name": "B15___06_1Timothy____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B15___06_1Timothy____ENGESVN2DA.mp3"
    },
    {
      "book": "2 Timothy",
      "number": 55,
      "chapter": 1,
      "length_in_seconds": 162,
      "length": "2:42",
      "file_name": "B16___01_2Timothy____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B16___01_2Timothy____ENGESVN2DA.mp3"
    },
    {
      "book": "2 Timothy",
      "number": 55,
      "chapter": 2,
      "length_in_seconds": 215,
      "length": "3:35",
      "file_name": "B16___02_2Timothy____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B16___02_2Timothy____ENGESVN2DA.mp3"
    },
    {
      "book": "2 Timothy",
      "number": 55,
      "chapter": 3,
      "length_in_seconds": 150,
      "length": "2:30",
      "file_name": "B16___03_2Timothy____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B16___03_2Timothy____ENGESVN2DA.mp3"
    },
    {
      "book": "2 Timothy",
      "number": 55,
      "chapter": 4,
      "length_in_seconds": 181,
      "length": "3:01",
      "file_name": "B16___04_2Timothy____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B16___04_2Timothy____ENGESVN2DA.mp3"
    },
    {
      "book": "Titus",
      "number": 56,
      "chapter": 1,
      "length_in_seconds": 138,
      "length": "2:18",
      "file_name": "B17___01_Titus_______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B17___01_Titus_______ENGESVN2DA.mp3"
    },
    {
      "book": "Titus",
      "number": 56,
      "chapter": 2,
      "length_in_seconds": 116,
      "length": "1:56",
      "file_name": "B17___02_Titus_______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B17___02_Titus_______ENGESVN2DA.mp3"
    },
    {
      "book": "Titus",
      "number": 56,
      "chapter": 3,
      "length_in_seconds": 137,
      "length": "2:17",
      "file_name": "B17___03_Titus_______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B17___03_Titus_______ENGESVN2DA.mp3"
    },
    {
      "book": "Philemon",
      "number": 57,
      "chapter": 1,
      "length_in_seconds": 187,
      "length": "3:07",
      "file_name": "B18___01_Philemon____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B18___01_Philemon____ENGESVN2DA.mp3"
    },
    {
      "book": "Hebrews",
      "number": 58,
      "chapter": 1,
      "length_in_seconds": 141,
      "length": "2:21",
      "file_name": "B19___01_Hebrews_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B19___01_Hebrews_____ENGESVN2DA.mp3"
    },
    {
      "book": "Hebrews",
      "number": 58,
      "chapter": 2,
      "length_in_seconds": 192,
      "length": "3:12",
      "file_name": "B19___02_Hebrews_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B19___02_Hebrews_____ENGESVN2DA.mp3"
    },
    {
      "book": "Hebrews",
      "number": 58,
      "chapter": 3,
      "length_in_seconds": 154,
      "length": "2:34",
      "file_name": "B19___03_Hebrews_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B19___03_Hebrews_____ENGESVN2DA.mp3"
    },
    {
      "book": "Hebrews",
      "number": 58,
      "chapter": 4,
      "length_in_seconds": 169,
      "length": "2:49",
      "file_name": "B19___04_Hebrews_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B19___04_Hebrews_____ENGESVN2DA.mp3"
    },
    {
      "book": "Hebrews",
      "number": 58,
      "chapter": 5,
      "length_in_seconds": 137,
      "length": "2:17",
      "file_name": "B19___05_Hebrews_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B19___05_Hebrews_____ENGESVN2DA.mp3"
    },
    {
      "book": "Hebrews",
      "number": 58,
      "chapter": 6,
      "length_in_seconds": 190,
      "length": "3:10",
      "file_name": "B19___06_Hebrews_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B19___06_Hebrews_____ENGESVN2DA.mp3"
    },
    {
      "book": "Hebrews",
      "number": 58,
      "chapter": 7,
      "length_in_seconds": 276,
      "length": "4:36",
      "file_name": "B19___07_Hebrews_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B19___07_Hebrews_____ENGESVN2DA.mp3"
    },
    {
      "book": "Hebrews",
      "number": 58,
      "chapter": 8,
      "length_in_seconds": 153,
      "length": "2:33",
      "file_name": "B19___08_Hebrews_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B19___08_Hebrews_____ENGESVN2DA.mp3"
    },
    {
      "book": "Hebrews",
      "number": 58,
      "chapter": 9,
      "length_in_seconds": 313,
      "length": "5:13",
      "file_name": "B19___09_Hebrews_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B19___09_Hebrews_____ENGESVN2DA.mp3"
    },
    {
      "book": "Hebrews",
      "number": 58,
      "chapter": 10,
      "length_in_seconds": 350,
      "length": "5:50",
      "file_name": "B19___10_Hebrews_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B19___10_Hebrews_____ENGESVN2DA.mp3"
    },
    {
      "book": "Hebrews",
      "number": 58,
      "chapter": 11,
      "length_in_seconds": 404,
      "length": "6:44",
      "file_name": "B19___11_Hebrews_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B19___11_Hebrews_____ENGESVN2DA.mp3"
    },
    {
      "book": "Hebrews",
      "number": 58,
      "chapter": 12,
      "length_in_seconds": 308,
      "length": "5:08",
      "file_name": "B19___12_Hebrews_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B19___12_Hebrews_____ENGESVN2DA.mp3"
    },
    {
      "book": "Hebrews",
      "number": 58,
      "chapter": 13,
      "length_in_seconds": 238,
      "length": "3:58",
      "file_name": "B19___13_Hebrews_____ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B19___13_Hebrews_____ENGESVN2DA.mp3"
    },
    {
      "book": "James",
      "number": 59,
      "chapter": 1,
      "length_in_seconds": 217,
      "length": "3:37",
      "file_name": "B20___01_James_______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B20___01_James_______ENGESVN2DA.mp3"
    },
    {
      "book": "James",
      "number": 59,
      "chapter": 2,
      "length_in_seconds": 223,
      "length": "3:43",
      "file_name": "B20___02_James_______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B20___02_James_______ENGESVN2DA.mp3"
    },
    {
      "book": "James",
      "number": 59,
      "chapter": 3,
      "length_in_seconds": 166,
      "length": "2:46",
      "file_name": "B20___03_James_______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B20___03_James_______ENGESVN2DA.mp3"
    },
    {
      "book": "James",
      "number": 59,
      "chapter": 4,
      "length_in_seconds": 164,
      "length": "2:44",
      "file_name": "B20___04_James_______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B20___04_James_______ENGESVN2DA.mp3"
    },
    {
      "book": "James",
      "number": 59,
      "chapter": 5,
      "length_in_seconds": 199,
      "length": "3:19",
      "file_name": "B20___05_James_______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B20___05_James_______ENGESVN2DA.mp3"
    },
    {
      "book": "1 Peter",
      "number": 60,
      "chapter": 1,
      "length_in_seconds": 238,
      "length": "3:58",
      "file_name": "B21___01_1Peter______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B21___01_1Peter______ENGESVN2DA.mp3"
    },
    {
      "book": "1 Peter",
      "number": 60,
      "chapter": 2,
      "length_in_seconds": 230,
      "length": "3:50",
      "file_name": "B21___02_1Peter______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B21___02_1Peter______ENGESVN2DA.mp3"
    },
    {
      "book": "1 Peter",
      "number": 60,
      "chapter": 3,
      "length_in_seconds": 209,
      "length": "3:29",
      "file_name": "B21___03_1Peter______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B21___03_1Peter______ENGESVN2DA.mp3"
    },
    {
      "book": "1 Peter",
      "number": 60,
      "chapter": 4,
      "length_in_seconds": 176,
      "length": "2:56",
      "file_name": "B21___04_1Peter______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B21___04_1Peter______ENGESVN2DA.mp3"
    },
    {
      "book": "1 Peter",
      "number": 60,
      "chapter": 5,
      "length_in_seconds": 137,
      "length": "2:17",
      "file_name": "B21___05_1Peter______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B21___05_1Peter______ENGESVN2DA.mp3"
    },
    {
      "book": "2 Peter",
      "number": 61,
      "chapter": 1,
      "length_in_seconds": 203,
      "length": "3:23",
      "file_name": "B22___01_2Peter______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B22___01_2Peter______ENGESVN2DA.mp3"
    },
    {
      "book": "2 Peter",
      "number": 61,
      "chapter": 2,
      "length_in_seconds": 238,
      "length": "3:58",
      "file_name": "B22___02_2Peter______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B22___02_2Peter______ENGESVN2DA.mp3"
    },
    {
      "book": "2 Peter",
      "number": 61,
      "chapter": 3,
      "length_in_seconds": 191,
      "length": "3:11",
      "file_name": "B22___03_2Peter______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B22___03_2Peter______ENGESVN2DA.mp3"
    },
    {
      "book": "1 John",
      "number": 62,
      "chapter": 1,
      "length_in_seconds": 98,
      "length": "1:38",
      "file_name": "B23___01_1John_______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B23___01_1John_______ENGESVN2DA.mp3"
    },
    {
      "book": "1 John",
      "number": 62,
      "chapter": 2,
      "length_in_seconds": 270,
      "length": "4:30",
      "file_name": "B23___02_1John_______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B23___02_1John_______ENGESVN2DA.mp3"
    },
    {
      "book": "1 John",
      "number": 62,
      "chapter": 3,
      "length_in_seconds": 209,
      "length": "3:29",
      "file_name": "B23___03_1John_______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B23___03_1John_______ENGESVN2DA.mp3"
    },
    {
      "book": "1 John",
      "number": 62,
      "chapter": 4,
      "length_in_seconds": 189,
      "length": "3:09",
      "file_name": "B23___04_1John_______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B23___04_1John_______ENGESVN2DA.mp3"
    },
    {
      "book": "1 John",
      "number": 62,
      "chapter": 5,
      "length_in_seconds": 190,
      "length": "3:10",
      "file_name": "B23___05_1John_______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B23___05_1John_______ENGESVN2DA.mp3"
    },
    {
      "book": "2 John",
      "number": 63,
      "chapter": 1,
      "length_in_seconds": 110,
      "length": "1:50",
      "file_name": "B24___01_2John_______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B24___01_2John_______ENGESVN2DA.mp3"
    },
    {
      "book": "3 John",
      "number": 64,
      "chapter": 1,
      "length_in_seconds": 123,
      "length": "2:03",
      "file_name": "B25___01_3John_______ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B25___01_3John_______ENGESVN2DA.mp3"
    },
    {
      "book": "Jude",
      "number": 65,
      "chapter": 1,
      "length_in_seconds": 244,
      "length": "4:04",
      "file_name": "B26___01_Jude________ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B26___01_Jude________ENGESVN2DA.mp3"
    },
    {
      "book": "Revelation",
      "number": 66,
      "chapter": 1,
      "length_in_seconds": 220,
      "length": "3:40",
      "file_name": "B27___01_Revelation__ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B27___01_Revelation__ENGESVN2DA.mp3"
    },
    {
      "book": "Revelation",
      "number": 66,
      "chapter": 2,
      "length_in_seconds": 316,
      "length": "5:16",
      "file_name": "B27___02_Revelation__ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B27___02_Revelation__ENGESVN2DA.mp3"
    },
    {
      "book": "Revelation",
      "number": 66,
      "chapter": 3,
      "length_in_seconds": 258,
      "length": "4:18",
      "file_name": "B27___03_Revelation__ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B27___03_Revelation__ENGESVN2DA.mp3"
    },
    {
      "book": "Revelation",
      "number": 66,
      "chapter": 4,
      "length_in_seconds": 140,
      "length": "2:20",
      "file_name": "B27___04_Revelation__ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B27___04_Revelation__ENGESVN2DA.mp3"
    },
    {
      "book": "Revelation",
      "number": 66,
      "chapter": 5,
      "length_in_seconds": 161,
      "length": "2:41",
      "file_name": "B27___05_Revelation__ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B27___05_Revelation__ENGESVN2DA.mp3"
    },
    {
      "book": "Revelation",
      "number": 66,
      "chapter": 6,
      "length_in_seconds": 194,
      "length": "3:14",
      "file_name": "B27___06_Revelation__ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B27___06_Revelation__ENGESVN2DA.mp3"
    },
    {
      "book": "Revelation",
      "number": 66,
      "chapter": 7,
      "length_in_seconds": 195,
      "length": "3:15",
      "file_name": "B27___07_Revelation__ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B27___07_Revelation__ENGESVN2DA.mp3"
    },
    {
      "book": "Revelation",
      "number": 66,
      "chapter": 8,
      "length_in_seconds": 151,
      "length": "2:31",
      "file_name": "B27___08_Revelation__ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B27___08_Revelation__ENGESVN2DA.mp3"
    },
    {
      "book": "Revelation",
      "number": 66,
      "chapter": 9,
      "length_in_seconds": 203,
      "length": "3:23",
      "file_name": "B27___09_Revelation__ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B27___09_Revelation__ENGESVN2DA.mp3"
    },
    {
      "book": "Revelation",
      "number": 66,
      "chapter": 10,
      "length_in_seconds": 123,
      "length": "2:03",
      "file_name": "B27___10_Revelation__ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B27___10_Revelation__ENGESVN2DA.mp3"
    },
    {
      "book": "Revelation",
      "number": 66,
      "chapter": 11,
      "length_in_seconds": 215,
      "length": "3:35",
      "file_name": "B27___11_Revelation__ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B27___11_Revelation__ENGESVN2DA.mp3"
    },
    {
      "book": "Revelation",
      "number": 66,
      "chapter": 12,
      "length_in_seconds": 193,
      "length": "3:13",
      "file_name": "B27___12_Revelation__ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B27___12_Revelation__ENGESVN2DA.mp3"
    },
    {
      "book": "Revelation",
      "number": 66,
      "chapter": 13,
      "length_in_seconds": 194,
      "length": "3:14",
      "file_name": "B27___13_Revelation__ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B27___13_Revelation__ENGESVN2DA.mp3"
    },
    {
      "book": "Revelation",
      "number": 66,
      "chapter": 14,
      "length_in_seconds": 254,
      "length": "4:14",
      "file_name": "B27___14_Revelation__ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B27___14_Revelation__ENGESVN2DA.mp3"
    },
    {
      "book": "Revelation",
      "number": 66,
      "chapter": 15,
      "length_in_seconds": 91,
      "length": "1:31",
      "file_name": "B27___15_Revelation__ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B27___15_Revelation__ENGESVN2DA.mp3"
    },
    {
      "book": "Revelation",
      "number": 66,
      "chapter": 16,
      "length_in_seconds": 205,
      "length": "3:25",
      "file_name": "B27___16_Revelation__ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B27___16_Revelation__ENGESVN2DA.mp3"
    },
    {
      "book": "Revelation",
      "number": 66,
      "chapter": 17,
      "length_in_seconds": 192,
      "length": "3:12",
      "file_name": "B27___17_Revelation__ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B27___17_Revelation__ENGESVN2DA.mp3"
    },
    {
      "book": "Revelation",
      "number": 66,
      "chapter": 18,
      "length_in_seconds": 347,
      "length": "5:47",
      "file_name": "B27___18_Revelation__ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B27___18_Revelation__ENGESVN2DA.mp3"
    },
    {
      "book": "Revelation",
      "number": 66,
      "chapter": 19,
      "length_in_seconds": 261,
      "length": "4:21",
      "file_name": "B27___19_Revelation__ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B27___19_Revelation__ENGESVN2DA.mp3"
    },
    {
      "book": "Revelation",
      "number": 66,
      "chapter": 20,
      "length_in_seconds": 170,
      "length": "2:50",
      "file_name": "B27___20_Revelation__ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B27___20_Revelation__ENGESVN2DA.mp3"
    },
    {
      "book": "Revelation",
      "number": 66,
      "chapter": 21,
      "length_in_seconds": 298,
      "length": "4:58",
      "file_name": "B27___21_Revelation__ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B27___21_Revelation__ENGESVN2DA.mp3"
    },
    {
      "book": "Revelation",
      "number": 66,
      "chapter": 22,
      "length_in_seconds": 281,
      "length": "4:41",
      "file_name": "B27___22_Revelation__ENGESVN2DA.mp3",
      "dam_id": "ENGESVN2DA",
      "path": "/ENGESVN2DA/B27___22_Revelation__ENGESVN2DA.mp3"
    }
  ],
  "bookNames": [
    "Genesis",
    "Exodus",
    "Leviticus",
    "Numbers",
    "Deuteronomy",
    "Joshua",
    "Judges",
    "Ruth",
    "1 Samuel",
    "2 Samuel",
    "1 Kings",
    "2 Kings",
    "1 Chronicles",
    "2 Chronicles",
    "Ezra",
    "Nehemiah",
    "Esther",
    "Job",
    "Psalms",
    "Proverbs",
    "Ecclesiastes",
    "Songof Songs",
    "Isaiah",
    "Jeremiah",
    "Lamentations",
    "Ezekiel",
    "Daniel",
    "Hosea",
    "Joel",
    "Amos",
    "Obadiah",
    "Jonah",
    "Micah",
    "Nahum",
    "Habakkuk",
    "Zephaniah",
    "Haggai",
    "Zechariah",
    "Malachi",
    "Matthew",
    "Mark",
    "Luke",
    "John",
    "Acts",
    "Romans",
    "1 Corinthians",
    "2 Corinthians",
    "Galatians",
    "Ephesians",
    "Philippians",
    "Colossians",
    "1 Thessalonians",
    "2 Thessalonians",
    "1 Timothy",
    "2 Timothy",
    "Titus",
    "Philemon",
    "Hebrews",
    "James",
    "1 Peter",
    "2 Peter",
    "1 John",
    "2 John",
    "3 John",
    "Jude",
    "Revelation"
  ],
  "bookSelectedChaptersCount": {
    "Genesis": 0,
    "Exodus": 0,
    "Leviticus": 0,
    "Numbers": 0,
    "Deuteronomy": 0,
    "Joshua": 0,
    "Judges": 0,
    "Ruth": 0,
    "1 Samuel": 0,
    "2 Samuel": 0,
    "1 Kings": 0,
    "2 Kings": 0,
    "1 Chronicles": 0,
    "2 Chronicles": 0,
    "Ezra": 0,
    "Nehemiah": 0,
    "Esther": 0,
    "Job": 0,
    "Psalms": 0,
    "Proverbs": 0,
    "Ecclesiastes": 0,
    "The Song Of Solomon": 0,
    "Isaiah": 0,
    "Jeremiah": 0,
    "Lamentations": 0,
    "Ezekiel": 0,
    "Daniel": 0,
    "Hosea": 0,
    "Joel": 0,
    "Amos": 0,
    "Obadiah": 0,
    "Jonah": 0,
    "Micah": 0,
    "Nahum": 0,
    "Habakkuk": 0,
    "Zephaniah": 0,
    "Haggai": 0,
    "Zechariah": 0,
    "Malachi": 0,
    "Matthew": 0,
    "Mark": 0,
    "Luke": 0,
    "John": 0,
    "Acts": 0,
    "Romans": 0,
    "1 Corinthians": 0,
    "2 Corinthians": 0,
    "Galations": 0,
    "Ephesians": 0,
    "Philippians": 0,
    "Colossians": 0,
    "1 Thessalonians": 0,
    "2 Thessalonians": 0,
    "1 Timothy": 0,
    "2 Timothy": 0,
    "Titus": 0,
    "Philemon": 0,
    "Hebrews": 0,
    "James": 0,
    "1 Peter": 0,
    "2 Peter": 0,
    "1 John": 0,
    "2 John": 0,
    "3 John": 0,
    "Jude": 0,
    "Revelation": 0
  },
  "selectedChapters" : []
}

export default Bible;