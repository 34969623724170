import React, { useContext } from 'react';
import { IonHeader, IonPage, IonToolbar, IonTitle, IonContent, IonFooter, IonGrid, IonRow, IonButton, IonIcon, IonCol, IonText, IonLoading } from '@ionic/react';
import { AppContext } from '../../State';
import { RouteComponentProps } from 'react-router';
import {Howl} from 'howler';
import { play, pause, skipForward, skipBackward } from 'ionicons/icons';
import { formattedTime } from '../../Helpers';
import './Player.css';

interface PlayerPageProps extends RouteComponentProps<{
  book: string;
}> {}

const baseUrl = 'https://cloud.faithcomesbyhearing.com/mp3audiobibles2';
const howls: Howl[] = [];
let currentHowlIndex = 0;
let currentHowl: Howl;
let currentInterval: NodeJS.Timeout;

const playAudio = (state: any, dispatch: any) => {
  const Bible = state.Bible;
  dispatch({
    type: 'setIsPlaying',
    isPlaying: true
  })
  // const totalSeconds = Bible.selectedChapters.reduce((total: number, book: any) => {
  //   return total + book.length_in_seconds
  // }, 0);
  Bible.selectedChapters.forEach((chapter: any) => {
    const sound: Howl = new Howl({
      src: [`${baseUrl}${chapter.path}`],
      preload: true,
      onend: () => {
        dispatch({
          type: 'updateSeconds',
          seconds: state.seconds - currentHowl.duration()
        })
        currentHowlIndex++;
        currentHowl = howls[currentHowlIndex];
        if (currentHowl) {
          currentHowl.play();
        } else {
          alert('End Of Walk!');
          dispatch({
            type: 'setIsPlaying',
            isPlaying: false
          })
        }
      }
    });
    howls.push(sound);
  });
  currentHowl = howls[currentHowlIndex];
  currentHowl.on('load', () => {
    dispatch({
      type: 'setShowLoading',
      showLoading: true,
    })
    currentHowl.play();
    currentInterval = setInterval(_ => {
      const displayTime = formattedTime(state.seconds - Math.round(currentHowl.seek() as number));
      dispatch({
        type: 'updateDisplayTime',
        displayTime: displayTime
      })
    }, 1000);
  })
}

const Player: React.FC<PlayerPageProps> = () => {
  const { state, dispatch } = useContext(AppContext);
  const { displayTime, Bible, showLoading, isPlaying } = state;
  (window as any).setShowLoading = showLoading.setShowLoading;
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Player</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow id="player-time" ion-justify-items-center>
            <IonCol>
              <IonText>
                <h1>{Bible.selectedChapters[currentHowlIndex].book}</h1>
                <h2>Chapter {Bible.selectedChapters[currentHowlIndex].chapter}</h2>
              </IonText>
              <IonText>
                <h1>{displayTime}</h1>
              </IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => dispatch({
            type: 'setShowLoading', 
            showLoading: false,
          })}
          message={'Loading...'}
          duration={5000}
        />
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonGrid>
            <IonRow id="player-actions">
              <IonCol>
                <IonButton>
                  <IonIcon icon={skipBackward}></IonIcon>
                </IonButton>
              </IonCol>
              <IonCol>
                <IonButton onClick={_ => {
                  if (isPlaying) {
                    howls[currentHowlIndex].pause();
                    clearTimeout(currentInterval);
                    dispatch({
                      type: 'setShowLoading', 
                      showLoading: false,
                    })
                  } else {
                    dispatch({
                      type: 'setShowLoading', 
                      showLoading: true,
                    })
                    playAudio(state, dispatch)
                  }
                }}>
                  <IonIcon icon={isPlaying ? pause : play}></IonIcon>
                </IonButton>
              </IonCol>
              <IonCol>
                <IonButton>
                  <IonIcon icon={skipForward}></IonIcon>
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default Player;