import React, { useContext, useCallback, useEffect } from 'react';
import { IonButtons, IonHeader, IonPage, IonToolbar, IonTitle, IonContent, IonBackButton, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle } from '@ionic/react';
import { AppContext } from '../../State';
// import { pin } from 'ionicons/icons';
// <IonIcon icon={pin} slot="start" />

const Bibles: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const { bibles } = state;

  const fetchBibles = useCallback(async () => {
    const ret = await fetch('http://dbt.io/library/volume?key=dfe98f65d8d22b8d8827d8ae9921ec71&media=audio&language_family_code=ENG&v=2');
    const json = await ret.json();
    dispatch({
      type: 'setBibles',
      bibles: json
    })
  }, [dispatch]);

  useEffect(() => {
    fetchBibles();
  }, [fetchBibles]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>Select Bible</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        { bibles.map((volume: any) => (
          <IonCard key={volume.dam_id} href={`/books/${volume.dam_id}`}>
            <IonCardHeader>
              <IonCardSubtitle>{volume.collection_name}</IonCardSubtitle>
              <IonCardTitle>{volume.version_name} ({volume.version_code})</IonCardTitle>
              <IonCardSubtitle>{volume.volume_name}</IonCardSubtitle>
            </IonCardHeader>
          </IonCard>
        )) }
      </IonContent>
    </IonPage>
  );
};

export default Bibles;